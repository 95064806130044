import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GC,
  GCFactory,
  QueryFilterInputType,
  QueryOptionsInputType,
  RankState,
  ServicePermission,
  StateManagementService,
} from '@clarilog/core';
import { CorePolicyValidator } from '@clarilog/core/services2/authorization/authorization-policy-builder.service';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import { GqlField } from '@clarilog/core/services2/graphql/generated-types/helpers';
import { CommonCoreService } from '@clarilog/core/services2/graphql/generated-types/services/common.service';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';
import {
  QueryBuilderCoreService,
  QueryBuilderType,
} from '@clarilog/core/services2/graphql/generated-types/services/query-builder.service';
import { UserCoreService } from '@clarilog/core/services2/graphql/generated-types/services/user.service';
import {
  LayoutInput,
  ScanConfigurationAppointmentInput,
  ValidationError,
} from '@clarilog/core/services2/graphql/generated-types/types';
import {
  CoreGraphQLDataSource,
  CoreGraphQLSource,
  GraphQLStore,
} from '@clarilog/core/services2/graphql/graphql-store.service';
import { Column, WorkItemConfiguration } from '@clarilog/shared2/models/schema';
import {
  CoreFeatureService,
  CoreModelCompilerService,
  ModelFieldCompilerService,
} from '@clarilog/shared2/services';
import {
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { exportDataGrid } from 'devextreme/excel_exporter';
import Calendar from 'devextreme/ui/calendar';
import { alert, confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import SelectBox from 'devextreme/ui/select_box';
import * as dot from 'dot-object';
import * as ExcelProper from 'exceljs';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import saveAs from 'file-saver';
import Globalize from 'globalize/dist/globalize';
import { EventArgs } from '../../nav-menu/nav-menu-item/nav-menu-item.component';
import { TemplatesRowService } from '../../templates-row/templates-row.service';
import { TemplatesService } from '../../templates/templates.service';
import { TranslatedFieldHelperService } from '../../translate-field/translate-field-helper-service';
import { CoreCustomOperationService } from './custom-operation.service';
import { ListComponentBase } from './list.component-base';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ValidationCallbackData } from 'devextreme/common';

export class ClickEventArgs<T, U> extends EventArgs<T> {
  value: U;
  data: U;
  ctrl: boolean;
}

export class AuditData {
  id: string;
  text: string;
}

export type ListMode = 'popup' | 'default';

const appointmentClassName = '.dx-scheduler-appointment';
const filterLookupExp = 'filterLookupExp';

/** Représente la classe du composent cl-list. */
@Component({
  selector: 'clc-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class CoreListComponent
  extends ListComponentBase
  implements OnInit, AfterContentChecked, OnDestroy, DoCheck
{
  window = window;
  /** Indique si le composant List est initialisé. */
  private _isInitialized: boolean = false;
  /** La source du gestionnaire de layout. */
  private _sourceLayoutManager;
  /** Identifiant de la vue personnalisée choisit par défaut. */
  public favoriteId: string;
  /** Les clés étendues. */
  private _expandedRowKeys: any[] = [];
  /** Valeur indiquant si la source de données est un tableau. */
  private _isDataSourceArray: boolean;
  /** Obtient le composent GridView ou TreeList. */
  private _component: any;
  /**Permet de sauvegarder le fromIndex */
  storeFromId: string;

  /** Clear Selection after filter change */
  @Input() clearSelectedAfterFilterChange: boolean = true;

  @Input() allowFilterPanel: boolean = true;
  @Input() allowSearchPanel: boolean = true;
  @Input() allowExportPanel: boolean = true;
  @Input() allowSelectColsPanel: boolean = true;

  /** Obtient ou définit une valuer indiquant si la mise à jour en live est activée. */
  @Input() isLiveUpdated: boolean;
  /** Obtient ou définit si l'on peut sélectionner dans la liste. */
  @Input() canSelect: boolean = true;
  /** Obtient ou définit le level maximum géré. */
  @Input() maxLevel: number = -1;
  /** Permet de déclencher le clic d'une ligne. */
  @Input() canExport: boolean = true;

  /**Obtient ou définit si la suppression est disponible */
  @Input() allowDelete: boolean = false;

  /**Indique si les champs sont modifiable directement dans la grille */
  @Input()
  allowUpdate: boolean =
    false; /** Obtient ou définit les actions de la liste. */

  @Output() onRowClick: EventEmitter<ClickEventArgs<CoreListComponent, any>> =
    new EventEmitter<ClickEventArgs<CoreListComponent, any>>();

  @Output() onSingleCellClick: EventEmitter<
    ClickEventArgs<CoreListComponent, any>
  > = new EventEmitter<ClickEventArgs<CoreListComponent, any>>();

  /** Se déclenche sur si la sélection change. */
  @Output() onSelectionKeyChanged: EventEmitter<any> = new EventEmitter<any>();

  /** Événement de rafraîchissement */
  @Output() onBeforeRefresh: EventEmitter<EventArgs<any>> = new EventEmitter<
    EventArgs<any>
  >();
  /** Évènement d'erreur sur la liste. */
  @Output() onError: EventEmitter<ValidationError[]> = new EventEmitter<
    ValidationError[]
  >();

  @Input() options: any;
  /** Obtient la valeur correspondant au layout GridView ou TreeList. */
  @Input() layoutValue: any[] = [];
  /** Obtient ou définit le mode. */
  @Input() mode: ListMode = 'default';

  /**Permet de connaitre le mode d'edition */
  @Input() editMode: String = 'cell';

  /**Transmets le filtre utiliser pour les vues personnalisées au work-items  */
  @Output() onDisplayFilter: EventEmitter<{
    hasCumulateFilter: boolean;
    value: Array<any>;
    lastFilterName: string;
    rawFilterValue: Array<{
      name: string;
      value: Array<any>;
    }>;
  }> = new EventEmitter<{
    hasCumulateFilter: boolean;
    value: Array<any>;
    lastFilterName: string;
    rawFilterValue: Array<{
      name: string;
      value: Array<any>;
    }>;
  }>();

  /** PErmet de choisir si le mode de sélection tout se fait par le composant ou par notre controle */
  @Input() useDevExtremSelectAllTree = false;

  /** Donne l'accès aux actions de sélection/désélection du noeud parents */
  @Input() activeSelectLevelAction: boolean;

  multipleMode: boolean;
  /** Obtient la source du gestionnaire de layout. */
  get sourceLayoutManager() {
    return this._sourceLayoutManager;
  }
  /** Obtient une valeur indiquant si le composant List est initialisé.  */
  get isInitialized(): boolean {
    return this._isInitialized;
  }
  /** Obtient le composent TreeList ou DataGrid. */
  get component() {
    return this._component;
  }
  /** Permet de connaître si le dataSource est un tableau static. */
  get isDataSourceArray(): boolean {
    return this._isDataSourceArray;
  }
  /** Obtient les clés étendues. */
  get expandedRowKeys(): any[] {
    return this._expandedRowKeys;
  }
  /** Obtient ou définit le delais de refresh du datasource. */
  @Input() delayAutoRefresh: number = undefined;
  /** Obtient ou définit le nom du fichier d'export */
  @Input() fileName: string;
  /** Obtient ou définit le nom du fichier d'export */
  @Input() exportFileName: string;
  /** Obtient ou définit le modele state */
  @Input() modelState: ModelState;

  public checkPermissionToRead: boolean = true;

  /** Définis la taille du paging choisi par l'utilisateur */
  addedScheduler = false;
  headerFilterLookup;
  popupVisible: boolean = false;

  appointmentSelected: any;

  schedulerAuditsColumns: Column[];

  disabledList: boolean = false;

  /** Gére les différents datasource des masters detail */
  masterDataSourceStorage: any = [];

  /** Obtient ou définit si un tenant est présent */
  isTenant: boolean = false;

  /** Pour le detect change du dataSource. */

  internalSource: DataSource | CoreGraphQLDataSource;

  schedulerSource: CustomStore;

  /** Indique si un appoiment a été cliqué */
  isAppointmentClick: boolean = false;

  /** Obtient ou définit la liste des type du temps. */
  enumTimeType: any;

  /** Obtient l'etat d'origine de la grille */
  originalState: any;

  /** Obtient ou définit la liste des pérriode par defaut. */
  enumDefaultTime: any;

  /** Obtient ou définit la operation personalisé. */
  customOperations: Array<any>;

  /** Obtient ou définit si un element est visible ou non. */

  isVisible: boolean = false;
  private btnCollapseInstance: any = null;
  private btnExpandInstance: any = null;

  expanded = true;

  target: string = appointmentClassName;
  appointmentContextMenuItemsScan: any[];
  appointmentContextMenuItemsTicketIncident: any[];
  appointmentContextMenuItemsTicketRequest: any[];
  dataSourceMenu: any[] = [];
  onContextMenuItemClick: any;

  auditSource: AuditData[] = [];

  private checkLoad: boolean = false;

  treelistAllSelectKey = 0;

  /** Contient les traduction des colonnes de type propriété d'entity */
  columnsByType: any[] = [];

  /** Gestion du select treelist */
  checkAllComponent: any;
  onSelectChangeAction: Boolean = false;

  /** Obtient ou définit si le default layout a été chargé */
  alreadyLoadDefaultLayout = false;

  /** Défini si la sélection est automatique clear au refresh */
  @Input() clearSelectionRefresh = true;

  /** Obtient ou définit si le default layout a été chargé */
  withoutHref = false;

  /** Définit l'injector */
  injector: Injector;

  /** Obtient l'index de la cellule active */
  cell: any;

  private _gc: GC;
  timer: NodeJS.Timeout;
  observer;

  /** Clic to up */
  showTop: boolean = false;
  /** Total d'élement */
  totalCount: number = -1;
  /** Obtient ou définit le delais de refresh du datasource. */
  @Input() showTotalCount: boolean = true;
  inProgressCount: boolean = false;
  countTimer;

  /** Obtient ou définit la recherche */
  atlasSearchValue: string;
  debouncer: Subject<string> = new Subject<string>();
  disabledAtlas: boolean = false;
  textBoxAtlas: any;
  atlasOptions: any;
  showAtlasIndex: boolean = false;
  selectedAtlasIndex: string[];
  atlasButtonIndexes: any;

  showFilterPopupSelect = false;
  filterPopupDataSource: ModelState;
  filterPopupColumn: any;
  filterPopupSelectedKeys: [];
  filterPopupSelectedData: any[] = [];
  clearButtonFilterPopup = false;
  useOldLayout = false;
  showClearPopupFilterButton = false;
  features: any;

  /** Obtient ou définit si la source a changé */
  @Input() pageSize: number = undefined;

  /** Obtient ou définit si la source a changé */
  @Input() sourceChanged: boolean = true;

  /** Obtient ou définit le mode compact */
  @Input() compactMode: boolean = false;

  /** Obtient ou définit si la checkbox auto-refresh est affichée ou non */
  @Input() displayAutoRefreshCheckbox: boolean = true;

  /** Si à false => Pause l'auto-refresh d'une liste pendant un certain moment (jusqu'au refresh de la page) */
  @Input() enableForceAutoRefresh: boolean = true;

  /** Obtient ou définit les items custom à ajouter sur la toolbar */
  @Input() toolbarItems?: any[];

  @Output() onClearFilter: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public templateService: TemplatesService,
    public templateRowService: TemplatesRowService,
    private layoutManager: UserCoreService,
    private authorizationService: AuthorizationCoreService,
    public router: ActivatedRoute,
    public route: Router,
    public customOperationService: CoreCustomOperationService,
    private commonCoreService: CommonCoreService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _stateService: StateManagementService,
    private queryBuilderCoreService: QueryBuilderCoreService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private localStorageService: LocalStorageService,
    private servicePermission: ServicePermission,
    injector: Injector,
    private policyValidator: CorePolicyValidator,
    gcFactory: GCFactory,
    private zone: NgZone,
    private el: ElementRef,
    private modelCompilerService: CoreModelCompilerService,
    public featureService: CoreFeatureService,
  ) {
    super();
    this._gc = gcFactory.create();
    this.loadStoring = this.loadStoring.bind(this);
    this.saveStoring = this.saveStoring.bind(this);
    this.columns = [
      {
        label: TranslateService.get('entities/ticket/researchIn'),
        field: 'value',
      },
    ];

    // Option d'affichage du popup
    this.atlasOptions = {
      icon: 'menu',
      stylingMode: 'text',
      onClick: () => {
        // show popup
        this.showAtlasIndex = true;
      },
      onInitialized: (e) => {
        this.atlasButtonIndexes = e.element;
      },
    };

    this.debouncer.pipe(debounceTime(600)).subscribe(() => {
      this.onAtlasSearch();
    });

    this.injector = injector;
    this.schedulerAuditsColumns = [
      {
        label: TranslateService.get('entities/alarm/name'),
        field: 'name',
      },
    ];

    this.appointmentContextMenuItemsScan = [
      {
        text: TranslateService.get('entities/scanByHost/defined'),
        onItemClick: this.linkAudits.bind(this),
      },
    ];

    this.appointmentContextMenuItemsTicketIncident = [
      {
        text: TranslateService.get('entities/incident/open'),
        onItemClick: this.goIncident.bind(this),
      },
    ];
    this.appointmentContextMenuItemsTicketRequest = [
      {
        text: TranslateService.get('entities/request/open'),
        onItemClick: this.goRequest.bind(this),
      },
    ];

    // Bind de DevExtreme
    this.onFavoriteClick = this.onFavoriteClick.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragChange = this.onDragChange.bind(this);
    this.onReorder = this.onReorder.bind(this);
    this.onUpdateLayoutClick = this.onUpdateLayoutClick.bind(this);
    this.onPopupShowing = this.onPopupShowing.bind(this);
    this.onClearLayoutClick = this.onClearLayoutClick.bind(this);
    this.customValidator = this.customValidator.bind(this);

    this.customOperations = [
      this.customOperationService.customDateOperation,
      this.customOperationService.customDeadlineDateOperation,
      this.customOperationService.customDefaultDateOperation,
      this.customOperationService.customBetweenOperation,
    ];

    this.customOperations[0].calculateFilterExpression = (
      filterValue: any,
      field: any,
    ) => {
      let value = filterValue[0][0];
      let type = filterValue[0][1];

      let date = this.customOperationService.addDate(type, -value);
      let today = new Date();
      // Problème #9630
      today.setUTCMilliseconds(0);
      today.setUTCSeconds(0);

      if (date != null && value > 0) {
        return [[field.dataField, '<', date]];
      }

      if (date != null && value == 0) {
        return [[field.dataField, '>', date]];
      }
    };

    this.customOperations[1].calculateFilterExpression = (
      filterValue: any,
      field: any,
    ) => {
      let value = filterValue[0][0];
      let type = filterValue[0][1];

      let date = this.customOperationService.addDate(type, value);

      let today = new Date();
      // Problème #9630
      today.setUTCMilliseconds(0);
      today.setUTCSeconds(0);
      if (date != null && value > 0) {
        return [
          [field.dataField, '<', date],
          'and',
          [field.dataField, '>', today],
        ];
      }

      if (date != null && value == 0) {
        return [[field.dataField, '>', date]];
      }
    };

    this.customOperations[2].calculateFilterExpression = (
      filterValue: any,
      field: any,
    ) => {
      let de = filterValue[0][0];
      let a = filterValue[0][1];
      let type = filterValue[0][2];

      let startValue = this.customOperationService.addDate(type, de);
      let endValue = this.customOperationService.addDate(type, a);

      if (startValue != null && endValue != null) {
        return [
          [field.dataField, '>', startValue],
          'and',
          [field.dataField, '<', endValue],
        ];
      }
    };

    this.customOperations[3].calculateFilterExpression = (
      filterValue: any,
      field: any,
    ) => {
      let de = filterValue[0][0];
      let a = filterValue[0][1];
      let type = filterValue[0][2];

      let startValue = this.customOperationService.addDate(
        type,
        de,
        true,
        true,
      );
      let endValue = this.customOperationService.addDate(type, a, true);

      if (startValue != null && endValue != null) {
        return [
          [field.dataField, '>', startValue],
          'and',
          [field.dataField, '<', endValue],
        ];
      }
    };

    this.enumTimeType = this.customOperationService.getEnumTimeType();

    this.enumDefaultTime = this.customOperationService.getEnumDefaultTime();

    this.observer = new ResizeObserver((entries) => {
      this.zone.run(() => {
        if (this.component != undefined) {
          if (this.type == 'Scheduler') {
            // force le repaint
            this.component.repaint();
          }
        }
      });
    });

    this.observer.observe(this.el.nativeElement);
  }

  /** Force le raffraichissement automatique */
  autoRefresh(delay: number) {
    if (this.enableForceAutoRefresh === true) {
      this.timer = setTimeout(() => {
        this.refresh();
        this.autoRefresh(delay);
      }, delay);
    }
  }

  ngOnDestroy(): void {
    this._gc.dispose();

    if (this.countTimer != undefined) {
      clearTimeout(this.timer);
    }

    if (this.observer != undefined) {
      this.observer.unobserve(this.el.nativeElement);
    }

    if (this.timer != undefined) {
      clearTimeout(this.timer);
    }

    if (this.component != undefined && this.component.state != undefined) {
      this._stateService.setState(
        'cl-list|' + this.fileName,
        this.component.state(),
      );
    }
    if (this.isLiveUpdated && this.source?.datasource != undefined) {
      if (
        (this.source.datasource.store() as GraphQLStore).context != undefined
      ) {
        (
          this.source.datasource.store() as GraphQLStore
        ).context.context.clearTimeout();
      }
    }
  }

  linkAudits(e) {
    this.selectedKeys = e.appointmentData.scanConfigurationIds;
    this.popupVisible = true;
    this.appointmentSelected = e.appointmentData;
  }

  goIncident(e) {
    this.onRowClick.emit({
      value: e.appointmentData.loanId,
      data: e.appointmentData,
      ctrl: e.event.ctrlKey,
      component: this,
      event: e.event,
    });
  }

  goRequest(e) {
    this.onRowClick.emit({
      value: e.appointmentData.loanId,
      data: e.appointmentData,
      ctrl: e.event.ctrlKey,
      component: this,
      event: e.event,
    });
  }

  async loadState() {
    // Load layout
    if (
      !this.alreadyLoadDefaultLayout &&
      this.layoutKey != undefined &&
      this.layoutKey != ''
    ) {
      this.alreadyLoadDefaultLayout = true;
      let storage = await this.localStorageService.layoutStorage
        .getDefault(this.layoutKey)
        .toPromise();

      if (storage != undefined) {
        this.favoriteId = storage.id;
        this.layoutValue[0] = storage.id;
      }
    }
  }

  onAppointmentContextMenu(e) {
    this.target = appointmentClassName;
    if (e.appointmentData.__typename != 'TicketAppointment') {
      this.dataSourceMenu = this.appointmentContextMenuItemsScan;
    }
    this.onContextMenuItemClick = this.onItemClick(e);
  }

  deleteAppointement(e, model) {
    confirm(
      TranslateService.get('confirm-permanent-delete', { count: 1 }),
      TranslateService.get('confirm-title'),
    ).then((f) => {
      if (f) {
        this.component.deleteAppointment(model.appointmentData);
      }
    });

    e.event.stopPropagation();
    e.event.preventDefault();
  }

  onAppointmentDeleting(e) {
    // e.cancel = true;
    // let id = e?.appointmentData?.id;
    // if (id != undefined && this.removeItems != undefined) {
    //   this.deleteAppointement(e, e.appointmentData);
    // }
  }

  onItemClick(contextMenuEvent) {
    return function (e) {
      e.itemData.onItemClick(contextMenuEvent, e);
    };
  }

  loadStoring = () => {
    return this._stateService.getState('cl-list|' + this.fileName);
  };
  saveStoring = (state) => {
    this._stateService.setState('cl-list|' + this.fileName, state);
  };

  ngAfterContentChecked() {
    //Permet de masquer le choix des colonnes
    if (
      this.component != undefined &&
      this.component.element != undefined &&
      this.component.hideColumnChooser != undefined
    ) {
      var element = this.component.element();
      var isVisible =
        element.offsetLeft +
        element.offsetTop +
        element.offsetHeight +
        element.offsetWidth;
      var getBoundingClientRect =
        element.getBoundingClientRect != undefined
          ? element.getBoundingClientRect()
          : undefined;
      if (
        element.offsetParent == null ||
        isVisible == 0 ||
        (getBoundingClientRect != undefined &&
          getBoundingClientRect.top < 0 &&
          getBoundingClientRect.y < 0)
      ) {
        this.component.hideColumnChooser();
      }
    }
    // Dans le ngInit le search s'applique sur un datasource vide
    if (
      this.source != undefined &&
      this.source.datasource !== undefined &&
      this.router.snapshot.queryParams.searchFilter != undefined
    ) {
      this.source.datasource.searchValue(
        this.router.snapshot.queryParams.searchFilter,
      );

      let currentParams = {
        ...this.router.snapshot.queryParams,
      };
      delete currentParams['searchFilter'];
      this.router.snapshot.queryParams = currentParams;
    }
  }

  userId: string;

  fetaureList: QueryBuilderType[];

  /** @inheritdoc */
  async ngOnInit(): Promise<void> {
    if (this.toolbarItems != undefined) {
      this.featureService.getAllFeature().then((response) => {
        this.features = response;
        this._component.repaint();
      });
    }

    // SEt du datsource
    if (this.compactMode === true) {
      this._isDataSourceArray = Array.isArray(this.source.datasource);
      this.internalSource = this.source.datasource;
    }

    // Set le datasource
    this.createSourceScheduler();

    if (this.hasAtlasSearch()) {
      this.selectedAtlasIndex = this.atlasIndexes.map((s) => {
        return s.field;
      });
    }

    if (this.delayAutoRefresh != undefined && this.delayAutoRefresh > 1000) {
      // Refresh auto uniquement si definit est supérieur a 1s
      this.autoRefresh(this.delayAutoRefresh);
    }

    this.isTenant = this.authorizationService.user.hasClaim('tenantId');
    this.userId = this.authorizationService.user.getClaim('userId');

    this.fetaureList = await this.queryBuilderCoreService.findTypes(false);

    this.multipleMode = this.multiple || false;
    if (
      this.source != undefined &&
      this.source.datasource !== undefined &&
      this.source.datasource instanceof CoreGraphQLDataSource &&
      (this.source.datasource.store() as GraphQLStore).context !== undefined &&
      this.isLiveUpdated != undefined
    ) {
      (this.source.datasource.store() as GraphQLStore).context.context.timeout(
        this.isLiveUpdated,
      );
    }

    if (this.type == 'Tree') {
      // TODO Le temps au composent de s'initialiser.
      setTimeout(() => {
        // Permet d'ouvrir automatiquement les noeuds.
        if (this.router.component != undefined) {
          let expandedKeys = localStorage.getItem(
            'expandedRowKeys' + (this.router.component as any).name,
          );
          if (expandedKeys != undefined) {
            this._expandedRowKeys = JSON.parse(expandedKeys);
          }
        }
      });
    }

    this.reloadLayout();

    // Vérification du context
    if (this.state?.sharedContext?.entry?.get('entityType') != undefined) {
      // Chargement des données
      let entityType = this.state?.sharedContext?.entry?.get('entityType');
      let getFields = await this.commonCoreService
        .allFields(
          this.commonCoreService.gqlField(),
          entityType.charAt(0).toUpperCase() + entityType.substring(1),
        )
        .toPromise();

      this.columnsByType = await this.commonCoreService.createFields(
        entityType,
        getFields,
        false,
        true,
        undefined,
        0,
        undefined,
      );
    }
    if (this.type === 'Scheduler') {
      // TODO check
      let service = this.injector.get(this.options.source.serviceName);
      let policy = this.servicePermission.getAuthorizations(service);
      this.options.allowAdding = this.policyValidator.validate(
        policy + '.update',
      );
      this.options.allowDeleting = this.policyValidator.validate(
        policy + '.delete',
      );
    }
  }

  reloadLayout(forceKey: string = undefined) {
    if (forceKey != undefined) {
      this.layoutKey = forceKey;
    }

    if (
      !(
        (this.type === 'Grid' || this.type === undefined) &&
        this.isTenant != undefined &&
        this.isTenant &&
        this.layoutKey != undefined
      )
    ) {
      return;
    }

    this._sourceLayoutManager = CoreGraphQLSource.create({
      query: (
        filters?: QueryFilterInputType[],
        options?: QueryOptionsInputType,
      ) => {
        let query = this.localStorageService.layoutStorage.find(this.layoutKey);
        return query;
      },
      insert: (entry) => {
        entry = this.createLayoutEntry(entry);

        localStorage.removeItem('filterName');
        this.localStorageService.layoutStorage.reset();
        let insertLayout = this.layoutManager.addLayout(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          this.userId,
          entry,
        );
        insertLayout.subscribe((response) => {
          if (response?.data == false) {
            notify(TranslateService.get('errors/unique'), 'error', 5000);
          }
        });
        return insertLayout;
      },
      delete: (id: string) => {
        this.localStorageService.layoutStorage.reset();
        let deleteAction = this.layoutManager.deleteLayout(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          this.userId,
          [id],
        );
        return deleteAction;
      },
      update: (id, entry) => {
        this.localStorageService.layoutStorage.reset();
        entry = this.createLayoutEntry(entry);
        let updateLayout = this.layoutManager.updateLayout(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          this.userId,
          id,
          entry,
        );

        updateLayout.subscribe((response) => {
          if (response?.data == false) {
            notify(TranslateService.get('errors/unique'), 'error', 5000);
          }
        });
        return updateLayout;
      },
    });
  }

  /** Obtient la valeur de traduction */
  getPropertyNameTemplate(propertyName: string) {
    if (
      this.columnsByType != undefined &&
      this.columnsByType.length > 0 &&
      propertyName != undefined
    ) {
      let col = this.columnsByType.filter((f) => f.dataField == propertyName);
      if (col != undefined && col.length > 0) {
        return col[0].caption;
      }
    }
    return propertyName;
  }

  private createLayoutEntry(entry: any) {
    entry.key = this.layoutKey;

    entry.value = JSON.stringify(this.component.state());
    let filterExist = this.component.filter();
    if (filterExist === undefined) {
      filterExist = null;
    }
    entry.filterValue = JSON.stringify(filterExist);
    entry.filterName = localStorage.getItem('filterName');
    let filterCumulatif = sessionStorage.getItem('keepfilter');

    if (filterCumulatif != undefined) {
      entry.rawFilterValue = filterCumulatif;
    }
    return entry;
  }
  ngDoCheck() {
    if (!this.compactMode) {
      // ATTENTION
      // Utilise pour le changement d'onglet dans la link list
      // Dans le changement des grille resultat d'alarme
      // Changement ROle / account
      // Filter

      if (
        this.component != undefined &&
        this.source != undefined &&
        this.internalSource != this.source.datasource
      ) {
        this._isDataSourceArray = Array.isArray(this.source.datasource);

        this.internalSource = this.source.datasource;
      }
    }
    this._changeDetectorRef.detectChanges();
  }

  async ngAfterViewInit() {}

  /** Efface la séléction. */
  async clearSelection() {
    if (this.component != undefined) {
      await this.component.clearSelection();
    }
  }
  /** Efface le filtre. */
  async clearFilter() {
    if (this.component != undefined) {
      await this.component.clearFilter();
    }
  }

  /** Efface tout. */
  async clear() {
    await this.clearFilter();
    await this.clearSelection();
  }

  /** Collapse all masterDetail */
  collapseAllMasterDetail() {
    if (
      this.masterDetail != undefined &&
      (this.masterDetail?.openMode == undefined ||
        this.masterDetail?.openMode == 'onClick')
    ) {
      this.component.collapseAll(-1);
    }
  }

  /** Rafraîchi la list (Pour l'instant uniquement la grille. */
  async refresh(e = undefined, clearSelection = true) {
    this.totalCount = -1;
    // Empty the source storage
    this.masterDataSourceStorage = [];
    this.collapseAllMasterDetail();

    this.checkLoad = false;
    this.onBeforeRefresh.emit(e);
    if (this.internalSource != undefined) {
      let source = <DataSource>this.internalSource;
      if (source != undefined && source.reload != undefined) {
        // if (
        //   this.type == 'Grid' &&
        //   this.component != undefined &&
        //   !this.compactMode
        // ) {
        //   source.filter(this.component.getCombinedFilter());
        // }
        source.reload().then(() => {
          if (this.type == 'Grid' && this.component != undefined) {
            //- Ne pas conserver la selection
            if (this.clearSelectionRefresh) {
              this.clearSelection();
            }
            this.component.repaint();
          }

          if (this.type == 'Scheduler' && this.component != undefined) {
            this.schedulerSource.load();
            this.component.repaint();
          }
          if (this.type == 'Tree' && this.component != undefined) {
            //- Ne pas conserver la selection
            if (this.clearSelectionRefresh) {
              this.clearSelection();
            }
            this.component.refresh(false);
          }

          // Gestion recherche Atlas
          if (this.disabledAtlas && this.textBoxAtlas != undefined) {
            this.textBoxAtlas.focus();
            let input = this.textBoxAtlas
              .element()
              .getElementsByTagName('input');
            if (input != undefined && input.length > 0) {
              input[0].setSelectionRange(99999999, 99999999);
            }
          }
          this.disabledAtlas = false;
        });
      }
    }
  }

  onAppointmentFormOpening(e) {
    if (
      e.appointmentData.__typename == 'TicketAppointment' ||
      this.route.url.includes('incidents') ||
      this.route.url.includes('requests')
    ) {
      e.cancel = true;
    } else {
      let form = e.form;

      let formItems = form.option('items');
      let auditItemExists = formItems[0].items.filter(
        (x) => x.dataField === 'scanConfigurationIds',
      );

      if (this.sourceRessource != undefined && auditItemExists.length == 0) {
        formItems[0].items.push({
          dataField: 'scanConfigurationIds',
          label: {
            text: TranslateService.get(
              'entities/scanConfiguration/_title/plural',
            ),
          },
          editorType: 'dxTagBox',
          editorOptions: {
            dataSource: this.sourceRessource.datasource,
            valueExpr: 'id',
            fieldExpr: 'scanConfigurationIds',
            displayExpr: 'name',
            searchMode: 'contains',
            minSearchLength: 0,
            searchEnabled: true,
            showSelectionControls: true,
            multiline: true,
          },
        });
      }
      formItems[0].items.forEach((item) => {
        if (
          item.dataField == 'scanConfigurationIds' ||
          item.dataField == 'text'
        ) {
          item.validationRules = [
            {
              type: 'required',
              message: TranslateService.get(`errors/required`),
            },
          ];
        }

        let removeRecurrenceItems =
          this.state?.model?.grid?.layout?.options?.removeRecurrenceItem;

        form.option('onFieldDataChanged', function (args) {
          if (args.dataField === 'repeat' && args.value) {
            if (removeRecurrenceItems !== undefined) {
              setTimeout(() => {
                let repeatAppointment = form
                  .getEditor('repeat')
                  .option('value');
                if (repeatAppointment) {
                  let selectDOM = form
                    .element()
                    .querySelector(
                      '.dx-selectbox.dx-recurrence-selectbox-freq',
                    );

                  let selectBox = SelectBox.getInstance(selectDOM);
                  let selectItems: any = selectBox.option('items');

                  if (selectItems.length === 5) {
                    selectItems = selectItems.filter(
                      (item) => !removeRecurrenceItems.includes(item.value),
                    );
                    selectBox.option('items', selectItems);
                  }
                }
              });
            }
          }
        });
      });

      form.option({ items: formItems });
    }
  }

  /** Action au changement de la liste. */
  public async onLayoutValueChanged(e) {
    if (e.value != undefined) {
      let item = await this.localStorageService.layoutStorage.findById(e.value);

      if (window.history?.state?.filters === undefined) {
        let customViewFilter = JSON.parse(item.filterValue);
        let lastCustomViewFilterName = item.filterName;
        let customViewFilterRawValue = JSON.parse(
          item.rawFilterValue,
        ) as Array<{
          name: string;
          value: Array<any>;
        }>;

        if (
          this.state?.sharedContext?.params?._params?.filteredAssetByRelation ==
          undefined
        ) {
          this.onDisplayFilter.emit({
            hasCumulateFilter:
              customViewFilterRawValue?.length > 1 ? true : false,
            value: customViewFilter,
            lastFilterName: lastCustomViewFilterName,
            rawFilterValue: customViewFilterRawValue,
          });
        }
      }
      if (item != undefined) {
        // Récupère l'état du composant.
        let layoutParse = JSON.parse(item.value);
        if (layoutParse != undefined) {
          layoutParse.selectedRowKeys = undefined;

          this.applyState(layoutParse);
          e.component.close();
        } else {
          this.applyState(undefined);
        }
      }
    } else {
      this.applyState(this.originalState);
    }
  }

  applyState(layoutParse) {
    if (layoutParse != undefined) {
      layoutParse = this.checkLayout(layoutParse);
    }
    this._component.state(layoutParse);
  }

  /** Vérifie les filtres et colonnes si présentent et si traduction */
  checkLayout(layoutParse) {
    try {
      //lève une exception si undefined
      if (layoutParse == undefined) throw new Error('Layout is empty');

      if (this.type != 'Grid') return;
      // check des colonnes du layout par rapport au col disponible a la creation
      if (layoutParse?.columns != undefined) {
        let checkCols = [];
        let languages =
          this.translatedFieldHelperService.getLanguageDatasource();

        let filter = layoutParse.filterValue;
        if (filter != undefined) {
          filter = JSON.stringify(filter);
        }
        let originalCol = this._component.instance().option('columns');
        originalCol.forEach((f) => {
          let findCols = layoutParse.columns.filter(
            (s) => f.dataField == s.dataField || f.name == s.name,
          );
          if (findCols == undefined || findCols.length == 0) {
            // Vérification si translate
            for (let lang of languages) {
              if (lang?.key != undefined) {
                findCols = layoutParse.columns.filter(
                  (s) => s.dataField + '.' + lang.key == f.dataField,
                );
                if (findCols != undefined && findCols.length > 0) {
                  if (filter != undefined) {
                    var re = new RegExp('"' + findCols[0].dataField + '"', 'g');
                    filter = filter.replace(re, '"' + f.dataField + '"');

                    var re = new RegExp('"' + findCols[0].name + '"', 'g');
                    filter = filter.replace(re, '"' + f.name + '"');
                  }
                  break;
                }
              }
            }
          }

          if (findCols != undefined && findCols.length > 0) {
            findCols[0].dataField = f.dataField;
            findCols[0].name = f.name;

            checkCols.push(findCols[0]);
          } else {
            // Remove la colonne
            // ATTENTION SI FILTER PASSE SUR LA COLONNE ???
            this._component.instance().deleteColumn(f.dataField);
          }
        });
        layoutParse.columns = checkCols;
        if (filter != undefined) {
          layoutParse.filterValue = JSON.parse(filter);
        }
      }

      //ne conserve que les données utiles
      for (let name in layoutParse) {
        switch (name) {
          case 'filterValue':
          case 'filterValues':
          case 'columns':
            break;
          default:
            delete layoutParse[name];
        }
      }

      return layoutParse;
    } catch (ex) {
      console.log(ex);
      if (layoutParse != undefined) {
        console.log(layoutParse);
      }

      notify(
        'Erreur lors du chargement de la mise en forme de la grille.',
        'error',
        5000,
      );
    }
  }

  replaceField(e) {
    return e.field.replace(/\.data\./gi, '.');
  }

  /** Tente de comptabiliser les élements de la grille */
  getTotalCount() {
    if (this.showTotalCount === true) {
      this.countTimer = setTimeout(() => {
        if (this.component != undefined) {
          let array = this.component.option('dataSource')?.store()?._array;
          if (array?.length != undefined) {
            this.totalCount = array.length;
          } else {
            let total = this.component.totalCount();
            if (total == -1) {
              total = this.component.getVisibleRows().length;
            }
            let pageSize = this.component.pageSize();
            if (pageSize != undefined && total != undefined) {
              if (total >= 0 && total < pageSize - 1) {
                this.totalCount = total;
              } else {
                this.totalCount = -1;
              }
            }
          }
        }
      }, 300);
    }
  }

  /** Changement d'option sur le dataGrid*/
  public onOptionChanged(e) {
    if (this.type == 'Grid') {
      if (e.name === 'dataSource' || e.name.indexOf('filter') != -1) {
        this.totalCount = -1;
      }
    }

    if (
      e.fullName == 'filterValue' &&
      this.clearSelectedAfterFilterChange == true
    ) {
      if (this.selectedKeys != null) {
        this._component.clearSelection();
      }
    }
  }
  /** Permet de définir une vue personnalisée par défaut depuis la liste. */
  public onFavoriteClick(e) {
    this.favoriteId = this.favoriteId === e.data.id ? null : e.data.id;

    // TODO En attendant les push

    if (e.data['id'] != undefined) {
      let layoutId = e.data['id'];

      let layout: LayoutInput = {
        key: e.data.key,
        name: e.data.name,
        value: e.data.value,
        rawFilterValue: e.data.rawFilterValue,
      };

      this.layoutManager
        .setDefaultLayout(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          this.userId,
          layoutId,
          layout,
        )
        .subscribe(async (s) => {
          await this.localStorageService.layoutStorage.reset();
        });
    }
  }
  /** Permet de mettre à jour la vue personnalisée sélectionnée depuis la liste. */
  onUpdateLayoutClick() {
    let items = this.sourceLayoutManager.items();
    let item = {
      ...items.filter((i) => i.id === this.layoutValue[0])[0],
    };
    delete item['__typename'];
    delete item['id'];

    item.value = JSON.stringify(this.component.state());
    item.filterName = localStorage.getItem('filterName');
    item.filterValue = JSON.stringify(this.component.filter());
    let filterCumulatif = sessionStorage.getItem('keepfilter');
    if (filterCumulatif != undefined) {
      item.rawFilterValue = filterCumulatif;
    }

    let userId = this.authorizationService.user.getClaim('userId');
    this.layoutManager
      .updateLayout(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
        userId,
        this.layoutValue[0],
        item,
      )
      .subscribe((_) => {
        notify(TranslateService.get('saveSuccess'), 'success', 2000);
        this.localStorageService.layoutStorage.reset();
        this.localStorageService.layoutStorage.refresh();
        this.sourceLayoutManager.reload();
      });
  }

  /** Permet de mettre à jour la vue par defaut. */
  onClearLayoutClick() {
    this.layoutValue = [];
    this.onClearFilter.emit();
  }

  onAppointmentClick(e) {
    e.cancel = true;
    this.onRowClick.emit({
      value: e.appointmentData.loanId,
      data: e.appointmentData,
      ctrl: e.event.ctrlKey,
      component: this,
      event: e.event,
    });
  }

  appointmentTooltipComponent(e) {
    // debugger;
  }

  createSourceScheduler() {
    if (this.checkLoad == false && this.type == 'Scheduler') {
      this.schedulerSource = new CustomStore({
        load: () => {
          let source = new DataSource(this.source.datasource.store());
          source.filter(this.source.datasource.filter());
          this.checkLoad = true;
          return source.load().then((r) => {
            return r;
          });
        },
        remove: (key: any) => {
          if (key != undefined && this.removeItems != undefined) {
            this.removeItems.context.params.set('ids', () => [key.id]);
            this.removeItems.context.params.set('fields', () =>
              ModelFieldCompilerService.createServiceSingleResultScalar(),
            );
            this.removeItems.fnCall().subscribe((result) => {
              this.refresh();
              if (result?.errors != undefined && result.errors.length > 0) {
                // Erreur
                notify(result.errors[0].message, 'error', 5000);
              } else {
                notify(TranslateService.get('deletedSuccess'), 'success', 5000);
              }
            });
          }
          return null;
        },
        insert: (values: any) => {
          if (this.insertRessource != undefined) {
            let appoint: ScanConfigurationAppointmentInput = {
              allDay: values.allDay,
              description: values.description,
              endDate: values.endDate,
              recurrenceRule: values.recurrenceRule,
              startDate: values.startDate,
              text: values.text,
              visible: true,
              disabled: false,
              scanConfigurationIds: values.scanConfigurationIds,
              offSet: null,
            };
            this.insertRessource.context.params.set(
              'scanConfigurationAppointment',
              () => appoint,
            );

            this.insertRessource.fnCall().subscribe((result) => {
              this.insertRessource.context.params.remove(
                'scanConfigurationAppointment',
              );
              if (!result.data) {
                if (result.errors != undefined) {
                  this.onError.emit(result.errors);
                }
                // Erreur
                notify(
                  "Une erreur s'est produite durant l'enregistrement.",
                  'error',
                  5000,
                );
              } else {
                this.refresh();
              }
            });
          }

          return null;
        },
        update: (key, values) => {
          if (this.updateRessource != undefined) {
            let appoint: ScanConfigurationAppointmentInput = {
              allDay: values.allDay,
              description: values.description,
              endDate: values.endDate,
              recurrenceRule: values.recurrenceRule,
              startDate: values.startDate,
              text: values.text,
              visible: true,
              disabled: false,
              scanConfigurationIds: values.scanConfigurationIds,
              offSet: null,
            };

            this.updateRessource.context.params.set('id', () => values.id);
            this.updateRessource.context.params.set('modified', () => appoint);

            this.updateRessource.fnCall().subscribe((result) => {
              this.updateRessource.context.params.remove('id');
              this.updateRessource.context.params.remove('modified');
              if (!result.data) {
                if (result.errors != undefined) {
                  this.onError.emit(result.errors);
                }
                // Erreur
                notify(
                  "Une erreur s'est produite durant l'enregistrement.",
                  'error',
                  5000,
                );
              } else {
                this.refresh();
              }
            });
          }

          return null;
        },
      });
    }
  }

  /** Lorsque le composant est initialisé. */
  async onInitialized(e) {
    this._component = e.component;

    if (this.type == 'Grid') {
      let clientHeight = e.element.clientHeight;
      if (clientHeight != undefined) {
        // Calcul auto du nombre de ligne
        // 800px = 40 élement
        let numberOfRow = (clientHeight * 40) / 800;
        numberOfRow = 10 * Math.floor(numberOfRow / 10);
        if (numberOfRow <= 100 && numberOfRow >= 10) {
          this.pageSize = numberOfRow;
        } else if (numberOfRow > 100) {
          this.pageSize = 50;
        } else {
          // Valeur par défaut
          this.pageSize = 20;
        }
      }

      this.originalState = e.component.state();

      // Trie du filter builder
      let newColumns = this.component.option('columns');

      // Application du filterBuilder
      if (newColumns != undefined) {
        // Vérification AtlasSearch, autorise le filter uniquement depuis le builder
        // Retro compatibilité pour les layouts

        newColumns.forEach((col) => {
          // Vérification si le fichier de model existe pour le filterpopup
          let hasModel = this.columns?.find(
            (t) => t.field == col.dataField,
          )?.headerFilterLookup;
          if (hasModel?.model != undefined) {
            col[filterLookupExp] = hasModel.columnExp;
            try {
              let path = this.getModelFilter(hasModel.model);
            } catch (ex) {
              col.headerFilter = undefined;
              col.headerCellTemplate = undefined;
              console.error('Filter model not found : ' + hasModel.model);
            }
          }

          // Atlas Search
          if (
            this.atlasIndexes?.find((g) => g.field == col.dataField) !=
            undefined
          ) {
            if (col.dataType !== 'number') {
              col.allowHeaderFiltering = true;
              col.allowFiltering = false;
            }
          }
        });
        this.component.option('columns', newColumns);

        // vérification du layout de base
        await this.loadState();

        // Vérification si le filterBuilder est disponible
        newColumns = newColumns.filter(
          (a) =>
            a.caption != undefined &&
            a.cellTemplate !== 'arrayTemplate' &&
            a.cellTemplate !== 'arrayEnumTemplate' &&
            (a.allowFiltering !== false || a.allowHeaderFiltering === true),
        );
        newColumns.sort((a, b) => a.caption.localeCompare(b.caption));
        this.component.option('filterBuilder.fields', newColumns);
      }
    }

    this._isInitialized = true;
  }

  onSelectChangedProgress = false;
  hasSelected = false;
  /** Lorsque la séléction change. */
  public onSelectionChanged(e) {
    if (this.onSelectChangedProgress) return;
    this.onSelectChangedProgress = true;
    e.component.beginUpdate();
    this.hasSelected = e.selectedRowKeys.length > 0;
    e.component.repaint();
    let tempkey = e.component.getSelectedRowKeys();
    if (this.type == 'Tree' && !this.recursive && this.multiple) {
      this.treelistSelectionChanged(e, this.options);
      tempkey = e.component.option('selectedRowKeys');
    } else {
      if (this.type == 'Tree') {
        tempkey = e.component.getSelectedRowKeys('all');
      }
    }

    if (this.selectedData != undefined) {
      this.selectedData.clear();
      let data = e.component.getSelectedRowsData();
      for (let item of data) {
        this.selectedData.push(item);
      }
    }
    if (this.selectedKeys != undefined) {
      let keys = tempkey;
      this.selectedKeys.clear();
      if (this.enabledExp == undefined) {
        for (let key of keys) {
          this.selectedKeys.push(key);
        }
      } else {
        this.selectedKeys.push(keys[keys.length - 1]);
      }
    }

    if (this.type == 'Tree' && this.enabledExp == undefined) {
      this.treelistAllSelectKey = e.component.getSelectedRowKeys('all').length;
    }

    /** Contrôle si le noeud peut etre sélectionné */
    if (
      e.selectedRowsData != undefined &&
      e.selectedRowsData.length > 0 &&
      this.enabledExp != undefined &&
      e.selectedRowKeys != undefined &&
      e.selectedRowKeys.length > 0
    ) {
      if (
        e.selectedRowsData[0] != undefined &&
        e.selectedRowsData[0][this.enabledExp] === false
      ) {
        e.component.expandRow(e.selectedRowsData[0]['id']);
        e.selectedRowsData.clear();
        e.selectedRowKeys.clear();
        this.selectedData.clear();
        this.selectedKeys.clear();
        e.component.option('selectedRowKeys', e.selectedRowKeys);
        this._changeDetectorRef.detectChanges();
      }
    }

    /** Controle si la sélection peut etre multiple */
    if (
      !this.multiple &&
      e.selectedRowKeys != undefined &&
      e.selectedRowKeys.length > 1
    ) {
      e.selectedRowKeys = [e.selectedRowKeys[0]];
      e.selectedRowsData = [e.selectedRowsData[0]];
      e.component.option('selectedRowKeys', e.selectedRowKeys);
      this._changeDetectorRef.detectChanges();
    }

    /** Controle si la sélection peut etre multiple */
    let selectedRowKeysOption = e.component.option('selectedRowKeys');

    if (
      !this.multiple &&
      selectedRowKeysOption != undefined &&
      selectedRowKeysOption.length > 1
    ) {
      selectedRowKeysOption = [selectedRowKeysOption[0]];
      e.component.option('selectedRowKeys', selectedRowKeysOption);
      this._changeDetectorRef.detectChanges();
    }

    this.onSelectChangedProgress = false;

    if (
      !this.useDevExtremSelectAllTree &&
      this.checkAllComponent != undefined
    ) {
      this.onSelectChangeAction = true;

      let countNodes = 0;
      e.component.forEachNode(() => {
        countNodes++;
      });
      let _values = [];
      e.component.getSelectedRowKeys('all').forEach((f) => _values.push(f));

      let checkValue = false;
      if (_values.length == countNodes) {
        checkValue = true;
      } else if (_values.length == 0) {
        checkValue = false;
      } else {
        checkValue = undefined;
      }
      this.checkAllComponent.option('value', checkValue);
      this.onSelectChangeAction = false;
    }

    e.component.endUpdate();

    this.onSelectionKeyChanged.emit({
      selectedRowKeys: e.component.option('selectedRowKeys'),
    });
  }
  /** Permet la suppression d'un layout depuis la liste. */
  public async onLayoutDeleteClick(e) {
    const ids = e.component.instance.getSelectedRowKeys();
    // Message de confirmation de suppression
    let result = await confirm(
      TranslateService.get('confirm-permanent-delete', { count: ids.length }),
      TranslateService.get('confirm-title'),
    );
    // Action de suppression du layout selectionné
    if (result === true) {
      // this.layoutManager.deleteLayouts(ModelFieldCompilerService.createServiceSingleResultScalar(), ids).subscribe((_) => {
      //   this.sourceLayoutManager.reload();
      //   this.layoutValue = [];
      // });
    }
  }
  /** Permet d'ajouter les boutons en haut de la liste. */
  public onLayoutManagerToolbarPreparing(e) {
    // Bouton d'ajout d'une vue personnalisée
    e.toolbarOptions.items.push({
      locateInMenu: 'auto',
      location: 'before',
      template: 'buttonNewLayoutTemplate',
    });
  }
  onPopupShowing(e) {
    let position = e.component.option('position');
    position.offset.y = 1;
    e.component.option('position', position);
  }

  treeListExportData(data: any[]) {
    let exportFile = '';
    let columns = this.columns;
    for (let column of columns) {
      if (
        (column.label != undefined && column.visible == true) ||
        column.toExportData == true
      ) {
        exportFile += column.label + ';';
      }
    }

    exportFile = exportFile.substring(0, exportFile.length - 1) + '\n';

    for (let column of columns) {
      if (
        (column.field != undefined && column.visible == true) ||
        column.toExportData == true
      ) {
        exportFile += column.field + ';';
      }
    }

    exportFile = exportFile.substring(0, exportFile.length - 1) + '\n';

    for (let entry of data) {
      for (let column of columns) {
        if (
          (column.field != undefined && column.visible == true) ||
          column.toExportData == true
        ) {
          // Choisit une valeur de l'objet.
          let val = dot.pick(column.field, entry);
          //Si la colonne ne possède pas de lookup et est constituée de la manière suivante "objet.prop" ex: "lifetime.yearDuration"
          if (column.field.includes('.') && column.lookup == undefined) {
            exportFile += val != undefined ? val : '';
          } // Dans le cas où la colonne possède un lookup
          else if (column.lookup != undefined) {
            let lookupSource = column.lookup.source as any;
            // on récupère la donnée traduite et pas la donnée brut (ex: enum)
            let res = lookupSource.filter((f) => f.id == val)[0].value;
            exportFile += res != undefined ? res : '';
          } else if (column.field === 'qualification') {
            val.forEach((value: string, index: number) => {
              value = value.toLowerCase().trim();
              if (value === 'demande') value = 'request'; //Certaines valeurs dans la base de données contiennent 'demande' plutôt que Request.
              val[index] = this.templateService.translateValue(
                value,
                'ticketType',
              );
            });
            exportFile += val;
          } else {
            exportFile +=
              entry[column.field] != undefined ? entry[column.field] : '';
          }
          exportFile += ';';
        }
      }
      exportFile = exportFile.substring(0, exportFile.length - 1) + '\n';
    }
    this.exportData(exportFile);
  }

  public exportData(data) {
    const element = document.createElement('a');
    const serviceName = this.fileName;
    const exportFileName = this.exportFileName;
    let type = 'text/csv;charset=UTF-8';
    let ext = '.csv';
    const blob = new Blob(['\ufeff' + data], { type: type });
    const date = new Date();
    const dateString =
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2);

    element.href = URL.createObjectURL(blob);
    if (exportFileName != undefined) {
      element.download = exportFileName + '_data_extract_' + dateString + ext;
    } else {
      element.download = serviceName + '_data_extract_' + dateString + ext;
    }
    document.body.appendChild(element);
    element.click();
    setTimeout(() => {
      element.remove();
    });
  }

  collapseAllClick(e) {
    this.expand(false);
  }
  expandAllClick(e) {
    this.expand(true);
  }

  expand(open: boolean) {
    let columns = this.component.getVisibleColumns();
    columns.forEach((f) => {
      if (f.groupIndex != undefined) {
        if (open) {
          this.component.expandAll(f.groupIndex);
        } else {
          this.component.collapseAll(f.groupIndex);
        }
      }
    });
  }

  /** Permet d'ajouter les boutons en haut de la liste. */
  public async onToolbarPreparing(e) {
    if (this.compactMode) {
      // Set le focus dans le search par defaut

      e.toolbarOptions.onItemRendered = (et) => {
        if (et.itemData.name == 'searchPanel') {
          let input = et.itemElement.getElementsByTagName('input');
          if (input != undefined && input.length > 0) {
            setTimeout(() => {
              input[0].focus();
            }, 500);
          }
        }
      };

      return;
    }

    let storageEnableForceAutoRefresh = localStorage.getItem(
      'enableForceAutoRefresh',
    );
    this.enableForceAutoRefresh = true;
    if (storageEnableForceAutoRefresh === 'false') {
      this.enableForceAutoRefresh = false;
    }

    // TODO A transformer comme au dessus.
    e.toolbarOptions.items.unshift(
      {
        widget: 'dxButton',
        location: 'after',
        locateInMenu: 'auto',
        options: {
          icon: 'refresh',
          hint: TranslateService.get('globals/refresh'),
          onClick: () => {
            this.refresh({ clearError: true });
            if (this.modelState != undefined) {
              this.modelState.on.emit({
                eventName: 'reload-tabs',
                eventData: undefined,
              });
            }
          },
        },
      },
      {
        location: 'after',
        widget: 'dxCheckBox',
        locateInMenu: 'auto',
        visible:
          this.displayAutoRefreshCheckbox &&
          this.delayAutoRefresh !== undefined &&
          this.delayAutoRefresh > 1000,
        options: {
          activeStateEnabled: true,
          text: TranslateService.get('globals/pauseAutoRefresh'),
          hint: this.enableForceAutoRefresh
            ? TranslateService.get('globals/pauseAutoRefreshHint')
            : TranslateService.get('globals/enableAutoRefreshHint'),
          onInitialized: (e) => {
            e.component.option('value', this.enableForceAutoRefresh);
          },
          onValueChanged: (e) => {
            // Set dans le storage
            localStorage.setItem('enableForceAutoRefresh', e.value.toString());

            this.enableForceAutoRefresh = e.value;
            if (this.enableForceAutoRefresh) {
              this.refresh();
              this.autoRefresh(this.delayAutoRefresh);
            } else {
              clearTimeout(this.timer);
            }
          },
        },
      },
    );

    if (!this.allowSearchPanel) {
      return;
    }

    if (this.type === 'Grid') {
      e.toolbarOptions.items.unshift(
        {
          widget: 'dxButton',
          location: 'before',
          cssClass: 'cl-item-toolbar',
          options: {
            visible: this.isVisible,
            icon: 'minus',
            stylingMode: 'text',
            hint: TranslateService.get('globals/collapseAll'),
            onInitialized: (args: any) => {
              this.btnCollapseInstance = args.component;
            },
            onClick: () => {
              this.collapseAllClick(this);
            },
          },
        },
        {
          widget: 'dxButton',
          location: 'before',
          cssClass: 'cl-item-toolbar',
          options: {
            visible: this.isVisible,
            icon: 'plus',
            stylingMode: 'text',
            hint: TranslateService.get('globals/expandAll'),
            onInitialized: (args: any) => {
              this.btnExpandInstance = args.component;
            },
            onClick: () => {
              this.expandAllClick(this);
            },
          },
        },
      );
    }

    if (this.type === 'Grid') {
      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'selectedCount',
        locateInMenu: 'auto',
      });
      // Option pour le rendre dipsonible en fonction de la liste

      // e.toolbarOptions.items.unshift({
      //   location: 'before',
      //   template: 'expertDisplayMode',
      //   locateInMenu: 'auto',
      // });

      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'clearFilterPopup',
        locateInMenu: 'auto',
      });
    }

    if (
      this.type === 'Grid' &&
      this.authorizationService.user.hasClaim('tenantId') != undefined &&
      this.layoutKey != undefined &&
      this.layoutKey != 'disable'
    ) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        template: 'layoutManagerTemplate',
        locateInMenu: 'auto',
      });
    }

    if (this.type === 'Grid' && this.hasAtlasSearch()) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        template: 'searchByAtlasTemplate',
        locateInMenu: 'auto',
      });
    }

    if (this.type === 'Tree') {
      if (this.canExport === true) {
        e.toolbarOptions.items.push({
          widget: 'dxButton',
          location: 'after',
          cssClass: 'cl-item-toolbar',
          options: {
            icon: 'export',
            stylingMode: 'text',
            hint: TranslateService.get('globals/export'),
            onClick: () => {
              (
                this.source.datasource.store() as GraphQLStore
              ).context.context.config
                .query()
                .subscribe((res) => {
                  this.treeListExportData(res.data);
                });
            },
          },
        });
      }

      e.toolbarOptions.items.unshift(
        {
          widget: 'dxButton',
          location: 'before',
          cssClass: 'cl-item-toolbar',
          options: {
            icon: 'minus',
            stylingMode: 'text',
            hint: TranslateService.get('globals/collapseAll'),
            onClick: () => {
              this.treelistCollapseAll(e);
            },
          },
        },
        {
          widget: 'dxButton',
          location: 'before',
          cssClass: 'cl-item-toolbar',
          options: {
            icon: 'plus',
            stylingMode: 'text',
            hint: TranslateService.get('globals/expandAll'),
            onClick: () => {
              this.treelistExpandAll(e);
            },
          },
        },
      );

      //ATTENTION
      this.useDevExtremSelectAllTree = false;
      e.toolbarOptions.items.unshift({
        widget: 'dxCheckBox',
        cssClass: 'cl-checkbox-style',
        location: 'before',
        options: {
          visible: !this.useDevExtremSelectAllTree,
          onInitialized: (args) => {
            this.checkAllComponent = args.component;
          },
          onValueChanged: (args) => {
            if (!this.onSelectChangeAction) {
              //this.selectedKeys.clear();
              if (args.value) {
                let nodes = this.treelistCheckAll(e, this.options);
                //this.selectedKeys = nodes;
                e.component.option('selectedRowKeys', nodes);
              } else {
                e.component.option('selectedRowKeys', []);
              }

              this.onSelectionKeyChanged.emit({
                selectedRowKeys: e.component.option('selectedRowKeys'),
              });

              this._changeDetectorRef.detectChanges();
            }
          },
        },
      });
    }

    if (this.allowSearchPanel && !this.hasAtlasSearch()) {
      let searchPanel = e.toolbarOptions.items.find(
        (e) => e.name == 'searchPanel',
      );
      if (searchPanel != undefined) {
        e.toolbarOptions.items.splice(
          e.toolbarOptions.items.findIndex((e) => e.name == 'searchPanel'),
          1,
        );
        e.toolbarOptions.items.unshift(searchPanel);
      }
    }

    if (this.toolbarItems != undefined && this.features != undefined) {
      this.toolbarItems.forEach(async (item) => {
        let visibleFeature = true;
        if (item.feature != undefined) {
          visibleFeature = this.featureService.checkFeature(
            item.feature,
            this.features,
          );
        }
        if (visibleFeature == true) {
          e.toolbarOptions.items.unshift({
            widget: 'dxButton',
            location: 'before',
            locateInMenu: 'auto',
            options: {
              disabled: !this.hasSelected,
              text: item.label,
              icon: item.icon,
              hint: item.hint,
              onClick: () => {
                if (this.modelState != undefined) {
                  this.modelState.on.emit({
                    eventName: item.eventName,
                    eventData: this.selectedData,
                  });
                }
              },
            },
          });
        }
        //e.component.repaint();
      });
    }
  }

  /** Obtient si l'index Atlas est utilisé */
  hasAtlasSearch(): boolean {
    return this.atlasIndexes != undefined && this.atlasIndexes.length > 0;
  }

  onAtlasSearch() {
    this.source.context.params.set(
      'selectedAtlasIndex',
      () => this.selectedAtlasIndex,
    );
    this.disabledAtlas = true;
    this.source.datasource.searchValue(this.atlasSearchValue);
    this.refresh();
  }
  onAtlasInitialized(e) {
    this.textBoxAtlas = e.component;
  }
  onAtlasValueChanged(e) {
    if (e.value?.trim() != e.previousValue?.trim()) {
      if (e.value?.trim() == undefined) {
        this.onAtlasSearch();
      } else {
        this.debouncer.next(e.value);
      }
    }
  }

  /** Indique le démarrage d'un drag. */
  public onDragStart(e) {
    e.itemData = this.component.getVisibleRows()[e.fromIndex].data;
    this.storeFromId = e.itemData.id;
    if (this.enabledExp != undefined && e.itemData != undefined) {
      if (e.itemData != undefined && e.itemData[this.enabledExp] === false) {
        e.cancel = true;
      }
    }
  }
  /** Permet de suivre les changements d'un drag & drop. */
  public onDragChange(e) {
    let fromId = this.component.getVisibleRows()[e.fromIndex];
    let toId = this.component.getVisibleRows()[e.toIndex];

    if (toId?.data != undefined && this.enabledExp != undefined) {
      if (toId.data[this.enabledExp] === false) {
        e.cancel = true;
        return;
      }
    }
    // Si élément non déplaçable
    // Objet source
    if (fromId != undefined && fromId.data != undefined) {
      if (fromId.data.canDrag != undefined) {
        e.cancel = !fromId.data.canDrag;
      }

      if (
        !e.cancel &&
        fromId.data.rankState != undefined &&
        fromId.data.rankState != RankState.None
      ) {
        e.cancel = true;
      }
    }
    // Objet cible
    if (!e.cancel && toId != undefined && toId.data != undefined) {
      if (toId.data.canDrag != undefined) {
        e.cancel = !toId.data.canDrag;
      }
      if (
        !e.cancel &&
        toId.data.rankState != undefined &&
        toId.data.rankState != RankState.None
      ) {
        e.cancel = true;
      }
    }

    if (this.type == 'Tree') {
      // Vérifie qu'il ne s'agit pas du noeud parent.
      if (!e.cancel) {
        if (e.dropInsideItem) {
          e.cancel = fromId.node.parent.key == toId.key;
        }

        if (!e.cancel && !e.dropInsideItem) {
          // uniquement vers noeud root
          if (toId?.node?.parent?.level !== -1) {
            e.cancel = true;
          }
        }
      }

      // Contrôle que l'on ne glisse pas un noeud dans l'un de ses noeuds enfant.
      if (!e.cancel) {
        let visibleRows = this.component.getVisibleRows(),
          selectedRowKeys = this.component.getSelectedRowKeys(),
          sourceKeys = selectedRowKeys.length
            ? selectedRowKeys
            : [e.itemData.id];

        sourceKeys
          .map((key) => {
            return this.component.getNodeByKey(key);
          })
          .forEach((sourceNode) => {
            let targetNode = visibleRows[e.toIndex].node;
            while (targetNode && targetNode.data) {
              if (targetNode.data.id === sourceNode.data.id) {
                e.cancel = true;
                break;
              }
              targetNode = targetNode.parent;
            }
          });
      }
      // Vérification en fonction d'un niveau maximal
      if (!e.cancel && this.maxLevel != undefined && this.maxLevel > 0) {
        if (toId.node.level + 1 >= this.maxLevel) {
          e.cancel = true;
        }
      }
    }
  }
  /** Indique la fin drop afin d'exécuter les méthodes de déplacement. */
  public onReorder(e) {
    confirm(
      TranslateService.get('globals/confirmDrag'),
      TranslateService.get('confirm-title'),
    ).then((isOk) => {
      if (isOk) {
        let fromId = this.component.getVisibleRows()[e.fromIndex].key;
        let toId = this.component.getVisibleRows()[e.toIndex].key;

        // A un moment T, le FromId change par rapport a sa valeur d'origine
        if (this.storeFromId != undefined && fromId != this.storeFromId)
          fromId = this.storeFromId;
        if (
          this.component.getVisibleRows()[e.toIndex].data.parentId != null ||
          this.component.getVisibleRows()[e.toIndex].data.parentId != undefined
        ) {
          if (!e.dropInsideItem && this.type == 'Tree') {
            toId = this.component.getVisibleRows()[e.toIndex].data.parentId;
          }
        }

        if (
          this.component.getVisibleRows()[e.toIndex].data.manufacturerId ==
            null &&
          this.component.getVisibleRows()[e.toIndex].data.__typename ==
            'ModelManufacturerType'
        ) {
          if (!e.dropInsideItem && this.type == 'Tree') {
            toId = null;
          }
        } else {
          if (this.type == 'Tree' && !e.dropInsideItem) {
            if (toId != undefined) {
              let node = this.component.getNodeByKey(toId);

              if (
                node != undefined &&
                node?.parent?.level != undefined &&
                node.parent.level != -1
              ) {
                toId = node.parent.key;
              } else {
                toId = null;
              }
            } else {
              toId = null;
            }
          }
        }

        this.drag.context.params.set('fromId', () => fromId);
        this.drag.context.params.set('toId', () => toId);

        // Appel de fonction
        this.drag.fnCall().subscribe((result) => {
          this.drag.context.params.remove('fromId');
          this.drag.context.params.remove('toId');
          if (!result.data) {
            if (result.errors != undefined) {
              this.onError.emit(result.errors);
            }
            // Erreur
            notify(
              "Une erreur s'est produite durant l'enregistrement.",
              'error',
              5000,
            );
          } else {
            // Actualise
            this.refresh();
          }
        });
      }
    });
  }

  underline(e, data) {
    if (data.key != undefined) {
      let column = this.columns.filter((f) => f.field == data.column.dataField);
      // Pemet de savoir l'action à entreprendre
      if (column != undefined && column.length > 0) {
        if (column[0].link != undefined) {
          this.onRowClick.emit({
            value: data.key,
            data: data.data,
            ctrl: e.ctrlKey,
            component: this,
            event: e,
          });
        }
      }
    }
  }

  href(data) {
    let url = '';
    let column = this.columns.filter((f) => f.field == data.column.dataField);
    if (column != undefined && column.length > 0) {
      if (column[0].link != undefined) {
        if (column[0].linkType != undefined) {
          url = column[0].linkType + '/edit/' + data.data.id;
          if (column[0].linkParams != undefined) {
            url += column[0].linkParams;
          }
        }
        if (column[0].linkHref != undefined && column[0].linkHref === false) {
          this.withoutHref = true;
        } else {
          this.withoutHref = false;
        }
      }
    }
    return url;
  }

  underlineAux(e, data) {
    if (data.key != undefined) {
      let column = this.columns.filter((f) => f.field == data.column.dataField);
      // Pemet de savoir l'action à entreprendre
      let command = e.button;
      if (column != undefined && column.length > 0) {
        if (
          column[0].link != undefined &&
          command != undefined &&
          command == 1
        ) {
          this.onRowClick.emit({
            value: data.key,
            data: data.data,
            ctrl: e.ctrlKey,
            component: this,
            event: e,
          });
        }
      }
    }
  }

  /** Événement de clic. */
  public onRowClicked(e) {
    let inverseExpand = false;
    if (e.rowType === 'group' && e.handled) {
      e.event.preventDefault();
      e.event.stopPropagation();
      inverseExpand = true;
    }
    if (e.rowType === 'group') {
      let key = e.component.getKeyByRowIndex(e.rowIndex);
      let expanded = e.component.isRowExpanded(key);
      if (inverseExpand) {
        expanded = !expanded;
      }
      if (expanded) {
        e.component.collapseRow(key);
      } else {
        e.component.expandRow(key);
      }
      return;
    }

    if (e.event.target.localName != 'span' && e.event.target.localName != 'i') {
      if (this.multiple === false) {
        this.selectedData.clear();
        this.selectedData.push(e.data);

        localStorage.setItem(
          'lastSelectedKey' + (this.router.component as any).name,
          e.key,
        );
      }

      if (
        (this.type == 'Grid' && this.multiple == true) ||
        (this.type == 'Tree' && this.multiple == true)
      ) {
        if (e.key != undefined) {
          let data = e.component.getSelectedRowKeys('all');
          if (data.includes(e.key)) {
            e.component.deselectRows(e.key);
          } else {
            data.push(e.key);
            e.component.selectRows(data, true);
          }
        }
      }
    }
  }
  /** Événement de clic. */
  public onCellClick(e) {
    // Fix 001 : DevExtreme select data.
    if (this.multiple === false) {
      this.selectedData.clear();
      this.selectedData.push(e.data);
    }
    // Si le clic est autorisé dans la colonne
    if (this.isAllowClick(e)) {
      if (this.type == 'Tree') {
        this.selectedData.clear();
        this.selectedData.push(e.data);
        // Mémorise le dernier élément cliqué.
        localStorage.setItem(
          'lastSelectedKey' + (this.router.component as any).name,
          e.key,
        );
      }
      this.onRowClick.emit({
        value: e.key,
        data: e.data,
        ctrl: e.event.ctrlKey,
        component: this,
        event: e.event,
      });
    }
  }

  private emitOnRowClickEvent(e) {
    this.onRowClick.emit({
      value: e.key,
      data: e.data,
      ctrl: true,
      component: this,
      event: e.event,
    });
  }

  /** Permet de vérifier si on fait un clic molette sur la cellule (ouverture dans un nouvel onglet). */
  public onCellPrepared(e) {
    // Check de vérification par rapport au valeur
    if (
      e.column != undefined &&
      e.column?.dataType === 'string' &&
      (e.column.cellTemplate == undefined || e.column.cellTemplate == '')
    ) {
      // Vérification de la valeur
      if (
        e.value != undefined &&
        (e.value.toString() == 'false' || e.value.toString() == 'true')
      ) {
        e.column.editCellTemplate = 'booleanTemplate';
        e.column.cellTemplate = 'booleanTemplate';
        console.log('Column dataType not set properly', e);
      }
    }

    // Applique le template du style Boolean a la place des checkbox de la grille
    if (
      e.column != undefined &&
      e.column.dataType == 'boolean' &&
      (e.column.cellTemplate == undefined || e.column.cellTemplate == '')
    ) {
      e.column.editCellTemplate = 'booleanTemplate';
    }

    if (this.type == 'Grid') {
      e.cellElement.onmousedown = (ev) => {
        if (
          ev.button == 0 &&
          e.column?.dataField != undefined &&
          e.column?.dataField == 'name' &&
          this.isAllowClick(e)
        ) {
          this.onSingleCellClick.emit(e);
        }

        if (ev.button == 1 && this.isAllowClick(e)) {
          this.emitOnRowClickEvent(e);
        }
      };
    }

    if (this.type == 'Grid') {
      if (e.column.dataField == 'cart.amount' && e.rowType == 'data') {
        e.cellElement.classList.add('cl-state-td-disabled');
      }
    }

    if (this.enabledExp != undefined && this.type == 'Tree') {
      if (e.data != undefined && e.data[this.enabledExp] === false) {
        e.cellElement.classList.add('cl-state-node-disabled');

        let checks = e.cellElement.getElementsByClassName('dx-select-checkbox');
        if (checks.length > 0) {
          checks[0].classList.add('dx-state-disabled');
        }
      }
    }

    if (e.data != undefined && e.data['error'] === true) {
      e.cellElement.classList.add('cl-state-node-in-error');
    }
  }
  /** Événement lorsque l'on étant une branche */
  public onRowExpandedTreeList(e) {
    this.saveStateTreeList(e);
  }

  /** Événement lorsque l'on réduit une branche */
  public onRowCollapsedTreeList(e) {
    this.saveStateTreeList(e);
    this.treelistCollapsed(e, this.options);
  }
  /** Sauvegarde en local l'état du Treelist */
  saveStateTreeList(e) {
    if (this.router.component != undefined) {
      localStorage.setItem(
        'expandedRowKeys' + (this.router.component as any).name,
        JSON.stringify(e.component.option('expandedRowKeys')),
      );
    }
  }

  public addRowExpandedTreeList(id) {
    if (this.router.component != undefined) {
      var expandedRow = this._component.option('expandedRowKeys');
      expandedRow.push(id);

      localStorage.setItem(
        'expandedRowKeys' + (this.router.component as any).name,
        JSON.stringify(expandedRow),
      );
    }
  }
  /** Permet de vérifier si le clic est autorisé sur la cellule. */
  private isAllowClick(e): boolean {
    let allowClick = false;

    if (this.type == 'Tree') {
      if (
        e.event.target.parentElement.classList.contains(
          'dx-treelist-expanded',
        ) ||
        e.event.target.parentElement.classList.contains(
          'dx-treelist-collapsed',
        ) ||
        e.event.target.classList.contains('dx-checkbox-icon')
      ) {
        return false;
      }
    }

    // Vérification si le clic dans la column n'est pas la case à cocher (sélection) et de type date (enlève les groupes)
    if (
      e.rowType == 'data' &&
      e.column != undefined &&
      e.column.command == undefined
    ) {
      // Vérification si la colonne a un template et autorise le click
      if (
        e.column.cellTemplate != undefined &&
        e.cellElement.getElementsByClassName('cl-no-click').length == 0
      ) {
        allowClick = true;
      } else if (e.column.cellTemplate == undefined) {
        allowClick = true;
      }
    }
    // Problème qui créé le Fix 001 car ce n'est pas sur la ligne.
    if (allowClick && this.type == 'Tree') {
      allowClick = e.event.target.classList.contains(
        'dx-treelist-text-content',
      );
    }

    return allowClick;
  }

  // /** Permet de rafraîchir l'état de la grille en fonction de son mode de sélection */
  onContentReadyTreeList(e) {
    if (!this.recursive && !this.useDevExtremSelectAllTree) {
      this.treelistContentReadyTreeList(e, this.options);
    }
  }

  // intercept exporting event
  public onExporting(e) {
    const workbook: ExcelProper.Workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    const templateService = this.templateService;
    // Force flag Export DataSource
    localStorage.setItem('UseExportMode', 'true');

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: function (options) {
        const { gridCell, excelCell } = options;

        if (gridCell.rowType === 'data') {
          if (gridCell.column?.dataField?.includes('description')) {
            if (gridCell.value != null) {
              let convertvalue = new DOMParser().parseFromString(
                gridCell.value,
                'text/html',
              ).documentElement.textContent;
              excelCell.value = convertvalue;
            }
          }
          if (
            gridCell.column?.format == 'ticketType' &&
            gridCell.column?.dataField?.includes('__typename')
          ) {
            excelCell.value = templateService.translateValue(
              gridCell.value,
              'ticketType',
            );
          }

          if (
            gridCell.column?.cellTemplate != undefined &&
            gridCell.column?.cellTemplate == 'entityNameTemplate'
          ) {
            let translatedName = TranslateService.get(
              'entities/' +
                gridCell.value.charAt(0).toLowerCase() +
                gridCell.value.slice(1) +
                '/_title/singular',
            );
            excelCell.value = translatedName;
          }

          if (
            gridCell.column?.dataField != undefined &&
            (gridCell.column.dataField.includes('elaspedTime') ||
              gridCell.column.dataField === 'task.data.duration') &&
            gridCell.column.dataField != 'elaspedTimeHour'
          ) {
            if (gridCell.value != null) {
              let hours: number = 0;
              let minutes: number = 0;
              let secondes: number = 0;
              let days: number = 0;
              let minus: boolean;

              let regex =
                /^([+-])?P(?!$)(\d+Y)?(\d+M)?(\d+W)?(\d+D)?(T(?=\d*\.?\d*[DHMS])(\d*\.?\d*D)?(\d*\.?\d*H)?(\d*\.?\d*M)?(\d*\.?\d*S)?)?$/gm;
              let m = regex.exec(gridCell.value);
              if (m != undefined) {
                if (m[5] != undefined) {
                  days = Number(m[5].split('D')[0]);
                }
                if (m[8] != undefined) {
                  hours = Number(m[8].split('H')[0]);
                }
                if (m[9] != undefined) {
                  minutes = Number(m[9].split('M')[0]);
                }
                if (m[10] != undefined) {
                  secondes = Number(m[10].split('S')[0].split('.')[0]);
                }
                if (m[1] != undefined) {
                  minus = m[1] === '+' ? false : true;
                }
              }

              excelCell.value = '';
              let dateTimeObject = {
                daysValue: days,
                hoursValue: hours,
                minutesValue: minutes,
              };
              if (dateTimeObject.daysValue > 0) {
                excelCell.value +=
                  dateTimeObject.daysValue +
                  ' ' +
                  TranslateService.get('enums/timeType/days') +
                  ' ';
              }
              if (dateTimeObject.hoursValue > 0) {
                excelCell.value +=
                  dateTimeObject.hoursValue +
                  ' ' +
                  TranslateService.get('enums/timeType/hours') +
                  ' ';
              }
              if (dateTimeObject.minutesValue > 0) {
                excelCell.value +=
                  dateTimeObject.minutesValue +
                  ' ' +
                  TranslateService.get('enums/timeType/minutes') +
                  ' ';
              }

              if (
                gridCell.value > 0 &&
                gridCell.data?.__typename == 'Intervention' &&
                gridCell.column.dataField.includes(
                  'ticket.data.elaspedTimeHour',
                ) &&
                excelCell.value.trim() == ''
              ) {
                excelCell.value = gridCell.value;
              }
            }
          }
        }
      },
    }).then(function () {
      //https://supportcenter.devexpress.com/ticket/details/t980050/datagrid-export-to-excel-limitation
      const MAX_EXCEL_EXPORT_LIMIT = 10001;
      if (
        workbook.getWorksheet('Main sheet').rowCount === MAX_EXCEL_EXPORT_LIMIT
      ) {
        let messageError = TranslateService.get(
          'globals/exportExcelMemoryLimitReachedError',
        );
        notify(messageError, 'error', 5000);
      }

      workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'DataGrid.xlsx',
        );
      });
    });
    e.cancel = true;

    return;
  }

  //TimeSpan format
  /** Ignore les accentuations et case sur la recherche d'une colonne */
  calculateFilterExpression(filterValue, selectedFilterOperation) {
    let column = this as any;
    let getter = (data) => {
      let result = data;
      column.dataField.split('.').forEach((f) => {
        result = result[f];
      });
      if (result.normalize != undefined) {
        return result.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      } else {
        return result;
      }
    };

    if (filterValue.normalize != undefined) {
      filterValue = filterValue
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    }
    return [getter, selectedFilterOperation || 'contains', filterValue];
  }

  selectionChanged(e, masterdetail) {
    if (masterdetail != undefined) {
      if (masterdetail.openMode == 'onClick') {
        e.component.collapseAll(-1);
        e.component.expandRow(e.currentSelectedRowKeys[0]);
      }
    }
  }

  /** Initialisation du composant */
  onContentReadyScheduler(e) {
    if (this.addedScheduler) return;
    var today = new Date();
    // Scroll auto sur l'heure actuelle
    e.component.scrollTo(today.getHours(), today.getMinutes());

    let element = document.querySelectorAll('.dx-scheduler-navigator');

    element[0].addEventListener('click', () => {
      setTimeout(() => {
        let cal = document.querySelectorAll('.dx-scheduler-navigator-calendar');
        let instance = Calendar.getInstance(cal[0]);
        if (instance != undefined) {
          instance.option('showTodayButton', true);
        }
      }, 100);
    });

    this.addedScheduler = true;
  }
  convertToOperator(
    operator:
      | 'Equal'
      | 'NotEqual'
      | 'GreaterThan'
      | 'GreaterThanOrEqual'
      | 'LessThan'
      | 'LessThanOrEqual'
      | 'Between'
      | 'Contains'
      | 'EndsWith'
      | 'NotContains'
      | 'StartsWith',
  ) {
    switch (operator) {
      case 'Equal':
        return '=';
      case 'NotEqual':
        return '<>';
      case 'GreaterThan':
        return '>';
      case 'GreaterThanOrEqual':
        return '>=';
      case 'LessThan':
        return '<';
      case 'LessThanOrEqual':
        return '<=';
      default:
        return operator.toLocaleLowerCase();
    }
  }

  /** Calcul du total */
  onCountProgress = false;
  onCount(e) {
    if (this.totalCount <= 0 && this.onCountProgress === false) {
      this.onCountProgress = true;

      if (
        (this.source.datasource.store() as GraphQLStore)?.context?.context
          ?.config?.context?.context != undefined
      ) {
        let save = (
          this.source.datasource.store() as GraphQLStore
        )?.context?.context?.config?.context?.context?.params?.get('fields');
        let fieldCount = [GqlField.create('totalCount')];
        (
          this.source.datasource.store() as GraphQLStore
        )?.context?.context?.config?.context?.context?.params.set(
          'fields',
          () => fieldCount,
        );

        this.inProgressCount = true;
        (this.source.datasource.store() as GraphQLStore).context.context.config
          .query()
          .subscribe(async (result) => {
            this.inProgressCount = false;
            (
              this.source.datasource.store() as GraphQLStore
            )?.context?.context?.config?.context?.context?.params.set(
              'fields',
              () => save,
            );
            if (result?.totalCount != undefined && result.totalCount != -1) {
              this.totalCount = result.totalCount;
            }
            this.onCountProgress = false;
          });

        // Réaffecte le field count
        setTimeout(() => {
          (
            this.source.datasource.store() as GraphQLStore
          )?.context?.context?.config?.context?.context?.params.set(
            'fields',
            () => save,
          );
        }, 100);
      }
    }
  }

  /** Remonte en haut de la grid */
  onTopGrid(e) {
    if (this.component != undefined) {
      this.component.getScrollable().scrollTo(0);
    }
  }
  /** Événement du composant Grid  */
  onContentReadyGrid(e) {
    this.getTotalCount();
    this.useOldLayout = false;
    // Si filter
    this.clearButtonFilterPopup = false;
    if (this.compactMode === false) {
      for (let i = 0; i < e.component.columnCount(); i++) {
        let hasFilter = e.component.columnOption(i);
        if (hasFilter != undefined && hasFilter.dataField !== '__typename') {
          // Ancien filtre si
          // Template header est valeur non GUID
          let hasValueNotGuid =
            ((hasFilter?.filterValues?.length > 0 &&
              hasFilter?.filterValues?.filter(
                (v) => !CommonCoreService.isGuid(v),
              )?.length > 0) ||
              (hasFilter?.filterValue != undefined &&
                !CommonCoreService.isGuid(hasFilter?.filterValue))) &&
            hasFilter.headerCellTemplate != undefined;

          // Si atlas search et utilisation d'un filtre spécifique
          let hasAtlasSearchIndex =
            hasFilter?.filterValue != undefined &&
            this.atlasIndexes?.find((g) => g.field == hasFilter.dataField) !=
              undefined &&
            hasFilter.dataType !== 'number';

          if (hasValueNotGuid || hasAtlasSearchIndex) {
            let col = this.columns.find((d) => d.field == hasFilter.dataField);
            if (
              col != undefined &&
              col.headerFilterLookup.useFieldValue === undefined
            ) {
              this.useOldLayout = true;
            }
          }
        }
      }
    }

    if (!this.compactMode) {
      setTimeout(() => {
        let scroll = e.component.getScrollable();
        if (scroll != undefined) {
          scroll.on('scroll', (args) => {
            this.showTop = !args.reachedTop;
          });
        }
      });
    }

    // Gestion de la sélection du text dans les headers de filtre
    let textBoxComponents = e.element.getElementsByClassName(
      'dx-texteditor-input',
    );
    if (textBoxComponents != undefined) {
      for (let i = 0; i < textBoxComponents.length; i++) {
        textBoxComponents[i].onfocus = function () {
          this.select();
        };
      }
    }
    var groupIndex = this.component
      .getVisibleColumns()
      .map((t) => t.groupIndex)
      .filter((i) => i != undefined).length;
    if (this.type === 'Grid' && groupIndex > 0) {
      this.isVisible = true;
      if (this.btnCollapseInstance !== null && this.btnExpandInstance) {
        this.btnCollapseInstance.option({
          visible: this.isVisible,
        });
        this.btnExpandInstance.option({
          visible: this.isVisible,
        });
      }
    }
  }

  /** Filtre le dataSource */
  masterDataSource(key, itemTab) {
    let item = this.masterDataSourceStorage.find(
      (i) => i.key === key && i.title === itemTab.label,
    );

    if (!item) {
      itemTab.control.source.context.params.set('id', () => key);
      item = {
        key: key,
        title: itemTab.label,
        dataSourceInstance: new CustomStore({
          load: () => {
            let source = new DataSource(
              itemTab.control.source.datasource.store(),
            );

            if (
              itemTab.control.useAutoFilter == undefined ||
              itemTab.control.useAutoFilter == true
            ) {
              source.filter(['id', '=', key]);
            }
            return source.load().then((r) => {
              return r;
            });
          },
        }),
      };
      this.masterDataSourceStorage.push(item);
    }
    return item.dataSourceInstance;
  }

  /** Gestion du rendu des rendez vous */
  onAppointmentRendered(e) {
    var el = e.appointmentElement;
    if (e.appointmentData.color != undefined) {
      el.style.backgroundColor = e.appointmentData.color;
    }

    el.onmouseenter = () => {
      if (!this.isAppointmentClick) {
        e.component.showAppointmentTooltip(
          e.appointmentData,
          e.appointmentElement,
          e.targetedAppointmentData,
        );
      }
    };

    el.onmouseleave = () => {
      if (!this.isAppointmentClick) {
        e.component.hideAppointmentTooltip();
      }
    };

    el.onblur = () => {
      this.isAppointmentClick = false;
    };

    el.onfocus = () => {
      this.isAppointmentClick = true;
    };
  }

  /** Affichage des dates */
  displayDate(e, params) {
    if (params != null && e != undefined) {
      return new Date(e).toLocaleDateString(Globalize.locale().locale);
    }

    if (params == null && e != undefined) {
      return (
        new Date(e).toLocaleDateString(Globalize.locale().locale) +
        ' ' +
        new Date(e).toLocaleTimeString(Globalize.locale().locale, {
          hour: '2-digit',
          minute: '2-digit',
        })
      );
    }
  }
  /** Comparaison des dates d'un élément du planning */
  compareDate(startDate, endDate) {
    // Si les dates de début et de fin ne sont pas nulles
    if (startDate != undefined && endDate != undefined) {
      // Récupère les dates en format "yyyy/mm/dd"
      var start = startDate.split('T')[0];
      var end = endDate.split('T')[0];
      // Si les dates sont identiques
      if (start === end) {
        //Retourne seulement que les heures du rendez-vous (HH:mm:ss)
        return (
          new Date(startDate).toLocaleTimeString(Globalize.locale().locale, {
            hour: '2-digit',
            minute: '2-digit',
          }) +
          '-' +
          new Date(endDate).toLocaleTimeString(Globalize.locale().locale, {
            hour: '2-digit',
            minute: '2-digit',
          })
        );
      } else {
        //Retourne la date et l'heure du rendez-vous (dd/mm/yyyy HH:mm:ss)
        return (
          new Date(startDate).toLocaleDateString(Globalize.locale().locale) +
          ' ' +
          new Date(startDate).toLocaleTimeString(Globalize.locale().locale, {
            hour: '2-digit',
            minute: '2-digit',
          }) +
          '-' +
          new Date(endDate).toLocaleDateString(Globalize.locale().locale) +
          ' ' +
          new Date(endDate).toLocaleTimeString(Globalize.locale().locale, {
            hour: '2-digit',
            minute: '2-digit',
          })
        );
      }
    }
  }

  /** Affichage des heures */
  displayTime(e) {
    let options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
    };
    if (e != undefined) {
      return new Date(e).toLocaleTimeString(Globalize.locale().locale, options);
    }

    return '';
  }

  /** Affichage de la description sans les balises html */
  displayWithoutHtml(value: string): String {
    if (value != undefined || value != null) {
      let contentHTML = new DOMParser().parseFromString(value, 'text/html')
        .documentElement.textContent;
      return contentHTML;
    }
    return '';
  }

  onClosed(e) {
    this.popupVisible = false;
    this.selectedData = [];
  }

  selectAuditsAffect(e) {
    let listAudits = [];

    e.forEach((result) => {
      listAudits.push(result.id);
    });

    let appoint: ScanConfigurationAppointmentInput = {
      allDay: this.appointmentSelected.allDay,
      offSet: null,
      description: this.appointmentSelected.description,
      endDate: this.appointmentSelected.endDate,
      recurrenceRule: this.appointmentSelected.recurrenceRule,
      startDate: this.appointmentSelected.startDate,
      text: this.appointmentSelected.text,
      visible: true,
      disabled: false,
      scanConfigurationIds: listAudits,
    };

    this.updateRessource.context.params.set(
      'id',
      () => this.appointmentSelected.id,
    );
    this.updateRessource.context.params.set('modified', () => appoint);

    this.updateRessource.fnCall().subscribe((result) => {
      this.updateRessource.context.params.remove('id');
      this.updateRessource.context.params.remove('modified');
      if (!result.data) {
        if (result.errors != undefined) {
          this.onError.emit(result.errors);
        }
        // Erreur
        notify(
          "Une erreur s'est produite durant l'enregistrement.",
          'error',
          5000,
        );
      } else {
        this.selectedKeys = [];
        this.popupVisible = false;
        this.refresh();
      }
    });
  }

  isErrorLayoutLoad: boolean = false;
  onDataErrorOccurred(e) {
    if (
      !this.isErrorLayoutLoad &&
      this.layoutValue != undefined &&
      this.layoutValue[0] != undefined
    ) {
      this.isErrorLayoutLoad = true;
      if (
        this.sourceLayoutManager != undefined &&
        this.sourceLayoutManager.items().length > 0
      ) {
        var l = this.sourceLayoutManager
          .items()
          .filter((f) => f.id == this.layoutValue[0]);
        if (l.length > 0) {
          e.error.message =
            TranslateService.get('globals/layoutError') + ` [${l[0].name}].`;

          alert(e.error.message, TranslateService.get('globals/warning'));
        }
      } else {
        e.error.message = TranslateService.get('globals/layoutError');
        alert(e.error.message, TranslateService.get('globals/warning'));
      }
      this.layoutValue[0] = undefined;
      this.isErrorLayoutLoad = false;
    }
  }

  checkValidator(data): boolean {
    let entityType: string = data?.entityType?.toLowerCase();
    let excludepolicies: string[] = ['messages', 'ticketemails', 'escalation'];
    if (entityType != undefined && entityType != null) {
      if (!excludepolicies.includes(entityType)) {
        let getReadPermission = this.policyValidator.validate(
          `${entityType}.read`,
        );
        return getReadPermission;
      }
    }
    return true;
  }

  /** Obtient le lien d'accès */
  getLink(data) {
    var type = this.fetaureList.filter(
      (f) => f.type.toLowerCase() == data['__typename'].toLowerCase(),
    );
    if (type.length > 0) {
      let keyName = 'id';
      let path = type[0].link;
      if (type[0].linkKey != undefined) {
        keyName = type[0].linkKey;
      }

      let infoForGenerateURL = this.getInfoForURl(data);
      if (infoForGenerateURL == null) {
        return './' + type[0].link + '/edit/' + data[keyName];
      }
      let objectt = {
        defaultPath: path,
        defaultKeyName: keyName,
      };
      return this.generateUrlForResultQueryBuilder(
        infoForGenerateURL,
        objectt,
        data,
      );
    }
  }

  public getInfoForURl(data: any): object {
    let path = null;
    let getTicketId = data.ticketId;
    if (getTicketId == undefined) {
      if (data.task != undefined) {
        if (data?.task?.data != undefined) {
          getTicketId = data.task.data.ticketId;
          if (data?.task?.data?.ticket?.data != null)
            path = data.task.data.ticket.data['__typename'];
        }
      }
    }

    if (data?.ticket?.data != undefined) {
      path = data.ticket.data.__typename;
    }

    if (path == null) return null;
    path = path.toLowerCase() + 's';
    return {
      pathURL: path,
      interventionOrTaskId: getTicketId,
    };
  }
  public generateUrlForResultQueryBuilder(infoToGenerateURL: any, a, data) {
    let generatedUrlForm =
      './' +
      infoToGenerateURL.pathURL +
      '/edit/' +
      infoToGenerateURL.interventionOrTaskId +
      '?navigateTo=treatment&tabs=';
    let typename = data['__typename'].toLowerCase();
    let taskIdOrInterventionId = data[a.defaultKeyName];
    switch (typename) {
      case 'tickettask':
        generatedUrlForm =
          generatedUrlForm + 'tasks&taskId=' + taskIdOrInterventionId;
        break;
      case 'intervention':
        generatedUrlForm =
          generatedUrlForm +
          'interventions&interventionId=' +
          taskIdOrInterventionId;
        break;
      default:
        generatedUrlForm =
          './' + a.defaultPath + '/edit/' + taskIdOrInterventionId;
        break;
    }
    return generatedUrlForm;
  }
  /** Préparation du menu de la grille */
  onContextMenuPreparing(e) {
    if (!this.recursive) {
      this.treelistContextMenuPreparing(
        e,
        false,
        this.options,
        this.activeSelectLevelAction,
      );
    }
  }

  getTranslatedField(field: string): string {
    return '';
  }

  onShowingPopUp(e) {
    e.component.content().classList.add('clc-popupp-layout'); //add class to content
  }

  public onEditingStart(e) {
    this.cell = e.component.getRowIndexByKey(e.key);

    if (e.column.lookup != undefined) {
      let manufacturerOrModel = [];
      let itemsLookup = e.column.lookup.items as Array<any>;
      let columnName = e.column.dataField;

      if (columnName.toLowerCase() == 'manufacturerId'.toLowerCase()) {
        manufacturerOrModel = e.column.lookup.items as Array<any>;
      } else if (
        e.data?.manufacturerId != null &&
        e.data?.manufacturerId != undefined
      ) {
        let manufacturer = e?.data?.manufacturerId;

        if (manufacturer != null) {
          itemsLookup.map((value) => {
            if (value.manufacturerId === manufacturer) {
              manufacturerOrModel.push(value);
            }
          });
        }
      }
      e.column.lookup.dataSource = manufacturerOrModel;
    }
  }

  onOptionChangedTree(e) {
    if (this.type == 'Tree') {
      // Permet de géré la recursivité au 1er clic
      // Par defaut au chargement de donnée, la récursivité n'est pas activé pour pouvoir sélection des noeuds parent unique
      if (
        ((this.options?.recursive != undefined && !this.options.recursive) ||
          (!this.recursive && this.multiple)) &&
        e.name == 'focusedRowIndex'
      ) {
        this.setRecusirf();
      }
    }
  }

  /**
   * Création d'un message d'erreur si le N° de série existe déjà
   * (impossible de savoir pourquoi le message d'erreur natif de devextreme ne s'affiche pas)
   * @param params params
   */
  createErrorMessage(params: ValidationCallbackData) {
    params.validator._$element[0].childNodes[0].classList.add('dx-invalid');
    params.validator._$element[0].childNodes[0].classList.add('dx-validator');
    params.validator._$element[0].childNodes[0].classList.add(
      'dx-datagrid-invalid',
    );

    const table = document.querySelector('div.dx-scrollable-content');
    if (table) {
      const child1 = document.createElement('div');
      child1.classList.add('dx-overlay-wrapper');
      child1.classList.add('dx-invalid-message');
      child1.classList.add('dx-invalid-message-always');
      child1.classList.add('dx-datagrid-invalid-message');
      child1.style.cssText =
        'position: fixed; text-align: center; margin: -6px auto;';

      const childOfChild = document.createElement('div');
      childOfChild.classList.add('dx-overlay-content');
      const text = document.createTextNode(params.rule.message);
      childOfChild.appendChild(text);
      childOfChild.style.cssText = 'bottom: 0;';
      child1.appendChild(childOfChild);

      table.appendChild(child1);
    }

    setTimeout(() => this.deleteErrorMessage(), 5000);
  }

  /**
   * Suppression du message d'erreur si le N° de série est valide.
   * @param params params
   */
  deleteErrorMessage() {
    const divToRemove = document.querySelectorAll(
      'div.dx-overlay-wrapper.dx-invalid-message.dx-invalid-message-always.dx-datagrid-invalid-message',
    );
    divToRemove.forEach((e) => e.remove());
  }

  /** Validation de l'unicité du numéro de série*/
  customValidator = function (params: ValidationCallbackData) {
    let column = this.columns.find((f) => f.field == params.column.dataField);
    let method = (<any>column?.validatorMethod) as ModelFnContext;
    if (method != undefined) {
      return new Promise((resolve) => {
        method.context.params.set('value', () => params.value);
        method.context.params.set('id', () => params.data?.id);
        method.fnCall().subscribe((r) => {
          if (r.data && !params.rule.isValid) {
            this.createErrorMessage(params);
          }

          resolve(!r.data);
        });
      });
    } else {
      return new Promise((resolve) => {
        resolve(true);
      });
    }
  };

  filterPopupClick(e, dataColumn) {
    e.event.stopPropagation();
    e.event.preventDefault();

    let hasModel = this.columns?.find(
      (t) => t.field == dataColumn.dataField,
    )?.headerFilterLookup;

    if (hasModel?.model != undefined) {
      this.filterPopupColumn = {
        column: dataColumn,
        button: e,
      };

      let values = this.component.columnOption(
        this.filterPopupColumn.column.index,
        'filterKeyValues',
      );

      if (values == undefined) {
        values = [];
      }
      this.showClearPopupFilterButton = values.length > 0;

      if (
        dataColumn.dataField !== '__typename' &&
        dataColumn.dataField !== 'qualification'
      ) {
        values = values.filter((d) => CommonCoreService.isGuid(d));
      }

      let modelFilter = this.getModelFilter(hasModel.model);
      this.modelCompilerService
        .coreCompile(modelFilter)
        .subscribe((modelSource) => {
          this.filterPopupDataSource = modelSource;
          this.showFilterPopupSelect = true;

          modelSource.sharedContext = this.modelState?.sharedContext; //.set('type', () => this.modelState?.sharedContext?.params.get('type'))

          setTimeout(() => {
            this.filterPopupSelectedKeys = JSON.parse(JSON.stringify(values));
          }, 300);
        });
    }
  }
  getModelFilter(model: string): WorkItemConfiguration {
    return require('./header-filter-model/' + model + '.json');
  }
  applyFilterColumn() {
    let keyValues = undefined;
    if (
      this.filterPopupSelectedKeys.length != undefined &&
      this.filterPopupSelectedKeys.length === 0
    ) {
      this.component.columnOption(
        this.filterPopupColumn.column.index,
        'filterValues',
        undefined,
      );
    } else {
      keyValues = this.filterPopupSelectedKeys;
      let col = this.columns.find(
        (d) => d.field == this.filterPopupColumn.column.dataField,
      );
      if (col?.headerFilterLookup?.useFieldValue != undefined) {
        let values = this.filterPopupSelectedData.map(
          (f) => f[col.headerFilterLookup.useFieldValue],
        );
        this.component.columnOption(
          this.filterPopupColumn.column.index,
          'filterValues',
          values,
        );
      } else {
        this.component.columnOption(
          this.filterPopupColumn.column.index,
          'filterValues',
          this.filterPopupSelectedKeys,
        );
      }
    }

    this.component.columnOption(
      this.filterPopupColumn.column.index,
      'filterKeyValues',
      keyValues,
    );

    this.refresh();
    this.showFilterPopupSelect = false;
  }
  onDeleteFilter(e) {
    this.filterPopupSelectedKeys = [];
    this.filterPopupSelectedData = [];
    this.applyFilterColumn();
  }
  onFilterPopupSelect(e) {
    this.applyFilterColumn();
  }

  onClearFilterPopup(e) {
    this.component.clearFilter();
    this.component.repaint();
  }
  /** Calcul du filter de la grille */
  calculateFilterExpressionGrid(value, operation, target) {
    const column = this as any;

    if (
      value &&
      column[filterLookupExp] != undefined &&
      CommonCoreService.isGuid(value)
    ) {
      let t = [column[filterLookupExp], '=', value];
      return t;
    }

    let f = column?.defaultCalculateFilterExpression?.apply(column, arguments);
    return f;
  }

  customizeTextFilterPanel(e) {
    let text = e.text;
    if (text != undefined) {
      // Suite de GUID
      // ex '3911c348-1927-460d-9e82-e0fca100b073', 'c86c5576-55f7-4071-a0c9-3e4481de2863', '7a2bef12-fd39-4eb6-836b-f8154700bcdc', '8779f06b-2771-4c78-a069-58b97391173a', '6e73c7ce-0f18-4ccf-be12-56ecd5bdf7c8', 'a9db8274-7c66-422b-9a8e-2dffe5c00396'
      let reg =
        /('[0-9A-F]{8}-[0-9A-F]{4}-[1-5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}', )+('[0-9A-F]{8}-[0-9A-F]{4}-[1-5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}')/gi;
      text = text.replace(reg, TranslateService.get('list-values'));

      // GUID
      reg =
        /'[0-9A-F]{8}-[0-9A-F]{4}-[1-5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}'/gi;
      text = text.replace(reg, "'" + TranslateService.get('list-values') + "'");

      return text;
    }

    //https://codesandbox.io/p/sandbox/t874501-ggoxe?file=%2Fsrc%2Fapp%2Fapp.component.ts%3A27%2C12
    // A garder pour optimiser le footer si besoin
    // return 'test';
  }

  displayHint(e) {
    let col = this.columns.find(
      (d) => d.field == e.dataField && d.hintDisplay != undefined,
    );
    if (col != undefined) {
      alert(col.hintDisplay, TranslateService.get('globals/Information'));
    }
  }

  getRowClass(rowData: any) {
    // Condition pour vérifier la valeur dans la rangée
    if (
      (rowData.data?.isGreyList != undefined && rowData.data?.isGreyList) ||
      (rowData?.isGreyList != undefined && rowData?.isGreyList)
    ) {
      return 'cell-greyvalue';
    }
    return '';
  }

  /**
   * Envoi de l'évènement au composant pour l'ouverture de la pop-up
   * @param id identifiant de l'article
   */
  openArticleKnowledge(id: string) {
    this.localStorageService.ModelState.on.emit({
      eventName: 'openPopUp',
      eventData: {
        showViewerPopUp: true,
        idArticleKnowledge: id,
      },
    });
  }

  customColumnGrouping(rowData) {
    let columnDataField = this['dataField'];

    let returnValue = rowData[columnDataField];
    if (returnValue instanceof Array) {
      return returnValue.sort();
    }
    if (typeof returnValue == 'string') {
      return (returnValue as string)?.toLowerCase().trim();
    }
    return rowData[columnDataField];
  }
}
