import { WarningMessageBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { TranslateService } from '@clarilog/shared';
import { of } from 'rxjs/internal/observable/of';
import { WarningMessageCategory } from '../types';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-warning-message')
export class WarningMessageCoreService extends WarningMessageBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  findTypeWarning() {
    let items = [
      {
        id: WarningMessageCategory.HelpMe,
        name: TranslateService.get('entities/helpme/_title/singular'),
      },
      {
        id: WarningMessageCategory.Operator,
        name: TranslateService.get('entities/warningMessage/operator'),
      },
    ];

    items.sort((a, b) => a.name.localeCompare(b.name));
    return of(items);
  }
}
