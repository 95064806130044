import { LifeCycleReasonBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

@Injectable({ providedIn: 'root' })
@Authorize('asset.manage-life-cycle-reason')
export class LifeCycleReasonCoreService extends LifeCycleReasonBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
