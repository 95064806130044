// https://github.com/sherif-elmetainy/angular-globalize
import { Injectable } from '@angular/core';
import deDxMessages from '@clarilog/core/modules/globalize/locales/devextreme/dx-analytics-core.de.json';
import frDxMessages from '@clarilog/core/modules/globalize/locales/devextreme/dx-analytics-core.fr.json';
import nlDxMessages from '@clarilog/core/modules/globalize/locales/devextreme/dx-analytics-core.nl.json';
import deDxReportMessages from '@clarilog/core/modules/globalize/locales/devextreme/dx-reporting.de.json';
import frDxReportMessages from '@clarilog/core/modules/globalize/locales/devextreme/dx-reporting.fr.json';
import nlDxReportMessages from '@clarilog/core/modules/globalize/locales/devextreme/dx-reporting.nl.json';
import { CurrentCultureService } from '@code-art-eg/angular-globalize';

import 'cldr';
import deUsCaGregorian from 'cldr-data/main/de/ca-gregorian.json';
import deUsCurrencies from 'cldr-data/main/de/currencies.json';
import deUsNumbers from 'cldr-data/main/de/numbers.json';
import deUsTimeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import enUsCaGregorian from 'cldr-data/main/en/ca-gregorian.json';
import enUsCurrencies from 'cldr-data/main/en/currencies.json';
import enUsNumbers from 'cldr-data/main/en/numbers.json';
import enUsTimeZoneNames from 'cldr-data/main/en/timeZoneNames.json';
import frUsCaGregorian from 'cldr-data/main/fr/ca-gregorian.json';
import frUsCurrencies from 'cldr-data/main/fr/currencies.json';
import frUsNumbers from 'cldr-data/main/fr/numbers.json';
import frUsTimeZoneNames from 'cldr-data/main/fr/timeZoneNames.json';
import nlUsCaGregorian from 'cldr-data/main/nl/ca-gregorian.json';
import nlUsCurrencies from 'cldr-data/main/nl/currencies.json';
import nlUsNumbers from 'cldr-data/main/nl/numbers.json';
import nlUsTimeZoneNames from 'cldr-data/main/nl/timeZoneNames.json';
import itUsCaGregorian from 'cldr-data/main/it/ca-gregorian.json';
import itUsCurrencies from 'cldr-data/main/it/currencies.json';
import itUsNumbers from 'cldr-data/main/it/numbers.json';
import itUsTimeZoneNames from 'cldr-data/main/it/timeZoneNames.json';
import currencyData from 'cldr-data/supplemental/currencyData.json';
import likelySubtags from 'cldr-data/supplemental/likelySubtags.json';
import metaZones from 'cldr-data/supplemental/metaZones.json';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import ordinals from 'cldr-data/supplemental/ordinals.json';
import plurals from 'cldr-data/supplemental/plurals.json';
import timeData from 'cldr-data/supplemental/timeData.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import 'cldr/event';
import 'cldr/supplemental';
import 'cldr/unresolved';
import deCldrData from 'devextreme-cldr-data/de.json';
import enCldrData from 'devextreme-cldr-data/en.json';
// TODO Vérifier que ça n'a pas d'incidence
import frCldrData from 'devextreme-cldr-data/fr.json';
import itCldrData from 'devextreme-cldr-data/it.json';
import nlCldrData from 'devextreme-cldr-data/nl.json';
import supplementalCldrData from 'devextreme-cldr-data/supplemental.json';
import config from 'devextreme/core/config';
import 'devextreme/localization/globalize/currency';
import 'devextreme/localization/globalize/date';
import 'devextreme/localization/globalize/message';
import 'devextreme/localization/globalize/number';
import deMessages from 'devextreme/localization/messages/de.json';
import enMessages from 'devextreme/localization/messages/en.json';
import frMessages from 'devextreme/localization/messages/fr.json';
import nlMessages from 'devextreme/localization/messages/nl.json';
import itMessages from 'devextreme/localization/messages/it.json';
import * as Globalize from 'globalize';
import 'globalize/currency';
import 'globalize/date';
import 'globalize/message';
import 'globalize/number';
import 'globalize/plural';
import 'globalize/relative-time';
import 'globalize/unit';
//import de from '../locales/de.json';
import analyticsCoreDe from '../locales/devextreme/dx-analytics-core.de.json';
import analyticsCoreFr from '../locales/devextreme/dx-analytics-core.fr.json';
import analyticsCoreNl from '../locales/devextreme/dx-analytics-core.nl.json';
import dashboardDe from '../locales/devextreme/dx-dashboard.de.json';
import dashboardFr from '../locales/devextreme/dx-dashboard.fr.json';
import dashboardNl from '../locales/devextreme/dx-dashboard.nl.json';
import dashboardIt from '../locales/devextreme/dx-dashboard.it.json';

import fileManagerFr from '../locales/devextreme/custom-devextreme.fr.json';
import fileManagerEn from '../locales/devextreme/custom-devextreme.en.json';
import fileManagerDe from '../locales/devextreme/custom-devextreme.de.json';
import fileManagerIt from '../locales/devextreme/custom-devextreme.it.json';
import fileManagerNl from '../locales/devextreme/custom-devextreme.nl.json';

//import en from '../locales/en.json';
//import fr from '../locales/fr.json';
//import it from '../locales/it.json';
//import nl from '../locales/nl.json';
import { ResourceManager } from 'devexpress-dashboard';

Globalize.load(frCldrData);
Globalize.load(enCldrData);
Globalize.load(nlCldrData);
Globalize.load(deCldrData);
Globalize.load(itCldrData);
Globalize.load(supplementalCldrData);

Globalize.load(likelySubtags);
Globalize.load(numberingSystems);
Globalize.load(plurals);
Globalize.load(metaZones);
Globalize.load(ordinals);

Globalize.load(currencyData);
Globalize.load(timeData);
Globalize.load(weekData);

Globalize.load(enUsNumbers);
Globalize.load(enUsCaGregorian);
Globalize.load(enUsTimeZoneNames);
Globalize.load(enUsCurrencies);

Globalize.load(frUsNumbers);
Globalize.load(frUsCaGregorian);
Globalize.load(frUsTimeZoneNames);
Globalize.load(frUsCurrencies);

Globalize.load(deUsNumbers);
Globalize.load(deUsCaGregorian);
Globalize.load(deUsTimeZoneNames);
Globalize.load(deUsCurrencies);

Globalize.load(nlUsNumbers);
Globalize.load(nlUsCaGregorian);
Globalize.load(nlUsTimeZoneNames);
Globalize.load(nlUsCurrencies);

Globalize.load(itUsNumbers);
Globalize.load(itUsCaGregorian);
Globalize.load(itUsTimeZoneNames);
Globalize.load(itUsCurrencies);
/**
 * Représente la culture de la langue
 *
 * @export
 * @class Locale
 */
export class Locale {
  name: string;
  value: string;
  /**
   * Représente la langue française
   *
   * @static
   * @returns {Locale} Retourne la langue française
   * @memberof Locale
   */
  static Fr(): Locale {
    return {
      name: 'Français',
      value: 'fr-FR',
    };
  }
  /**
   * Représente la langue anglaise
   *
   * @static
   * @returns {Locale} Retourne la langue anglaise
   * @memberof Locale
   */
  static En(): Locale {
    return {
      name: 'English',
      value: 'en-US',
    };
  }
  /**
   * Représente la langue allemande
   *
   * @static
   * @returns {Locale} Retourne la langue allemande
   * @memberof Locale
   */
  static De(): Locale {
    return {
      name: 'Deutsch',
      value: 'de-DE',
    };
  }
  /**
   * Représente la langue néerlandaise
   *
   * @static
   * @returns {Locale} Retourne la langue néerlandaise
   * @memberof Locale
   */
  static Nl(): Locale {
    return {
      name: 'Nederlands',
      value: 'nl-NL',
    };
  }
  /**
   * Représente la langue italienne
   *
   * @static
   * @returns {Locale} Retourne la langue italienne
   * @memberof Locale
   */
  static It(): Locale {
    return {
      name: 'Italian',
      value: 'it-IT',
    };
  }
}

let locales: Locale[] = [
  Locale.Fr(),
  Locale.It(),
  Locale.En(),
  Locale.De(),
  Locale.Nl(),
];

/**
 * Permet de gérer la localisation
 *
 * @export
 * @class GlobalizeService
 */

@Injectable({
  providedIn: 'root',
})
export class LocalizeService {
  _locale: Locale;
  constructor(private cultureService: CurrentCultureService) {}
  lang(): Locale[] {
    return locales;
  }
  initialize() {
    Globalize.loadMessages(frMessages);
    Globalize.loadMessages(enMessages);
    Globalize.loadMessages(nlMessages);
    Globalize.loadMessages(deMessages);
    Globalize.loadMessages(itMessages);

    Globalize.locale(navigator.language);
  }
  /**
   * Permet de connaitre les langues supportées
   *
   * @returns
   * @memberof GlobalizeService
   */
  getLocales() {
    return locales;
  }
  /**
   * Récupère la langue actuelle
   *
   * @returns {Locale} La langue actuelle
   * @memberof GlobalizeService
   */
  getLocal(): Locale {
    return this._locale;
  }
  /**
   * Permet de choisir sa langue
   *
   * @param {Locale} local La classe locale
   * @memberof GlobalizeService
   */
  setLocal(local: Locale, modelLang: string = null) {
    this._locale = local;
    config({ defaultCurrency: 'EUR', forceIsoDateParsing: true });
    switch (this._locale.value) {
      case Locale.Fr().value:
        Globalize.loadMessages(dashboardFr);
        Globalize.loadMessages(analyticsCoreFr);
        Globalize.loadMessages(frMessages);
        ResourceManager.setLocalizationMessages(dashboardFr);
        Globalize.loadMessages(fileManagerFr);
        break;
      case Locale.En().value:
        Globalize.loadMessages(enMessages);
        Globalize.loadMessages(fileManagerEn);
        break;

      case Locale.De().value:
        ResourceManager.setLocalizationMessages(dashboardDe);
        Globalize.loadMessages(dashboardDe);
        Globalize.loadMessages(deMessages);
        Globalize.loadMessages(analyticsCoreDe);
        Globalize.loadMessages(fileManagerDe);
        break;

      case Locale.Nl().value:
        Globalize.loadMessages(dashboardNl);
        ResourceManager.setLocalizationMessages(dashboardNl);
        Globalize.loadMessages(analyticsCoreNl);
        Globalize.loadMessages(nlMessages);
        Globalize.loadMessages(fileManagerNl);
        break;

      case Locale.It().value:
        Globalize.loadMessages(itMessages);
        ResourceManager.setLocalizationMessages(dashboardIt);
        Globalize.loadMessages(fileManagerIt);
        break;
      default:
        Globalize.loadMessages(dashboardFr);
        Globalize.loadMessages(analyticsCoreFr);
        Globalize.loadMessages(frMessages);
        ResourceManager.setLocalizationMessages(dashboardFr);
        Globalize.loadMessages(fileManagerFr);
        break;
    }
    if (modelLang != null) {
      Globalize.loadMessages(modelLang);
    }

    if (this._locale.value != undefined) {
      Globalize.locale(this._locale.value);
    } else {
      //Prend en compte la langue du navigateur au cas échéant
      Globalize.locale(navigator.language);
    }
  }
  /** Permet la recuperation des json de traduction pour le report */
  static getTranslationMessages(): any[] {
    let globalize = new Globalize(Globalize.locale().locale);
    let result = [];
    switch (globalize.cldr.attributes.language) {
      case 'fr':
        result.push(frDxMessages);
        result.push(frDxReportMessages);
        break;
      case 'de':
        result.push(deDxMessages);
        result.push(deDxReportMessages);
        break;
      case 'nl':
        result.push(nlDxMessages);
        result.push(nlDxReportMessages);
        break;
      case 'it':
        //result.push(itDxMessages);
        //result.push(itDxReportMessages);
        break;
      default:
        result.push(frDxMessages);
        result.push(frDxReportMessages);
        break;
    }
    return result;
  }
}
