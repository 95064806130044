import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAssetLoansBaseVariables, FirstAssetLoansBaseVariables, CountAssetLoansBaseVariables, FindAssetLoansBaseVariables, SearchAssetLoansBaseVariables, ExportSchemaAssetLoansBaseVariables, ExportDataAssetLoansBaseVariables, CustomQueryAssetLoansBaseVariables, CustomQueryIdAssetLoansBaseVariables, UsedAssetLoansBaseVariables, ExistAssetLoansBaseVariables, InsertAssetLoansBaseVariables, UpdateAssetLoansBaseVariables, ImportDataAssetLoansBaseVariables, UpdateManyAssetLoansBaseVariables, DeleteAssetLoansBaseVariables } from '../gqls'
import { GetAssetLoansDocument, FirstAssetLoansDocument, CountAssetLoansDocument, FindAssetLoansDocument, SearchAssetLoansDocument, ExportSchemaAssetLoansDocument, ExportDataAssetLoansDocument, CustomQueryAssetLoansDocument, CustomQueryIdAssetLoansDocument, UsedAssetLoansDocument, ExistAssetLoansDocument, InsertAssetLoansDocument, UpdateAssetLoansDocument, ImportDataAssetLoansDocument, UpdateManyAssetLoansDocument, DeleteAssetLoansDocument } from '../gqls'
import { ServiceSingleResultOfAssetLoan, QueryContextOfAssetLoan, FilterOfAssetLoan, ServiceSingleResultOfInt64, ServiceListResultOfAssetLoan, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, AssetLoanInput, FieldUpdateOperatorOfAssetLoan } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AssetLoanBaseService {
    
public modelName = 'assetLoan';
public modelPluralName = 'assetLoans';

	private getAssetLoansQuery: GetAssetLoansDocument;
	private firstAssetLoansQuery: FirstAssetLoansDocument;
	private countAssetLoansQuery: CountAssetLoansDocument;
	private findAssetLoansQuery: FindAssetLoansDocument;
	private searchAssetLoansQuery: SearchAssetLoansDocument;
	private exportSchemaAssetLoansQuery: ExportSchemaAssetLoansDocument;
	private exportDataAssetLoansQuery: ExportDataAssetLoansDocument;
	private customQueryAssetLoansQuery: CustomQueryAssetLoansDocument;
	private customQueryIdAssetLoansQuery: CustomQueryIdAssetLoansDocument;
	private usedAssetLoansQuery: UsedAssetLoansDocument;
	private existAssetLoansQuery: ExistAssetLoansDocument;
	private insertAssetLoansMutation: InsertAssetLoansDocument;
	private updateAssetLoansMutation: UpdateAssetLoansDocument;
	private importDataAssetLoansMutation: ImportDataAssetLoansDocument;
	private updateManyAssetLoansMutation: UpdateManyAssetLoansDocument;
	private deleteAssetLoansMutation: DeleteAssetLoansDocument;

	constructor(private injector: Injector) {
		this.getAssetLoansQuery = this.injector.get(GetAssetLoansDocument);
		this.firstAssetLoansQuery = this.injector.get(FirstAssetLoansDocument);
		this.countAssetLoansQuery = this.injector.get(CountAssetLoansDocument);
		this.findAssetLoansQuery = this.injector.get(FindAssetLoansDocument);
		this.searchAssetLoansQuery = this.injector.get(SearchAssetLoansDocument);
		this.exportSchemaAssetLoansQuery = this.injector.get(ExportSchemaAssetLoansDocument);
		this.exportDataAssetLoansQuery = this.injector.get(ExportDataAssetLoansDocument);
		this.customQueryAssetLoansQuery = this.injector.get(CustomQueryAssetLoansDocument);
		this.customQueryIdAssetLoansQuery = this.injector.get(CustomQueryIdAssetLoansDocument);
		this.usedAssetLoansQuery = this.injector.get(UsedAssetLoansDocument);
		this.existAssetLoansQuery = this.injector.get(ExistAssetLoansDocument);
		this.insertAssetLoansMutation = this.injector.get(InsertAssetLoansDocument);
		this.updateAssetLoansMutation = this.injector.get(UpdateAssetLoansDocument);
		this.importDataAssetLoansMutation = this.injector.get(ImportDataAssetLoansDocument);
		this.updateManyAssetLoansMutation = this.injector.get(UpdateManyAssetLoansDocument);
		this.deleteAssetLoansMutation = this.injector.get(DeleteAssetLoansDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetLoan> {
    
    		let variables: GetAssetLoansBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAssetLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.get));
            }
            else{
                let result:ServiceSingleResultOfAssetLoan={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetLoan,
		@Args('filter?') filter?: FilterOfAssetLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetLoan> {
    
    		let variables: FirstAssetLoansBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAssetLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAssetLoansBaseVariables = {
    			filter: filter
    		}
    				return this.countAssetLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetLoan,
		@Args('filter?') filter?: FilterOfAssetLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetLoan> {
    
    		let variables: FindAssetLoansBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAssetLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAssetLoan,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetLoan> {
    
    		let variables: SearchAssetLoansBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAssetLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAssetLoansBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAssetLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAssetLoansBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAssetLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAssetLoan,
		@Args('filter?') filter?: FilterOfAssetLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetLoan> {
    
    		let variables: CustomQueryAssetLoansBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAssetLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetLoan,
		@Args('filter?') filter?: FilterOfAssetLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetLoan> {
    
    		let variables: CustomQueryIdAssetLoansBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAssetLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAssetLoansBaseVariables = {
    			ids: ids
    		}
    				return this.usedAssetLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAssetLoansBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAssetLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AssetLoanInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetLoan> {
    
    		let variables: InsertAssetLoansBaseVariables = {
    			entity: entity
    		}
    				return this.insertAssetLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetLoan> {
    
    		let variables: UpdateAssetLoansBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAssetLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAssetLoansBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAssetLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAssetLoansBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAssetLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetLoansBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetLoans.delete));
    	}
    
}