import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLoanStatusesBaseVariables, FirstLoanStatusesBaseVariables, CountLoanStatusesBaseVariables, FindLoanStatusesBaseVariables, SearchLoanStatusesBaseVariables, ExportSchemaLoanStatusesBaseVariables, ExportDataLoanStatusesBaseVariables, CustomQueryLoanStatusesBaseVariables, CustomQueryIdLoanStatusesBaseVariables, UsedLoanStatusesBaseVariables, ExistLoanStatusesBaseVariables, FindUnassociatedLoansLoanStatusesBaseVariables, InsertLoanStatusesBaseVariables, UpdateLoanStatusesBaseVariables, ImportDataLoanStatusesBaseVariables, UpdateManyLoanStatusesBaseVariables, DeleteLoanStatusesBaseVariables, AddLoansLoanStatusesBaseVariables, RemoveLoansLoanStatusesBaseVariables } from '../gqls'
import { GetLoanStatusesDocument, FirstLoanStatusesDocument, CountLoanStatusesDocument, FindLoanStatusesDocument, SearchLoanStatusesDocument, ExportSchemaLoanStatusesDocument, ExportDataLoanStatusesDocument, CustomQueryLoanStatusesDocument, CustomQueryIdLoanStatusesDocument, UsedLoanStatusesDocument, ExistLoanStatusesDocument, FindUnassociatedLoansLoanStatusesDocument, InsertLoanStatusesDocument, UpdateLoanStatusesDocument, ImportDataLoanStatusesDocument, UpdateManyLoanStatusesDocument, DeleteLoanStatusesDocument, AddLoansLoanStatusesDocument, RemoveLoansLoanStatusesDocument } from '../gqls'
import { ServiceSingleResultOfLoanStatus, QueryContextOfLoanStatus, FilterOfLoanStatus, ServiceSingleResultOfInt64, ServiceListResultOfLoanStatus, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfLoan, FilterOfLoan, ServiceListResultOfLoan, LoanStatusInput, FieldUpdateOperatorOfLoanStatus } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LoanStatusBaseService {
    
public modelName = 'loanStatus';
public modelPluralName = 'loanStatuses';

	private getLoanStatusesQuery: GetLoanStatusesDocument;
	private firstLoanStatusesQuery: FirstLoanStatusesDocument;
	private countLoanStatusesQuery: CountLoanStatusesDocument;
	private findLoanStatusesQuery: FindLoanStatusesDocument;
	private searchLoanStatusesQuery: SearchLoanStatusesDocument;
	private exportSchemaLoanStatusesQuery: ExportSchemaLoanStatusesDocument;
	private exportDataLoanStatusesQuery: ExportDataLoanStatusesDocument;
	private customQueryLoanStatusesQuery: CustomQueryLoanStatusesDocument;
	private customQueryIdLoanStatusesQuery: CustomQueryIdLoanStatusesDocument;
	private usedLoanStatusesQuery: UsedLoanStatusesDocument;
	private existLoanStatusesQuery: ExistLoanStatusesDocument;
	private findUnassociatedLoansLoanStatusesQuery: FindUnassociatedLoansLoanStatusesDocument;
	private insertLoanStatusesMutation: InsertLoanStatusesDocument;
	private updateLoanStatusesMutation: UpdateLoanStatusesDocument;
	private importDataLoanStatusesMutation: ImportDataLoanStatusesDocument;
	private updateManyLoanStatusesMutation: UpdateManyLoanStatusesDocument;
	private deleteLoanStatusesMutation: DeleteLoanStatusesDocument;
	private addLoansLoanStatusesMutation: AddLoansLoanStatusesDocument;
	private removeLoansLoanStatusesMutation: RemoveLoansLoanStatusesDocument;

	constructor(private injector: Injector) {
		this.getLoanStatusesQuery = this.injector.get(GetLoanStatusesDocument);
		this.firstLoanStatusesQuery = this.injector.get(FirstLoanStatusesDocument);
		this.countLoanStatusesQuery = this.injector.get(CountLoanStatusesDocument);
		this.findLoanStatusesQuery = this.injector.get(FindLoanStatusesDocument);
		this.searchLoanStatusesQuery = this.injector.get(SearchLoanStatusesDocument);
		this.exportSchemaLoanStatusesQuery = this.injector.get(ExportSchemaLoanStatusesDocument);
		this.exportDataLoanStatusesQuery = this.injector.get(ExportDataLoanStatusesDocument);
		this.customQueryLoanStatusesQuery = this.injector.get(CustomQueryLoanStatusesDocument);
		this.customQueryIdLoanStatusesQuery = this.injector.get(CustomQueryIdLoanStatusesDocument);
		this.usedLoanStatusesQuery = this.injector.get(UsedLoanStatusesDocument);
		this.existLoanStatusesQuery = this.injector.get(ExistLoanStatusesDocument);
		this.findUnassociatedLoansLoanStatusesQuery = this.injector.get(FindUnassociatedLoansLoanStatusesDocument);
		this.insertLoanStatusesMutation = this.injector.get(InsertLoanStatusesDocument);
		this.updateLoanStatusesMutation = this.injector.get(UpdateLoanStatusesDocument);
		this.importDataLoanStatusesMutation = this.injector.get(ImportDataLoanStatusesDocument);
		this.updateManyLoanStatusesMutation = this.injector.get(UpdateManyLoanStatusesDocument);
		this.deleteLoanStatusesMutation = this.injector.get(DeleteLoanStatusesDocument);
		this.addLoansLoanStatusesMutation = this.injector.get(AddLoansLoanStatusesDocument);
		this.removeLoansLoanStatusesMutation = this.injector.get(RemoveLoansLoanStatusesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLoanStatus> {
    
    		let variables: GetLoanStatusesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLoanStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.get));
            }
            else{
                let result:ServiceSingleResultOfLoanStatus={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLoanStatus,
		@Args('filter?') filter?: FilterOfLoanStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLoanStatus> {
    
    		let variables: FirstLoanStatusesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLoanStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLoanStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLoanStatusesBaseVariables = {
    			filter: filter
    		}
    				return this.countLoanStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLoanStatus,
		@Args('filter?') filter?: FilterOfLoanStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLoanStatus> {
    
    		let variables: FindLoanStatusesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLoanStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLoanStatus,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLoanStatus> {
    
    		let variables: SearchLoanStatusesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLoanStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLoanStatusesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLoanStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLoanStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLoanStatusesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLoanStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLoanStatus,
		@Args('filter?') filter?: FilterOfLoanStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLoanStatus> {
    
    		let variables: CustomQueryLoanStatusesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLoanStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLoanStatus,
		@Args('filter?') filter?: FilterOfLoanStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLoanStatus> {
    
    		let variables: CustomQueryIdLoanStatusesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLoanStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLoanStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.usedLoanStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLoanStatusesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLoanStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.exist));
    	}

	@InjectArgs
	public findAssociatedLoans(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfLoan,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLoan,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfLoan> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('loans', fields, null, [
			GqlSubFieldArg.create('filterOfLoans', 'filter'),
			GqlSubFieldArg.create('optionsOfLoans', 'options'),
		]),
		])
		extendedVariables['filterOfLoans'] = filter;
		extendedVariables['optionsOfLoans'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.loans));
            }
            else{
                let result: ServiceListResultOfLoan = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedLoans(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLoan,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLoan> {
    
    		let variables: FindUnassociatedLoansLoanStatusesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedLoansLoanStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.findUnassociatedLoans));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: LoanStatusInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLoanStatus> {
    
    		let variables: InsertLoanStatusesBaseVariables = {
    			entity: entity
    		}
    				return this.insertLoanStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLoanStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLoanStatus> {
    
    		let variables: UpdateLoanStatusesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateLoanStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataLoanStatusesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataLoanStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfLoanStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyLoanStatusesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyLoanStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteLoanStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteLoanStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addLoans(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('loanIds') loanIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddLoansLoanStatusesBaseVariables = {
    			id: id,
			loanIds: loanIds
    		}
    				return this.addLoansLoanStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.addLoans));
    	}

    	/**  */
    	@InjectArgs
    	public removeLoans(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('loanIds') loanIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveLoansLoanStatusesBaseVariables = {
    			loanIds: loanIds
    		}
    				return this.removeLoansLoanStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loanStatuses.removeLoans));
    	}
    
}