import { SoftwareGroupBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { InjectArgs } from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlFields, GqlSubField } from '../helpers';

@Injectable({ providedIn: 'root' })
@Authorize('software.manage-software-group')
export class SoftwareGroupCoreService extends SoftwareGroupBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  /** Obtient les fields de recherche */
  public searchFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('name'),
        GqlField.create('id'),
      ]),
      GqlField.create('totalCount'),
    ];
  }
}
