import { Injectable } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField } from '../helpers';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { IncidentCoreService } from './incident.service';
@Injectable({ providedIn: 'root' })
@Authorize('incident-help-desk-user')
export class IncidentHelpDeskCoreService extends IncidentCoreService {
  @InjectArgs
  public getCountElement(@Args('type') type: string) {
    switch (type) {
      case 'new':
        return this.findByUser([GqlField.create('totalCount')], 'new');
      case 'in-progress':
        return this.findByUser([GqlField.create('totalCount')], 'in-progress');
      case 'closed':
        return this.findByUser([GqlField.create('totalCount')], 'closed');
      case 'open':
        return this.findByUser([GqlField.create('totalCount')], 'open');

      case 'my-service':
        return this.findByCurrentSubordinate([GqlField.create('totalCount')]);
    }
  }
}
