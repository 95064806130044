import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetDomainsBaseVariables, FirstDomainsBaseVariables, CountDomainsBaseVariables, FindDomainsBaseVariables, SearchDomainsBaseVariables, ExportSchemaDomainsBaseVariables, ExportDataDomainsBaseVariables, CustomQueryDomainsBaseVariables, CustomQueryIdDomainsBaseVariables, UsedDomainsBaseVariables, ExistDomainsBaseVariables, InsertDomainsBaseVariables, UpdateDomainsBaseVariables, ImportDataDomainsBaseVariables, UpdateManyDomainsBaseVariables, DeleteDomainsBaseVariables } from '../gqls'
import { GetDomainsDocument, FirstDomainsDocument, CountDomainsDocument, FindDomainsDocument, SearchDomainsDocument, ExportSchemaDomainsDocument, ExportDataDomainsDocument, CustomQueryDomainsDocument, CustomQueryIdDomainsDocument, UsedDomainsDocument, ExistDomainsDocument, InsertDomainsDocument, UpdateDomainsDocument, ImportDataDomainsDocument, UpdateManyDomainsDocument, DeleteDomainsDocument } from '../gqls'
import { ServiceSingleResultOfDomain, QueryContextOfDomain, FilterOfDomain, ServiceSingleResultOfInt64, ServiceListResultOfDomain, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, DomainInput, FieldUpdateOperatorOfDomain } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class DomainBaseService {
    
public modelName = 'domain';
public modelPluralName = 'domains';

	private getDomainsQuery: GetDomainsDocument;
	private firstDomainsQuery: FirstDomainsDocument;
	private countDomainsQuery: CountDomainsDocument;
	private findDomainsQuery: FindDomainsDocument;
	private searchDomainsQuery: SearchDomainsDocument;
	private exportSchemaDomainsQuery: ExportSchemaDomainsDocument;
	private exportDataDomainsQuery: ExportDataDomainsDocument;
	private customQueryDomainsQuery: CustomQueryDomainsDocument;
	private customQueryIdDomainsQuery: CustomQueryIdDomainsDocument;
	private usedDomainsQuery: UsedDomainsDocument;
	private existDomainsQuery: ExistDomainsDocument;
	private insertDomainsMutation: InsertDomainsDocument;
	private updateDomainsMutation: UpdateDomainsDocument;
	private importDataDomainsMutation: ImportDataDomainsDocument;
	private updateManyDomainsMutation: UpdateManyDomainsDocument;
	private deleteDomainsMutation: DeleteDomainsDocument;

	constructor(private injector: Injector) {
		this.getDomainsQuery = this.injector.get(GetDomainsDocument);
		this.firstDomainsQuery = this.injector.get(FirstDomainsDocument);
		this.countDomainsQuery = this.injector.get(CountDomainsDocument);
		this.findDomainsQuery = this.injector.get(FindDomainsDocument);
		this.searchDomainsQuery = this.injector.get(SearchDomainsDocument);
		this.exportSchemaDomainsQuery = this.injector.get(ExportSchemaDomainsDocument);
		this.exportDataDomainsQuery = this.injector.get(ExportDataDomainsDocument);
		this.customQueryDomainsQuery = this.injector.get(CustomQueryDomainsDocument);
		this.customQueryIdDomainsQuery = this.injector.get(CustomQueryIdDomainsDocument);
		this.usedDomainsQuery = this.injector.get(UsedDomainsDocument);
		this.existDomainsQuery = this.injector.get(ExistDomainsDocument);
		this.insertDomainsMutation = this.injector.get(InsertDomainsDocument);
		this.updateDomainsMutation = this.injector.get(UpdateDomainsDocument);
		this.importDataDomainsMutation = this.injector.get(ImportDataDomainsDocument);
		this.updateManyDomainsMutation = this.injector.get(UpdateManyDomainsDocument);
		this.deleteDomainsMutation = this.injector.get(DeleteDomainsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDomain> {
    
    		let variables: GetDomainsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getDomainsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.domains.get));
            }
            else{
                let result:ServiceSingleResultOfDomain={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDomain,
		@Args('filter?') filter?: FilterOfDomain,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDomain> {
    
    		let variables: FirstDomainsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstDomainsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.domains.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDomain,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountDomainsBaseVariables = {
    			filter: filter
    		}
    				return this.countDomainsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.domains.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDomain,
		@Args('filter?') filter?: FilterOfDomain,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDomain> {
    
    		let variables: FindDomainsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findDomainsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.domains.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfDomain,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDomain> {
    
    		let variables: SearchDomainsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchDomainsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.domains.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaDomainsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaDomainsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.domains.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDomain,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataDomainsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataDomainsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.domains.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfDomain,
		@Args('filter?') filter?: FilterOfDomain,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDomain> {
    
    		let variables: CustomQueryDomainsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryDomainsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.domains.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfDomain,
		@Args('filter?') filter?: FilterOfDomain,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDomain> {
    
    		let variables: CustomQueryIdDomainsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdDomainsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.domains.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedDomainsBaseVariables = {
    			ids: ids
    		}
    				return this.usedDomainsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.domains.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistDomainsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existDomainsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.domains.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: DomainInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDomain> {
    
    		let variables: InsertDomainsBaseVariables = {
    			entity: entity
    		}
    				return this.insertDomainsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.domains.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfDomain,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDomain> {
    
    		let variables: UpdateDomainsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateDomainsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.domains.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataDomainsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataDomainsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.domains.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfDomain,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyDomainsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyDomainsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.domains.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteDomainsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteDomainsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.domains.delete));
    	}
    
}