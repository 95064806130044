<dx-tag-box
  (onInitialized)="onInitialized($event)"
  (onOpened)="onTagBoxOpened($event)"
  (onCustomItemCreating)="onTagBoxCustomItemCreating($event)"
  (onFocusOut)="onFocusOut($event)"
  (onKeyDown)="onKeyDown($event)"
  (onValueChanged)="onValueChanged($event)"
  [acceptCustomValue]="true"
  [dataSource]="sourceUsers"
  [searchEnabled]="loadUsers || loadConnectors"
  [displayExpr]="'name'"
  [valueExpr]="loadUsers === true ? 'email' : 'name'"
  [value]="_values"
  [multiline]="true"
  placeholder=""
  [showSelectionControls]="!loadUsers && !loadConnectors"
  [itemTemplate]="'customItem'"
>
  <div *dxTemplate="let data of 'customItem'">
    <div class="custom-item">
      <div class="item-search-result user-name-email-flexbox">
        <div>{{ data?.name }}</div>
        <span *ngIf="data?.email?.includes('@')">{{ data?.email }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="loadUsers === false && !loadConnectors">
    <div *dxTemplate="let tagData of 'tag'" class="dx-tag-content">
      {{ getDisplay(tagData) }}
    </div>
  </div>
</dx-tag-box>
