import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetUserPrivilegesBaseVariables, FirstUserPrivilegesBaseVariables, CountUserPrivilegesBaseVariables, FindUserPrivilegesBaseVariables, SearchUserPrivilegesBaseVariables, ExportSchemaUserPrivilegesBaseVariables, ExportDataUserPrivilegesBaseVariables, CustomQueryUserPrivilegesBaseVariables, CustomQueryIdUserPrivilegesBaseVariables, UsedUserPrivilegesBaseVariables, FindNotUsersUserPrivilegesBaseVariables, FindUsersUserPrivilegesBaseVariables, DefaultEntryValueUserPrivilegesBaseVariables, ExistUserPrivilegesBaseVariables, FindRecyclesUserPrivilegesBaseVariables, CountRecyclesUserPrivilegesBaseVariables, ReadOnlyUserPrivilegesBaseVariables, FindArchivedUserPrivilegesBaseVariables, CountAllUserPrivilegesBaseVariables, FindDynamicPropertyFieldsUserPrivilegesBaseVariables, FindUnassociatedUsersUserPrivilegesBaseVariables, InsertUserPrivilegesBaseVariables, UpdateUserPrivilegesBaseVariables, ImportDataUserPrivilegesBaseVariables, UpdateManyUserPrivilegesBaseVariables, DeleteUserPrivilegesBaseVariables, AddUsersCustomUserPrivilegesBaseVariables, RemoveUsersCustomUserPrivilegesBaseVariables, RestoreUserPrivilegesBaseVariables, RecycleUserPrivilegesBaseVariables, RestoreArchivedUserPrivilegesBaseVariables, ArchivedUserPrivilegesBaseVariables, AddFileDynamicFieldUserPrivilegesBaseVariables, RemoveFileDynamicFieldUserPrivilegesBaseVariables, AddUsersUserPrivilegesBaseVariables, RemoveUsersUserPrivilegesBaseVariables } from '../gqls'
import { GetUserPrivilegesDocument, FirstUserPrivilegesDocument, CountUserPrivilegesDocument, FindUserPrivilegesDocument, SearchUserPrivilegesDocument, ExportSchemaUserPrivilegesDocument, ExportDataUserPrivilegesDocument, CustomQueryUserPrivilegesDocument, CustomQueryIdUserPrivilegesDocument, UsedUserPrivilegesDocument, FindNotUsersUserPrivilegesDocument, FindUsersUserPrivilegesDocument, DefaultEntryValueUserPrivilegesDocument, ExistUserPrivilegesDocument, FindRecyclesUserPrivilegesDocument, CountRecyclesUserPrivilegesDocument, ReadOnlyUserPrivilegesDocument, FindArchivedUserPrivilegesDocument, CountAllUserPrivilegesDocument, FindDynamicPropertyFieldsUserPrivilegesDocument, FindUnassociatedUsersUserPrivilegesDocument, InsertUserPrivilegesDocument, UpdateUserPrivilegesDocument, ImportDataUserPrivilegesDocument, UpdateManyUserPrivilegesDocument, DeleteUserPrivilegesDocument, AddUsersCustomUserPrivilegesDocument, RemoveUsersCustomUserPrivilegesDocument, RestoreUserPrivilegesDocument, RecycleUserPrivilegesDocument, RestoreArchivedUserPrivilegesDocument, ArchivedUserPrivilegesDocument, AddFileDynamicFieldUserPrivilegesDocument, RemoveFileDynamicFieldUserPrivilegesDocument, AddUsersUserPrivilegesDocument, RemoveUsersUserPrivilegesDocument } from '../gqls'
import { ServiceSingleResultOfUserPrivilege, QueryContextOfUserPrivilege, FilterOfUserPrivilege, ServiceSingleResultOfInt64, ServiceListResultOfUserPrivilege, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfUser, QueryContextOfUser, FilterOfUser, ServiceListResultOfPrivilegeEntry, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfUserPrivilege, UserPrivilegeInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class UserPrivilegeBaseService {
    
public modelName = 'userPrivilege';
public modelPluralName = 'userPrivileges';

	private getUserPrivilegesQuery: GetUserPrivilegesDocument;
	private firstUserPrivilegesQuery: FirstUserPrivilegesDocument;
	private countUserPrivilegesQuery: CountUserPrivilegesDocument;
	private findUserPrivilegesQuery: FindUserPrivilegesDocument;
	private searchUserPrivilegesQuery: SearchUserPrivilegesDocument;
	private exportSchemaUserPrivilegesQuery: ExportSchemaUserPrivilegesDocument;
	private exportDataUserPrivilegesQuery: ExportDataUserPrivilegesDocument;
	private customQueryUserPrivilegesQuery: CustomQueryUserPrivilegesDocument;
	private customQueryIdUserPrivilegesQuery: CustomQueryIdUserPrivilegesDocument;
	private usedUserPrivilegesQuery: UsedUserPrivilegesDocument;
	private findNotUsersUserPrivilegesQuery: FindNotUsersUserPrivilegesDocument;
	private findUsersUserPrivilegesQuery: FindUsersUserPrivilegesDocument;
	private defaultEntryValueUserPrivilegesQuery: DefaultEntryValueUserPrivilegesDocument;
	private existUserPrivilegesQuery: ExistUserPrivilegesDocument;
	private findRecyclesUserPrivilegesQuery: FindRecyclesUserPrivilegesDocument;
	private countRecyclesUserPrivilegesQuery: CountRecyclesUserPrivilegesDocument;
	private readOnlyUserPrivilegesQuery: ReadOnlyUserPrivilegesDocument;
	private findArchivedUserPrivilegesQuery: FindArchivedUserPrivilegesDocument;
	private countAllUserPrivilegesQuery: CountAllUserPrivilegesDocument;
	private findDynamicPropertyFieldsUserPrivilegesQuery: FindDynamicPropertyFieldsUserPrivilegesDocument;
	private findUnassociatedUsersUserPrivilegesQuery: FindUnassociatedUsersUserPrivilegesDocument;
	private insertUserPrivilegesMutation: InsertUserPrivilegesDocument;
	private updateUserPrivilegesMutation: UpdateUserPrivilegesDocument;
	private importDataUserPrivilegesMutation: ImportDataUserPrivilegesDocument;
	private updateManyUserPrivilegesMutation: UpdateManyUserPrivilegesDocument;
	private deleteUserPrivilegesMutation: DeleteUserPrivilegesDocument;
	private addUsersCustomUserPrivilegesMutation: AddUsersCustomUserPrivilegesDocument;
	private removeUsersCustomUserPrivilegesMutation: RemoveUsersCustomUserPrivilegesDocument;
	private restoreUserPrivilegesMutation: RestoreUserPrivilegesDocument;
	private recycleUserPrivilegesMutation: RecycleUserPrivilegesDocument;
	private restoreArchivedUserPrivilegesMutation: RestoreArchivedUserPrivilegesDocument;
	private archivedUserPrivilegesMutation: ArchivedUserPrivilegesDocument;
	private addFileDynamicFieldUserPrivilegesMutation: AddFileDynamicFieldUserPrivilegesDocument;
	private removeFileDynamicFieldUserPrivilegesMutation: RemoveFileDynamicFieldUserPrivilegesDocument;
	private addUsersUserPrivilegesMutation: AddUsersUserPrivilegesDocument;
	private removeUsersUserPrivilegesMutation: RemoveUsersUserPrivilegesDocument;

	constructor(private injector: Injector) {
		this.getUserPrivilegesQuery = this.injector.get(GetUserPrivilegesDocument);
		this.firstUserPrivilegesQuery = this.injector.get(FirstUserPrivilegesDocument);
		this.countUserPrivilegesQuery = this.injector.get(CountUserPrivilegesDocument);
		this.findUserPrivilegesQuery = this.injector.get(FindUserPrivilegesDocument);
		this.searchUserPrivilegesQuery = this.injector.get(SearchUserPrivilegesDocument);
		this.exportSchemaUserPrivilegesQuery = this.injector.get(ExportSchemaUserPrivilegesDocument);
		this.exportDataUserPrivilegesQuery = this.injector.get(ExportDataUserPrivilegesDocument);
		this.customQueryUserPrivilegesQuery = this.injector.get(CustomQueryUserPrivilegesDocument);
		this.customQueryIdUserPrivilegesQuery = this.injector.get(CustomQueryIdUserPrivilegesDocument);
		this.usedUserPrivilegesQuery = this.injector.get(UsedUserPrivilegesDocument);
		this.findNotUsersUserPrivilegesQuery = this.injector.get(FindNotUsersUserPrivilegesDocument);
		this.findUsersUserPrivilegesQuery = this.injector.get(FindUsersUserPrivilegesDocument);
		this.defaultEntryValueUserPrivilegesQuery = this.injector.get(DefaultEntryValueUserPrivilegesDocument);
		this.existUserPrivilegesQuery = this.injector.get(ExistUserPrivilegesDocument);
		this.findRecyclesUserPrivilegesQuery = this.injector.get(FindRecyclesUserPrivilegesDocument);
		this.countRecyclesUserPrivilegesQuery = this.injector.get(CountRecyclesUserPrivilegesDocument);
		this.readOnlyUserPrivilegesQuery = this.injector.get(ReadOnlyUserPrivilegesDocument);
		this.findArchivedUserPrivilegesQuery = this.injector.get(FindArchivedUserPrivilegesDocument);
		this.countAllUserPrivilegesQuery = this.injector.get(CountAllUserPrivilegesDocument);
		this.findDynamicPropertyFieldsUserPrivilegesQuery = this.injector.get(FindDynamicPropertyFieldsUserPrivilegesDocument);
		this.findUnassociatedUsersUserPrivilegesQuery = this.injector.get(FindUnassociatedUsersUserPrivilegesDocument);
		this.insertUserPrivilegesMutation = this.injector.get(InsertUserPrivilegesDocument);
		this.updateUserPrivilegesMutation = this.injector.get(UpdateUserPrivilegesDocument);
		this.importDataUserPrivilegesMutation = this.injector.get(ImportDataUserPrivilegesDocument);
		this.updateManyUserPrivilegesMutation = this.injector.get(UpdateManyUserPrivilegesDocument);
		this.deleteUserPrivilegesMutation = this.injector.get(DeleteUserPrivilegesDocument);
		this.addUsersCustomUserPrivilegesMutation = this.injector.get(AddUsersCustomUserPrivilegesDocument);
		this.removeUsersCustomUserPrivilegesMutation = this.injector.get(RemoveUsersCustomUserPrivilegesDocument);
		this.restoreUserPrivilegesMutation = this.injector.get(RestoreUserPrivilegesDocument);
		this.recycleUserPrivilegesMutation = this.injector.get(RecycleUserPrivilegesDocument);
		this.restoreArchivedUserPrivilegesMutation = this.injector.get(RestoreArchivedUserPrivilegesDocument);
		this.archivedUserPrivilegesMutation = this.injector.get(ArchivedUserPrivilegesDocument);
		this.addFileDynamicFieldUserPrivilegesMutation = this.injector.get(AddFileDynamicFieldUserPrivilegesDocument);
		this.removeFileDynamicFieldUserPrivilegesMutation = this.injector.get(RemoveFileDynamicFieldUserPrivilegesDocument);
		this.addUsersUserPrivilegesMutation = this.injector.get(AddUsersUserPrivilegesDocument);
		this.removeUsersUserPrivilegesMutation = this.injector.get(RemoveUsersUserPrivilegesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserPrivilege> {
    
    		let variables: GetUserPrivilegesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.get));
            }
            else{
                let result:ServiceSingleResultOfUserPrivilege={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUserPrivilege,
		@Args('filter?') filter?: FilterOfUserPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserPrivilege> {
    
    		let variables: FirstUserPrivilegesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUserPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountUserPrivilegesBaseVariables = {
    			filter: filter
    		}
    				return this.countUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUserPrivilege,
		@Args('filter?') filter?: FilterOfUserPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserPrivilege> {
    
    		let variables: FindUserPrivilegesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfUserPrivilege,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserPrivilege> {
    
    		let variables: SearchUserPrivilegesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaUserPrivilegesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUserPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataUserPrivilegesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfUserPrivilege,
		@Args('filter?') filter?: FilterOfUserPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserPrivilege> {
    
    		let variables: CustomQueryUserPrivilegesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfUserPrivilege,
		@Args('filter?') filter?: FilterOfUserPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserPrivilege> {
    
    		let variables: CustomQueryIdUserPrivilegesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedUserPrivilegesBaseVariables = {
    			ids: ids
    		}
    				return this.usedUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.used));
    	}

    	/**  */
    	@InjectArgs
    	public findNotUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('memberOfPopulationIds') memberOfPopulationIds: Array<string>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindNotUsersUserPrivilegesBaseVariables = {
    			id: id,
			memberOfPopulationIds: memberOfPopulationIds,
			filter: filter,
			options: options
    		}
    				return this.findNotUsersUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.findNotUsers));
    	}

    	/**  */
    	@InjectArgs
    	public findUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('memberOfPopulationIds') memberOfPopulationIds: Array<string>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUsersUserPrivilegesBaseVariables = {
    			id: id,
			memberOfPopulationIds: memberOfPopulationIds,
			filter: filter,
			options: options
    		}
    				return this.findUsersUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.findUsers));
    	}

    	/**  */
    	@InjectArgs
    	public defaultEntryValue(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('name?') name?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrivilegeEntry> {
    
    		let variables: DefaultEntryValueUserPrivilegesBaseVariables = {
    			name: name
    		}
    				return this.defaultEntryValueUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.defaultEntryValue));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistUserPrivilegesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUserPrivilege,
		@Args('filter?') filter?: FilterOfUserPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserPrivilege> {
    
    		let variables: FindRecyclesUserPrivilegesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUserPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesUserPrivilegesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyUserPrivilegesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUserPrivilege,
		@Args('filter?') filter?: FilterOfUserPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserPrivilege> {
    
    		let variables: FindArchivedUserPrivilegesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUserPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllUserPrivilegesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfUserPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsUserPrivilegesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedUsers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfUser> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('users', fields, null, [
			GqlSubFieldArg.create('filterOfUsers', 'filter'),
			GqlSubFieldArg.create('optionsOfUsers', 'options'),
		]),
		])
		extendedVariables['filterOfUsers'] = filter;
		extendedVariables['optionsOfUsers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.users));
            }
            else{
                let result: ServiceListResultOfUser = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUnassociatedUsersUserPrivilegesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedUsersUserPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.findUnassociatedUsers));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: UserPrivilegeInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserPrivilege> {
    
    		let variables: InsertUserPrivilegesBaseVariables = {
    			entity: entity
    		}
    				return this.insertUserPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfUserPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserPrivilege> {
    
    		let variables: UpdateUserPrivilegesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateUserPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataUserPrivilegesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataUserPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfUserPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyUserPrivilegesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyUserPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteUserPrivilegesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteUserPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addUsersCustom(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddUsersCustomUserPrivilegesBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.addUsersCustomUserPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.addUsersCustom));
    	}

    	/**  */
    	@InjectArgs
    	public removeUsersCustom(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveUsersCustomUserPrivilegesBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.removeUsersCustomUserPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.removeUsersCustom));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreUserPrivilegesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreUserPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleUserPrivilegesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleUserPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedUserPrivilegesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedUserPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedUserPrivilegesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedUserPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldUserPrivilegesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldUserPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldUserPrivilegesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldUserPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddUsersUserPrivilegesBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.addUsersUserPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.addUsers));
    	}

    	/**  */
    	@InjectArgs
    	public removeUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveUsersUserPrivilegesBaseVariables = {
    			userIds: userIds
    		}
    				return this.removeUsersUserPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userPrivileges.removeUsers));
    	}
    
}