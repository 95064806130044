import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfPopulation, FilterOfPopulation, QueryContextOfPopulation, ServiceSingleResultOfInt64, ServiceListResultOfPopulation, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfUser, FilterOfUser, QueryContextOfUser, ServiceListResultOfArticleKnowledge, FilterOfArticleKnowledge, QueryContextOfArticleKnowledge, ServiceListResultOfIncidentModel, FilterOfIncidentModel, QueryContextOfIncidentModel, ServiceListResultOfNews, FilterOfNews, QueryContextOfNews, ServiceListResultOfImpact, FilterOfImpact, QueryContextOfImpact, ServiceListResultOfPriority, FilterOfPriority, QueryContextOfPriority, ServiceListResultOfUrgency, FilterOfUrgency, QueryContextOfUrgency, ServiceListResultOfWarningMessage, FilterOfWarningMessage, QueryContextOfWarningMessage, ServiceListResultOfTicketAffectedRule, FilterOfTicketAffectedRule, QueryContextOfTicketAffectedRule, ServiceListResultOfServiceLevelAgreement, FilterOfServiceLevelAgreement, QueryContextOfServiceLevelAgreement, PopulationInput, FieldUpdateOperatorOfPopulation } from '../types'

export type GetPopulationsResultType = {
    populations: {
        get?: Maybe<ServiceSingleResultOfPopulation>
    }
}

export type GetPopulationsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getPopulations ${args} {
    populations {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfPopulation", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstPopulationsResultType = {
    populations: {
        first?: Maybe<ServiceSingleResultOfPopulation>
    }
}

export type FirstPopulationsBaseVariables = {
	filter: Maybe<FilterOfPopulation>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfPopulation>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfPopulation = null, $options: QueryContextOfPopulation = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstPopulations ${args} {
    populations {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfPopulation", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountPopulationsResultType = {
    populations: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountPopulationsBaseVariables = {
	filter: Maybe<FilterOfPopulation>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfPopulation = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countPopulations ${args} {
    populations {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindPopulationsResultType = {
    populations: {
        find?: Maybe<ServiceListResultOfPopulation>
    }
}

export type FindPopulationsBaseVariables = {
	options: Maybe<QueryContextOfPopulation>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfPopulation>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfPopulation = null, $filter: FilterOfPopulation = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findPopulations ${args} {
    populations {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPopulation", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchPopulationsResultType = {
    populations: {
        search?: Maybe<ServiceListResultOfPopulation>
    }
}

export type SearchPopulationsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfPopulation>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfPopulation = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchPopulations ${args} {
    populations {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPopulation", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaPopulationsResultType = {
    populations: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaPopulationsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaPopulations ${args} {
    populations {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataPopulationsResultType = {
    populations: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataPopulationsBaseVariables = {
	filter: Maybe<FilterOfPopulation>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfPopulation = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataPopulations ${args} {
    populations {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryPopulationsResultType = {
    populations: {
        customQuery?: Maybe<ServiceListResultOfPopulation>
    }
}

export type CustomQueryPopulationsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfPopulation>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfPopulation>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfPopulation = null, $options: QueryContextOfPopulation = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryPopulations ${args} {
    populations {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPopulation", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdPopulationsResultType = {
    populations: {
        customQueryId?: Maybe<ServiceListResultOfPopulation>
    }
}

export type CustomQueryIdPopulationsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfPopulation>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfPopulation>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfPopulation = null, $options: QueryContextOfPopulation = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdPopulations ${args} {
    populations {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPopulation", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedPopulationsResultType = {
    populations: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedPopulationsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedPopulations ${args} {
    populations {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAvailabledMembersPopulationsResultType = {
    populations: {
        findAvailabledMembers?: Maybe<ServiceListResultOfUser>
    }
}

export type FindAvailabledMembersPopulationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAvailabledMembersPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAvailabledMembersPopulations ${args} {
    populations {
        findAvailabledMembers(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAvailabledMembersPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAvailabledMembersPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindLiveMembersPopulationsResultType = {
    populations: {
        findLiveMembers?: Maybe<ServiceListResultOfUser>
    }
}

export type FindLiveMembersPopulationsBaseVariables = {
	id: Maybe<string>, /**  */
	memberLocationIds: Array<string>, /**  */
	memberOrganizationalUnitIds: Array<string>, /**  */
	memberDistinguishedNameGroups: Array<Maybe<string>>, /**  */
	memberAzureGroupObjectIds: Array<Maybe<string>>, /**  */
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindLiveMembersPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $memberLocationIds: [Uuid!], $memberOrganizationalUnitIds: [Uuid!], $memberDistinguishedNameGroups: [String] = null, $memberAzureGroupObjectIds: [String] = null, $filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findLiveMembersPopulations ${args} {
    populations {
        findLiveMembers(id: $id, memberLocationIds: $memberLocationIds, memberOrganizationalUnitIds: $memberOrganizationalUnitIds, memberDistinguishedNameGroups: $memberDistinguishedNameGroups, memberAzureGroupObjectIds: $memberAzureGroupObjectIds, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindLiveMembersPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindLiveMembersPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindArticleKnowledgesPopulationsResultType = {
    populations: {
        findArticleKnowledges?: Maybe<ServiceListResultOfArticleKnowledge>
    }
}

export type FindArticleKnowledgesPopulationsBaseVariables = {
	filter: Maybe<FilterOfArticleKnowledge>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfArticleKnowledge>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindArticleKnowledgesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfArticleKnowledge = null, $options: QueryContextOfArticleKnowledge = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findArticleKnowledgesPopulations ${args} {
    populations {
        findArticleKnowledges(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindArticleKnowledgesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindArticleKnowledgesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfArticleKnowledge", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindSecurityGroupAssociateArticleKnowledgesPopulationsResultType = {
    populations: {
        findSecurityGroupAssociateArticleKnowledges?: Maybe<ServiceListResultOfArticleKnowledge>
    }
}

export type FindSecurityGroupAssociateArticleKnowledgesPopulationsBaseVariables = {
	securityGroupId: Maybe<string>, /**  */
	filter: Maybe<FilterOfArticleKnowledge>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfArticleKnowledge>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindSecurityGroupAssociateArticleKnowledgesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$securityGroupId: Uuid = null, $filter: FilterOfArticleKnowledge = null, $options: QueryContextOfArticleKnowledge = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findSecurityGroupAssociateArticleKnowledgesPopulations ${args} {
    populations {
        findSecurityGroupAssociateArticleKnowledges(securityGroupId: $securityGroupId, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindSecurityGroupAssociateArticleKnowledgesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindSecurityGroupAssociateArticleKnowledgesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfArticleKnowledge", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindIncidentModelsPopulationsResultType = {
    populations: {
        findIncidentModels?: Maybe<ServiceListResultOfIncidentModel>
    }
}

export type FindIncidentModelsPopulationsBaseVariables = {
	filter: Maybe<FilterOfIncidentModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfIncidentModel>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindIncidentModelsPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfIncidentModel = null, $options: QueryContextOfIncidentModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findIncidentModelsPopulations ${args} {
    populations {
        findIncidentModels(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindIncidentModelsPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindIncidentModelsPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfIncidentModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindSecurityGroupAssociateIncidentModelsPopulationsResultType = {
    populations: {
        findSecurityGroupAssociateIncidentModels?: Maybe<ServiceListResultOfIncidentModel>
    }
}

export type FindSecurityGroupAssociateIncidentModelsPopulationsBaseVariables = {
	securityGroupId: Maybe<string>, /**  */
	filter: Maybe<FilterOfIncidentModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfIncidentModel>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindSecurityGroupAssociateIncidentModelsPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$securityGroupId: Uuid = null, $filter: FilterOfIncidentModel = null, $options: QueryContextOfIncidentModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findSecurityGroupAssociateIncidentModelsPopulations ${args} {
    populations {
        findSecurityGroupAssociateIncidentModels(securityGroupId: $securityGroupId, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindSecurityGroupAssociateIncidentModelsPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindSecurityGroupAssociateIncidentModelsPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfIncidentModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindNewsPopulationsResultType = {
    populations: {
        findNews?: Maybe<ServiceListResultOfNews>
    }
}

export type FindNewsPopulationsBaseVariables = {
	filter: Maybe<FilterOfNews>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfNews>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindNewsPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfNews = null, $options: QueryContextOfNews = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findNewsPopulations ${args} {
    populations {
        findNews(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindNewsPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindNewsPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfNews", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindSecurityGroupAssociateNewsPopulationsResultType = {
    populations: {
        findSecurityGroupAssociateNews?: Maybe<ServiceListResultOfNews>
    }
}

export type FindSecurityGroupAssociateNewsPopulationsBaseVariables = {
	securityGroupId: Maybe<string>, /**  */
	filter: Maybe<FilterOfNews>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfNews>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindSecurityGroupAssociateNewsPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$securityGroupId: Uuid = null, $filter: FilterOfNews = null, $options: QueryContextOfNews = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findSecurityGroupAssociateNewsPopulations ${args} {
    populations {
        findSecurityGroupAssociateNews(securityGroupId: $securityGroupId, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindSecurityGroupAssociateNewsPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindSecurityGroupAssociateNewsPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfNews", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindImpactsPopulationsResultType = {
    populations: {
        findImpacts?: Maybe<ServiceListResultOfImpact>
    }
}

export type FindImpactsPopulationsBaseVariables = {
	filter: Maybe<FilterOfImpact>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfImpact>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindImpactsPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfImpact = null, $options: QueryContextOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findImpactsPopulations ${args} {
    populations {
        findImpacts(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindImpactsPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindImpactsPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfImpact", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindSecurityGroupAssociateImpactsPopulationsResultType = {
    populations: {
        findSecurityGroupAssociateImpacts?: Maybe<ServiceListResultOfImpact>
    }
}

export type FindSecurityGroupAssociateImpactsPopulationsBaseVariables = {
	securityGroupId: Maybe<string>, /**  */
	filter: Maybe<FilterOfImpact>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfImpact>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindSecurityGroupAssociateImpactsPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$securityGroupId: Uuid = null, $filter: FilterOfImpact = null, $options: QueryContextOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findSecurityGroupAssociateImpactsPopulations ${args} {
    populations {
        findSecurityGroupAssociateImpacts(securityGroupId: $securityGroupId, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindSecurityGroupAssociateImpactsPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindSecurityGroupAssociateImpactsPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfImpact", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindPrioritiesPopulationsResultType = {
    populations: {
        findPriorities?: Maybe<ServiceListResultOfPriority>
    }
}

export type FindPrioritiesPopulationsBaseVariables = {
	filter: Maybe<FilterOfPriority>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfPriority>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindPrioritiesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfPriority = null, $options: QueryContextOfPriority = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findPrioritiesPopulations ${args} {
    populations {
        findPriorities(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindPrioritiesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindPrioritiesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPriority", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindSecurityGroupAssociatePrioritiesPopulationsResultType = {
    populations: {
        findSecurityGroupAssociatePriorities?: Maybe<ServiceListResultOfPriority>
    }
}

export type FindSecurityGroupAssociatePrioritiesPopulationsBaseVariables = {
	securityGroupId: Maybe<string>, /**  */
	filter: Maybe<FilterOfPriority>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfPriority>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindSecurityGroupAssociatePrioritiesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$securityGroupId: Uuid = null, $filter: FilterOfPriority = null, $options: QueryContextOfPriority = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findSecurityGroupAssociatePrioritiesPopulations ${args} {
    populations {
        findSecurityGroupAssociatePriorities(securityGroupId: $securityGroupId, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindSecurityGroupAssociatePrioritiesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindSecurityGroupAssociatePrioritiesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPriority", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUrgenciesPopulationsResultType = {
    populations: {
        findUrgencies?: Maybe<ServiceListResultOfUrgency>
    }
}

export type FindUrgenciesPopulationsBaseVariables = {
	filter: Maybe<FilterOfUrgency>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUrgency>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUrgenciesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfUrgency = null, $options: QueryContextOfUrgency = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUrgenciesPopulations ${args} {
    populations {
        findUrgencies(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUrgenciesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUrgenciesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUrgency", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindSecurityGroupAssociateUrgenciesPopulationsResultType = {
    populations: {
        findSecurityGroupAssociateUrgencies?: Maybe<ServiceListResultOfUrgency>
    }
}

export type FindSecurityGroupAssociateUrgenciesPopulationsBaseVariables = {
	securityGroupId: Maybe<string>, /**  */
	filter: Maybe<FilterOfUrgency>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUrgency>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindSecurityGroupAssociateUrgenciesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$securityGroupId: Uuid = null, $filter: FilterOfUrgency = null, $options: QueryContextOfUrgency = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findSecurityGroupAssociateUrgenciesPopulations ${args} {
    populations {
        findSecurityGroupAssociateUrgencies(securityGroupId: $securityGroupId, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindSecurityGroupAssociateUrgenciesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindSecurityGroupAssociateUrgenciesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUrgency", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindWarningMessagesPopulationsResultType = {
    populations: {
        findWarningMessages?: Maybe<ServiceListResultOfWarningMessage>
    }
}

export type FindWarningMessagesPopulationsBaseVariables = {
	filter: Maybe<FilterOfWarningMessage>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfWarningMessage>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindWarningMessagesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfWarningMessage = null, $options: QueryContextOfWarningMessage = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findWarningMessagesPopulations ${args} {
    populations {
        findWarningMessages(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindWarningMessagesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindWarningMessagesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfWarningMessage", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindSecurityGroupAssociateWarningMessagesPopulationsResultType = {
    populations: {
        findSecurityGroupAssociateWarningMessages?: Maybe<ServiceListResultOfWarningMessage>
    }
}

export type FindSecurityGroupAssociateWarningMessagesPopulationsBaseVariables = {
	securityGroupId: Maybe<string>, /**  */
	filter: Maybe<FilterOfWarningMessage>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfWarningMessage>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindSecurityGroupAssociateWarningMessagesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$securityGroupId: Uuid = null, $filter: FilterOfWarningMessage = null, $options: QueryContextOfWarningMessage = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findSecurityGroupAssociateWarningMessagesPopulations ${args} {
    populations {
        findSecurityGroupAssociateWarningMessages(securityGroupId: $securityGroupId, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindSecurityGroupAssociateWarningMessagesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindSecurityGroupAssociateWarningMessagesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfWarningMessage", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistPopulationsResultType = {
    populations: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistPopulationsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existPopulations ${args} {
    populations {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedTicketAffectedRulesPopulationsResultType = {
    populations: {
        findUnassociatedTicketAffectedRules?: Maybe<ServiceListResultOfTicketAffectedRule>
    }
}

export type FindUnassociatedTicketAffectedRulesPopulationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfTicketAffectedRule>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketAffectedRule>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedTicketAffectedRulesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfTicketAffectedRule = null, $options: QueryContextOfTicketAffectedRule = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedTicketAffectedRulesPopulations ${args} {
    populations {
        findUnassociatedTicketAffectedRules(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedTicketAffectedRulesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedTicketAffectedRulesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketAffectedRule", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedServiceLevelAgreementsPopulationsResultType = {
    populations: {
        findUnassociatedServiceLevelAgreements?: Maybe<ServiceListResultOfServiceLevelAgreement>
    }
}

export type FindUnassociatedServiceLevelAgreementsPopulationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfServiceLevelAgreement>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfServiceLevelAgreement>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedServiceLevelAgreementsPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfServiceLevelAgreement = null, $options: QueryContextOfServiceLevelAgreement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedServiceLevelAgreementsPopulations ${args} {
    populations {
        findUnassociatedServiceLevelAgreements(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedServiceLevelAgreementsPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedServiceLevelAgreementsPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfServiceLevelAgreement", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertPopulationsResultType = {
    populations: {
        insert?: Maybe<ServiceSingleResultOfPopulation>
    }
}

export type InsertPopulationsBaseVariables = {
	entity: PopulationInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: PopulationInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertPopulations ${args} {
    populations {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfPopulation", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdatePopulationsResultType = {
    populations: {
        update?: Maybe<ServiceSingleResultOfPopulation>
    }
}

export type UpdatePopulationsBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfPopulation>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdatePopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfPopulation = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updatePopulations ${args} {
    populations {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdatePopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdatePopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfPopulation", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataPopulationsResultType = {
    populations: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataPopulationsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataPopulations ${args} {
    populations {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyPopulationsResultType = {
    populations: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyPopulationsBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfPopulation>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfPopulation = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyPopulations ${args} {
    populations {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeletePopulationsResultType = {
    populations: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeletePopulationsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeletePopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deletePopulations ${args} {
    populations {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeletePopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeletePopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddMembersPopulationsResultType = {
    populations: {
        addMembers?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddMembersPopulationsBaseVariables = {
	id: string, /**  */
	userIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddMembersPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $userIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addMembersPopulations ${args} {
    populations {
        addMembers(id: $id, userIds: $userIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddMembersPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddMembersPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveMembersPopulationsResultType = {
    populations: {
        removeMembers?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveMembersPopulationsBaseVariables = {
	id: string, /**  */
	userIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveMembersPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $userIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeMembersPopulations ${args} {
    populations {
        removeMembers(id: $id, userIds: $userIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveMembersPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveMembersPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSecurityGroupArticleKnowledgesPopulationsResultType = {
    populations: {
        addSecurityGroupArticleKnowledges?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSecurityGroupArticleKnowledgesPopulationsBaseVariables = {
	id: string, /**  */
	articleKnowledgeIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSecurityGroupArticleKnowledgesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $articleKnowledgeIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSecurityGroupArticleKnowledgesPopulations ${args} {
    populations {
        addSecurityGroupArticleKnowledges(id: $id, articleKnowledgeIds: $articleKnowledgeIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSecurityGroupArticleKnowledgesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSecurityGroupArticleKnowledgesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSecurityGroupArticleKnowledgesPopulationsResultType = {
    populations: {
        removeSecurityGroupArticleKnowledges?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSecurityGroupArticleKnowledgesPopulationsBaseVariables = {
	id: string, /**  */
	articleKnowledgeIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSecurityGroupArticleKnowledgesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $articleKnowledgeIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSecurityGroupArticleKnowledgesPopulations ${args} {
    populations {
        removeSecurityGroupArticleKnowledges(id: $id, articleKnowledgeIds: $articleKnowledgeIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSecurityGroupArticleKnowledgesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSecurityGroupArticleKnowledgesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSecurityGroupIncidentModelsPopulationsResultType = {
    populations: {
        addSecurityGroupIncidentModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSecurityGroupIncidentModelsPopulationsBaseVariables = {
	id: string, /**  */
	incidentModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSecurityGroupIncidentModelsPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $incidentModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSecurityGroupIncidentModelsPopulations ${args} {
    populations {
        addSecurityGroupIncidentModels(id: $id, incidentModelIds: $incidentModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSecurityGroupIncidentModelsPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSecurityGroupIncidentModelsPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSecurityGroupIncidentModelsPopulationsResultType = {
    populations: {
        removeSecurityGroupIncidentModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSecurityGroupIncidentModelsPopulationsBaseVariables = {
	id: string, /**  */
	incidentModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSecurityGroupIncidentModelsPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $incidentModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSecurityGroupIncidentModelsPopulations ${args} {
    populations {
        removeSecurityGroupIncidentModels(id: $id, incidentModelIds: $incidentModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSecurityGroupIncidentModelsPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSecurityGroupIncidentModelsPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSecurityGroupNewsPopulationsResultType = {
    populations: {
        addSecurityGroupNews?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSecurityGroupNewsPopulationsBaseVariables = {
	id: string, /**  */
	newsIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSecurityGroupNewsPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $newsIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSecurityGroupNewsPopulations ${args} {
    populations {
        addSecurityGroupNews(id: $id, newsIds: $newsIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSecurityGroupNewsPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSecurityGroupNewsPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSecurityGroupNewsPopulationsResultType = {
    populations: {
        removeSecurityGroupNews?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSecurityGroupNewsPopulationsBaseVariables = {
	id: string, /**  */
	newsIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSecurityGroupNewsPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $newsIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSecurityGroupNewsPopulations ${args} {
    populations {
        removeSecurityGroupNews(id: $id, newsIds: $newsIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSecurityGroupNewsPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSecurityGroupNewsPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSecurityGroupImpactsPopulationsResultType = {
    populations: {
        addSecurityGroupImpacts?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSecurityGroupImpactsPopulationsBaseVariables = {
	id: string, /**  */
	impactIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSecurityGroupImpactsPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $impactIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSecurityGroupImpactsPopulations ${args} {
    populations {
        addSecurityGroupImpacts(id: $id, impactIds: $impactIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSecurityGroupImpactsPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSecurityGroupImpactsPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSecurityGroupImpactsPopulationsResultType = {
    populations: {
        removeSecurityGroupImpacts?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSecurityGroupImpactsPopulationsBaseVariables = {
	id: string, /**  */
	impactIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSecurityGroupImpactsPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $impactIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSecurityGroupImpactsPopulations ${args} {
    populations {
        removeSecurityGroupImpacts(id: $id, impactIds: $impactIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSecurityGroupImpactsPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSecurityGroupImpactsPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSecurityGroupPrioritiesPopulationsResultType = {
    populations: {
        addSecurityGroupPriorities?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSecurityGroupPrioritiesPopulationsBaseVariables = {
	id: string, /**  */
	priorityIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSecurityGroupPrioritiesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $priorityIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSecurityGroupPrioritiesPopulations ${args} {
    populations {
        addSecurityGroupPriorities(id: $id, priorityIds: $priorityIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSecurityGroupPrioritiesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSecurityGroupPrioritiesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSecurityGroupPrioritiesPopulationsResultType = {
    populations: {
        removeSecurityGroupPriorities?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSecurityGroupPrioritiesPopulationsBaseVariables = {
	id: string, /**  */
	priorityIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSecurityGroupPrioritiesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $priorityIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSecurityGroupPrioritiesPopulations ${args} {
    populations {
        removeSecurityGroupPriorities(id: $id, priorityIds: $priorityIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSecurityGroupPrioritiesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSecurityGroupPrioritiesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSecurityGroupUrgenciesPopulationsResultType = {
    populations: {
        addSecurityGroupUrgencies?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSecurityGroupUrgenciesPopulationsBaseVariables = {
	id: string, /**  */
	urgencyIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSecurityGroupUrgenciesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $urgencyIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSecurityGroupUrgenciesPopulations ${args} {
    populations {
        addSecurityGroupUrgencies(id: $id, urgencyIds: $urgencyIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSecurityGroupUrgenciesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSecurityGroupUrgenciesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSecurityGroupUrgenciesPopulationsResultType = {
    populations: {
        removeSecurityGroupUrgencies?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSecurityGroupUrgenciesPopulationsBaseVariables = {
	id: string, /**  */
	urgencyIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSecurityGroupUrgenciesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $urgencyIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSecurityGroupUrgenciesPopulations ${args} {
    populations {
        removeSecurityGroupUrgencies(id: $id, urgencyIds: $urgencyIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSecurityGroupUrgenciesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSecurityGroupUrgenciesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSecurityGroupWarningMessagesPopulationsResultType = {
    populations: {
        addSecurityGroupWarningMessages?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSecurityGroupWarningMessagesPopulationsBaseVariables = {
	id: string, /**  */
	warningMessageIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSecurityGroupWarningMessagesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $warningMessageIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSecurityGroupWarningMessagesPopulations ${args} {
    populations {
        addSecurityGroupWarningMessages(id: $id, warningMessageIds: $warningMessageIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSecurityGroupWarningMessagesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSecurityGroupWarningMessagesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSecurityGroupWarningMessagesPopulationsResultType = {
    populations: {
        removeSecurityGroupWarningMessages?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSecurityGroupWarningMessagesPopulationsBaseVariables = {
	id: string, /**  */
	warningMessageIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSecurityGroupWarningMessagesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $warningMessageIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSecurityGroupWarningMessagesPopulations ${args} {
    populations {
        removeSecurityGroupWarningMessages(id: $id, warningMessageIds: $warningMessageIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSecurityGroupWarningMessagesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSecurityGroupWarningMessagesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddTicketAffectedRulesPopulationsResultType = {
    populations: {
        addTicketAffectedRules?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddTicketAffectedRulesPopulationsBaseVariables = {
	id: string, /**  */
	ticketAffectedRuleIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddTicketAffectedRulesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $ticketAffectedRuleIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addTicketAffectedRulesPopulations ${args} {
    populations {
        addTicketAffectedRules(id: $id, ticketAffectedRuleIds: $ticketAffectedRuleIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddTicketAffectedRulesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddTicketAffectedRulesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveTicketAffectedRulesPopulationsResultType = {
    populations: {
        removeTicketAffectedRules?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveTicketAffectedRulesPopulationsBaseVariables = {
	id: string, /**  */
	ticketAffectedRuleIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveTicketAffectedRulesPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $ticketAffectedRuleIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeTicketAffectedRulesPopulations ${args} {
    populations {
        removeTicketAffectedRules(id: $id, ticketAffectedRuleIds: $ticketAffectedRuleIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveTicketAffectedRulesPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveTicketAffectedRulesPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddServiceLevelAgreementsPopulationsResultType = {
    populations: {
        addServiceLevelAgreements?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddServiceLevelAgreementsPopulationsBaseVariables = {
	id: string, /**  */
	serviceLevelAgreementIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddServiceLevelAgreementsPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $serviceLevelAgreementIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addServiceLevelAgreementsPopulations ${args} {
    populations {
        addServiceLevelAgreements(id: $id, serviceLevelAgreementIds: $serviceLevelAgreementIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddServiceLevelAgreementsPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddServiceLevelAgreementsPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveServiceLevelAgreementsPopulationsResultType = {
    populations: {
        removeServiceLevelAgreements?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveServiceLevelAgreementsPopulationsBaseVariables = {
	id: string, /**  */
	serviceLevelAgreementIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveServiceLevelAgreementsPopulationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $serviceLevelAgreementIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeServiceLevelAgreementsPopulations ${args} {
    populations {
        removeServiceLevelAgreements(id: $id, serviceLevelAgreementIds: $serviceLevelAgreementIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveServiceLevelAgreementsPopulationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveServiceLevelAgreementsPopulationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
