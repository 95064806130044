import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanByAzureAdAppointmentsBaseVariables, FirstScanByAzureAdAppointmentsBaseVariables, CountScanByAzureAdAppointmentsBaseVariables, FindScanByAzureAdAppointmentsBaseVariables, SearchScanByAzureAdAppointmentsBaseVariables, ExportSchemaScanByAzureAdAppointmentsBaseVariables, ExportDataScanByAzureAdAppointmentsBaseVariables, CustomQueryScanByAzureAdAppointmentsBaseVariables, CustomQueryIdScanByAzureAdAppointmentsBaseVariables, UsedScanByAzureAdAppointmentsBaseVariables, ExistScanByAzureAdAppointmentsBaseVariables, InsertScanByAzureAdAppointmentsBaseVariables, UpdateScanByAzureAdAppointmentsBaseVariables, ImportDataScanByAzureAdAppointmentsBaseVariables, UpdateManyScanByAzureAdAppointmentsBaseVariables, DeleteScanByAzureAdAppointmentsBaseVariables } from '../gqls'
import { GetScanByAzureAdAppointmentsDocument, FirstScanByAzureAdAppointmentsDocument, CountScanByAzureAdAppointmentsDocument, FindScanByAzureAdAppointmentsDocument, SearchScanByAzureAdAppointmentsDocument, ExportSchemaScanByAzureAdAppointmentsDocument, ExportDataScanByAzureAdAppointmentsDocument, CustomQueryScanByAzureAdAppointmentsDocument, CustomQueryIdScanByAzureAdAppointmentsDocument, UsedScanByAzureAdAppointmentsDocument, ExistScanByAzureAdAppointmentsDocument, InsertScanByAzureAdAppointmentsDocument, UpdateScanByAzureAdAppointmentsDocument, ImportDataScanByAzureAdAppointmentsDocument, UpdateManyScanByAzureAdAppointmentsDocument, DeleteScanByAzureAdAppointmentsDocument } from '../gqls'
import { ServiceSingleResultOfScanByAzureAdAppointment, QueryContextOfScanByAzureAdAppointment, FilterOfScanByAzureAdAppointment, ServiceSingleResultOfInt64, ServiceListResultOfScanByAzureAdAppointment, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ScanByAzureAdAppointmentInput, FieldUpdateOperatorOfScanByAzureAdAppointment } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScanByAzureAdAppointmentBaseService {
    
public modelName = 'scanByAzureAdAppointment';
public modelPluralName = 'scanByAzureAdAppointments';

	private getScanByAzureAdAppointmentsQuery: GetScanByAzureAdAppointmentsDocument;
	private firstScanByAzureAdAppointmentsQuery: FirstScanByAzureAdAppointmentsDocument;
	private countScanByAzureAdAppointmentsQuery: CountScanByAzureAdAppointmentsDocument;
	private findScanByAzureAdAppointmentsQuery: FindScanByAzureAdAppointmentsDocument;
	private searchScanByAzureAdAppointmentsQuery: SearchScanByAzureAdAppointmentsDocument;
	private exportSchemaScanByAzureAdAppointmentsQuery: ExportSchemaScanByAzureAdAppointmentsDocument;
	private exportDataScanByAzureAdAppointmentsQuery: ExportDataScanByAzureAdAppointmentsDocument;
	private customQueryScanByAzureAdAppointmentsQuery: CustomQueryScanByAzureAdAppointmentsDocument;
	private customQueryIdScanByAzureAdAppointmentsQuery: CustomQueryIdScanByAzureAdAppointmentsDocument;
	private usedScanByAzureAdAppointmentsQuery: UsedScanByAzureAdAppointmentsDocument;
	private existScanByAzureAdAppointmentsQuery: ExistScanByAzureAdAppointmentsDocument;
	private insertScanByAzureAdAppointmentsMutation: InsertScanByAzureAdAppointmentsDocument;
	private updateScanByAzureAdAppointmentsMutation: UpdateScanByAzureAdAppointmentsDocument;
	private importDataScanByAzureAdAppointmentsMutation: ImportDataScanByAzureAdAppointmentsDocument;
	private updateManyScanByAzureAdAppointmentsMutation: UpdateManyScanByAzureAdAppointmentsDocument;
	private deleteScanByAzureAdAppointmentsMutation: DeleteScanByAzureAdAppointmentsDocument;

	constructor(private injector: Injector) {
		this.getScanByAzureAdAppointmentsQuery = this.injector.get(GetScanByAzureAdAppointmentsDocument);
		this.firstScanByAzureAdAppointmentsQuery = this.injector.get(FirstScanByAzureAdAppointmentsDocument);
		this.countScanByAzureAdAppointmentsQuery = this.injector.get(CountScanByAzureAdAppointmentsDocument);
		this.findScanByAzureAdAppointmentsQuery = this.injector.get(FindScanByAzureAdAppointmentsDocument);
		this.searchScanByAzureAdAppointmentsQuery = this.injector.get(SearchScanByAzureAdAppointmentsDocument);
		this.exportSchemaScanByAzureAdAppointmentsQuery = this.injector.get(ExportSchemaScanByAzureAdAppointmentsDocument);
		this.exportDataScanByAzureAdAppointmentsQuery = this.injector.get(ExportDataScanByAzureAdAppointmentsDocument);
		this.customQueryScanByAzureAdAppointmentsQuery = this.injector.get(CustomQueryScanByAzureAdAppointmentsDocument);
		this.customQueryIdScanByAzureAdAppointmentsQuery = this.injector.get(CustomQueryIdScanByAzureAdAppointmentsDocument);
		this.usedScanByAzureAdAppointmentsQuery = this.injector.get(UsedScanByAzureAdAppointmentsDocument);
		this.existScanByAzureAdAppointmentsQuery = this.injector.get(ExistScanByAzureAdAppointmentsDocument);
		this.insertScanByAzureAdAppointmentsMutation = this.injector.get(InsertScanByAzureAdAppointmentsDocument);
		this.updateScanByAzureAdAppointmentsMutation = this.injector.get(UpdateScanByAzureAdAppointmentsDocument);
		this.importDataScanByAzureAdAppointmentsMutation = this.injector.get(ImportDataScanByAzureAdAppointmentsDocument);
		this.updateManyScanByAzureAdAppointmentsMutation = this.injector.get(UpdateManyScanByAzureAdAppointmentsDocument);
		this.deleteScanByAzureAdAppointmentsMutation = this.injector.get(DeleteScanByAzureAdAppointmentsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAzureAdAppointment> {
    
    		let variables: GetScanByAzureAdAppointmentsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScanByAzureAdAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.get));
            }
            else{
                let result:ServiceSingleResultOfScanByAzureAdAppointment={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByAzureAdAppointment,
		@Args('filter?') filter?: FilterOfScanByAzureAdAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAzureAdAppointment> {
    
    		let variables: FirstScanByAzureAdAppointmentsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScanByAzureAdAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByAzureAdAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScanByAzureAdAppointmentsBaseVariables = {
    			filter: filter
    		}
    				return this.countScanByAzureAdAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByAzureAdAppointment,
		@Args('filter?') filter?: FilterOfScanByAzureAdAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAzureAdAppointment> {
    
    		let variables: FindScanByAzureAdAppointmentsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScanByAzureAdAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScanByAzureAdAppointment,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAzureAdAppointment> {
    
    		let variables: SearchScanByAzureAdAppointmentsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScanByAzureAdAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScanByAzureAdAppointmentsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScanByAzureAdAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByAzureAdAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScanByAzureAdAppointmentsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScanByAzureAdAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScanByAzureAdAppointment,
		@Args('filter?') filter?: FilterOfScanByAzureAdAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAzureAdAppointment> {
    
    		let variables: CustomQueryScanByAzureAdAppointmentsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScanByAzureAdAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScanByAzureAdAppointment,
		@Args('filter?') filter?: FilterOfScanByAzureAdAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAzureAdAppointment> {
    
    		let variables: CustomQueryIdScanByAzureAdAppointmentsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScanByAzureAdAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScanByAzureAdAppointmentsBaseVariables = {
    			ids: ids
    		}
    				return this.usedScanByAzureAdAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScanByAzureAdAppointmentsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScanByAzureAdAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ScanByAzureAdAppointmentInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAzureAdAppointment> {
    
    		let variables: InsertScanByAzureAdAppointmentsBaseVariables = {
    			entity: entity
    		}
    				return this.insertScanByAzureAdAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByAzureAdAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAzureAdAppointment> {
    
    		let variables: UpdateScanByAzureAdAppointmentsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateScanByAzureAdAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataScanByAzureAdAppointmentsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataScanByAzureAdAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByAzureAdAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScanByAzureAdAppointmentsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScanByAzureAdAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScanByAzureAdAppointmentsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScanByAzureAdAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointments.delete));
    	}
    
}