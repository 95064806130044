import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';

/** Représente la classe du composent cl-timespan. */
@Component({
  selector: 'clc-timespan',
  templateUrl: './timespan.component.html',
  styleUrls: ['./timespan.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreTimespanComponent),
      multi: true,
    },
  ],
})
export class CoreTimespanComponent
  implements OnInit, ControlValueAccessor, AfterViewInit
{
  @Input() state: ModelState;
  /** Obtient ou définit le status du readonly */
  @Input() readOnly: boolean = false;
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** @inheritdoc */
  /** Obtient ou définit la valeur. */

  private _value: string;
  @Input()
  public get value(): string {
    return this._value;
  }
  public set value(value: string) {
    this._value = value;
    this.fromTimespan(value);
  }
  /** Obtient ou définit l'état d'activation. */
  @Input() disabled: boolean = false;
  @Input() enableMinus: boolean = false;
  @Input() showDays: boolean = true;
  @Output() onValueChanged = new EventEmitter();

  hours: number = 0;
  minutes: number = 0;
  secondes: number = 0;
  days: number = 0;
  // Saved Value because in chrono mode the calul is saved + time elapsed since chrono start
  _secondes: number = 0;
  _minutes: number = 0;
  _hours: number = 0;
  _days: number = 0;
  _ms: number = 0;
  minus: boolean = false;

  @Input() modeChrono: boolean = false;
  @Input() modeInline: boolean = false;
  timeStartChrono: Date;
  timeEndChrono: Date;
  isChronoStart: boolean = false;
  orgiginalValue: string;

  chronoInterval: NodeJS.Timeout;

  constructor() {}
  /** @inheritdoc */
  public writeValue(value: any) {
    this.value = value;
    this.fromTimespan(value);
  }
  /** @inheritdoc */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  ngAfterViewInit(): void {}

  valueChanged(value) {
    if (value != this.orgiginalValue) {
      this.onTouched();
      this.onChange(value);
      this.onValueChanged.emit(value);
    }
    if (value == 'PT0M0S' || value == 'P0DT0H0M0S' || value == 'PT0S') {
      this.onTouched();
      this.onChange(value);
      this.onValueChanged.emit(value);
    }
    if (this.days == 0 && this.hours == 0 && this.minutes < 1) {
      this.onTouched();
      this.onChange(this.orgiginalValue);
      this.onValueChanged.emit(this.orgiginalValue);
    }
  }

  toTimespan(days: number, hours: number, minutes: number, seconds: number) {
    let timestamp = `${this.minus ? '-' : ''}P${days}DT${hours}H${minutes}M${
      seconds.toString().split('.')[0]
    }S`;
    return timestamp;
  }

  fromTimespan(value) {
    let regex =
      /^([+-])?P(?!$)(\d+Y)?(\d+M)?(\d+W)?(\d+D)?(T(?=\d*\.?\d*[DHMS])(\d*\.?\d*D)?(\d*\.?\d*H)?(\d*\.?\d*M)?(\d*\.?\d*S)?)?$/gm;
    let m = regex.exec(value);
    if (m != undefined) {
      if (m[5] != undefined) {
        this.days = Number(m[5].split('D')[0]);
      }
      if (m[8] != undefined) {
        this.hours = Number(m[8].split('H')[0]);
      }
      if (m[9] != undefined) {
        this.minutes = Number(m[9].split('M')[0]);
      }
      if (m[10] != undefined) {
        this.secondes = Number(m[10].split('S')[0].split('.')[0]);
      }
      if (m[1] != undefined) {
        this.minus = m[1] === '+' ? false : true;
      }
      this.toMs();
    }
    this.orgiginalValue = this.toTimespan(
      this.days,
      this.hours,
      this.minutes,
      this.secondes,
    );
  }

  toMs() {
    this._ms =
      ((this.days * 24 + this.hours * 60 + this.minutes) * 60 + this.secondes) *
      1000;
  }

  toComponent(ms: number) {
    //this._ms += ms; // recup num second en tous
    let tmpSec = ms / 1000 + this._secondes;
    let tmpMin = tmpSec / 60 + this._minutes; // recup combien de min fond tmpSec

    tmpSec = tmpSec % 60; // reste des tmpSec  on garde ex 123s == 2 min et 3 SEC
    let tmpHour = tmpMin / 60 + this._hours;
    tmpMin = tmpMin % 60;

    let tmpDays = tmpHour / 24 + this._days;

    tmpDays = tmpDays % 24;

    /**
     * 123000ms = 123s = 2m et 3s
     */

    this.minutes = Math.trunc(tmpMin);
    this.hours = Math.trunc(tmpHour);
    this.secondes = Math.trunc(tmpSec);
    this.days = Math.trunc(tmpDays);

    this.valueChanged(
      this.toTimespan(this.days, this.hours, this.minutes, this.secondes),
    );
  }

  ngOnInit(): void {
    if (this.enableMinus == undefined) {
      this.enableMinus = false;
    }
    if (this.modeChrono == undefined) {
      this.modeChrono = false;
    }
    // throw new Error("Method not implemented.");
  }

  // Qeustion chrono si on arrete et on reprend ca continue ou ca restart a 0
  startStopChrono() {
    if (this.isChronoStart === true && this.modeChrono === true) {
      this.timeEndChrono = new Date();
      if (this.chronoInterval != undefined) {
        clearInterval(this.chronoInterval);
      }
      this.toComponent(
        this.timeEndChrono.getTime() - this.timeStartChrono.getTime(),
      );
    } else {
      this._hours = this.hours;
      this._days = this.days;
      this._minutes = this.minutes;
      this._secondes = this.secondes;
      this.timeStartChrono = new Date();
      this.chronoInterval = setInterval(() => {
        let now = new Date();

        this.toComponent(now.getTime() - this.timeStartChrono.getTime());
      }, 900);
    }
    this.isChronoStart = !this.isChronoStart;
  }

  numberBoxValueChanges(e) {
    if (e.value == undefined) {
      e.component.option('value', 0);
    }
    if (this.isChronoStart === false) {
      this.toMs();
    }
    this.valueChanged(
      this.toTimespan(this.days, this.hours, this.minutes, this.secondes),
    );
  }

  setMinus() {
    this.minus = !this.minus;
    this.valueChanged(
      this.toTimespan(this.days, this.hours, this.minutes, this.secondes),
    );
  }
}
