import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanRegistryKeysBaseVariables, FirstScanRegistryKeysBaseVariables, CountScanRegistryKeysBaseVariables, FindScanRegistryKeysBaseVariables, SearchScanRegistryKeysBaseVariables, ExportSchemaScanRegistryKeysBaseVariables, ExportDataScanRegistryKeysBaseVariables, CustomQueryScanRegistryKeysBaseVariables, CustomQueryIdScanRegistryKeysBaseVariables, UsedScanRegistryKeysBaseVariables, FindAllUnassociatedScanConfigurationsScanRegistryKeysBaseVariables, FindAllAssociatedScanConfigurationsScanRegistryKeysBaseVariables, ExistScanRegistryKeysBaseVariables, FindUnassociatedScanRegistriesScanRegistryKeysBaseVariables, InsertScanRegistryKeysBaseVariables, UpdateScanRegistryKeysBaseVariables, ImportDataScanRegistryKeysBaseVariables, UpdateManyScanRegistryKeysBaseVariables, DeleteScanRegistryKeysBaseVariables, AddScanConfigurationsScanRegistryKeysBaseVariables, RemoveScanConfigurationsScanRegistryKeysBaseVariables, AddScanRegistriesScanRegistryKeysBaseVariables, RemoveScanRegistriesScanRegistryKeysBaseVariables } from '../gqls'
import { GetScanRegistryKeysDocument, FirstScanRegistryKeysDocument, CountScanRegistryKeysDocument, FindScanRegistryKeysDocument, SearchScanRegistryKeysDocument, ExportSchemaScanRegistryKeysDocument, ExportDataScanRegistryKeysDocument, CustomQueryScanRegistryKeysDocument, CustomQueryIdScanRegistryKeysDocument, UsedScanRegistryKeysDocument, FindAllUnassociatedScanConfigurationsScanRegistryKeysDocument, FindAllAssociatedScanConfigurationsScanRegistryKeysDocument, ExistScanRegistryKeysDocument, FindUnassociatedScanRegistriesScanRegistryKeysDocument, InsertScanRegistryKeysDocument, UpdateScanRegistryKeysDocument, ImportDataScanRegistryKeysDocument, UpdateManyScanRegistryKeysDocument, DeleteScanRegistryKeysDocument, AddScanConfigurationsScanRegistryKeysDocument, RemoveScanConfigurationsScanRegistryKeysDocument, AddScanRegistriesScanRegistryKeysDocument, RemoveScanRegistriesScanRegistryKeysDocument } from '../gqls'
import { ServiceSingleResultOfScanRegistryKey, QueryContextOfScanRegistryKey, FilterOfScanRegistryKey, ServiceSingleResultOfInt64, ServiceListResultOfScanRegistryKey, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfScanConfiguration, QueryContextOfScanConfiguration, FilterOfScanConfiguration, QueryContextOfScanRegistry, FilterOfScanRegistry, ServiceListResultOfScanRegistry, ScanRegistryKeyInput, FieldUpdateOperatorOfScanRegistryKey } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScanRegistryKeyBaseService {
    
public modelName = 'scanRegistryKey';
public modelPluralName = 'scanRegistryKeys';

	private getScanRegistryKeysQuery: GetScanRegistryKeysDocument;
	private firstScanRegistryKeysQuery: FirstScanRegistryKeysDocument;
	private countScanRegistryKeysQuery: CountScanRegistryKeysDocument;
	private findScanRegistryKeysQuery: FindScanRegistryKeysDocument;
	private searchScanRegistryKeysQuery: SearchScanRegistryKeysDocument;
	private exportSchemaScanRegistryKeysQuery: ExportSchemaScanRegistryKeysDocument;
	private exportDataScanRegistryKeysQuery: ExportDataScanRegistryKeysDocument;
	private customQueryScanRegistryKeysQuery: CustomQueryScanRegistryKeysDocument;
	private customQueryIdScanRegistryKeysQuery: CustomQueryIdScanRegistryKeysDocument;
	private usedScanRegistryKeysQuery: UsedScanRegistryKeysDocument;
	private findAllUnassociatedScanConfigurationsScanRegistryKeysQuery: FindAllUnassociatedScanConfigurationsScanRegistryKeysDocument;
	private findAllAssociatedScanConfigurationsScanRegistryKeysQuery: FindAllAssociatedScanConfigurationsScanRegistryKeysDocument;
	private existScanRegistryKeysQuery: ExistScanRegistryKeysDocument;
	private findUnassociatedScanRegistriesScanRegistryKeysQuery: FindUnassociatedScanRegistriesScanRegistryKeysDocument;
	private insertScanRegistryKeysMutation: InsertScanRegistryKeysDocument;
	private updateScanRegistryKeysMutation: UpdateScanRegistryKeysDocument;
	private importDataScanRegistryKeysMutation: ImportDataScanRegistryKeysDocument;
	private updateManyScanRegistryKeysMutation: UpdateManyScanRegistryKeysDocument;
	private deleteScanRegistryKeysMutation: DeleteScanRegistryKeysDocument;
	private addScanConfigurationsScanRegistryKeysMutation: AddScanConfigurationsScanRegistryKeysDocument;
	private removeScanConfigurationsScanRegistryKeysMutation: RemoveScanConfigurationsScanRegistryKeysDocument;
	private addScanRegistriesScanRegistryKeysMutation: AddScanRegistriesScanRegistryKeysDocument;
	private removeScanRegistriesScanRegistryKeysMutation: RemoveScanRegistriesScanRegistryKeysDocument;

	constructor(private injector: Injector) {
		this.getScanRegistryKeysQuery = this.injector.get(GetScanRegistryKeysDocument);
		this.firstScanRegistryKeysQuery = this.injector.get(FirstScanRegistryKeysDocument);
		this.countScanRegistryKeysQuery = this.injector.get(CountScanRegistryKeysDocument);
		this.findScanRegistryKeysQuery = this.injector.get(FindScanRegistryKeysDocument);
		this.searchScanRegistryKeysQuery = this.injector.get(SearchScanRegistryKeysDocument);
		this.exportSchemaScanRegistryKeysQuery = this.injector.get(ExportSchemaScanRegistryKeysDocument);
		this.exportDataScanRegistryKeysQuery = this.injector.get(ExportDataScanRegistryKeysDocument);
		this.customQueryScanRegistryKeysQuery = this.injector.get(CustomQueryScanRegistryKeysDocument);
		this.customQueryIdScanRegistryKeysQuery = this.injector.get(CustomQueryIdScanRegistryKeysDocument);
		this.usedScanRegistryKeysQuery = this.injector.get(UsedScanRegistryKeysDocument);
		this.findAllUnassociatedScanConfigurationsScanRegistryKeysQuery = this.injector.get(FindAllUnassociatedScanConfigurationsScanRegistryKeysDocument);
		this.findAllAssociatedScanConfigurationsScanRegistryKeysQuery = this.injector.get(FindAllAssociatedScanConfigurationsScanRegistryKeysDocument);
		this.existScanRegistryKeysQuery = this.injector.get(ExistScanRegistryKeysDocument);
		this.findUnassociatedScanRegistriesScanRegistryKeysQuery = this.injector.get(FindUnassociatedScanRegistriesScanRegistryKeysDocument);
		this.insertScanRegistryKeysMutation = this.injector.get(InsertScanRegistryKeysDocument);
		this.updateScanRegistryKeysMutation = this.injector.get(UpdateScanRegistryKeysDocument);
		this.importDataScanRegistryKeysMutation = this.injector.get(ImportDataScanRegistryKeysDocument);
		this.updateManyScanRegistryKeysMutation = this.injector.get(UpdateManyScanRegistryKeysDocument);
		this.deleteScanRegistryKeysMutation = this.injector.get(DeleteScanRegistryKeysDocument);
		this.addScanConfigurationsScanRegistryKeysMutation = this.injector.get(AddScanConfigurationsScanRegistryKeysDocument);
		this.removeScanConfigurationsScanRegistryKeysMutation = this.injector.get(RemoveScanConfigurationsScanRegistryKeysDocument);
		this.addScanRegistriesScanRegistryKeysMutation = this.injector.get(AddScanRegistriesScanRegistryKeysDocument);
		this.removeScanRegistriesScanRegistryKeysMutation = this.injector.get(RemoveScanRegistriesScanRegistryKeysDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanRegistryKey> {
    
    		let variables: GetScanRegistryKeysBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScanRegistryKeysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.get));
            }
            else{
                let result:ServiceSingleResultOfScanRegistryKey={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanRegistryKey,
		@Args('filter?') filter?: FilterOfScanRegistryKey,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanRegistryKey> {
    
    		let variables: FirstScanRegistryKeysBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScanRegistryKeysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanRegistryKey,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScanRegistryKeysBaseVariables = {
    			filter: filter
    		}
    				return this.countScanRegistryKeysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanRegistryKey,
		@Args('filter?') filter?: FilterOfScanRegistryKey,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanRegistryKey> {
    
    		let variables: FindScanRegistryKeysBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScanRegistryKeysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScanRegistryKey,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanRegistryKey> {
    
    		let variables: SearchScanRegistryKeysBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScanRegistryKeysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScanRegistryKeysBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScanRegistryKeysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanRegistryKey,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScanRegistryKeysBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScanRegistryKeysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScanRegistryKey,
		@Args('filter?') filter?: FilterOfScanRegistryKey,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanRegistryKey> {
    
    		let variables: CustomQueryScanRegistryKeysBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScanRegistryKeysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScanRegistryKey,
		@Args('filter?') filter?: FilterOfScanRegistryKey,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanRegistryKey> {
    
    		let variables: CustomQueryIdScanRegistryKeysBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScanRegistryKeysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScanRegistryKeysBaseVariables = {
    			ids: ids
    		}
    				return this.usedScanRegistryKeysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.used));
    	}

    	/**  */
    	@InjectArgs
    	public findAllUnassociatedScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindAllUnassociatedScanConfigurationsScanRegistryKeysBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAllUnassociatedScanConfigurationsScanRegistryKeysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.findAllUnassociatedScanConfigurations));
    	}

    	/**  */
    	@InjectArgs
    	public findAllAssociatedScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindAllAssociatedScanConfigurationsScanRegistryKeysBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAllAssociatedScanConfigurationsScanRegistryKeysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.findAllAssociatedScanConfigurations));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScanRegistryKeysBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScanRegistryKeysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.exist));
    	}

	@InjectArgs
	public findAssociatedScanRegistries(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanRegistry,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanRegistry,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanRegistry> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanRegistries', fields, null, [
			GqlSubFieldArg.create('filterOfScanRegistries', 'filter'),
			GqlSubFieldArg.create('optionsOfScanRegistries', 'options'),
		]),
		])
		extendedVariables['filterOfScanRegistries'] = filter;
		extendedVariables['optionsOfScanRegistries'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanRegistries));
            }
            else{
                let result: ServiceListResultOfScanRegistry = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanRegistries(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanRegistry,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanRegistry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanRegistry> {
    
    		let variables: FindUnassociatedScanRegistriesScanRegistryKeysBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanRegistriesScanRegistryKeysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.findUnassociatedScanRegistries));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ScanRegistryKeyInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanRegistryKey> {
    
    		let variables: InsertScanRegistryKeysBaseVariables = {
    			entity: entity
    		}
    				return this.insertScanRegistryKeysMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanRegistryKey,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanRegistryKey> {
    
    		let variables: UpdateScanRegistryKeysBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateScanRegistryKeysMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataScanRegistryKeysBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataScanRegistryKeysMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanRegistryKey,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScanRegistryKeysBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScanRegistryKeysMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScanRegistryKeysBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScanRegistryKeysMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationIds') scanConfigurationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddScanConfigurationsScanRegistryKeysBaseVariables = {
    			id: id,
			scanConfigurationIds: scanConfigurationIds
    		}
    				return this.addScanConfigurationsScanRegistryKeysMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.addScanConfigurations));
    	}

    	/**  */
    	@InjectArgs
    	public removeScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationIds') scanConfigurationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveScanConfigurationsScanRegistryKeysBaseVariables = {
    			id: id,
			scanConfigurationIds: scanConfigurationIds
    		}
    				return this.removeScanConfigurationsScanRegistryKeysMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.removeScanConfigurations));
    	}

    	/**  */
    	@InjectArgs
    	public addScanRegistries(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanRegistryIds') scanRegistryIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddScanRegistriesScanRegistryKeysBaseVariables = {
    			id: id,
			scanRegistryIds: scanRegistryIds
    		}
    				return this.addScanRegistriesScanRegistryKeysMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.addScanRegistries));
    	}

    	/**  */
    	@InjectArgs
    	public removeScanRegistries(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanRegistryIds') scanRegistryIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveScanRegistriesScanRegistryKeysBaseVariables = {
    			scanRegistryIds: scanRegistryIds
    		}
    				return this.removeScanRegistriesScanRegistryKeysMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistryKeys.removeScanRegistries));
    	}
    
}