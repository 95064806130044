import { InterventionModelBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { FormGroupHelpers } from '@clarilog/shared2/components/form/work-form/form-group-helpers';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { GqlField, GqlSubField } from '../helpers';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { FileManagerCoreService } from './file-manager.service';
import { UserCoreService } from './user.service';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization';
import { LocalStorageService } from './local-storage-service/local-storage-service';
import { UntypedFormGroup } from '@angular/forms';

@Authorize('help-desk-operator.manage-intervention-model')
@Injectable({ providedIn: 'root' })
export class InterventionModelCoreService extends InterventionModelBaseService {
  constructor(
    injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private userService: UserCoreService,
    private authService: AuthorizationCoreService,
    private localStorageService: LocalStorageService,
  ) {
    super(injector);
  }

  /** Applique le modéle d'intervention */
  public manageInterventionModel(interventionId, form, state: ModelState) {
    this.get(
      [
        GqlSubField.create('data', [
          GqlSubField.create(
            'name',
            this.translatedFieldHelperService.translatedFields(),
          ),
          GqlField.create('elaspedTime'),
          GqlField.create('cost'),
          GqlField.create('activityId'),
          GqlField.create('fileIds'),
          GqlSubField.create('files', FileManagerCoreService.FileModelFields()),
          GqlField.create('operatorTeamId'),
          GqlField.create('operatorId'),
          GqlSubField.create(
            'description',
            this.translatedFieldHelperService.translatedFields(),
          ),
        ]),
      ],
      interventionId,
    ).subscribe(async (x) => {
      if (x.data != null) {
        this.fillFieldModel(form, 'description', x.data.description, state);
        this.fillFieldModel(form, 'activityId', x.data.activityId, state);
        this.fillFieldModel(form, 'elaspedTime', x.data.elaspedTime, state);
        this.fillFieldModel(form, 'cost', x.data.cost, state);

        let getNewOperatorTeamId = x.data.operatorTeamId;
        let getNewOperatorId = x.data.operatorId;
        let userId = this.authService.user.getClaim('userId');
        if (getNewOperatorTeamId != null || getNewOperatorTeamId != undefined) {
          if (getNewOperatorId == null || getNewOperatorId == undefined) {
            let isOperator = await this.localStorageService.user
              .isOperator()
              .toPromise();
            if (isOperator) {
              await this.userService
                .get(
                  [
                    GqlSubField.create('data', [
                      GqlField.create('operatorTeamIds'),
                    ]),
                  ],
                  userId,
                )
                .subscribe((response) => {
                  let getOperatorTeamIds = response.data?.operatorTeamIds;
                  if (
                    getOperatorTeamIds?.length == 0 ||
                    !getOperatorTeamIds?.includes(getNewOperatorTeamId)
                  ) {
                    userId = null;
                  }
                  this.fillFieldModel(form, 'operatorId', userId, state);
                });
            } else {
              this.fillFieldModel(form, 'operatorId', null, state);
            }
          } else {
            this.fillFieldModel(form, 'operatorId', getNewOperatorId, state);
          }
          this.fillFieldModel(
            form,
            'operatorTeamId',
            x.data.operatorTeamId,
            state,
          );
        }

        if (
          (getNewOperatorId != null || getNewOperatorId != undefined) &&
          getNewOperatorTeamId == null
        ) {
          this.setFieldOperatorTeamByOperatorId(getNewOperatorId, form, state);
        }

        if (
          (getNewOperatorTeamId == undefined || getNewOperatorTeamId == null) &&
          (getNewOperatorId == undefined || getNewOperatorId == null)
        ) {
          let isOperator = await this.localStorageService.user
            .isOperator()
            .toPromise();
          if (isOperator) {
            this.setFieldOperatorTeamByOperatorId(userId, form, state);
          }
        }
        this.fillFieldModel(
          form,
          'fileIds',
          {
            itemsAdded: x.data.fileIds,
            itemsFilesAdded: x.data.files?.data,
            itemsRemoved: [],
            itemsUpdated: [],
          },
          state,
        );
      }
    });
  }

  /** Applique la valeur au champ du formulaire */
  private fillFieldModel(form, name, value, state: ModelState) {
    let field = FormGroupHelpers.formControlByName(form, name);
    if (field != undefined) {
      field.setValue(value);
      field.markAsDirty();
    } else {
      if (state?.sharedContext != undefined) {
        state.sharedContext.entry.set(name, () => value);
      }
    }
  }

  private async setFieldOperatorTeamByOperatorId(
    getNewOperatorId: string,
    form: UntypedFormGroup,
    state: ModelState,
  ) {
    let getMainOperator = await this.userService
      .get(
        [GqlSubField.create('data', [GqlField.create('mainOperatorTeamId')])],
        getNewOperatorId,
      )
      .toPromise();
    this.fillFieldModel(
      form,
      'operatorTeamId',
      getMainOperator.data.mainOperatorTeamId,
      state,
    );
    this.fillFieldModel(form, 'operatorId', getNewOperatorId, state);
  }
}
