import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAgentHistoriesBaseVariables, FirstAgentHistoriesBaseVariables, CountAgentHistoriesBaseVariables, FindAgentHistoriesBaseVariables, SearchAgentHistoriesBaseVariables, ExportSchemaAgentHistoriesBaseVariables, ExportDataAgentHistoriesBaseVariables, CustomQueryAgentHistoriesBaseVariables, CustomQueryIdAgentHistoriesBaseVariables, UsedAgentHistoriesBaseVariables, ExistAgentHistoriesBaseVariables, UpdateManyAgentHistoriesBaseVariables, DeleteAgentHistoriesBaseVariables } from '../gqls'
import { GetAgentHistoriesDocument, FirstAgentHistoriesDocument, CountAgentHistoriesDocument, FindAgentHistoriesDocument, SearchAgentHistoriesDocument, ExportSchemaAgentHistoriesDocument, ExportDataAgentHistoriesDocument, CustomQueryAgentHistoriesDocument, CustomQueryIdAgentHistoriesDocument, UsedAgentHistoriesDocument, ExistAgentHistoriesDocument, UpdateManyAgentHistoriesDocument, DeleteAgentHistoriesDocument } from '../gqls'
import { ServiceSingleResultOfAgentHistory, QueryContextOfAgentHistory, FilterOfAgentHistory, ServiceSingleResultOfInt64, ServiceListResultOfAgentHistory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, FieldUpdateOperatorOfAgentHistory } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AgentHistoryBaseService {
    
public modelName = 'agentHistory';
public modelPluralName = 'agentHistories';

	private getAgentHistoriesQuery: GetAgentHistoriesDocument;
	private firstAgentHistoriesQuery: FirstAgentHistoriesDocument;
	private countAgentHistoriesQuery: CountAgentHistoriesDocument;
	private findAgentHistoriesQuery: FindAgentHistoriesDocument;
	private searchAgentHistoriesQuery: SearchAgentHistoriesDocument;
	private exportSchemaAgentHistoriesQuery: ExportSchemaAgentHistoriesDocument;
	private exportDataAgentHistoriesQuery: ExportDataAgentHistoriesDocument;
	private customQueryAgentHistoriesQuery: CustomQueryAgentHistoriesDocument;
	private customQueryIdAgentHistoriesQuery: CustomQueryIdAgentHistoriesDocument;
	private usedAgentHistoriesQuery: UsedAgentHistoriesDocument;
	private existAgentHistoriesQuery: ExistAgentHistoriesDocument;
	private updateManyAgentHistoriesMutation: UpdateManyAgentHistoriesDocument;
	private deleteAgentHistoriesMutation: DeleteAgentHistoriesDocument;

	constructor(private injector: Injector) {
		this.getAgentHistoriesQuery = this.injector.get(GetAgentHistoriesDocument);
		this.firstAgentHistoriesQuery = this.injector.get(FirstAgentHistoriesDocument);
		this.countAgentHistoriesQuery = this.injector.get(CountAgentHistoriesDocument);
		this.findAgentHistoriesQuery = this.injector.get(FindAgentHistoriesDocument);
		this.searchAgentHistoriesQuery = this.injector.get(SearchAgentHistoriesDocument);
		this.exportSchemaAgentHistoriesQuery = this.injector.get(ExportSchemaAgentHistoriesDocument);
		this.exportDataAgentHistoriesQuery = this.injector.get(ExportDataAgentHistoriesDocument);
		this.customQueryAgentHistoriesQuery = this.injector.get(CustomQueryAgentHistoriesDocument);
		this.customQueryIdAgentHistoriesQuery = this.injector.get(CustomQueryIdAgentHistoriesDocument);
		this.usedAgentHistoriesQuery = this.injector.get(UsedAgentHistoriesDocument);
		this.existAgentHistoriesQuery = this.injector.get(ExistAgentHistoriesDocument);
		this.updateManyAgentHistoriesMutation = this.injector.get(UpdateManyAgentHistoriesDocument);
		this.deleteAgentHistoriesMutation = this.injector.get(DeleteAgentHistoriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAgentHistory> {
    
    		let variables: GetAgentHistoriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAgentHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentHistories.get));
            }
            else{
                let result:ServiceSingleResultOfAgentHistory={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAgentHistory,
		@Args('filter?') filter?: FilterOfAgentHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAgentHistory> {
    
    		let variables: FirstAgentHistoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAgentHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentHistories.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAgentHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAgentHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countAgentHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentHistories.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAgentHistory,
		@Args('filter?') filter?: FilterOfAgentHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAgentHistory> {
    
    		let variables: FindAgentHistoriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAgentHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentHistories.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAgentHistory,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAgentHistory> {
    
    		let variables: SearchAgentHistoriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAgentHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentHistories.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAgentHistoriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAgentHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentHistories.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAgentHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAgentHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAgentHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentHistories.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAgentHistory,
		@Args('filter?') filter?: FilterOfAgentHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAgentHistory> {
    
    		let variables: CustomQueryAgentHistoriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAgentHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentHistories.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAgentHistory,
		@Args('filter?') filter?: FilterOfAgentHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAgentHistory> {
    
    		let variables: CustomQueryIdAgentHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAgentHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentHistories.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAgentHistoriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedAgentHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentHistories.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAgentHistoriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAgentHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentHistories.exist));
    	}
    
    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAgentHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAgentHistoriesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAgentHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agentHistories.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAgentHistoriesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAgentHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agentHistories.delete));
    	}
    
}