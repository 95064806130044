import { ModelManufacturerBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Args, InjectArgs } from '@clarilog/core/modules';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfModelManufacturer,
  QueryContextOfModelManufacturer,
  ServiceListResultOfModelManufacturer,
  Sort,
} from '../types';
import { Observable } from 'rxjs';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
@Injectable({ providedIn: 'root' })
@Authorize('asset.manage-model-manufacturer')
export class ModelManufacturerCoreService extends ModelManufacturerBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public findModel(
    @Args('fields') fields: Array<GqlSubField | GqlField>,
    @Args('filter?') filter: FilterOfModelManufacturer,
    @Args('options?') options: QueryContextOfModelManufacturer,
    @Args('extendedVariable?') extendedVariables: any = null,
  ): Observable<ServiceListResultOfModelManufacturer> {
    return this.find(fields, options, filter, extendedVariables);
  }

  @InjectArgs
  public findListModelManufacturer(
    @Args('fields') fields: Array<GqlSubField | GqlField>,
    @Args('filter?') filter: FilterOfModelManufacturer,
    @Args('options?') options: QueryContextOfModelManufacturer,
    @Args('selectedKeys?') selectedKeys: any[],
    @Args('extendedVariable?') extendedVariables: any = null,
  ): Observable<ServiceListResultOfModelManufacturer> {
    let createFilter: FilterOfModelManufacturer = filter;
    let list: string[] = [];
    selectedKeys?.map((element) => {
      list.push(element.id);
      let index = list.findIndex((x) => x === element?.manufacturerId);
      if (element.manufacturerId && index === -1) {
        list.push(element.manufacturerId);
      }
    });

    if (list.length > 0) {
      createFilter = { id: { nin: list } };
    }
    if (filter != null) {
      createFilter = { and: [filter] };
    }
    return this.findListManufacturer(
      fields,
      options,
      createFilter,
      extendedVariables,
    );
  }

  public findLookupManufacturer() {
    return new Observable<any[]>((observer) => {
      this.find(
        [
          GqlSubField.create('data', [
            GqlField.create('name'),
            GqlField.create('id'),
          ]),
        ],
        { sort: [{ name: Sort.Asc }] },
        {
          manufacturerId: { eq: null },
        },
      ).subscribe((response) => {
        observer.next(response.data);
        observer.complete();
      });
    });
  }

  public findLookupModelManufacturer() {
    return new Observable<any[]>((observer) => {
      this.findModel(
        [
          GqlSubField.create('data', [
            GqlField.create('name'),
            GqlField.create('id'),
            GqlField.create('manufacturerId'),
          ]),
        ],

        {
          manufacturerId: { ne: null },
        },
        { sort: [{ name: Sort.Asc }] },
      ).subscribe((response) => {
        observer.next(response.data);
        observer.complete();
      });
    });
  }

  public findModelList() {
    return new Observable<any[]>((observer) => {
      this.findModel(
        [
          GqlSubField.create('data', [
            GqlField.create('name'),
            GqlField.create('id'),
          ]),
        ],
        null,
        { sort: [{ name: Sort.Asc }] },
      ).subscribe((response) => {
        console.timeEnd();
        observer.next(response.data);
        observer.complete();
      });
    });
  }

  @InjectArgs
  public findManufacturerList(
    @Args('fields') fields: Array<GqlSubField | GqlField>,
    @Args('filter?') filter: FilterOfModelManufacturer,
    @Args('options?') options: QueryContextOfModelManufacturer,
    @Args('extendedVariable?') extendedVariables: any = null,
  ): Observable<ServiceListResultOfModelManufacturer> {
    options.sort = [{ name: Sort.Asc }];
    return this.find(fields, options, filter, extendedVariables);
  }
}
