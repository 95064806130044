import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { TicketTagBaseService } from '../service-bases';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-ticket-tag')
export class TicketTagCoreService extends TicketTagBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  getTicketType(): any[] {
    return [
      {
        id: 'Incident',
        name: TranslateService.get('entities/incident/_title/singular'),
      },
      {
        id: 'Request',
        name: TranslateService.get('entities/request/_title/singular'),
      },
    ];
  }
}
