import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetProcessorsBaseVariables, FirstProcessorsBaseVariables, CountProcessorsBaseVariables, FindProcessorsBaseVariables, SearchProcessorsBaseVariables, ExportSchemaProcessorsBaseVariables, ExportDataProcessorsBaseVariables, CustomQueryProcessorsBaseVariables, CustomQueryIdProcessorsBaseVariables, UsedProcessorsBaseVariables, ExistProcessorsBaseVariables } from '../gqls'
import { GetProcessorsDocument, FirstProcessorsDocument, CountProcessorsDocument, FindProcessorsDocument, SearchProcessorsDocument, ExportSchemaProcessorsDocument, ExportDataProcessorsDocument, CustomQueryProcessorsDocument, CustomQueryIdProcessorsDocument, UsedProcessorsDocument, ExistProcessorsDocument } from '../gqls'
import { ServiceSingleResultOfProcessor, QueryContextOfProcessor, FilterOfProcessor, ServiceSingleResultOfInt64, ServiceListResultOfProcessor, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ProcessorBaseService {
    
public modelName = 'processor';
public modelPluralName = 'processors';

	private getProcessorsQuery: GetProcessorsDocument;
	private firstProcessorsQuery: FirstProcessorsDocument;
	private countProcessorsQuery: CountProcessorsDocument;
	private findProcessorsQuery: FindProcessorsDocument;
	private searchProcessorsQuery: SearchProcessorsDocument;
	private exportSchemaProcessorsQuery: ExportSchemaProcessorsDocument;
	private exportDataProcessorsQuery: ExportDataProcessorsDocument;
	private customQueryProcessorsQuery: CustomQueryProcessorsDocument;
	private customQueryIdProcessorsQuery: CustomQueryIdProcessorsDocument;
	private usedProcessorsQuery: UsedProcessorsDocument;
	private existProcessorsQuery: ExistProcessorsDocument;

	constructor(private injector: Injector) {
		this.getProcessorsQuery = this.injector.get(GetProcessorsDocument);
		this.firstProcessorsQuery = this.injector.get(FirstProcessorsDocument);
		this.countProcessorsQuery = this.injector.get(CountProcessorsDocument);
		this.findProcessorsQuery = this.injector.get(FindProcessorsDocument);
		this.searchProcessorsQuery = this.injector.get(SearchProcessorsDocument);
		this.exportSchemaProcessorsQuery = this.injector.get(ExportSchemaProcessorsDocument);
		this.exportDataProcessorsQuery = this.injector.get(ExportDataProcessorsDocument);
		this.customQueryProcessorsQuery = this.injector.get(CustomQueryProcessorsDocument);
		this.customQueryIdProcessorsQuery = this.injector.get(CustomQueryIdProcessorsDocument);
		this.usedProcessorsQuery = this.injector.get(UsedProcessorsDocument);
		this.existProcessorsQuery = this.injector.get(ExistProcessorsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfProcessor> {
    
    		let variables: GetProcessorsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getProcessorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processors.get));
            }
            else{
                let result:ServiceSingleResultOfProcessor={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfProcessor,
		@Args('filter?') filter?: FilterOfProcessor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfProcessor> {
    
    		let variables: FirstProcessorsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstProcessorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processors.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfProcessor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountProcessorsBaseVariables = {
    			filter: filter
    		}
    				return this.countProcessorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processors.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfProcessor,
		@Args('filter?') filter?: FilterOfProcessor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfProcessor> {
    
    		let variables: FindProcessorsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findProcessorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processors.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfProcessor,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfProcessor> {
    
    		let variables: SearchProcessorsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchProcessorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processors.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaProcessorsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaProcessorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processors.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfProcessor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataProcessorsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataProcessorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processors.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfProcessor,
		@Args('filter?') filter?: FilterOfProcessor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfProcessor> {
    
    		let variables: CustomQueryProcessorsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryProcessorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processors.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfProcessor,
		@Args('filter?') filter?: FilterOfProcessor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfProcessor> {
    
    		let variables: CustomQueryIdProcessorsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdProcessorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processors.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedProcessorsBaseVariables = {
    			ids: ids
    		}
    				return this.usedProcessorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processors.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistProcessorsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existProcessorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processors.exist));
    	}
    
    
}