import { MessageBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import {
  AreaListCustomization,
  FilterOfMessage,
  MessageInput,
  QueryContextOfMessage,
  ServiceListResultOfMessage,
  ServiceSingleResultOfBoolean,
} from '../types';
import { GqlField, GqlSubField } from '../helpers';
import { InsertMessagesBaseVariables } from '../gqls';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { AbstractControl } from '@angular/forms';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { of } from 'rxjs';
import { InjectArgs, Args } from '@clarilog/core/modules';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization';

@Injectable({ providedIn: 'root' })
export class MessageCoreService extends MessageBaseService {
  constructor(
    injector: Injector,
    private authorizationService: AuthorizationCoreService,
  ) {
    super(injector);
  }

  findMentionEmail(): any {
    let mentions = [
      {
        key: 'ticketAuthorEmail',
        name: TranslateService.get(`entities/message/authorMail`),
      },
      {
        key: '[ticketPropertyTeams].userIds',
        name: TranslateService.get(`entities/incident/allUsersImpacted`),
      },

      {
        key: 'ticketOtherUserImpactedOperatorMail',
        name: TranslateService.get(
          `entities/incident/allUsersImpactedOperator`,
        ),
      },
      {
        key: 'ticketOtherUserImpactedUserHelpDeskMail',
        name: TranslateService.get(
          `entities/incident/allUsersImpactedHelpDesk`,
        ),
      },
      {
        key: 'ticketOtherUserImpactedSupplierMail',
        name: TranslateService.get(
          `entities/incident/allUsersImpactedSupplier`,
        ),
      },

      {
        key: '[ticketProperty].userMakeRequest.email',
        name: TranslateService.get(`entities/incident/userMakeRequest`),
      },
      {
        key: '[ticketProperty].userAffected.email',
        name: TranslateService.get(`entities/incident/userAffected`),
      },
      {
        key: '[ticketProperty].operatorReferent.email',
        name: TranslateService.get(`entities/incident/operatorReferentId`),
      },
      {
        key: '[ticketPropertyTeams].operatorTeamReferentId',
        name: TranslateService.get(`entities/incident/operatorTeamReferentId`),
      },
      {
        key: '[ticketProperty].operatorAffected.email',
        name: TranslateService.get(`entities/incident/operatorAffected`),
      },
      {
        key: '[ticketPropertyTeams].operatorTeamAffectedId',
        name: TranslateService.get(`entities/incident/operatorTeamAffectedTo`),
      },
      {
        key: 'ticketOperatorTeamAffectedUsersMail',
        name: TranslateService.get(`entities/incident/operatorTeamAffectedId`),
      },
      {
        key: 'ticketOperatorTeamReferentUsersMail',
        name: TranslateService.get(
          `entities/incident/operatorTeamReferentMailList`,
        ),
      },
    ];

    mentions.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return mentions;
  }

  /** Liste des champs de mention pour le sujet */
  findMentionSubject(): any {
    let mentions = [
      {
        key: 'ticketAuthorEmail',
        name: TranslateService.get(`entities/message/authorMail`),
      },
      {
        key: 'ticketAuthorName',
        name: TranslateService.get(`entities/message/authorName`),
      },
      {
        key: 'ticketCreationDate',
        name: TranslateService.get(`entities/incident/created`),
      },
      {
        key: '[ticketDateUTC].created',
        name: TranslateService.get(`entities/incident/createdUTC`),
      },
      {
        key: 'urlTicket',
        name: TranslateService.get(`entities/message/ticketUrl`),
      },
      {
        key: 'urlUserTicket',
        name: TranslateService.get(`entities/message/userTicketUrl`),
      },
      {
        key: 'replyTag',
        name: TranslateService.get(`entities/incident/replyTag`),
      },
      {
        key: '[ticketProperty].ticketNumber',
        name: TranslateService.get(`entities/ticket/ticketNumber`),
      },
      {
        key: '[ticketProperty].status.name',
        name: TranslateService.get(`entities/ticketStatus/_title/singular`),
      },
      {
        key: '[ticketProperty].title',
        name: TranslateService.get(`entities/incident/title`),
      },
      {
        key: '[ticketProperty].ticketCategory.name',
        name: TranslateService.get(`entities/ticketCategory/_title/singular`),
      },

      {
        key: '[ticketProperty].ticketStatusReasons.name',
        name: TranslateService.get(`entities/ticketStatusReason/reason`),
      },
      {
        key: '[ticketProperty].impact.name',
        name: TranslateService.get(`entities/impact/_title/singular`),
      },
      {
        key: '[ticketProperty].urgency.name',
        name: TranslateService.get(`entities/urgency/_title/singular`),
      },
      {
        key: '[ticketProperty].priority.name',
        name: TranslateService.get(`entities/priority/_title/singular`),
      },
      {
        key: '[ticketProperty].userMakeRequest.name',
        name: TranslateService.get(`entities/incident/userMakeRequest`),
      },
      {
        key: '[ticketProperty].userAffected.name',
        name: TranslateService.get(`entities/incident/userAffected`),
      },
      {
        key: '[ticketProperty].operatorReferent.name',
        name: TranslateService.get(`entities/incident/operatorReferentId`),
      },
      {
        key: '[ticketProperty].operatorTeamReferent.name',
        name: TranslateService.get(`entities/incident/operatorTeamReferentId`),
      },
      {
        key: '[ticketProperty].operatorAffected.name',
        name: TranslateService.get(`entities/incident/operatorAffected`),
      },
      {
        key: '[ticketProperty].operatorTeamAffected.name',
        name: TranslateService.get(`entities/incident/operatorTeamAffectedTo`),
      },
      {
        key: '[ticketProperty].levelSupport',
        name: TranslateService.get(`entities/incident/levelSupport`),
      },
      {
        key: '[ticketProperty].resolutionCategory.name',
        name: TranslateService.get(`entities/incident/resolutionCategory`),
      },
    ];
    mentions.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return mentions;
  }

  /** Liste des champs de mention pour le corps */
  findMentionBody(): any {
    let mentions = this.findMentionSubject();
    mentions = [
      {
        key: 'content',
        name: TranslateService.get(`entities/message/_title/singular`),
      },
      {
        key: '[ticketProperty].channel.name',
        name: TranslateService.get(`entities/channel/_title/singular`),
      },
      {
        key: '[ticketProperty].description',
        name: TranslateService.get(`entities/incident/description`),
      },
      {
        key: '[ticketProperty].ticketCategory.path',
        name: TranslateService.get(`entities/incident/categoryPath`),
      },
      {
        key: '[ticketProperty].location.name',
        name: TranslateService.get(`entities/location/_title/singular`),
      },
      {
        key: '[ticketProperty].organizationalUnit.name',
        name: TranslateService.get(
          `entities/organizationalUnit/_title/singular`,
        ),
      },
      {
        key: '[ticketProperty].sla.name',
        name: TranslateService.get(`entities/incident/serviceLevelAgreement`),
      },
      {
        key: '[ticketProperty].respondBefore',
        name: TranslateService.get(`entities/incident/respondBefore`),
      },
      {
        key: '[ticketDateUTC].resolveBefore',
        name: TranslateService.get(`entities/incident/resolveBeforeUTC`),
      },
      {
        key: '[ticketDateUTC].respondBefore',
        name: TranslateService.get(`entities/incident/respondBeforeUTC`),
      },
      {
        key: '[ticketProperty].resolveBefore',
        name: TranslateService.get(`entities/incident/resolveBefore`),
      },
      {
        key: '[ticketProperty].planned',
        name: TranslateService.get(`entities/incident/planned`),
      },
      {
        key: '[ticketProperty].estimate',
        name: TranslateService.get(`entities/incident/duration`),
      },
      {
        key: '[ticketProperty].resolutionComment',
        name: TranslateService.get(`entities/incident/resolutionComment`),
      },
      {
        key: '[ticketProperty].privateResolutionComment',
        name: TranslateService.get(
          `entities/incident/privateResolutionComment`,
        ),
      },
      {
        key: 'userMakeRequest.email',
        name: TranslateService.get(`entities/incident/userMakeRequestEmail`),
      },
      {
        key: '[ticketUserMakeRequestProperty].userMakeRequest.PrincipalIpPhone',
        name: TranslateService.get(`entities/incident/userMakeRequestVoIP`),
      },
      {
        key: '[ticketUserMakeRequestProperty].userMakeRequest.PrincipalMobile',
        name: TranslateService.get(`entities/incident/userMakeRequestMobile`),
      },
      {
        key: '[ticketUserMakeRequestProperty].userMakeRequest.PrincipalPhone',
        name: TranslateService.get(`entities/incident/userMakeRequestPhone`),
      },
      {
        key: 'operatorAffected.email',
        name: TranslateService.get(`entities/incident/operatorAffectedEmail`),
      },
      {
        key: '[ticketOperatorConnectedProperty].name',
        name: TranslateService.get(`entities/incident/operatorConnectedName`),
      },
      {
        key: '[ticketOperatorAffectedProperty].principalIpPhone',
        name: TranslateService.get(`entities/incident/operatorAffectedVoIP`),
      },
      {
        key: '[ticketOperatorAffectedProperty].principalMobile',
        name: TranslateService.get(`entities/incident/operatorAffectedMobile`),
      },
      {
        key: '[ticketOperatorAffectedProperty].principalPhone',
        name: TranslateService.get(`entities/incident/operatorAffectedPhone`),
      },
      {
        key: 'userAffected.email',
        name: TranslateService.get(`entities/incident/userAffectedEmail`),
      },
      {
        key: '[ticketUserAffectedProperty].principalIpPhone',
        name: TranslateService.get(`entities/incident/userAffectedVoIP`),
      },
      {
        key: '[ticketUserAffectedProperty].principalMobile',
        name: TranslateService.get(`entities/incident/userAffectedMobile`),
      },
      {
        key: '[ticketUserAffectedProperty].principalPhone',
        name: TranslateService.get(`entities/incident/userAffectedPhone`),
      },
      {
        key: '[ticketProperty].tags',
        name: TranslateService.get(`entities/incident/tags`),
      },
      {
        key: 'urlUserMakeRequest',
        name: TranslateService.get(`entities/incident/urlUserMakeRequest`),
      },
      {
        key: 'urlUserAffected',
        name: TranslateService.get(`entities/incident/urlUserAffected`),
      },
      {
        key: 'urlMainImpactedAsset',
        name: TranslateService.get(`entities/incident/urlMainImpactedAsset`),
      },
      {
        key: 'urlRawIncidentOperator',
        name: TranslateService.get(`entities/incident/urlRawIncidentOperator`),
      },
      {
        key: 'urlRawIncidentHelpMe',
        name: TranslateService.get(`entities/incident/urlRawIncidentHelpMe`),
      },
      {
        key: 'urlTicketMessagesHelpMe',
        name: TranslateService.get(`entities/ticket/helpMeTicketLink`),
      },
      {
        key: 'mainAssetImpacted',
        name: TranslateService.get(`entities/incident/mainAssetImpacted`),
      },
      ...mentions,
    ];

    mentions.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return mentions;
  }

  /** Permet d'ajouter une nouvelle entité. */
  public insert2(
    fields: Array<GqlField | GqlSubField>,
    entity: MessageInput,
    extendedVariables?: any,
  ): Observable<ServiceSingleResultOfBoolean> {
    let variables: InsertMessagesBaseVariables = {
      entity: entity,
    };
    return this.insert(fields, entity, extendedVariables).pipe(
      map((res) => {
        if (res.errors) {
          throw new Error(res.errors.toString());
        } else {
          return { data: true } as ServiceSingleResultOfBoolean;
        }
      }),
    );
  }
  /** Retourne le service */
  public getService() {
    return this;
  }
  /** Validation  */
  public oneOrMore(
    state: ModelState,
    control: AbstractControl,
  ): Observable<boolean> {
    // si nouveau
    let id = state.sharedContext.params.get('id');
    if (id == undefined) {
      return of(false);
    } else {
      // count email
      let result = this.count(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
        {
          ticketId: {
            eq: id,
          },
        },
      ).pipe(
        map((r) => {
          if (r?.data != undefined && r.data > 0) {
            return true;
          }

          return false;
        }),
      );

      return result;
    }
  }

  @InjectArgs
  public findVisibleOnHelpMePortal(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfMessage,
    @Args('filter?') filter?: FilterOfMessage,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfMessage> {
    if (options == undefined || options == null) {
      options = {};
    }

    let myHelpMePortal: AreaListCustomization;

    if (
      this.authorizationService.user.getHelpMeAreaListCustomization() !=
      undefined
    ) {
      myHelpMePortal =
        this.authorizationService.user.getHelpMeAreaListCustomization();

      if (myHelpMePortal.messageNumberEnable == true) {
        if (myHelpMePortal.messageNumber > 0) {
          options.limit = myHelpMePortal.messageNumber;
        }
      }
    }

    return this.findNotRead(fields, options, filter, extendedVariables);
  }
}
