import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { alert } from 'devextreme/ui/dialog';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'clc-pnp-entity-list',
  templateUrl: './pnp-entity-list.component.html',
  styleUrls: ['./pnp-entity-list.component.scss']
})
export class CorePnpEntityListComponent implements OnInit {

  source: any;
  expandedRowKeys: any[];

  /** Obtient ou définit la source de données. */
  @Input() dataSource: DataSource;

  constructor(
    private _domSanitizationService: DomSanitizer
  ) { }

  async ngOnInit() {
    this.source = [];
    this.expandedRowKeys = [];
    let datasource = this.dataSource['datasource'];
    if (datasource == undefined) return;

    datasource.pageSize(500000);
    datasource.load().then(result => {
      if (result.length > 0) {
        result.reduce((res, value) => {
          if (!res[value.classGuid]) {

            let items = result.filter(x => x.classGuid == value.classGuid);

            items.sort(function (a, b) {
              if (a.name < b.name) { return -1; }
              if (a.name > b.name) { return 1; }
              return 0;
            })

            res[value.classGuid] = {
              id: value.classGuid,
              classGuid: value.classGuid,
              name: value.pnpEntityString,
              items: items,
              configManagerErrorCode: items.filter(f => f.configManagerErrorCode != 0).length,
              ico: value.ico
            };
            if (items.filter(f => f.configManagerErrorCode != 0).length > 0) {
              this.expandedRowKeys.push(value.classGuid);
            }

            this.source.push(res[value.classGuid]);
          }
          return res;
        });

        this.source.sort(function (a, b) {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
      }
    });

  }

  onRowClick(e) {

    if (e.event.target.parentElement.classList.contains("dx-treelist-expanded") ||
      e.event.target.parentElement.classList.contains("dx-treelist-collapsed") ||
      e.event.target.classList.contains("dx-checkbox-icon")) {
      return;
    }

    let stringError = e.data.configManagerErrorCodeString;
    if (e.level > 0 && e.data.configManagerErrorCode != 0) {
      if (stringError == undefined || stringError == "" || stringError == null) {
        stringError = TranslateService.get("globals/popupWarning");
        alert(stringError, TranslateService.get("description"));
      } else {
        alert(stringError, TranslateService.get("description"));
      }
    }

    if (e.level == 0) {
      if (!e.isExpanded) {
        e.component.expandRow(e.key);
      }
      else {
        e.component.collapseRow(e.key);
      }

    }
  }

  loadIco(ico: string): SafeUrl {
    return this._domSanitizationService.bypassSecurityTrustUrl(ico);
  }
}
