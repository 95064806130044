import { NetworkCredentialBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  InjectArgs,
  Args,
} from '@clarilog/core/modules/decorators/args-decorator';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { of, Observable } from 'rxjs';
import { WmiCredentialCoreService } from './wmi-credential.service';
import { EsxCredentialCoreService } from './esx-credential.service';
import { SnmpCredentialCoreService } from './snmp-credential.service';
import { SshCredentialCoreService } from './ssh-credential.service';
import { IamtCredentialCoreService } from './iamt-credential.service';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { CoreLicenseReader } from '@clarilog/core/services2/license-reader/license-reader';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfNetworkCredential,
  FilterOfScanConfiguration,
  QueryContextOfNetworkCredential,
  QueryContextOfScanConfiguration,
  ServiceListResultOfNetworkCredential,
  ServiceListResultOfScanConfiguration,
} from '../types';
import { map } from 'rxjs/operators';
import { ScanConfigurationCoreService } from './scan-configuration.service';

@Injectable({ providedIn: 'root' })
@Authorize('scan-configuration.manage-network-credential')
export class NetworkCredentialCoreService extends NetworkCredentialBaseService {
  constructor(
    injector: Injector,
    private wmiService: WmiCredentialCoreService,
    private esxService: EsxCredentialCoreService,
    private snmpService: SnmpCredentialCoreService,
    private iamtService: IamtCredentialCoreService,
    private sshService: SshCredentialCoreService,
    public licenseReader: CoreLicenseReader,
    private scanConfigurationService: ScanConfigurationCoreService,
  ) {
    super(injector);
  }
  /** Permet de choisir le type d'élément à créer  */
  findByKey(): Observable<{ id: string; name: string }[]> {
    let licenceAsset = this.licenseReader.hasFeature('featureAssets');

    let items = [
      {
        id: 'wmi-credential',
        name: TranslateService.get('entities/wmiCredential/_title/singular'),
        visibled: true,
      },
      {
        id: 'ssh-credential',
        name: TranslateService.get('entities/sshCredential/_title/singular'),
        visibled: licenceAsset,
      },
      {
        id: 'snmp-credential',
        name: TranslateService.get('entities/snmpCredential/_title/singular'),
        visibled: licenceAsset,
      },
      {
        id: 'esx-credential',
        name: TranslateService.get('entities/esxCredential/_title/singular'),
        visibled: licenceAsset,
      },
    ];
    items = items.filter((f) => f.visibled);
    items.sort((a, b) => a.name.localeCompare(b.name));
    return of(items);
  }
  /** Converti la valeur en url */
  @InjectArgs
  toUrl(@Args('type') type: string): string {
    switch (type) {
      case 'WmiCredential':
        return 'wmi-credential';
      case 'SshCredential':
        return 'ssh-credential';
      case 'IamtCredential':
        return 'iamt-credential';
      case 'EsxCredential':
        return 'esx-credential';
      case 'SnmpCredential':
        return 'snmp-credential';
    }
  }

  /**
   * Obtient le query en fonction du Type
   */
  getQueryByType(@Args('type') type: string) {
    switch (type) {
      case 'WmiCredential':
        return this.wmi();
      case 'SshCredential':
        return this.ssh();
      case 'IamtCredential':
        return this.iamt();
      case 'SnmpCredential':
        return this.snmp();
      case 'EsxCredential':
        return this.esx();
      default:
        return this;
    }
  }

  /** Obtient le nom traduit du type */
  @InjectArgs
  translateType(@Args('type') type: string): string {
    switch (type) {
      case 'wmi-credential':
        return 'wmiCredential';
      case 'ssh-credential':
        return 'sshCredential';
      case 'iamt-credential':
        return 'iamtCredential';
      case 'esx-credential':
        return 'esxCredential';
      case 'snmp-credential':
        return 'snmpCredential';
    }
  }
  /**
   * Retourne le service pour les authentification WMI
   */
  wmi(): WmiCredentialCoreService {
    return this.wmiService;
  }

  /**
   * Retourne le service pour les authentification ESX
   */
  esx(): EsxCredentialCoreService {
    return this.esxService;
  }

  /**
   * Retourne le service pour les authentification SNMP
   */
  snmp(): SnmpCredentialCoreService {
    return this.snmpService;
  }

  /**
   * Retourne le service pour les authentification IAMT
   */
  iamt(): IamtCredentialCoreService {
    return this.iamtService;
  }

  /**
   * Retourne le service pour les authentification SSH
   */
  ssh(): SshCredentialCoreService {
    return this.sshService;
  }

  @InjectArgs
  public findAssociatedScanConfigurationsCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfScanConfiguration,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfScanConfiguration,

    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScanConfiguration> {
    let dataOption =
      this.scanConfigurationService.cleanAndRetrieveNewOption(options);
    return this.findAssociatedScanConfigurations(
      fields,
      dataOption.options,
      id,
      filter,
      extendedVariables,
    ).pipe(
      map((result) => {
        return this.scanConfigurationService.orderResultByType(
          result,
          dataOption.sortTypenameValue,
        );
      }),
    );
  }

  /**  */
  @InjectArgs
  public findUnassociatedScanConfigurationsCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfScanConfiguration,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfScanConfiguration,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScanConfiguration> {
    let dataOption =
      this.scanConfigurationService.cleanAndRetrieveNewOption(options);
    return this.findUnassociatedScanConfigurations(
      fields,
      dataOption.options,
      id,
      filter,
      extendedVariables,
    ).pipe(
      map((result) => {
        return this.scanConfigurationService.orderResultByType(
          result,
          dataOption.sortTypenameValue,
        );
      }),
    );
  }

  @InjectArgs
  public findCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfNetworkCredential,
    @Args('filter?') filter?: FilterOfNetworkCredential,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfNetworkCredential> {
    let dataOption =
      this.scanConfigurationService.cleanAndRetrieveNewOption(options);
    return this.find(
      fields,
      dataOption.options,
      filter,
      extendedVariables,
    ).pipe(
      map((result) => {
        return this.scanConfigurationService.orderResultByType(
          result,
          dataOption.sortTypenameValue,
        );
      }),
    );
  }
}
