import { context } from 'devexpress-dashboard/model/index.metadata';
import { of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Observable } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import {
  GqlSubField,
  GqlField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { CoreGraphQLDataSource } from '@clarilog/core/services2/graphql/graphql-store.service';
import { GC } from '@clarilog/core/services/gc/gc';
import { FormControl, UntypedFormGroup, AbstractControl } from '@angular/forms';
import {
  Control,
  DependsOn,
  WorkItemConfiguration,
} from '@clarilog/shared2/models/schema';
import { FormGroupHelpers } from '@clarilog/shared2/components/form/work-form/form-group-helpers';
import { EventEmitter, Injectable, Injector, Output } from '@angular/core';
import { CoreWorkFormComponent } from '@clarilog/shared2/components';
import { Badge } from '@clarilog/core/services2/graphql/generated-types/types';

export abstract class AbstractModelContext {
  public serviceName: string = '';
  public methodeName: string = '';
  public context: ModelContextCore;
  public id: string;

  public rootState: ModelState; // rootstate

  constructor(serviceName: string, methodName: string) {
    this.serviceName = serviceName;
    this.methodeName = methodName;

    this.context = new ModelContextCore();
  }

  public getArgs(params: string[]): any[] {
    let result = [];

    params.forEach((param) => {
      let argValue = this.context.params.get(param);
      if (argValue == undefined) {
        argValue = this.rootState.sharedContext.params.get(param);
      }
      if (argValue == undefined) {
        argValue = this.rootState.getFormControlValue(param.replace('?', ''));
      }

      // Ne prend pas en compte la valeur sur un id
      if (param.replace('?', '') == 'id' && argValue == '') {
        argValue = undefined;
      }

      if (param === 'currentContext') {
        result.push(this);
      } else {
        if (argValue === undefined) {
          if (param.includes('?') === false) {
            throw new Error(
              `Le paramètre '${param}' n'existe pas dans le context d'execution.`,
            );
          } else {
            result.push(null);
          }
        } else {
          result.push(argValue);
        }
      }
    });

    return result;
  }
}

export type ModelDataSourceContextCtorArgs = {
  datasource?: DataSource | CoreGraphQLDataSource;
  serviceName?: string;
  methodName?: string;
  rootState?: ModelState;
};

export class ModelFnContext extends AbstractModelContext {
  public fnCall: () => Observable<any>;
  constructor(serviceName: string, methodName: string) {
    super(serviceName, methodName);
  }
}

export class ModelEventContext extends AbstractModelContext {
  public eventEmitter: () => EventEmitter<void>;
  constructor(serviceName: string, methodName: string) {
    super(serviceName, methodName);
  }
}

export class ModelDataSourceContext extends AbstractModelContext {
  public datasource: DataSource | CoreGraphQLDataSource;
  public test: any;

  constructor(options: ModelDataSourceContextCtorArgs) {
    super(options.serviceName, options.methodName);
    this.datasource = options.datasource;
    this.rootState = options.rootState;
  }
}

export class ModelDependsOnContext {
  public control: AbstractControl;
  public depControl: AbstractControl;

  public modelControl: any;

  public fn: (value) => void;

  constructor(
    control: AbstractControl,
    depControl: AbstractControl,
    modelControl: any,
    fn: (value) => void,
  ) {
    this.control = control;
    this.depControl = depControl;
    this.modelControl = modelControl;
    this.fn = fn;
  }

  validate() {
    this.fn(this.depControl.value);
  }
}
@Injectable({ providedIn: 'root' })
export class ModelState {
  public model: WorkItemConfiguration = null;
  public gc: GC;
  public form: UntypedFormGroup = null;
  public formComponent: CoreWorkFormComponent = null;
  /** si on est dans un edit */
  public id: string;
  public isSubForm: boolean = false;
  public injector: Injector;

  public contexts: AbstractModelContext[] = [];
  public dependsOnContexts: ModelDependsOnContext[] = [];
  public sharedContext: ModelContextCore = new ModelContextCore();
  public formFields: Array<GqlSubField | GqlField> = [];

  public canBeSave: boolean = false;

  /** Evenement action d'un bouton */
  @Output() onButtonActionClicked = new EventEmitter<any>();
  @Output() on = new EventEmitter<{ eventName: string; eventData: any }>();

  public addContext(context: AbstractModelContext) {
    context.rootState = this;
    this.contexts.push(context);
  }

  constructor(injector: Injector) {
    this.gc = new GC();
    this.injector = injector;
  }

  public subFormReady: EventEmitter<{
    gc: GC;
    subModelState: ModelState;
    key: string;
  }> = new EventEmitter<{ gc: GC; subModelState: ModelState; key: string }>();

  getFormControlValue(name: string) {
    if (this.form == undefined) {
      return null;
    }
    let control = FormGroupHelpers.formControlByName(this.form, name);

    return control == undefined ? null : control.value;
  }

  // public call(...args) {
  //     console.log(this.id)
  //     return of()

  // }

  // public pipe(...args) {
  //     console.log(this.id)
  //     return of()
  // }

  // public resource(...args) {
  //     console.log(this.id)
  //     return of()

  // }
}

export class ModelContextCore {
  private _params: ParamsCoreMap;
  private _entry: ParamsCoreMap;
  private _lock: ParamsCoreMap;
  private _entity: any;
  private _badges: Badge[];

  constructor() {
    this._params = new ParamsCoreMap();
    this._entry = new ParamsCoreMap();
    this._lock = new ParamsCoreMap();
  }
  /** Permet de verrouillé un champs */
  public get lock(): ParamsCoreMap {
    return this._lock;
  }
  public get params(): ParamsCoreMap {
    return this._params;
  }
  public get badges(): Badge[] {
    return this._badges;
  }
  public set badges(value: Badge[]) {
    this._badges = value;
  }

  public get entry(): ParamsCoreMap {
    return this._entry;
  }

  /** Obtient ou définit l'objet de base */
  public get entity(): any {
    return this._entity;
  }
  public set entity(value: any) {
    this._entity = value;
  }

  public clear() {
    this._params.clear();
  }
}
/** Représente un map de clé/valeur. */
export class ParamsCoreMap {
  _params: { [k: string]: () => any } = {};
  set(key: string, builder: () => any) {
    key = key.replace(/\./g, '_');
    this._params[key] = builder();
  }
  remove(key: string) {
    key = key.replace('?', '');
    delete this._params[key];
  }
  clear() {
    this._params = {};
  }
  get(key: string): any {
    if (key == 'currentContext') {
      return this._params;
    }

    let parameter = this._params[key];

    if (parameter === undefined) {
      // if params = filter? optional params
      parameter = this._params[key + '?'];
    }

    if (parameter == undefined) {
      return this.getWithReplaceUndescore(key);
    }
    return parameter;
  }

  getWithReplaceUndescore(key: string): any {
    if (key != '__typename') {
      key = key.replace(/\_/g, '.').replace('?', '');
    }
    if (key == 'currentContext') {
      return this._params;
    }

    let parameter = this._params[key];

    if (parameter === undefined) {
      // if params = filter? optional params
      parameter = this._params[key + '?'];
    }

    if (parameter == undefined) {
      return null;
    }
    return parameter;
  }

  materialize(): any {
    let obj = {};
    if (this.count() > 0) {
      const entry = this.all();
      for (let member in entry) {
        obj[member] = this.get(member);
      }
    }
    return obj;
  }
  all() {
    return this._params;
  }
  count() {
    return Object.keys(this._params).length;
  }
}
