import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanRegistriesBaseVariables, FirstScanRegistriesBaseVariables, CountScanRegistriesBaseVariables, FindScanRegistriesBaseVariables, SearchScanRegistriesBaseVariables, ExportSchemaScanRegistriesBaseVariables, ExportDataScanRegistriesBaseVariables, CustomQueryScanRegistriesBaseVariables, CustomQueryIdScanRegistriesBaseVariables, UsedScanRegistriesBaseVariables, ExistScanRegistriesBaseVariables } from '../gqls'
import { GetScanRegistriesDocument, FirstScanRegistriesDocument, CountScanRegistriesDocument, FindScanRegistriesDocument, SearchScanRegistriesDocument, ExportSchemaScanRegistriesDocument, ExportDataScanRegistriesDocument, CustomQueryScanRegistriesDocument, CustomQueryIdScanRegistriesDocument, UsedScanRegistriesDocument, ExistScanRegistriesDocument } from '../gqls'
import { ServiceSingleResultOfScanRegistry, QueryContextOfScanRegistry, FilterOfScanRegistry, ServiceSingleResultOfInt64, ServiceListResultOfScanRegistry, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScanRegistryBaseService {
    
public modelName = 'scanRegistry';
public modelPluralName = 'scanRegistries';

	private getScanRegistriesQuery: GetScanRegistriesDocument;
	private firstScanRegistriesQuery: FirstScanRegistriesDocument;
	private countScanRegistriesQuery: CountScanRegistriesDocument;
	private findScanRegistriesQuery: FindScanRegistriesDocument;
	private searchScanRegistriesQuery: SearchScanRegistriesDocument;
	private exportSchemaScanRegistriesQuery: ExportSchemaScanRegistriesDocument;
	private exportDataScanRegistriesQuery: ExportDataScanRegistriesDocument;
	private customQueryScanRegistriesQuery: CustomQueryScanRegistriesDocument;
	private customQueryIdScanRegistriesQuery: CustomQueryIdScanRegistriesDocument;
	private usedScanRegistriesQuery: UsedScanRegistriesDocument;
	private existScanRegistriesQuery: ExistScanRegistriesDocument;

	constructor(private injector: Injector) {
		this.getScanRegistriesQuery = this.injector.get(GetScanRegistriesDocument);
		this.firstScanRegistriesQuery = this.injector.get(FirstScanRegistriesDocument);
		this.countScanRegistriesQuery = this.injector.get(CountScanRegistriesDocument);
		this.findScanRegistriesQuery = this.injector.get(FindScanRegistriesDocument);
		this.searchScanRegistriesQuery = this.injector.get(SearchScanRegistriesDocument);
		this.exportSchemaScanRegistriesQuery = this.injector.get(ExportSchemaScanRegistriesDocument);
		this.exportDataScanRegistriesQuery = this.injector.get(ExportDataScanRegistriesDocument);
		this.customQueryScanRegistriesQuery = this.injector.get(CustomQueryScanRegistriesDocument);
		this.customQueryIdScanRegistriesQuery = this.injector.get(CustomQueryIdScanRegistriesDocument);
		this.usedScanRegistriesQuery = this.injector.get(UsedScanRegistriesDocument);
		this.existScanRegistriesQuery = this.injector.get(ExistScanRegistriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanRegistry> {
    
    		let variables: GetScanRegistriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScanRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistries.get));
            }
            else{
                let result:ServiceSingleResultOfScanRegistry={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanRegistry,
		@Args('filter?') filter?: FilterOfScanRegistry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanRegistry> {
    
    		let variables: FirstScanRegistriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScanRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistries.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanRegistry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScanRegistriesBaseVariables = {
    			filter: filter
    		}
    				return this.countScanRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistries.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanRegistry,
		@Args('filter?') filter?: FilterOfScanRegistry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanRegistry> {
    
    		let variables: FindScanRegistriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScanRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistries.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScanRegistry,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanRegistry> {
    
    		let variables: SearchScanRegistriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScanRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistries.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScanRegistriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScanRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistries.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanRegistry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScanRegistriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScanRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistries.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScanRegistry,
		@Args('filter?') filter?: FilterOfScanRegistry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanRegistry> {
    
    		let variables: CustomQueryScanRegistriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScanRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistries.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScanRegistry,
		@Args('filter?') filter?: FilterOfScanRegistry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanRegistry> {
    
    		let variables: CustomQueryIdScanRegistriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScanRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistries.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScanRegistriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedScanRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistries.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScanRegistriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScanRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanRegistries.exist));
    	}
    
    
}