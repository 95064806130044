import { ContractBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  FilterOfSecurityGroup,
  QueryContextOfSecurityGroup,
  ServiceListResultOfSecurityGroup,
} from '../types';
import {
  Args,
  Authorize,
  InjectArgs,
  EntityAttributesType,
} from '@clarilog/core';
import { GqlField, GqlFields, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';

@Injectable({ providedIn: 'root' })
@Authorize('contract')
export class ContractCoreService extends ContractBaseService {
  constructor(
    injector: Injector,
    public translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }

  /** Obtient les fields de recherche */
  public searchFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlField.create('contractCategoryId'),
        GqlField.create('name'),
        GqlSubField.create('contractCategory', [
          GqlSubField.create('data', [
            GqlSubField.create(
              'name',
              this.translatedFieldHelperService.translatedFields(),
            ),
          ]),
        ]),
      ]),
      GqlField.create('totalCount'),
    ];
  }
}
