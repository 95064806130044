import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import {
  CurrentInStockListOptions,
  LinkListOptions,
  PageControl,
  SchedulerOptions,
  StockCreatedAssetOptions,
} from '@clarilog/shared2/models/schema';
import { Subscription } from 'rxjs';
import { EventArgs } from '../../nav-menu';
import { CoreWorkItemControlDynamicComponent } from '../work-item-control-dynamic/work-item-control-dynamic.component';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { CorePermissionsListComponent } from '../../list/permissions-list/permissions-list.component';
import { CoreLinkListComponent } from '../../list/link-list/link-list.component';
import { CoreTrackingLogListComponent } from '../../tracking-log/tracking-log-list/tracking-log-list.component';
import { CoreWorkItemTabGroupComponent } from '../work-item-tab-group/work-item-tab-group.component';
import { MessageTicketComponent } from '../../message-ticket/message-ticket.component';
import { CoreFileManagerComponent } from '../../file-manager/file-manager.component';
import { LogicDiagramComponent } from '../../logic-diagram/logic-diagram.component';
import { CoreCheckLinkListComponent } from '../../list/check-link-list/check-link-list.component';
import { CoreGridComponent } from '../../list/grid/grid.component';
import {
  CoreDynamicLinkListComponent,
  CorePrintLinkListComponent,
  CoreSubFormGanttComponent,
  CoreSubFormLinkListComponent,
} from '../../list';

/** Représente un composant dynamique. */
@Component({
  selector: 'clc-work-item-group-dynamic',
  templateUrl: './work-item-group-dynamic.component.html',
  styleUrls: ['./work-item-group-dynamic.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class CoreWorkItemGroupDynamicComponent implements OnInit, OnDestroy {
  /** Représente la directive FormGroup. */
  public parent: FormGroupDirective;
  /** Représente le premier control. */
  private firstControl: CoreWorkItemControlDynamicComponent;
  /** Obtient ou définit le composent LinkList. */
  /// TODO UNCOM
  @ViewChild(CoreLinkListComponent) linkList: CoreLinkListComponent;
  @ViewChild(CoreGridComponent) gridList: CoreGridComponent;
  @ViewChild(CoreCheckLinkListComponent)
  checklinkList: CoreCheckLinkListComponent;
  @ViewChild(CorePermissionsListComponent)
  permissionListComponent: CorePermissionsListComponent;
  @ViewChild(CoreTrackingLogListComponent)
  trackingListComponent: CoreTrackingLogListComponent;
  @ViewChild(CoreWorkItemTabGroupComponent)
  itemTabGroupComponent: CoreWorkItemTabGroupComponent;
  @ViewChild(MessageTicketComponent)
  messageTicketComponent: MessageTicketComponent;
  @ViewChild(CoreFileManagerComponent)
  fileManagerComponent: CoreFileManagerComponent;
  @ViewChild(LogicDiagramComponent)
  logicDiagramComponent: LogicDiagramComponent;
  @ViewChild(CoreSubFormLinkListComponent)
  subFormLinkListComponent: CoreSubFormLinkListComponent;
  @ViewChild(CoreSubFormGanttComponent)
  ganttComponent: CoreSubFormGanttComponent;

  @ViewChild(CorePrintLinkListComponent)
  printLinkList: CorePrintLinkListComponent;

  @ViewChild(CoreDynamicLinkListComponent)
  dynamicLinkList: CoreDynamicLinkListComponent;
  /// END TODO UNCOM

  /** Obtient ou définit les données supplémentaires. */
  @Input() data: any;

  /** Gestion des activations du composant */
  onLoadedSubscription: Subscription;

  constructor(
    /** Récupère le FormGroup parent. */
    private formGroupDirective: FormGroupDirective,
  ) {
    this.parent = this.formGroupDirective;
  }
  ngOnDestroy(): void {
    if (this.onLoadedSubscription != undefined) {
      this.onLoadedSubscription.unsubscribe();
    }
  }

  @Input() state: ModelState;
  /** @inheritdoc */
  ngOnInit() {
    this.onLoaded.emit(this);
    this.createComponentsContext();
    this.onLoadedSubscription = this.parent.form.root.onLoadedChanged.subscribe(
      (r) => {
        if (r) {
          this.onActivated(false);
        }
      },
    );
  }
  /** Permet de créer les composent en fonction de data. */
  private createComponentsContext() {
    if ((this.data as PageControl).control !== undefined) {
      if (
        (this.data as PageControl).control.type === 'LinkListComponent' ||
        (this.data as PageControl).control.type === 'CheckLinkListComponent'
      ) {
        let options = (this.data as PageControl).control
          .options as LinkListOptions;
        options.source = options.source || undefined;
        options.selectSource = options.selectSource || undefined;
      } else if (
        (this.data as PageControl).control.type ===
        'StockCreatedAssetListComponent'
      ) {
        let options = (this.data as PageControl).control
          .options as StockCreatedAssetOptions;
        options.source = options.source || undefined;
        options.exportSource = options.exportSource || undefined;
      } else if (
        (this.data as PageControl).control.type ===
        'CurrentInStockListComponent'
      ) {
        let options = (this.data as PageControl).control
          .options as CurrentInStockListOptions;
        options.source = options.source || undefined;
        options.exportSource = options.exportSource || undefined;
      } else if (
        (this.data as PageControl).control.type === 'SchedulerComponent'
      ) {
        let options = (this.data as PageControl).control
          .options as SchedulerOptions;

        options.currentView = options.currentView || 'workWeek';
        options.views = options.views || [
          'day',
          'week',
          'workWeek',
          'month',
          'agenda',
        ];
        options.showAllDayPanel =
          options.showAllDayPanel != undefined ? options.showAllDayPanel : true;
        options.showAllDayPanel =
          options.showAllDayPanel != undefined ? options.showAllDayPanel : true;
        options.endDayHour =
          options.endDayHour != undefined ? options.endDayHour : 24;
        options.startDayHour =
          options.startDayHour != undefined ? options.startDayHour : 0;
        options.allowAdding =
          options.allowAdding != undefined ? options.allowAdding : true;
        options.allowDeleting =
          options.allowDeleting != undefined ? options.allowDeleting : true;
        options.allowUpdating =
          options.allowUpdating != undefined ? options.allowUpdating : true;
        options.defaultDuration =
          options.defaultDuration != undefined ? options.defaultDuration : 30;
      }
    }
  }

  @Output() onLoaded = new EventEmitter<CoreWorkItemGroupDynamicComponent>();

  /** Lorsque le composant n'est plus actif. */
  onDeactivated() {
    if (this.itemTabGroupComponent != undefined) {
      let instanceWorkGroup = this.itemTabGroupComponent.instanceWorkGroup;
      if (instanceWorkGroup != undefined && instanceWorkGroup.length > 0) {
        let data = {
          oldTab: {
            item: {
              control: {
                type: 'MessageTicketComponent',
              },
            },
          },
        };
        instanceWorkGroup.forEach((i) => {
          if (i.messageTicketComponent != undefined) {
            this.state.on.emit({ eventName: 'tab-change', eventData: data });
          }
          if (i.data?.control?.type === 'TicketEmailListComponent') {
            this.state.on.emit({ eventName: 'tab-change', eventData: data });
          }
        });
      }
    }
  }

  /** Lorsque le composant devient actif. */
  onActivated(refresh: boolean = true) {
    if (this.firstControl != undefined) {
      this.firstControl.onActivated();
    }
    if (this.linkList != undefined) {
      this.linkList.refresh();
    }
    if (this.checklinkList != undefined) {
      this.checklinkList.refresh();
    }

    if (this.gridList != undefined && this.gridList.options.type == 'Grid') {
      this.gridList.refresh();
    }

    if (this.permissionListComponent != undefined && refresh == true) {
      this.permissionListComponent.refresh();
    }
    if (this.trackingListComponent != undefined) {
      this.trackingListComponent.refresh();
    }
    if (this.subFormLinkListComponent != undefined) {
      this.subFormLinkListComponent.refresh();
    }

    if (this.printLinkList != undefined) {
      this.printLinkList.refresh();
    }

    if (this.dynamicLinkList != undefined) {
      this.dynamicLinkList.refresh();
    }

    if (this.ganttComponent != undefined) {
      this.ganttComponent.refresh();
    }

    if (this.logicDiagramComponent != undefined) {
      //this.logicDiagramComponent.refresh();
    }

    if (this.fileManagerComponent != undefined && refresh == true) {
      this.fileManagerComponent.refresh();
    }

    if (this.itemTabGroupComponent != undefined) {
      let instanceWorkGroup = this.itemTabGroupComponent.instanceWorkGroup;

      if (instanceWorkGroup != undefined && instanceWorkGroup.length > 0) {
        instanceWorkGroup.forEach((i) => {
          if (i.messageTicketComponent != undefined) {
            i.messageTicketComponent.refresh();
          }
          if (i.fileManagerComponent != undefined) {
            i.fileManagerComponent.refresh();
          }
          if (i.gridList != undefined && i.gridList.autoRefresh === true) {
            i.gridList.refresh();
          }
        });
      }

      if (this.ganttComponent != undefined) {
        this.ganttComponent.refresh();
      }
    }
  }

  /** Se produit lors de l'initialisation des composants DevExtreme. */
  onComponentInitialized(e: EventArgs<CoreWorkItemControlDynamicComponent>) {
    if (this.firstControl == undefined) {
      this.firstControl = e.component;
    }
  }

  checkControlVisibility(group) {
    let toBeVisible = false;

    group.controls.forEach((control) => {
      if (control.visible === true || control.visible == undefined) {
        toBeVisible = true;
      }
    });

    return toBeVisible;
  }
  checkSectionControlVisibility(section) {
    let toBeVisible = false;

    section.groups.forEach((group) => {
      if (this.checkControlVisibility(group) === true) {
        toBeVisible = true;
      }
    });

    return toBeVisible;
  }

  returnVisibleSection(sections) {
    let visibleSection = [];
    for (let section of sections) {
      if (this.checkSectionControlVisibility(section) === true) {
        visibleSection.push(section);
      }
    }
    return visibleSection;
  }

  calcPercentageByVisibility(data) {
    // let visibleSection = this.returnVisibleSection(data.sections);
    // if (visibleSection.length < 3) {
    //   "50"
    // }
    return data.percentage;
  }

  calcLocationByVisibility(section, sections) {
    let visibleSection = this.returnVisibleSection(sections);

    if (visibleSection.length === 1 && section.location !== 'full') {
      return undefined;
    }
    return section.location;
  }
}
