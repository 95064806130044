import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAffectedRulesBaseVariables, FirstAffectedRulesBaseVariables, CountAffectedRulesBaseVariables, FindAffectedRulesBaseVariables, SearchAffectedRulesBaseVariables, ExportSchemaAffectedRulesBaseVariables, ExportDataAffectedRulesBaseVariables, CustomQueryAffectedRulesBaseVariables, CustomQueryIdAffectedRulesBaseVariables, UsedAffectedRulesBaseVariables, ExistAffectedRulesBaseVariables, UpdateManyAffectedRulesBaseVariables, DeleteAffectedRulesBaseVariables, OrderAffectedRulesBaseVariables } from '../gqls'
import { GetAffectedRulesDocument, FirstAffectedRulesDocument, CountAffectedRulesDocument, FindAffectedRulesDocument, SearchAffectedRulesDocument, ExportSchemaAffectedRulesDocument, ExportDataAffectedRulesDocument, CustomQueryAffectedRulesDocument, CustomQueryIdAffectedRulesDocument, UsedAffectedRulesDocument, ExistAffectedRulesDocument, UpdateManyAffectedRulesDocument, DeleteAffectedRulesDocument, OrderAffectedRulesDocument } from '../gqls'
import { ServiceSingleResultOfAffectedRule, QueryContextOfAffectedRule, FilterOfAffectedRule, ServiceSingleResultOfInt64, ServiceListResultOfAffectedRule, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, FieldUpdateOperatorOfAffectedRule } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AffectedRuleBaseService {
    
public modelName = 'affectedRule';
public modelPluralName = 'affectedRules';

	private getAffectedRulesQuery: GetAffectedRulesDocument;
	private firstAffectedRulesQuery: FirstAffectedRulesDocument;
	private countAffectedRulesQuery: CountAffectedRulesDocument;
	private findAffectedRulesQuery: FindAffectedRulesDocument;
	private searchAffectedRulesQuery: SearchAffectedRulesDocument;
	private exportSchemaAffectedRulesQuery: ExportSchemaAffectedRulesDocument;
	private exportDataAffectedRulesQuery: ExportDataAffectedRulesDocument;
	private customQueryAffectedRulesQuery: CustomQueryAffectedRulesDocument;
	private customQueryIdAffectedRulesQuery: CustomQueryIdAffectedRulesDocument;
	private usedAffectedRulesQuery: UsedAffectedRulesDocument;
	private existAffectedRulesQuery: ExistAffectedRulesDocument;
	private updateManyAffectedRulesMutation: UpdateManyAffectedRulesDocument;
	private deleteAffectedRulesMutation: DeleteAffectedRulesDocument;
	private orderAffectedRulesMutation: OrderAffectedRulesDocument;

	constructor(private injector: Injector) {
		this.getAffectedRulesQuery = this.injector.get(GetAffectedRulesDocument);
		this.firstAffectedRulesQuery = this.injector.get(FirstAffectedRulesDocument);
		this.countAffectedRulesQuery = this.injector.get(CountAffectedRulesDocument);
		this.findAffectedRulesQuery = this.injector.get(FindAffectedRulesDocument);
		this.searchAffectedRulesQuery = this.injector.get(SearchAffectedRulesDocument);
		this.exportSchemaAffectedRulesQuery = this.injector.get(ExportSchemaAffectedRulesDocument);
		this.exportDataAffectedRulesQuery = this.injector.get(ExportDataAffectedRulesDocument);
		this.customQueryAffectedRulesQuery = this.injector.get(CustomQueryAffectedRulesDocument);
		this.customQueryIdAffectedRulesQuery = this.injector.get(CustomQueryIdAffectedRulesDocument);
		this.usedAffectedRulesQuery = this.injector.get(UsedAffectedRulesDocument);
		this.existAffectedRulesQuery = this.injector.get(ExistAffectedRulesDocument);
		this.updateManyAffectedRulesMutation = this.injector.get(UpdateManyAffectedRulesDocument);
		this.deleteAffectedRulesMutation = this.injector.get(DeleteAffectedRulesDocument);
		this.orderAffectedRulesMutation = this.injector.get(OrderAffectedRulesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAffectedRule> {
    
    		let variables: GetAffectedRulesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.affectedRules.get));
            }
            else{
                let result:ServiceSingleResultOfAffectedRule={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAffectedRule,
		@Args('filter?') filter?: FilterOfAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAffectedRule> {
    
    		let variables: FirstAffectedRulesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.affectedRules.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAffectedRulesBaseVariables = {
    			filter: filter
    		}
    				return this.countAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.affectedRules.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAffectedRule,
		@Args('filter?') filter?: FilterOfAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAffectedRule> {
    
    		let variables: FindAffectedRulesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.affectedRules.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAffectedRule,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAffectedRule> {
    
    		let variables: SearchAffectedRulesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.affectedRules.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAffectedRulesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.affectedRules.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAffectedRulesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.affectedRules.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAffectedRule,
		@Args('filter?') filter?: FilterOfAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAffectedRule> {
    
    		let variables: CustomQueryAffectedRulesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.affectedRules.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAffectedRule,
		@Args('filter?') filter?: FilterOfAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAffectedRule> {
    
    		let variables: CustomQueryIdAffectedRulesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.affectedRules.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAffectedRulesBaseVariables = {
    			ids: ids
    		}
    				return this.usedAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.affectedRules.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAffectedRulesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.affectedRules.exist));
    	}
    
    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAffectedRulesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.affectedRules.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAffectedRulesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.affectedRules.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public order(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: OrderAffectedRulesBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.orderAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.affectedRules.order));
    	}
    
}