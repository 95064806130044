import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSnmpSummariesBaseVariables, FirstSnmpSummariesBaseVariables, CountSnmpSummariesBaseVariables, FindSnmpSummariesBaseVariables, SearchSnmpSummariesBaseVariables, ExportSchemaSnmpSummariesBaseVariables, ExportDataSnmpSummariesBaseVariables, CustomQuerySnmpSummariesBaseVariables, CustomQueryIdSnmpSummariesBaseVariables, UsedSnmpSummariesBaseVariables, ExistSnmpSummariesBaseVariables } from '../gqls'
import { GetSnmpSummariesDocument, FirstSnmpSummariesDocument, CountSnmpSummariesDocument, FindSnmpSummariesDocument, SearchSnmpSummariesDocument, ExportSchemaSnmpSummariesDocument, ExportDataSnmpSummariesDocument, CustomQuerySnmpSummariesDocument, CustomQueryIdSnmpSummariesDocument, UsedSnmpSummariesDocument, ExistSnmpSummariesDocument } from '../gqls'
import { ServiceSingleResultOfSnmpSummary, QueryContextOfSnmpSummary, FilterOfSnmpSummary, ServiceSingleResultOfInt64, ServiceListResultOfSnmpSummary, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SnmpSummaryBaseService {
    
public modelName = 'snmpSummary';
public modelPluralName = 'snmpSummaries';

	private getSnmpSummariesQuery: GetSnmpSummariesDocument;
	private firstSnmpSummariesQuery: FirstSnmpSummariesDocument;
	private countSnmpSummariesQuery: CountSnmpSummariesDocument;
	private findSnmpSummariesQuery: FindSnmpSummariesDocument;
	private searchSnmpSummariesQuery: SearchSnmpSummariesDocument;
	private exportSchemaSnmpSummariesQuery: ExportSchemaSnmpSummariesDocument;
	private exportDataSnmpSummariesQuery: ExportDataSnmpSummariesDocument;
	private customQuerySnmpSummariesQuery: CustomQuerySnmpSummariesDocument;
	private customQueryIdSnmpSummariesQuery: CustomQueryIdSnmpSummariesDocument;
	private usedSnmpSummariesQuery: UsedSnmpSummariesDocument;
	private existSnmpSummariesQuery: ExistSnmpSummariesDocument;

	constructor(private injector: Injector) {
		this.getSnmpSummariesQuery = this.injector.get(GetSnmpSummariesDocument);
		this.firstSnmpSummariesQuery = this.injector.get(FirstSnmpSummariesDocument);
		this.countSnmpSummariesQuery = this.injector.get(CountSnmpSummariesDocument);
		this.findSnmpSummariesQuery = this.injector.get(FindSnmpSummariesDocument);
		this.searchSnmpSummariesQuery = this.injector.get(SearchSnmpSummariesDocument);
		this.exportSchemaSnmpSummariesQuery = this.injector.get(ExportSchemaSnmpSummariesDocument);
		this.exportDataSnmpSummariesQuery = this.injector.get(ExportDataSnmpSummariesDocument);
		this.customQuerySnmpSummariesQuery = this.injector.get(CustomQuerySnmpSummariesDocument);
		this.customQueryIdSnmpSummariesQuery = this.injector.get(CustomQueryIdSnmpSummariesDocument);
		this.usedSnmpSummariesQuery = this.injector.get(UsedSnmpSummariesDocument);
		this.existSnmpSummariesQuery = this.injector.get(ExistSnmpSummariesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpSummary> {
    
    		let variables: GetSnmpSummariesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSnmpSummariesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpSummaries.get));
            }
            else{
                let result:ServiceSingleResultOfSnmpSummary={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSnmpSummary,
		@Args('filter?') filter?: FilterOfSnmpSummary,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpSummary> {
    
    		let variables: FirstSnmpSummariesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSnmpSummariesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpSummaries.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSnmpSummary,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSnmpSummariesBaseVariables = {
    			filter: filter
    		}
    				return this.countSnmpSummariesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpSummaries.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSnmpSummary,
		@Args('filter?') filter?: FilterOfSnmpSummary,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpSummary> {
    
    		let variables: FindSnmpSummariesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSnmpSummariesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpSummaries.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSnmpSummary,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpSummary> {
    
    		let variables: SearchSnmpSummariesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSnmpSummariesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpSummaries.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSnmpSummariesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSnmpSummariesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpSummaries.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSnmpSummary,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSnmpSummariesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSnmpSummariesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpSummaries.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSnmpSummary,
		@Args('filter?') filter?: FilterOfSnmpSummary,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpSummary> {
    
    		let variables: CustomQuerySnmpSummariesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySnmpSummariesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpSummaries.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSnmpSummary,
		@Args('filter?') filter?: FilterOfSnmpSummary,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpSummary> {
    
    		let variables: CustomQueryIdSnmpSummariesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSnmpSummariesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpSummaries.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSnmpSummariesBaseVariables = {
    			ids: ids
    		}
    				return this.usedSnmpSummariesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpSummaries.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSnmpSummariesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSnmpSummariesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpSummaries.exist));
    	}
    
    
}