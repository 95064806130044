import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPrivilegesBaseVariables, FirstPrivilegesBaseVariables, CountPrivilegesBaseVariables, FindPrivilegesBaseVariables, SearchPrivilegesBaseVariables, ExportSchemaPrivilegesBaseVariables, ExportDataPrivilegesBaseVariables, CustomQueryPrivilegesBaseVariables, CustomQueryIdPrivilegesBaseVariables, UsedPrivilegesBaseVariables, DefaultTicketValuesPrivilegesBaseVariables, DefaultTaskValuesPrivilegesBaseVariables, DefaultAuthEditValuesPrivilegesBaseVariables, DefaultActionValuesPrivilegesBaseVariables, DefaultNotificationValuePrivilegesBaseVariables, FindNotOperatorsPrivilegesBaseVariables, ExistPrivilegesBaseVariables, FindRecyclesPrivilegesBaseVariables, CountRecyclesPrivilegesBaseVariables, ReadOnlyPrivilegesBaseVariables, FindArchivedPrivilegesBaseVariables, CountAllPrivilegesBaseVariables, FindDynamicPropertyFieldsPrivilegesBaseVariables, FindUnassociatedOperatorsPrivilegesBaseVariables, InsertPrivilegesBaseVariables, UpdatePrivilegesBaseVariables, ImportDataPrivilegesBaseVariables, UpdateManyPrivilegesBaseVariables, DeletePrivilegesBaseVariables, AddOperatorsCustomPrivilegesBaseVariables, RemoveOperatorsCustomPrivilegesBaseVariables, RestorePrivilegesBaseVariables, RecyclePrivilegesBaseVariables, RestoreArchivedPrivilegesBaseVariables, ArchivedPrivilegesBaseVariables, AddFileDynamicFieldPrivilegesBaseVariables, RemoveFileDynamicFieldPrivilegesBaseVariables, AddOperatorsPrivilegesBaseVariables, RemoveOperatorsPrivilegesBaseVariables } from '../gqls'
import { GetPrivilegesDocument, FirstPrivilegesDocument, CountPrivilegesDocument, FindPrivilegesDocument, SearchPrivilegesDocument, ExportSchemaPrivilegesDocument, ExportDataPrivilegesDocument, CustomQueryPrivilegesDocument, CustomQueryIdPrivilegesDocument, UsedPrivilegesDocument, DefaultTicketValuesPrivilegesDocument, DefaultTaskValuesPrivilegesDocument, DefaultAuthEditValuesPrivilegesDocument, DefaultActionValuesPrivilegesDocument, DefaultNotificationValuePrivilegesDocument, FindNotOperatorsPrivilegesDocument, ExistPrivilegesDocument, FindRecyclesPrivilegesDocument, CountRecyclesPrivilegesDocument, ReadOnlyPrivilegesDocument, FindArchivedPrivilegesDocument, CountAllPrivilegesDocument, FindDynamicPropertyFieldsPrivilegesDocument, FindUnassociatedOperatorsPrivilegesDocument, InsertPrivilegesDocument, UpdatePrivilegesDocument, ImportDataPrivilegesDocument, UpdateManyPrivilegesDocument, DeletePrivilegesDocument, AddOperatorsCustomPrivilegesDocument, RemoveOperatorsCustomPrivilegesDocument, RestorePrivilegesDocument, RecyclePrivilegesDocument, RestoreArchivedPrivilegesDocument, ArchivedPrivilegesDocument, AddFileDynamicFieldPrivilegesDocument, RemoveFileDynamicFieldPrivilegesDocument, AddOperatorsPrivilegesDocument, RemoveOperatorsPrivilegesDocument } from '../gqls'
import { ServiceSingleResultOfPrivilege, QueryContextOfPrivilege, FilterOfPrivilege, ServiceSingleResultOfInt64, ServiceListResultOfPrivilege, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfPrivilegeEntry, ServiceListResultOfUser, QueryContextOfUser, FilterOfUser, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfPrivilege, PrivilegeInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PrivilegeBaseService {
    
public modelName = 'privilege';
public modelPluralName = 'privileges';

	private getPrivilegesQuery: GetPrivilegesDocument;
	private firstPrivilegesQuery: FirstPrivilegesDocument;
	private countPrivilegesQuery: CountPrivilegesDocument;
	private findPrivilegesQuery: FindPrivilegesDocument;
	private searchPrivilegesQuery: SearchPrivilegesDocument;
	private exportSchemaPrivilegesQuery: ExportSchemaPrivilegesDocument;
	private exportDataPrivilegesQuery: ExportDataPrivilegesDocument;
	private customQueryPrivilegesQuery: CustomQueryPrivilegesDocument;
	private customQueryIdPrivilegesQuery: CustomQueryIdPrivilegesDocument;
	private usedPrivilegesQuery: UsedPrivilegesDocument;
	private defaultTicketValuesPrivilegesQuery: DefaultTicketValuesPrivilegesDocument;
	private defaultTaskValuesPrivilegesQuery: DefaultTaskValuesPrivilegesDocument;
	private defaultAuthEditValuesPrivilegesQuery: DefaultAuthEditValuesPrivilegesDocument;
	private defaultActionValuesPrivilegesQuery: DefaultActionValuesPrivilegesDocument;
	private defaultNotificationValuePrivilegesQuery: DefaultNotificationValuePrivilegesDocument;
	private findNotOperatorsPrivilegesQuery: FindNotOperatorsPrivilegesDocument;
	private existPrivilegesQuery: ExistPrivilegesDocument;
	private findRecyclesPrivilegesQuery: FindRecyclesPrivilegesDocument;
	private countRecyclesPrivilegesQuery: CountRecyclesPrivilegesDocument;
	private readOnlyPrivilegesQuery: ReadOnlyPrivilegesDocument;
	private findArchivedPrivilegesQuery: FindArchivedPrivilegesDocument;
	private countAllPrivilegesQuery: CountAllPrivilegesDocument;
	private findDynamicPropertyFieldsPrivilegesQuery: FindDynamicPropertyFieldsPrivilegesDocument;
	private findUnassociatedOperatorsPrivilegesQuery: FindUnassociatedOperatorsPrivilegesDocument;
	private insertPrivilegesMutation: InsertPrivilegesDocument;
	private updatePrivilegesMutation: UpdatePrivilegesDocument;
	private importDataPrivilegesMutation: ImportDataPrivilegesDocument;
	private updateManyPrivilegesMutation: UpdateManyPrivilegesDocument;
	private deletePrivilegesMutation: DeletePrivilegesDocument;
	private addOperatorsCustomPrivilegesMutation: AddOperatorsCustomPrivilegesDocument;
	private removeOperatorsCustomPrivilegesMutation: RemoveOperatorsCustomPrivilegesDocument;
	private restorePrivilegesMutation: RestorePrivilegesDocument;
	private recyclePrivilegesMutation: RecyclePrivilegesDocument;
	private restoreArchivedPrivilegesMutation: RestoreArchivedPrivilegesDocument;
	private archivedPrivilegesMutation: ArchivedPrivilegesDocument;
	private addFileDynamicFieldPrivilegesMutation: AddFileDynamicFieldPrivilegesDocument;
	private removeFileDynamicFieldPrivilegesMutation: RemoveFileDynamicFieldPrivilegesDocument;
	private addOperatorsPrivilegesMutation: AddOperatorsPrivilegesDocument;
	private removeOperatorsPrivilegesMutation: RemoveOperatorsPrivilegesDocument;

	constructor(private injector: Injector) {
		this.getPrivilegesQuery = this.injector.get(GetPrivilegesDocument);
		this.firstPrivilegesQuery = this.injector.get(FirstPrivilegesDocument);
		this.countPrivilegesQuery = this.injector.get(CountPrivilegesDocument);
		this.findPrivilegesQuery = this.injector.get(FindPrivilegesDocument);
		this.searchPrivilegesQuery = this.injector.get(SearchPrivilegesDocument);
		this.exportSchemaPrivilegesQuery = this.injector.get(ExportSchemaPrivilegesDocument);
		this.exportDataPrivilegesQuery = this.injector.get(ExportDataPrivilegesDocument);
		this.customQueryPrivilegesQuery = this.injector.get(CustomQueryPrivilegesDocument);
		this.customQueryIdPrivilegesQuery = this.injector.get(CustomQueryIdPrivilegesDocument);
		this.usedPrivilegesQuery = this.injector.get(UsedPrivilegesDocument);
		this.defaultTicketValuesPrivilegesQuery = this.injector.get(DefaultTicketValuesPrivilegesDocument);
		this.defaultTaskValuesPrivilegesQuery = this.injector.get(DefaultTaskValuesPrivilegesDocument);
		this.defaultAuthEditValuesPrivilegesQuery = this.injector.get(DefaultAuthEditValuesPrivilegesDocument);
		this.defaultActionValuesPrivilegesQuery = this.injector.get(DefaultActionValuesPrivilegesDocument);
		this.defaultNotificationValuePrivilegesQuery = this.injector.get(DefaultNotificationValuePrivilegesDocument);
		this.findNotOperatorsPrivilegesQuery = this.injector.get(FindNotOperatorsPrivilegesDocument);
		this.existPrivilegesQuery = this.injector.get(ExistPrivilegesDocument);
		this.findRecyclesPrivilegesQuery = this.injector.get(FindRecyclesPrivilegesDocument);
		this.countRecyclesPrivilegesQuery = this.injector.get(CountRecyclesPrivilegesDocument);
		this.readOnlyPrivilegesQuery = this.injector.get(ReadOnlyPrivilegesDocument);
		this.findArchivedPrivilegesQuery = this.injector.get(FindArchivedPrivilegesDocument);
		this.countAllPrivilegesQuery = this.injector.get(CountAllPrivilegesDocument);
		this.findDynamicPropertyFieldsPrivilegesQuery = this.injector.get(FindDynamicPropertyFieldsPrivilegesDocument);
		this.findUnassociatedOperatorsPrivilegesQuery = this.injector.get(FindUnassociatedOperatorsPrivilegesDocument);
		this.insertPrivilegesMutation = this.injector.get(InsertPrivilegesDocument);
		this.updatePrivilegesMutation = this.injector.get(UpdatePrivilegesDocument);
		this.importDataPrivilegesMutation = this.injector.get(ImportDataPrivilegesDocument);
		this.updateManyPrivilegesMutation = this.injector.get(UpdateManyPrivilegesDocument);
		this.deletePrivilegesMutation = this.injector.get(DeletePrivilegesDocument);
		this.addOperatorsCustomPrivilegesMutation = this.injector.get(AddOperatorsCustomPrivilegesDocument);
		this.removeOperatorsCustomPrivilegesMutation = this.injector.get(RemoveOperatorsCustomPrivilegesDocument);
		this.restorePrivilegesMutation = this.injector.get(RestorePrivilegesDocument);
		this.recyclePrivilegesMutation = this.injector.get(RecyclePrivilegesDocument);
		this.restoreArchivedPrivilegesMutation = this.injector.get(RestoreArchivedPrivilegesDocument);
		this.archivedPrivilegesMutation = this.injector.get(ArchivedPrivilegesDocument);
		this.addFileDynamicFieldPrivilegesMutation = this.injector.get(AddFileDynamicFieldPrivilegesDocument);
		this.removeFileDynamicFieldPrivilegesMutation = this.injector.get(RemoveFileDynamicFieldPrivilegesDocument);
		this.addOperatorsPrivilegesMutation = this.injector.get(AddOperatorsPrivilegesDocument);
		this.removeOperatorsPrivilegesMutation = this.injector.get(RemoveOperatorsPrivilegesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPrivilege> {
    
    		let variables: GetPrivilegesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.get));
            }
            else{
                let result:ServiceSingleResultOfPrivilege={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPrivilege,
		@Args('filter?') filter?: FilterOfPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPrivilege> {
    
    		let variables: FirstPrivilegesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPrivilegesBaseVariables = {
    			filter: filter
    		}
    				return this.countPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPrivilege,
		@Args('filter?') filter?: FilterOfPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrivilege> {
    
    		let variables: FindPrivilegesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPrivilege,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrivilege> {
    
    		let variables: SearchPrivilegesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPrivilegesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPrivilegesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPrivilege,
		@Args('filter?') filter?: FilterOfPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrivilege> {
    
    		let variables: CustomQueryPrivilegesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPrivilege,
		@Args('filter?') filter?: FilterOfPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrivilege> {
    
    		let variables: CustomQueryIdPrivilegesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPrivilegesBaseVariables = {
    			ids: ids
    		}
    				return this.usedPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.used));
    	}

    	/**  */
    	@InjectArgs
    	public defaultTicketValues(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrivilegeEntry> {
    
    		let variables: DefaultTicketValuesPrivilegesBaseVariables = {
    
    		}
    				return this.defaultTicketValuesPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.defaultTicketValues));
    	}

    	/**  */
    	@InjectArgs
    	public defaultTaskValues(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrivilegeEntry> {
    
    		let variables: DefaultTaskValuesPrivilegesBaseVariables = {
    
    		}
    				return this.defaultTaskValuesPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.defaultTaskValues));
    	}

    	/**  */
    	@InjectArgs
    	public defaultAuthEditValues(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrivilegeEntry> {
    
    		let variables: DefaultAuthEditValuesPrivilegesBaseVariables = {
    
    		}
    				return this.defaultAuthEditValuesPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.defaultAuthEditValues));
    	}

    	/**  */
    	@InjectArgs
    	public defaultActionValues(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrivilegeEntry> {
    
    		let variables: DefaultActionValuesPrivilegesBaseVariables = {
    
    		}
    				return this.defaultActionValuesPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.defaultActionValues));
    	}

    	/**  */
    	@InjectArgs
    	public defaultNotificationValue(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrivilegeEntry> {
    
    		let variables: DefaultNotificationValuePrivilegesBaseVariables = {
    
    		}
    				return this.defaultNotificationValuePrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.defaultNotificationValue));
    	}

    	/**  */
    	@InjectArgs
    	public findNotOperators(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindNotOperatorsPrivilegesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findNotOperatorsPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.findNotOperators));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPrivilegesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPrivilege,
		@Args('filter?') filter?: FilterOfPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrivilege> {
    
    		let variables: FindRecyclesPrivilegesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesPrivilegesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyPrivilegesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPrivilege,
		@Args('filter?') filter?: FilterOfPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrivilege> {
    
    		let variables: FindArchivedPrivilegesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllPrivilegesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsPrivilegesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedOperators(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfUser> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('operators', fields, null, [
			GqlSubFieldArg.create('filterOfOperators', 'filter'),
			GqlSubFieldArg.create('optionsOfOperators', 'options'),
		]),
		])
		extendedVariables['filterOfOperators'] = filter;
		extendedVariables['optionsOfOperators'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.operators));
            }
            else{
                let result: ServiceListResultOfUser = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedOperators(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUnassociatedOperatorsPrivilegesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedOperatorsPrivilegesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.findUnassociatedOperators));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: PrivilegeInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPrivilege> {
    
    		let variables: InsertPrivilegesBaseVariables = {
    			entity: entity
    		}
    				return this.insertPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPrivilege> {
    
    		let variables: UpdatePrivilegesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updatePrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataPrivilegesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfPrivilege,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyPrivilegesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeletePrivilegesBaseVariables = {
    			ids: ids
    		}
    				return this.deletePrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorsCustom(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorsCustomPrivilegesBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.addOperatorsCustomPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.addOperatorsCustom));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorsCustom(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorsCustomPrivilegesBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.removeOperatorsCustomPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.removeOperatorsCustom));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestorePrivilegesBaseVariables = {
    			ids: ids
    		}
    				return this.restorePrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecyclePrivilegesBaseVariables = {
    			ids: ids
    		}
    				return this.recyclePrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedPrivilegesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedPrivilegesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldPrivilegesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldPrivilegesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addOperators(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorsPrivilegesBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.addOperatorsPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.addOperators));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperators(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorsPrivilegesBaseVariables = {
    			userIds: userIds
    		}
    				return this.removeOperatorsPrivilegesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.privileges.removeOperators));
    	}
    
}