<div class="cl-visible-selectable-entity">
  <dx-box class="cl-box-ratio" direction="row" height="100%" width="100%">
    <dxi-item class="cl-ratio" [ratio]="1">
      <dx-tree-list
        (onToolbarPreparing)="onToolbarPreparing($event, true)"
        (onSelectionChanged)="onSelectionShowChanged($event)"
        (onInitialized)="onInitializedShow($event)"
        [selectedRowKeys]="selectedKeysShow"
        [dataSource]="sourceShow"
        [showColumnHeaders]="false"
        [columnAutoWidth]="true"
        [keyExpr]="'id'"
        [parentIdExpr]="'parentId'"
        dataStructure="plain"
        [rootValue]="null"
        [height]="'100%'"
        [width]="'100%'"
        [hoverStateEnabled]="true"
        [remoteOperations]="{
          filtering: false,
          grouping: false,
          paging: false,
          sorting: false,
          summary: false
        }"
        [showRowLines]="false"
        [showBorders]="false"
        [searchPanel]="{
          visible: false,
          width: 200,
          placeholder: 'globals/search' | translate
        }"
        [columnChooser]="{ enabled: false }"
        [paging]="{
          enabled: true,
          pageSize: 20
        }"
        [pager]="{
          showPageSizeSelector: false,
          allowedPageSizes: [500000],
          showInfo: false,
          visible: false
        }"
        [filterRow]="{
          visible: true
        }"
        [filterPanel]="{
          visible: false
        }"
        [sorting]="{
          mode: 'multiple'
        }"
      >
        <dxi-column
          *ngFor="let column of options.columns"
          [dataField]="column.field"
          [caption]="column.label"
          [format]="column.format"
          [type]="column.type"
          [width]="column.width"
          [dataType]="column.dataType"
          [visible]="column.visible != undefined ? column.visible : true"
          [showInColumnChooser]="
            column.showInColumnChooser != undefined
              ? column.showInColumnChooser
              : true
          "
          [sortIndex]="column.sortIndex"
          [sortOrder]="column.sortOrder"
          [allowEditing]="
            column.allowEditing != undefined ? column.allowEditing : false
          "
          [allowSorting]="
            column.allowSorting != undefined ? column.allowSorting : true
          "
          [allowFiltering]="
            column.allowFiltering != undefined ? column.allowFiltering : true
          "
          [cellTemplate]="
            column.template == undefined
              ? (column.field == 'name' || column.link === true) &&
                options?.route != undefined
                ? 'underlineTemplate'
                : column.template
              : column.template
          "
        ></dxi-column>
        <dxo-selection mode="multiple" [recursive]="false"></dxo-selection>
        <div
          *dxTemplate="let data of 'underlineTemplate'"
          style="display: inline-block"
        >
          <a
            href=""
            (auxclick)="underline($event, data); (false)"
            (click)="underline($event, data); (false)"
            >{{ data.value }}</a
          >
        </div>
        <ng-container
          *ngFor="
            let column of options.columns
              | filter : 'template' : undefined : true
          "
        >
          <div *dxTemplate="let data of column.template">
            <ng-template
              *ngTemplateOutlet="
                templateService.get(column.template);
                context: {
                  $implicit: data,
                  column: column
                }
              "
            >
            </ng-template>
          </div>
        </ng-container>
      </dx-tree-list>
    </dxi-item>
    <dxi-item class="cl-ratio" [ratio]="1">
      <dx-tree-list
        (onToolbarPreparing)="onToolbarPreparing($event, false)"
        (onSelectionChanged)="onSelectionAllowChanged($event)"
        (onInitialized)="onInitializedAllow($event)"
        [selectedRowKeys]="selectedKeysAllow"
        [dataSource]="sourceAllow"
        [showColumnHeaders]="false"
        [columnAutoWidth]="true"
        [keyExpr]="'id'"
        [autoExpandAll]="true"
        [parentIdExpr]="'parentId'"
        dataStructure="plain"
        [rootValue]="null"
        [height]="'100%'"
        [width]="'100%'"
        [hoverStateEnabled]="true"
        [remoteOperations]="{
          filtering: false,
          grouping: false,
          paging: false,
          sorting: false,
          summary: false
        }"
        [showRowLines]="false"
        [showBorders]="false"
        [searchPanel]="{
          visible: false,
          width: 200,
          placeholder: 'globals/search' | translate
        }"
        [columnChooser]="{ enabled: false }"
        [paging]="{
          enabled: true,
          pageSize: 20
        }"
        [pager]="{
          showPageSizeSelector: false,
          allowedPageSizes: [500000],
          showInfo: false,
          visible: false
        }"
        [filterRow]="{
          visible: true
        }"
        [filterPanel]="{
          visible: false
        }"
        [sorting]="{
          mode: 'multiple'
        }"
      >
        <dxi-column
          *ngFor="let column of options.columns"
          [dataField]="column.field"
          [caption]="column.label"
          [format]="column.format"
          [type]="column.type"
          [width]="column.width"
          [dataType]="column.dataType"
          [visible]="column.visible != undefined ? column.visible : true"
          [showInColumnChooser]="
            column.showInColumnChooser != undefined
              ? column.showInColumnChooser
              : true
          "
          [sortIndex]="column.sortIndex"
          [sortOrder]="column.sortOrder"
          [allowEditing]="
            column.allowEditing != undefined ? column.allowEditing : false
          "
          [allowSorting]="
            column.allowSorting != undefined ? column.allowSorting : true
          "
          [allowFiltering]="
            column.allowFiltering != undefined ? column.allowFiltering : true
          "
          [cellTemplate]="
            column.template == undefined
              ? (column.field == 'name' || column.link === true) &&
                options?.route != undefined
                ? 'underlineTemplate'
                : column.template
              : column.template
          "
        ></dxi-column>
        <dxo-selection mode="multiple" [recursive]="false"></dxo-selection>
        <div
          *dxTemplate="let data of 'underlineTemplate'"
          style="display: inline-block"
        >
          <a
            href=""
            (auxclick)="underline($event, data); (false)"
            (click)="underline($event, data); (false)"
            >{{ data.value }}</a
          >
        </div>
        <ng-container
          *ngFor="
            let column of options.columns
              | filter : 'template' : undefined : true
          "
        >
          <div *dxTemplate="let data of column.template">
            <ng-template
              *ngTemplateOutlet="
                templateService.get(column.template);
                context: {
                  $implicit: data,
                  column: column
                }
              "
            >
            </ng-template>
          </div>
        </ng-container>
      </dx-tree-list>
    </dxi-item>
  </dx-box>
</div>
