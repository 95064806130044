import { AssetAcquisitionStateBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql';

@Injectable({ providedIn: 'root' })
@Authorize('asset.manage-acquisition-state')
export class AssetAcquisitionStateCoreService extends AssetAcquisitionStateBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
