import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  FieldUpdateOperator,
  ServiceSingleResult,
  ValidationErrorCategory,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { ModelCompilerContextService } from '@clarilog/shared2/services/compiler/model-compiler-context.service';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import * as dot from 'dot-object';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FormGroupHelpers } from './form-group-helpers';
import { AsyncFormControl } from './form-link-array';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';

/** Représente le service permettant de sauvegarder le @type {FormGroup}. */
@Injectable({
  providedIn: 'root',
})
export class CoreFormRecorder {
  constructor(
    private modelContext: ModelCompilerContextService,
    private localStorageService: LocalStorageService,
  ) {}
  /** Enregistre les données saisies par l'utilisateur. */
  public save(
    id: string,
    form: UntypedFormGroup,
    service: {
      update: (
        fields: Array<GqlField | GqlSubField>,
        id: string,
        entry: any,
      ) => Observable<ServiceSingleResult<any>>;
      insert: (
        fields: Array<GqlField | GqlSubField>,
        entity: any,
      ) => Observable<ServiceSingleResult<any>>;
    },
    modelState: ModelState,
  ): Observable<ServiceSingleResult<any>> {
    let observable: Observable<ServiceSingleResult<any>> = Observable.create(
      (observer) => {
        if (FormGroupHelpers.valid(form) === false) {
          form.markAllAsTouched();
          observer.next(<ServiceSingleResult<any>>{
            data: { id: id },
            errors: [
              {
                validationCategory: ValidationErrorCategory.Error,
                messageError: 'Erreur sur le formulaire',
              },
            ],
          });
          observer.complete();
        } else {
          let obj = {};

          //Ajoute les paramètres de entry dans l'objet.
          // NE prend pas en compte les entry (valeur par defaut) si mode edition
          if (modelState.sharedContext.entry.count() > 0 && id == undefined) {
            const entry = modelState.sharedContext.entry.all();

            this.cleanDataFromTypeName(entry);

            for (let member in entry) {
              let memberValue = modelState.sharedContext.entry._params[member];
              if (memberValue != undefined) {
                // split
                let split = member.split('_');
                var container = obj;

                if (split.length > 1) {
                  split.map((k, i, values) => {
                    container = container[k] =
                      i == values.length - 1 ? memberValue : {};
                  });
                } else {
                  obj[member] = memberValue;
                }
              }
            }
          }

          let materialize = this.materialize(form, id ? false : true);
          obj = Object.assign(obj, materialize);
          //delete obj['__typename'];
          this.cleanDataFromTypeName(obj);

          // Rustine fichier attaché on composant direct (non async)
          var filesIds = obj['fileIds'];
          if (filesIds != undefined) {
            delete obj['fileIds'];
            if (filesIds['itemsAdded'] != undefined) {
              obj['fileIds'] = filesIds['itemsAdded'];
            }
          }

          modelState.sharedContext.params.set('isNew', () => false);

          if (id != null) {
            // TODO Ne pas mettre ici : update
            if (Object.keys(obj).length === 0) {
              this.updateAsyncControls(form).subscribe(
                (result) => {
                  modelState.canBeSave = false;
                  if (result.errors != undefined && result.errors.length > 0) {
                    observer.next({
                      isValid: false,
                      ...result,
                    });
                  } else {
                    observer.next({
                      isValid: true,
                      ...result,
                    });
                  }
                  observer.complete();
                },
                (err) => {
                  observer.error(err);
                  observer.complete();
                },
              );
            } else {
              let updateEntry: FieldUpdateOperator = { set: { ...obj } };
              let fnContext = this.modelContext.callService(
                service,
                'update',
                modelState,
              );
              fnContext.context.params.set('entry', () => updateEntry);
              fnContext.context.params.set(
                'fields',
                () => modelState.formFields,
              );
              fnContext.context.params.set('id', () => id);
              fnContext.fnCall().subscribe(
                (result) => {
                  this.reloadNavMenuBadge(false, modelState, updateEntry);

                  modelState.canBeSave = false;
                  if (
                    result?.errors == undefined ||
                    result.errors.length == 0
                  ) {
                    this.updateAsyncControls(form).subscribe((result) => {
                      if (
                        result.errors != undefined &&
                        result.errors.length > 0
                      ) {
                        observer.next({
                          isValid: false,
                          ...result,
                        });
                      } else {
                        observer.next({
                          isValid: true,
                          ...result,
                        });
                      }
                      observer.complete();
                    });
                  } else {
                    observer.next(result);
                    observer.complete();
                  }
                },
                (err) => {
                  observer.error(err);
                  observer.complete();
                },
              );
            }
          } else {
            let fnContext = this.modelContext.callService(
              service,
              'insert',
              modelState,
            );
            fnContext.context.params.set('entity', () => obj);
            fnContext.context.params.set('fields', () => modelState.formFields);
            fnContext.fnCall().subscribe(
              (result) => {
                this.reloadNavMenuBadge(true, modelState);
                modelState.canBeSave = false;

                // Mise à jour de l'id du context.
                if (result != undefined && result.data != undefined) {
                  modelState.sharedContext.params.set(
                    'id',
                    () => result.data.id,
                  );

                  modelState.sharedContext.params.set('isNew', () => true);

                  this.updateAsyncControls(form).subscribe(
                    (resultUpdate) => {
                      if (
                        resultUpdate.errors != undefined &&
                        resultUpdate.errors.length > 0
                      ) {
                        resultUpdate.data = result.data;
                        observer.next({
                          isValid: false,
                          ...resultUpdate,
                        });
                      } else {
                        observer.next({
                          isValid: true,
                          ...result,
                        });
                      }
                      observer.complete();
                    },
                    (err) => {
                      observer.error(err);
                      observer.complete();
                    },
                  );
                } else {
                  observer.next(result);
                  observer.complete();
                }
              },
              (err) => {
                observer.error(err);
                observer.complete();
              },
            );
          }
        }
      },
    );
    return observable;
  }

  /** Demande de reload des compteurs du menu */
  private reloadNavMenuBadge(
    isNew: boolean,
    modelState: ModelState,
    fieldUpdateOperator: FieldUpdateOperator = undefined,
  ) {
    if (this.localStorageService?.ModelState != undefined) {
      let canReload = true;
      if (isNew !== true) {
        canReload = false;
        if (
          modelState?.formComponent?.service != undefined &&
          modelState?.formComponent?.service['canReloadNavMenu'] != undefined
        ) {
          canReload =
            modelState?.formComponent?.service['canReloadNavMenu'](
              fieldUpdateOperator,
            );
        }
      }

      if (canReload === true) {
        this.localStorageService.ModelState.on.emit({
          eventName: LocalStorageService.EventConst.ReloadNavMenuConst,
          eventData: undefined,
        });
      }
    }
  }

  private cleanDataFromTypeName(data: any): any {
    if (data == undefined) {
      return data;
    }

    if (data['__typename'] != undefined) {
      delete data['__typename'];
    }

    if (Array.isArray(data)) {
      (data as Array<any>).forEach((el) => {
        if (typeof el == 'object') {
          el = this.cleanDataFromTypeName(el);
        }
      });
    } else {
      let keys = Object.keys(data);

      keys.forEach((key) => {
        if (typeof data[key] == 'object') {
          data[key] = this.cleanDataFromTypeName(data[key]);
        }
      });
    }
    return data;
  }

  /** Permet de materialiser l'objet FormGroup en objet. */
  public materialize(
    form: AbstractControl,
    inserting: boolean = false,
    checkDefaultValue: boolean = true,
  ): any {
    let obj = {};
    let dictionary = FormGroupHelpers.formControls(form);
    for (let entry of dictionary) {
      let control = entry.value;

      if (!(control instanceof AsyncFormControl)) {
        if (
          (((control.dirty === true || control.isReset === true) &&
            control.disabled === false &&
            (control['readOnly'] === false ||
              control['readOnly'] == undefined) &&
            (!checkDefaultValue || control.originalValue != control.value)) ||
            control['forceSendValue'] === true) &&
          control['managed'] == undefined
        ) {
          // Controle cas forceSendValue
          // Mise en comment pr bug 7417
          // if (
          //   control['forceSendValue'] === true &&
          //   control.originalValue == control.value
          // ) {
          //    continue;
          // }
          if (entry.key.indexOf('_') > -1 && entry.key != '__typename') {
            let propertyStr = entry.key.replace(/\_/g, '.');
            dot.str(
              propertyStr,
              control.value === undefined ? null : control.value,
              obj,
            );
          } else {
            if (control.value === 'undefined') {
              obj[entry.key] = undefined;
            } else {
              obj[entry.key] =
                control.value === undefined ? null : control.value;
            }
          }
        }
      }
    }
    return obj;
  }

  /** Met à jour les liens de type ManyToOne ou ManyToMany. */
  private updateAsyncControls(
    form: AbstractControl,
  ): Observable<ServiceSingleResult<any>> {
    let observables$: Observable<ServiceSingleResult<any>>[] = [];
    let dictionary = FormGroupHelpers.formControls(form);
    for (let entry of dictionary) {
      let control = entry.value as AsyncFormControl;
      if (control instanceof AsyncFormControl) {
        let value = control.value;
        let functionAsync = control.functionAsync(entry.key, value);
        if (functionAsync != undefined) {
          observables$.push(functionAsync);
        }
      }
    }
    if (observables$.length > 0) {
      return forkJoin(observables$).pipe(
        switchMap((r) => {
          return r;
        }),
      );
    } else {
      return of(<ServiceSingleResult<any>>{
        errors: [],
      });
    }
  }
}
