import { TicketStatusBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import {
  FilterOfTicketStatus,
  ServiceListResultOfTicketStatus,
  QueryContextOfTicketStatus,
  Sort,
} from '../types';
import { GqlField, GqlSubField } from '../helpers';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-ticket-status')
export class TicketStatusCoreService extends TicketStatusBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  public getService() {
    return this;
  }

  @InjectArgs
  public findByQualification(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('qualification') qualification: string[],   
    @Args('options?') options?: QueryContextOfTicketStatus,
    @Args('filter') filter?: FilterOfTicketStatus,
  ): Observable<ServiceListResultOfTicketStatus> {
    let filterByQualification: FilterOfTicketStatus = {
      qualification: { elemMatch: { in: qualification } },
    };
    if (filter != undefined) {
      filterByQualification.and = [filter];
    }
    if (options == undefined) options = {};
    options.sort = [{ name: [{ fr: Sort.Asc }] }];
    return this.find(fields, options, filterByQualification);
  }
}
