
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { StockTransferBaseService } from '../service-bases'
import { Injectable, Injector } from '@angular/core';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator';
import { Observable } from 'rxjs/internal/Observable';
import { FilterOfOutCart, QueryContextOfOutCart, ServiceListResultOfOutCart } from '../types';
import { GqlField, GqlSubField } from '../helpers';
import { FindStorageConsumableItemsStockTransfersBaseVariables } from '../gqls/stock-transfer.gqls';

@Injectable({ providedIn: 'root' })
@Authorize('stock-transfer')
export class StockTransferCoreService extends StockTransferBaseService {
    constructor(injector: Injector) {
        super(injector)
    }

    @InjectArgs
    public findCustomStorageConsumableItems(
        @Args('fields') fields: Array<GqlField | GqlSubField>,
        @Args('fromStorageUnitId?') fromStorageUnitId?: string,
        @Args('toStorageUnitId?') toStorageUnitId?: string,
        @Args('options?') options?: QueryContextOfOutCart,
        @Args('id?') id?: string,
        @Args('filter?') filter?: FilterOfOutCart,
        @Args('extendedVariables?') extendedVariables?: any
    ): Observable<ServiceListResultOfOutCart> {
        return this.findStorageConsumableItems(fields,toStorageUnitId, options,  id, fromStorageUnitId, filter, extendedVariables);
    }
}