import { Injectable } from '@angular/core';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import ArrayStore from 'devextreme/data/array_store';

@Injectable({
  providedIn: 'root',
})
export class CoreCustomOperationService {
  customBetweenOperation: any;
  customDateOperation: any;
  customDeadlineDateOperation: any;
  customDefaultDateOperation: any;
  customFromTo: any;
  customSelectValues: any;
  customNotSelectValues: any;
  customValueChanged: any;
  customArrayEq: any;
  customArrayNotEq: any;
  customArrayMoreThan: any;
  customArrayLessThan: any;
  customArrayContains: any;
  customBetweenSizeGo: any;

  // Obtient toutes les custom operations
  allCustomOperation() {
    return [
      this.customBetweenOperation,
      this.customDateOperation,
      this.customDeadlineDateOperation,
      this.customDefaultDateOperation,
      this.customSelectValues,
      this.customNotSelectValues,
      this.customArrayEq,
      this.customArrayNotEq,
      this.customArrayMoreThan,
      this.customArrayLessThan,
      this.customArrayContains,
    ];
  }
  // Obtient toutes les custom operations de changement de valeur
  allCustomOperationWithChangeValues() {
    return [this.customValueChanged, this.customFromTo];
  }

  getDefaultOperations(type: string) {
    switch (type) {
      case 'ID':
      case 'Uuid':
      case 'Guid':
        return ['=', '<>', 'isblank', 'isnotblank'];
      case 'string':
      case 'String':
        return [
          'contains',
          'notcontains',
          'startswith',
          'endswith',
          '=',
          '<>',
          'isblank',
          'isnotblank',
        ];
      case 'date':
      case 'datetime':
      case 'Date':
      case 'DateTime':
        return [
          '=',
          '<>',
          '<',
          '>',
          '<=',
          '>=',
          'between',
          'isblank',
          'isnotblank',
        ];
      case 'boolean':
      case 'boolean':
        return ['=', '<>', 'isblank', 'isnotblank'];
      case 'numeric':
      case 'number':
        return [
          '=',
          '<>',
          '<',
          '>',
          '<=',
          '>=',
          'between',
          'isblank',
          'isnotblank',
        ];
      case 'object':
        return ['isblank', 'isnotblank'];
      case 'Guid[]':
        return ['isblank', 'isnotblank'];
    }

    return [];
  }

  constructor() {
    let getDisplayExpr = (value, fieldName, isOperator: boolean = false) => {
      if (isOperator && value?.__typename === 'User') {
        if (value?.serviceDeskActivated && fieldName === 'name') {
          return `${TranslateService.get('globals/userSupplier')} ${
            value['name']
          }`;
        }

        return `${value[fieldName]}`;
      }

      if (fieldName.indexOf('.') > 0) {
        let split = fieldName.split('.');

        let v = value;
        split.forEach((f) => {
          v = v[f];
        });

        return v;
      }
      return value[fieldName];
    };

    /** Check whether the field is an operator field or not */
    let checkField = (field) => {
      if (field?.dataField !== undefined && field?.dataField !== null) {
        return (
          field?.dataField == 'operator' ||
          field?.dataField.includes('operatorAffected') ||
          field?.dataField.includes('operatorReferent')
        );
      }

      return false;
    };

    //operation custom customBetween
    this.customBetweenOperation = {
      name: 'customBetween',
      caption: 'Entre (custom)',
      icon: 'range',
      dataTypes: ['datetime', 'date'],
      customizeText(field) {
        if (field.value[2] == null) return '';
        else
          return (
            field.value[0] +
            ' - ' +
            field.value[1] +
            ' ' +
            TranslateService.get(`enums/timeType/` + field.value[2])
          );
      },
      editorTemplate: 'betweenNumberBoxTemplate',
    };

    //operation custom customBetween
    this.customFromTo = {
      name: 'customFromTo',
      caption: TranslateService.get('clFilbuilder-CustomFromTo'),
      icon: 'range',
      dataTypes: ['ID'],
      async customizeText(field) {
        if (
          Array.isArray(field.value) === false ||
          field.value[1] == undefined ||
          field.value[0] == undefined
        )
          return '';
        else if (field.field.lookup.dataSource?.find != undefined) {
          let fromValue = field.field.lookup.dataSource.find(
            (x) => x[field.field.lookup.valueExpr] == field.value[0],
          );
          let toValue = field.field.lookup.dataSource.find(
            (x) => x[field.field.lookup.valueExpr] == field.value[1],
          );

          return (
            TranslateService.get('clFilbuilder-CustomFrom') +
            (checkField(field.field)
              ? getDisplayExpr(fromValue, field.field.lookup.displayExpr, true)
              : fromValue[field.field.lookup.displayExpr]) +
            +' ' +
            TranslateService.get('clFilbuilder-CustomTo') +
            ' ' +
            (checkField(field.field)
              ? getDisplayExpr(toValue, field.field.lookup.displayExpr, true)
              : toValue[field.field.lookup.displayExpr])
          );
        } else if (field.field.lookup.dataSource?.byKey != undefined) {
          let from = await field.field.lookup.dataSource
            ?.byKey(field.value[0])
            .promise();
          let to = await field.field.lookup.dataSource
            ?.byKey(field.value[1])
            .promise();

          return `${TranslateService.get(
            'clFilbuilder-CustomFrom',
          )} ${getDisplayExpr(
            from,
            field.field.lookup.displayExpr,
            checkField(field.field),
          )} ${TranslateService.get('clFilbuilder-CustomTo')} ${getDisplayExpr(
            to,
            field.field.lookup.displayExpr,
            checkField(field.field),
          )}`;
        }
      },
      editorTemplate: 'fromToTemplate',
    };

    //operation custom customBetween
    this.customValueChanged = {
      name: 'customValueChanged',
      caption: TranslateService.get('clFilbuilder-customValueChanged'),
      icon: 'edit',
      dataTypes: [
        'string',
        'number',
        'date',
        'boolean',
        'object',
        'datetime',
        'ID',
      ],
      hasValue: false,
    };

    //operation custom customBetween
    this.customSelectValues = {
      name: 'customSelectValues',
      caption: TranslateService.get('clFilbuilder-customSelectValues'),
      icon: 'selectall',
      dataTypes: ['ID'],
      async customizeText(field) {
        if (Array.isArray(field.value) === false || field.value.length === 0) {
          return 'Pas de valeur';
        } else {
          // Create filter
          let filter = {};
          filter['or'] = [];
          field.value.forEach((f) => {
            filter['or'].push({
              id: { eq: f },
            });
          });

          let result = await field.field.lookup.dataSource.load({
            filter: { value: filter },
            skip: 0,
            take: field.value.length,
          });

          let res2 = result.data.map(
            (x) =>
              getDisplayExpr(
                x,
                field.field.lookup.displayExpr,
                checkField(field.field),
              ) + ' ',
          );

          return res2;
        }
      },
      editorTemplate: 'selectTemplate',
    };

    //operation custom customBetween
    this.customNotSelectValues = {
      name: 'customNotSelectValues',
      caption: TranslateService.get('clFilbuilder-customNotSelectValues'),
      icon: 'unselectall',
      dataTypes: ['ID'],

      async customizeText(field) {
        if (Array.isArray(field.value) === false || field.value.length === 0) {
          return 'Pas de valeur';
        } else {
          // Create filter
          let filter = {};
          filter['or'] = [];
          field.value.forEach((f) => {
            filter['or'].push({
              id: { eq: f },
            });
          });

          let result = await field.field.lookup.dataSource.load({
            filter: { value: filter },
            skip: 0,
            take: field.value.length,
          });

          let res2 = result.data.map(
            (x) =>
              getDisplayExpr(
                x,
                field.field.lookup.displayExpr,
                checkField(field.field),
              ) + ' ',
          );

          return res2;
        }
      },
      editorTemplate: 'selectTemplate',
    };

    this.customBetweenSizeGo = {
      name: 'customBetweenSizeGo',
      caption: TranslateService.get('clFilbuilder-between'),
      icon: 'range',
      dataTypes: ['number'],
      customizeText(field) {
        return (
          field.value[0] / 1024 / 1024 / 1024 +
          ' Go' +
          ' - ' +
          field.value[1] / 1024 / 1024 / 1024 +
          ' Go'
        );
      },
      editorTemplate: 'customBetweenSizeGo',
    };

    // custom operation customDate
    this.customDateOperation = {
      name: 'customDate',
      caption: TranslateService.get('clFilbuilder-customDateOperation'),
      icon: 'more',
      dataTypes: ['datetime', 'date'],
      customizeText(field) {
        if (field.value[1] == null) return '';
        else
          return (
            field.value[0] +
            '  ' +
            TranslateService.get(`enums/timeType/` + field.value[1])
          );
      },
      editorTemplate: 'numberBoxTemplate',
    };

    // custom operation customDate
    this.customDeadlineDateOperation = {
      name: 'customDeadlineDate',
      caption: TranslateService.get('clFilbuilder-customDeadlineDateOperation'),
      icon: 'more',
      dataTypes: ['datetime', 'date'],
      customizeText(field) {
        if (field.value[1] == null) return '';
        else
          return (
            field.value[0] +
            '  ' +
            TranslateService.get(`enums/timeType/` + field.value[1])
          );
      },
      editorTemplate: 'numberBoxTemplate',
    };

    //custom operation customDefaultDate
    this.customDefaultDateOperation = {
      name: 'customDefaultDate',
      caption: TranslateService.get('clFilbuilder-customDefaultDateOperation'),
      icon: 'more',
      dataTypes: ['datetime', 'date'],
      customizeText(field) {
        return TranslateService.get(`enums/defaultTime/` + field.value);
      },
      editorTemplate: 'defaultTimeTemplate',
    };

    this.customArrayEq = {
      name: 'customArrayEq',
      caption: TranslateService.get('clFilbuilder-customEqual'),
      icon: 'equal',
    };

    this.customArrayNotEq = {
      name: 'customArrayNotEq',
      caption: TranslateService.get('clFilbuilder-customNotEqual'),
      icon: 'notequal',
    };

    this.customArrayMoreThan = {
      name: 'customArrayMoreThan',
      caption: 'Est plus grand ou égal à',
      icon: 'greaterorequal',
    };

    this.customArrayLessThan = {
      name: 'customArrayLessThan',
      caption: 'Est plus petit ou égal à',
      icon: 'lessorequal',
    };

    this.customArrayContains = {
      name: 'customArrayContains',
      caption: 'Contient',
      icon: 'showpanel',
      async customizeText(field) {
        if (Array.isArray(field.value) === false || field.value.length === 0)
          return TranslateService.get(`globals/noDataOrigin`);
        else {
          let filter = {};
          filter[field.field.lookup.valueExpr] = { in: field.value };
          let res = await field.field.lookup.dataSource.load({
            filter: { value: filter },
          });

          return res.data.map(
            (x) => getDisplayExpr(x, field.field.lookup.displayExpr) + ' ',
          );
        }
      },
    };

    this.customDateOperation.caption = TranslateService.get(
      `enums/defaultTime/moreThan`,
    );
    this.customBetweenOperation.caption = TranslateService.get(
      `enums/defaultTime/between`,
    );
    this.customDefaultDateOperation.caption = TranslateService.get(
      `enums/defaultTime/default`,
    );

    this.customDefaultDateOperation = {
      name: 'customTimeDate',
      caption: 'Est égal à',
      icon: 'more',
      customizeText(field) {
        let regex =
          /^([+-])?P(?!$)(\d+Y)?(\d+M)?(\d+W)?(\d+D)?(T(?=\d*\.?\d*[DHMS])(\d*\.?\d*D)?(\d*\.?\d*H)?(\d*\.?\d*M)?(\d*\.?\d*S)?)?$/gm;
        let m = regex.exec(field.value);
        let days = 0;
        let hours = 0;
        let minutes = 0;
        let secondes = 0;
        let displayValue = '';
        if (m != undefined) {
          if (m[5] != undefined) {
            days = Number(m[5].split('D')[0]);
            if (days > 0) {
              displayValue += days + ' jour(s) ';
            }
          }
          if (m[8] != undefined) {
            hours = Number(m[8].split('H')[0]);
            if (hours > 0) {
              displayValue += hours + ' heure(s) ';
            }
          }
          if (m[9] != undefined) {
            minutes = Number(m[9].split('M')[0]);
            if (minutes > 0) {
              displayValue += minutes + ' minute(s) ';
            }
          }
        }

        return displayValue;
      },
      editorTemplate: 'timeTemplate',
    };
  }

  // Ajout ou retrait pour les dates
  addDate(
    type: any,
    value: any,
    isCustomBetween: boolean = false,
    isStartDate: boolean = false,
  ) {
    let week = value * 7;

    let d = new Date();

    switch (type) {
      case 'minutes':
        d.setUTCDate(d.getUTCMinutes() + value);
        // Problème #9630
        d.setUTCMilliseconds(0);
        d.setUTCSeconds(0);
        return d;
      case 'hours':
        d.setUTCDate(d.getUTCHours() + value);
        // Problème #9630
        d.setUTCMilliseconds(0);
        d.setUTCSeconds(0);
        return d;
      default:
        return this.getCustomBetweenValues(
          type,
          value,
          d,
          week,
          isCustomBetween,
          isStartDate,
        );
    }
  }

  /** Permet de former les filtres pour les jours/semaines/mois/années qui ne doivent pas prendre en compte l'heure actuelle */
  private getCustomBetweenValues(
    type: any,
    value: any,
    d: Date,
    week: any,
    isCustomBetween: boolean = false,
    isStartDate: boolean = false,
  ) {
    if (isCustomBetween) {
      if (isStartDate) {
        d.setUTCHours(0, 0, 0);
      } else {
        d.setUTCHours(23, 59, 59);
      }
    }
    // Problème #9630
    d.setUTCMilliseconds(0);
    d.setUTCSeconds(0);
    switch (type) {
      case 'days':
        d.setUTCDate(d.getUTCDate() + value);
        return d;
      case 'weeks':
        d.setUTCDate(d.getUTCDate() + (week as any));
        return d;
      case 'months':
        d.setUTCMonth(d.getUTCMonth() + value);
        return d;
      case 'years':
        d.setUTCFullYear(d.getUTCFullYear() + value);
        return d;
      default:
        return null;
    }
  }

  //function de trie en fonction du type
  trieDate(type: any) {
    const value = new Date();

    let start;
    let end;

    switch (type) {
      case 'currentWeek':
        start = this.FirstDayOfWeek(value);
        end = this.LastDayOfWeek(value);

        return [start, end];
      case 'previousWeek':
        start = this.FirstDayOfPreviousWeek(value);
        end = this.LastDayOfPreviousWeek(value);

        return [start, end];
      case 'currentMonth':
        start = this.FirstDayOfMonth(value);
        end = this.LastDayOfMonth(value);

        return [start, end];
      case 'previousMonth':
        start = this.FirstDayOfPreviousMonth(value);
        end = this.LastDayOfPreviousMonth(value);

        return [start, end];
      case 'currentYear':
        start = this.FirstDayOfYear(value);
        end = this.LastDayOfYear(value);

        return [start, end];
      case 'previousYear':
        start = this.FirstDayOfPreviousYear(value);
        end = this.LastDayOfPreviousYear(value);

        return [start, end];
      default:
        return null;
    }
  }

  //functions pour trieDate
  FirstDayOfWeek(value: any) {
    let res = new Date(value.setDate(value.getDate() - value.getDay()));
    res.setUTCDate(res.getUTCDate() + 1);

    return res;
  }

  LastDayOfWeek(value: any) {
    let res = this.FirstDayOfWeek(value);
    res.setUTCDate(res.getUTCDate() + 6);
    return res;
  }

  FirstDayOfPreviousWeek(value: any) {
    let previousDate = this.FirstDayOfWeek(value);
    previousDate.setUTCDate(previousDate.getUTCDate() - 7);
    return previousDate;
  }

  LastDayOfPreviousWeek(value: any) {
    let ldowDate = this.FirstDayOfPreviousWeek(value);
    ldowDate.setUTCDate(ldowDate.getUTCDate() + 6);
    return ldowDate;
  }

  FirstDayOfMonth(value: any) {
    let date = new Date(value.getUTCFullYear(), value.getUTCMonth(), 1);
    return date;
  }

  LastDayOfMonth(value: any) {
    let res = this.FirstDayOfMonth(value);

    res.setUTCMonth(res.getUTCMonth() + 1);
    res.setUTCDate(res.getUTCDate() - 1);

    return res;
  }

  FirstDayOfPreviousMonth(value: any) {
    let res = this.FirstDayOfMonth(value);

    res.setUTCMonth(res.getUTCMonth() - 1);

    return res;
  }

  LastDayOfPreviousMonth(value: any) {
    let res = this.LastDayOfMonth(value);

    res.setUTCMonth(res.getUTCMonth() - 1);
    return res;
  }

  FirstDayOfYear(value: any) {
    let d = new Date(value.getUTCFullYear(), 0, 1);
    return d;
  }

  LastDayOfYear(value: any) {
    let res = this.FirstDayOfYear(value);

    res.setUTCFullYear(res.getUTCFullYear() + 1);
    res.setUTCDate(res.getUTCDate() - 1);
    return res;
  }

  FirstDayOfPreviousYear(value: any) {
    let res = this.FirstDayOfYear(value);

    res.setUTCFullYear(res.getUTCFullYear() - 1);
    return res;
  }

  LastDayOfPreviousYear(value: any) {
    let res = this.LastDayOfYear(value);

    res.setUTCFullYear(res.getUTCFullYear() - 1);
    return res;
  }

  getEnumTimeType() {
    return new ArrayStore({
      data: [
        {
          id: 'minutes',
          name: TranslateService.get(`enums/timeType/minutes`),
        },
        {
          id: 'hours',
          name: TranslateService.get(`enums/timeType/hours`),
        },
        {
          id: 'days',
          name: TranslateService.get(`enums/timeType/days`),
        },
        {
          id: 'weeks',
          name: TranslateService.get(`enums/timeType/weeks`),
        },
        {
          id: 'months',
          name: TranslateService.get(`enums/timeType/months`),
        },
        {
          id: 'years',
          name: TranslateService.get(`enums/timeType/years`),
        },
      ],
      key: 'id',
    });
  }

  getEnumDefaultTime() {
    return new ArrayStore({
      data: [
        {
          id: 'currentWeek',
          name: TranslateService.get(`enums/defaultTime/currentWeek`),
        },
        {
          id: 'previousWeek',
          name: TranslateService.get(`enums/defaultTime/previousWeek`),
        },
        {
          id: 'currentMonth',
          name: TranslateService.get(`enums/defaultTime/currentMonth`),
        },
        {
          id: 'previousMonth',
          name: TranslateService.get(`enums/defaultTime/previousMonth`),
        },
        {
          id: 'currentYear',
          name: TranslateService.get(`enums/defaultTime/currentYear`),
        },
        {
          id: 'previousYear',
          name: TranslateService.get(`enums/defaultTime/previousYear`),
        },
      ],
      key: 'id',
    });
  }
}
