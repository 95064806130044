import { TermsContractBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@clarilog/shared';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';

import { ModelDataSourceContext } from '@clarilog/shared2/services/compiler/model-state';
import { CoreGraphQLSource } from '@clarilog/core/services2/graphql/graphql-store.service';
import { CriteriaHelpers } from '@clarilog/core/services2';
import { GqlField, GqlSubField } from '../helpers';
import { Authorize } from '@clarilog/core/services/graphql';

@Injectable({ providedIn: 'root' })
@Authorize({
  policies: ['user.manage-book-assignment', 'loan.manage-loan-vouchers'],
  operator: 'or',
})
export class TermsContractCoreService extends TermsContractBaseService {
  constructor(
    injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }

  /** */
  findMentionBodyLoanVoucher(): {
    name: string;
    key: string;
  }[] {
    let mentions = [
      {
        key: 'number',
        name: TranslateService.get('entities/loan/number'),
      },
      {
        key: '[loanProperties].provision',
        name: TranslateService.get('entities/loan/provision'),
      },
      {
        key: 'slipNumber',
        name: TranslateService.get('entities/loan/slipNumber'),
      },
      {
        key: '[loanProperties].recordDate',
        name: TranslateService.get('entities/loan/recordDate'),
      },
      {
        key: '[loanProperties].manager.name',
        name: TranslateService.get('entities/loan/managerId'),
      },
      {
        key: 'loanBorrower',
        name: TranslateService.get('entities/loan/borrower'),
      },

      {
        key: 'loanTypeBorrower',
        name: TranslateService.get('entities/loan/loanBorrowerType'),
      },

      {
        key: '[loanProperties].loanStatus.name',
        name: TranslateService.get('entities/loan/loanStatus'),
      },

      {
        key: '[loanProperties].realReturnDate',
        name: TranslateService.get('entities/loan/realReturnDate'),
      },
      {
        key: 'loanDuration',
        name: TranslateService.get('entities/loan/duration'),
      },
      {
        key: '[loanProperties].returnDate',
        name: TranslateService.get('entities/loan/returnDate'),
      },
    ];

    return mentions.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  /** */
  createLoanVoucherSource() {
    return new ModelDataSourceContext({
      datasource: CoreGraphQLSource.create({
        query: (filters?: any, options?: any) => {
          let filter = null;
          if (filters != undefined)
            filter = CriteriaHelpers.convertDxFilter(
              CriteriaHelpers.decompile(filters as any),
            );
          let customMethod = this.findCustom(
            this.createField(),
            'loan',
            options,
            filter,
          );
          return customMethod;
        },
      }),
    });
  }

  /** */
  findMentionBodyAssignment(): {
    name: string;
    key: string;
  }[] {
    let mentions = [
      {
        key: 'name',
        name: TranslateService.get('entities/user/name'),
      },
      {
        key: 'firstName',
        name: TranslateService.get('entities/user/firstName'),
      },
      {
        key: 'lastName',
        name: TranslateService.get('entities/user/lastName'),
      },
      {
        key: 'email',
        name: TranslateService.get('entities/user/email'),
      },
      {
        key: 'azureObjectId',
        name: TranslateService.get('entities/user/azureObjectId'),
      },
      {
        key: 'officeName',
        name: TranslateService.get('entities/user/officeName'),
      },
      {
        key: '[userProperties].hiringDate',
        name: TranslateService.get('entities/user/hiringDate'),
      },
      {
        key: '[userProperties].whenCreated',
        name: TranslateService.get('entities/user/whenCreated'),
      },
      {
        key: '[userProperties].lastConnected',
        name: TranslateService.get('entities/user/lastConnected'),
      },
      {
        key: 'userAccountExpires',
        name: TranslateService.get('entities/user/userAccountExpires'),
      },
      {
        key: 'allowDialIn',
        name: TranslateService.get('entities/user/allowDialIn'),
      },
      {
        key: 'allowEditOrganization',
        name: TranslateService.get('entities/user/allowEditOrganization'),
      },
      {
        key: '[userProperties].lastLogOff',
        name: TranslateService.get('entities/user/lastLogOff'),
      },
      {
        key: '[userProperties].lastLogOn',
        name: TranslateService.get('entities/user/lastLogOn'),
      },
      {
        key: '[userProperties].exitDate',
        name: TranslateService.get('entities/user/exitDate'),
      },
      {
        key: 'recipentSystemNotification',
        name: TranslateService.get('entities/user/recipentSystemNotification'),
      },
      {
        key: 'domain',
        name: TranslateService.get('entities/user/domain'),
      },
      {
        key: 'principalFax',
        name: TranslateService.get('entities/user/principalFax'),
      },
      {
        key: 'principalHomePhone',
        name: TranslateService.get('entities/user/principalHomePhone'),
      },
      {
        key: 'title',
        name: TranslateService.get('entities/user/title'),
      },
      {
        key: 'objectSid',
        name: TranslateService.get('entities/user/objectSid'),
      },
      {
        key: 'initial',
        name: TranslateService.get('entities/user/initial'),
      },
      {
        key: 'memberOf',
        name: TranslateService.get('entities/user/memberOf'),
      },
      {
        key: 'principalMobile',
        name: TranslateService.get('entities/user/principalMobile'),
      },
      {
        key: 'registrationNumber',
        name: TranslateService.get('entities/user/registrationNumber'),
      },
      {
        key: 'supportLevelOperator',
        name: TranslateService.get('entities/user/supportLevelOperator'),
      },
      {
        key: 'userName',
        name: TranslateService.get('entities/user/userName'),
      },
      {
        key: 'userPrincipalName',
        name: TranslateService.get('entities/user/userPrincipalName'),
      },
      {
        key: 'logOnTo',
        name: TranslateService.get('entities/user/logOnTo'),
      },
      {
        key: 'distinguishedName',
        name: TranslateService.get('entities/user/distinguishedName'),
      },
      {
        key: 'userAccountControl',
        name: TranslateService.get('entities/user/userAccountControl'),
      },
      {
        key: 'principalPager',
        name: TranslateService.get('entities/user/principalPager'),
      },
      {
        key: 'manager',
        name: TranslateService.get('entities/user/manager'),
      },
      {
        key: 'secretary',
        name: TranslateService.get('entities/user/secretary'),
      },
      {
        key: 'department',
        name: TranslateService.get('entities/user/department'),
      },
      {
        key: 'principalWebSite',
        name: TranslateService.get('entities/user/principalWebSite'),
      },
      {
        key: 'webSites',
        name: TranslateService.get('entities/user/webSites'),
      },
      {
        key: 'company',
        name: TranslateService.get('entities/user/company'),
      },
      {
        key: 'principalPhone',
        name: TranslateService.get('entities/user/principalPhone'),
      },
      {
        key: 'vip',
        name: TranslateService.get('entities/user/vip'),
      },
      {
        key: 'helpDeskActivated',
        name: TranslateService.get('entities/user/helpDeskActivated'),
      },
      {
        key: 'principalIpPhone',
        name: TranslateService.get('entities/user/principalIpPhone'),
      },
      {
        key: '[userProperties].calendar.name',
        name: `${TranslateService.get(
          'entities/calendarConnector/_title/singular',
        )} - ${TranslateService.get('entities/calendarConnector/name')}`,
      },

      {
        key: '[userProperties].businessTime.name',
        name: `${TranslateService.get(
          'entities/businessTime/_title/singular',
        )} - ${TranslateService.get('entities/businessTime/name')}`,
      },

      {
        key: '[userProperties].businessTime.isDefault',
        name: `${TranslateService.get(
          'entities/businessTime/_title/singular',
        )} - ${TranslateService.get('entities/businessTime/isDefault')}`,
      },
    ];
    mentions = mentions.concat(this.findMentionBodyAssignmentAddress());
    mentions = mentions.concat(this.findMentionBodyAssignmentAsset());
    mentions = mentions.concat(this.findMentionBodyAssignmentOperatorTeams());
    mentions = mentions.concat(this.findMentionBodyAssignmentInviation());
    mentions = mentions.concat(this.findMentionBodyAssignmentLocation());
    mentions = mentions.concat(this.findMentionBodyAssignmentOrganization());
    return mentions.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  private findMentionBodyAssignmentAddress(): {
    name: string;
    key: string;
  }[] {
    return [
      {
        key: '[userProperties].address.street',
        name: `${TranslateService.get('entities/address/_title/singular')}`,
      },
      {
        key: '[userProperties].address.additional',
        name: `${TranslateService.get(
          'entities/address/_title/singular',
        )} - ${TranslateService.get('entities/address/additional')}`,
      },
      {
        key: '[userProperties].address.city',
        name: `${TranslateService.get(
          'entities/address/_title/singular',
        )} - ${TranslateService.get('entities/address/city')}`,
      },
      {
        key: '[userProperties].address.province',
        name: `${TranslateService.get(
          'entities/address/_title/singular',
        )} - ${TranslateService.get('entities/address/province')}`,
      },
      {
        key: '[userProperties].address.country',
        name: `${TranslateService.get(
          'entities/address/_title/singular',
        )} - ${TranslateService.get('entities/address/country')}`,
      },
      {
        key: '[userProperties].address.zipCode',
        name: `${TranslateService.get(
          'entities/address/_title/singular',
        )} - ${TranslateService.get('entities/address/zipCode')}`,
      },
      {
        key: '[userProperties].address.postalCode',
        name: `${TranslateService.get(
          'entities/address/_title/singular',
        )} - ${TranslateService.get('entities/address/postalCode')}`,
      },
    ];
  }

  private findMentionBodyAssignmentAsset(): {
    name: string;
    key: string;
  }[] {
    return [
      {
        key: '[mainAssetProperties].macAddress',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/macAddress')}`,
      },
      {
        key: '[mainAssetProperties].ipAddress',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/ipAddress')}`,
      },

      {
        key: '[mainAssetProperties].domain',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/computerSystem/domain')}`,
      },
      {
        key: '[mainAssetProperties].uuid',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/uuid')}`,
      },
      {
        key: '[mainAssetProperties].name',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/name')}`,
      },
      {
        key: '[mainAssetProperties].fqdn',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/fqdn')}`,
      },
      {
        key: '[mainAssetProperties].netBios',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/netBios')}`,
      },
      {
        key: '[mainAssetProperties].barCode',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/barCode')}`,
      },
      {
        key: '[mainAssetProperties].comment',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/comment')}`,
      },
      {
        key: '[mainAssetProperties].featureComment',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/featureComment')}`,
      },

      {
        key: '[mainAssetProperties].warrantyEnd',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/warrantyEnd')}`,
      },
      {
        key: '[mainAssetProperties].isAudited',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/isAudited')}`,
      },
      {
        key: '[mainAssetProperties].firstAudit',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/firstAudit')}`,
      },
      {
        key: '[mainAssetProperties].lastAudit',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/lastAudit')}`,
      },
      {
        key: '[mainAssetProperties].lastSuccessAudit',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/lastSuccessAudit')}`,
      },
      {
        key: '[mainAssetProperties].warrantyStart',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/warrantyStart')}`,
      },
      {
        key: '[mainAssetProperties].memories',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/memories')}`,
      },
      {
        key: '[mainAssetProperties].serialNumber',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/serialNumber')}`,
      },
      {
        key: '[mainAssetProperties].inventoryNumber',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/inventoryNumber')}`,
      },
      {
        key: '[mainAssetProperties].distinguishedName',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/distinguishedName')}`,
      },
      {
        key: '[mainAssetProperties].userName',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/userName')}`,
      },
      {
        key: '[mainAssetProperties].inventoryNumber',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/inventoryNumber')}`,
      },
      {
        key: '[mainAssetProperties].processor',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/processor')}`,
      },
      {
        key: '[mainAssetProperties].operatingSystem',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/operatingSystem')}`,
      },
      {
        key: '[mainAssetProperties].skuNumber',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/skuNumber')}`,
      },
      {
        key: '[mainAssetProperties].loanable',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/loanable')}`,
      },
      {
        key: '[mainAssetProperties].inventoryNumber',
        name: `${TranslateService.get(
          'entities/user/mainAsset',
        )} - ${TranslateService.get('entities/asset/inventoryNumber')}`,
      },
    ];
  }

  private findMentionBodyAssignmentInviation(): {
    name: string;
    key: string;
  }[] {
    return [
      {
        key: '[userProperties].invitation.email',
        name: `${TranslateService.get(
          'entities/workflowInvitation/_title/singular',
        )} - ${TranslateService.get('entities/user/email')}`,
      },
      {
        key: '[userProperties].invitation.sendDate',
        name: `${TranslateService.get(
          'entities/workflowInvitation/_title/singular',
        )} - ${TranslateService.get('entities/user/sendDate')}`,
      },
      {
        key: '[userProperties].invitation.revokeDate',
        name: `${TranslateService.get(
          'entities/workflowInvitation/_title/singular',
        )} - ${TranslateService.get('entities/user/revokeDate')}`,
      },
      {
        key: '[userProperties].invitation.acceptDate',
        name: `${TranslateService.get(
          'entities/workflowInvitation/_title/singular',
        )} - ${TranslateService.get('entities/user/acceptDate')}`,
      },
    ];
  }

  private findMentionBodyAssignmentOperatorTeams(): {
    name: string;
    key: string;
  }[] {
    return [
      {
        key: '[mainOperatorTeamProperties].emails',
        name: `${TranslateService.get(
          'entities/user/mainOperatorTeam',
        )} - ${TranslateService.get('entities/operatorTeam/emails')}`,
      },
      {
        key: '[mainOperatorTeamProperties].name',
        name: `${TranslateService.get(
          'entities/user/mainOperatorTeam',
        )} - ${TranslateService.get('entities/operatorTeam/name')}`,
      },
      {
        key: '[mainOperatorTeamProperties].default',
        name: `${TranslateService.get(
          'entities/user/mainOperatorTeam',
        )} - ${TranslateService.get('entities/operatorTeam/default')}`,
      },
    ];
  }

  private findMentionBodyAssignmentLocation(): {
    name: string;
    key: string;
  }[] {
    return [
      {
        key: '[locationProperties].path',
        name: `${TranslateService.get(
          'entities/location/_title/singular',
        )} - ${TranslateService.get('entities/location/path')}`,
      },
      {
        key: '[locationProperties].name',
        name: `${TranslateService.get(
          'entities/location/_title/singular',
        )} - ${TranslateService.get('entities/location/name')}`,
      },
      {
        key: '[locationProperties].barCode',
        name: `${TranslateService.get(
          'entities/location/_title/singular',
        )} - ${TranslateService.get('entities/location/barCode')}`,
      },
    ];
  }

  private findMentionBodyAssignmentOrganization(): {
    name: string;
    key: string;
  }[] {
    return [
      {
        key: '[organizationProperties].path',
        name: `${TranslateService.get(
          'entities/organizationalUnit/_title/singular',
        )} - ${TranslateService.get('entities/organizationalUnit/path')}`,
      },
      {
        key: '[organizationProperties].name',
        name: `${TranslateService.get(
          'entities/organizationalUnit/_title/singular',
        )} - ${TranslateService.get('entities/organizationalUnit/name')}`,
      },
    ];
  }

  /** */
  createAssignmentBookSource() {
    return new ModelDataSourceContext({
      datasource: CoreGraphQLSource.create({
        query: (filters?: any, options?: any) => {
          let filter = null;
          if (filters != undefined)
            filter = CriteriaHelpers.convertDxFilter(
              CriteriaHelpers.decompile(filters as any),
            );
          return this.findCustom(this.createField(), 'user', options, filter);
        },
      }),
    });
  }

  private createField(): GqlSubField[] {
    return [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlSubField.create(
          'title',
          this.translatedFieldHelperService.translatedFields(),
        ),
        GqlField.create('entityType'),
      ]),
    ];
  }
}
