import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RoleCoreService } from '@clarilog/core/services2/graphql/generated-types/services/role.service';
import { EmailPrototypeCoreService } from '@clarilog/core/services2/graphql/generated-types/services/email-prototype.service';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  FilterOfUser,
  Role,
  User,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { map } from 'rxjs/internal/operators/map';
import { ModelDataSourceContext } from '@clarilog/shared2/services/compiler/model-state';
import { CoreGraphQLSource } from '@clarilog/core/services2/graphql/graphql-store.service';
import { UserCoreService } from '@clarilog/core/services2/graphql/generated-types/services/user.service';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { TicketCoreService } from '@clarilog/core/services2/graphql/generated-types/services/ticket.service';
import { EmailConnectorCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import { TranslatedFieldHelperService } from '../../translate-field/translate-field-helper-service';

/**
 * Composant type TagBox relatif aux prototype d'email.
 */

@Component({
  selector: 'clc-tag-box-email',
  templateUrl: './tag-box-email.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagBoxEmailComponent),
      multi: true,
    },
  ],
})
export class TagBoxEmailComponent implements ControlValueAccessor, OnInit {
  /** Sauvegarde les valeurs. */
  _values: any[];
  emails: any[];
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** Sauvegarde l'objet du composant. */
  instanceComponent: any;
  instance: any;
  /** La souscription de l'observable. */
  sub: any;
  @Input() source: any;
  sourceUsers: any;
  /** Définit si les rôles sont chargés automatiquement */
  @Input() loadRoles: boolean = false;
  @Input() loadUsers: boolean = false;
  @Input() loadConnectors: boolean = false;

  @Input() onlyVisibleHelpDesk: boolean = false;

  emailMentions;
  constructor(
    public emailPrototypeService: EmailPrototypeCoreService,
    public roleService: RoleCoreService,
    public userService: UserCoreService,
    public emailConnectorService: EmailConnectorCoreService,
    public activatedRoute: ActivatedRoute,
    public ticketCoreService: TicketCoreService,
    private changeDetectorRef: ChangeDetectorRef,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    this.emailMentions = this.ticketCoreService.findMentionEmail();
  }

  ngOnInit() {
    if (this.loadUsers == true) {
      let items = [];

      if (this.source != undefined) {
        this.source.forEach((element) => {
          items.push({
            __typename: 'Role',
            email: element['name'] + ' [_' + element['key'] + ']',
            name: element['name'],
          });
        });
      }

      if (this.loadRoles == true) {
        this.loadRolesList(items, true);
      }

      let context = new ModelDataSourceContext({
        serviceName: 'userService',
        methodName: 'find',
      });
      this.sourceUsers = CoreGraphQLSource.create({
        context: context,
        query: (filter?: any, options?: any) => {
          let itemsToReturn = options.skip >= options.limit ? [] : items;

          if (this.instance.component.customItem != undefined) {
            let resultCustomItem = this.instance.component.customItem;
            this.instance.component.customItem = undefined;
            return resultCustomItem;
          }
          if (options.textSearch == '*') {
            return itemsToReturn;
          }
          if (options.textSearch == '' || options.textSearch == undefined) {
            return itemsToReturn;
          }

          if (
            options.textSearch.trim != undefined &&
            options.textSearch.trim() == ''
          ) {
            return itemsToReturn;
          }

          let field = [
            GqlSubField.create('data', [
              GqlField.create('name'),
              GqlField.create('email'),
              GqlField.create('id'),
            ]),
          ];

          let myFilter = {};
          let myFilter2 = {};
          let myFilterLastName = {};
          let myFilterFirstName = {};
          myFilter['email'] = { contains: options.textSearch };
          myFilter2['name'] = { contains: options.textSearch };
          myFilterLastName['lastName'] = { contains: options.textSearch };
          myFilterFirstName['firstName'] = { contains: options.textSearch };

          let filterRs = {
            or: [myFilter, myFilter2, myFilterFirstName, myFilterFirstName],
          };

          let myFilter3 = {};
          myFilter3['email'] = { contains: '@' };

          let filters = {
            and: [filterRs, myFilter3],
          };

          if (this.onlyVisibleHelpDesk) {
            let helpDeskFilter: FilterOfUser = {
              helpDeskActivated: { eq: true },
            };

            filters.and.push(helpDeskFilter);
          }

          return this.userService.find(field, options, filters).pipe(
            map((result) => {
              if (
                this.instance.customItem != undefined &&
                options.textSearch.trim().length == 0
              ) {
                let result = this.instance.customItem;
                this.instance.customItem = undefined;

                return result;
              }

              return result;
            }),
          );
        },
      });
    }
  }

  /** Charge les connecteurs  */
  loadedConnectors() {
    if (this.loadConnectors == true) {
      let lang = this.translatedFieldHelperService.getTranslateKey();
      let field = [
        GqlSubField.create('data', [
          GqlSubField.create('name', [GqlField.create(lang)]),
          GqlField.create('email'),
          GqlField.create('id'),
        ]),
      ];

      this.emailConnectorService
        .find(field, undefined, undefined)
        .subscribe((result) => {
          if (result?.data != undefined) {
            this.sub.option(
              'items',
              result.data.map((s) => {
                return {
                  email: s.name[lang],
                  name: s.email,
                };
              }),
            );
          }
        });
    }
  }

  async onInitialized(e) {
    this.instanceComponent = e.component;
    this.instance = e;
    this._values = [];
    this.emails = [];
  }

  /** Gestion du menu déroulant des valeurs custom */
  onTagBoxOpened(e) {
    this.tagBoxSource(e);
    e.component.option('opened', true);
  }

  /** Mise à jour du datasource du tagBox si custom element */
  tagBoxSource(e) {
    if (e == undefined) return;
    this.sub = e.component != undefined ? e.component : e;
    if (
      this.sub.option('items') != undefined &&
      this.sub.option('items').length == 0
    ) {
      let items: any[] = [];

      if (this.source != undefined) {
        if (!this.loadUsers) {
          this.source.forEach((element) => {
            items.push({
              name: element['name'] + ' [_' + element['key'] + ']',
            });
          });
        }
      }

      if (this.loadRoles == true) {
        if (!this.loadUsers) {
          this.loadRolesList(items, false);
        }
      }

      if (this.loadUsers || this.loadConnectors) {
        this.setUserItems(items);
      }

      if (!this.loadUsers && !this.loadRoles) {
        this.setItems(items);
      }

      this.loadedConnectors();
    }
  }

  /**
   * Charge la liste des rôles lorsque "loadRoles" est à true
   * @param items
   * @param isLoadUsersTrue - est true si le loadUsers == true
   */
  loadRolesList(items: any, isLoadUsersTrue: boolean) {
    let s = this.roleService
      .find([
        GqlSubField.create('data', [
          GqlField.create('name'),
          GqlField.create('id'),
        ]),
      ])
      .subscribe(async (res) => {
        if (isLoadUsersTrue) {
          res.data
            .filter((f) => f.name)
            .forEach((f) => {
              let exist = items.find((x) => x.name == f.name);
              if (exist == undefined) {
                items.push({ __typename: 'Role', email: f.name, name: f.name });
              }
            });
          this.sortItems(items);
        } else {
          res.data.filter((f) => f.name).forEach((f) => items.push(f));
          this.setItems(items);
        }

        s.unsubscribe();
      });
  }

  /** Trie de la liste */
  sortItems(items) {
    items.sort(function (a, b) {
      return a.name
        .toLocaleLowerCase()
        .localeCompare(b.name.toLocaleLowerCase(), 'fr', {
          ignorePunctuation: true,
        });
    });
  }

  /** Set la source */
  setItems(items: Role[]) {
    if (this._values != undefined) {
      this._values.forEach(function (v) {
        let exist = items.find((x) => x.name == v);
        if (exist == undefined) {
          items.unshift({ name: v } as any);
        }
      });
    }

    this.sortItems(items);
    this.sub.option('items', items);
  }

  setUserItems(items: User[]) {
    if (this._values != undefined) {
      this._values.forEach(function (v) {
        let exist = items.find((x) => x.name == v);
        if (exist == undefined) {
          items.unshift({ name: v, email: v } as User);
        }
      });
    }
    this.sortItems(items);
    this.instance.customItem = items;
  }

  /** Gestion du drop down */
  onTagBoxKeyDown(e) {
    if (e.component.option('acceptCustomValue')) {
      e.component.option('opened', false);
    }
  }

  onKeyDown(e) {
    let fieldValue = e.component.option('text');
    if (e.event.keyCode === 9) {
      if (this.emailIsValid(fieldValue)) {
        let item = { name: fieldValue };
        let items = e.component.option('items');
        items.unshift(item);
        e.component.option('items', items);
        e.customItem = item;

        let valueComponent = e.component.option('value');
        valueComponent.push(fieldValue);
        e.component.option('value', valueComponent);

        setTimeout(() => {
          e.component.resetOption('text');
          e.component.repaint();
          e.component.focus();
        });

        this._values = valueComponent;
        this.onChange(this._values);
        this.onTouched();

        e.event.preventDefault();
      }
    }
  }

  /** Validation de la création d'un item */
  onTagBoxCustomItemCreating(e) {
    if (this.emailIsValid(e.text)) {
      if (this.loadUsers) {
        e.customItem = { name: e.text, email: e.text } as User;
      } else {
        let item = { name: e.text };
        let items = e.component.option('items');
        items.unshift(item);
        e.component.option('items', items);
        e.customItem = item;
      }
    }
  }

  /** Permet de vérifier si c'est bien sous le format d'une adresse mail */
  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  writeValue(values: any[]): void {
    setTimeout((_) => {
      this._values = values;
      if (
        (this.loadUsers == true || this.loadConnectors == true) &&
        this.instanceComponent != null
      ) {
        let items = [];
        if (this._values != undefined) {
          this._values.forEach((v) => {
            let name = v;
            let translateName = undefined;

            if (name != undefined && name.indexOf('[_') > 0) {
              // Gestion de la traduction des valeur variable
              if (this.emailMentions != undefined) {
                let key = name.substring(name.indexOf('[_') + 2);
                key = key.substring(0, key.length - 1);
                let findMention = this.emailMentions.filter(
                  (s) => s.key == key,
                );
                if (findMention != undefined && findMention.length > 0) {
                  translateName = TranslateService.get(
                    findMention[0].translate,
                  );
                }
              }

              if (translateName == undefined) {
                name = name.substring(0, name.indexOf('[_')).trimEnd();
              } else {
                name = translateName;
              }
            }
            items.push({ name: name, email: v } as User);
          });
          this.instance.component.option('value', items);
          this.instance.component.customItem = items;
        }
      } else this.tagBoxSource(this.instanceComponent);
      this.changeDetectorRef.detectChanges();
    });
  }
  get values(): any[] {
    if (this._values == undefined) {
      this._values = [];
    }
    return this._values;
  }
  set values(values: any[]) {
    this._values = values;
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  onValueChanged(e) {
    if (this._values?.length != e.value?.length) {
      e.value = e.value.filter((f) => f != undefined);
      this._values = e.value;
      this.onChange(e.value);
    }
  }

  onFocusOut(e) {
    if (this.sourceUsers != undefined) {
      this.sourceUsers.searchValue(e.component.option('text'));
      this.sourceUsers.reload();
    }
  }

  getDisplay(e) {
    if (e != null) {
      if (e.name != undefined && e.name.indexOf('[_') > 0) {
        return e.name.substring(0, e.name.indexOf('[_')).trimEnd();
      }
      return e.name;
    }
  }
}
