
import { SubstituteBaseService } from '../service-bases'
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

@Injectable({ providedIn: 'root' })
@Authorize('scan-configuration.manage-substitute')
export class SubstituteCoreService extends SubstituteBaseService {
    constructor(injector: Injector) {
        super(injector)
    }
}