<dx-popup
  [width]="width"
  [height]="height"
  [showTitle]="false"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="hideOnOutsideClick"
  [deferRendering]="true"
  [(visible)]="isVisible"
  (onShown)="onShown($event)"
  (onHiding)="close()"
>
  <dx-load-panel
    shadingColor="rgba(0,0,0,0.1)"
    [visible]="isLoading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
  ></dx-load-panel>

  <dx-box direction="col" width="100%" height="100%">
    <dxi-item [baseSize]="95">
      <cl-work-page-header>
        <cl-work-page-header-content>
          <cl-work-page-header-content-title
            >{{
              customTitle === false
                ? ('massiveEditOf' | translate : { elementName: title })
                : title
            }}
          </cl-work-page-header-content-title>
          <cl-work-page-header-content-body>
            <cl-toolbar>
              <cl-toolbar-items>
                <cl-toolbar-item-button
                  (onClick)="save($event)"
                  [type]="'default'"
                  [text]="'save' | translate"
                  location="after"
                  [disabled]="
                    !(canSave$ | async) ||
                    (modelState.form != undefined &&
                      modelState.form.status === 'INVALID')
                  "
                >
                </cl-toolbar-item-button>
              </cl-toolbar-items>
            </cl-toolbar>
          </cl-work-page-header-content-body>
        </cl-work-page-header-content>
      </cl-work-page-header>
    </dxi-item>
    <dxi-item [ratio]="1" [baseSize]="0">
      <dx-scroll-view width="100%" height="100%">
        <cl-work-page-content>
          <!-- <div
              *ngIf="modelState != undefined"
              class="work-item-container-scroll"
              (mousewheel)="$event.stopPropagation()"
            > -->
          <div class="">
            <div
              *ngIf="modelState != undefined"
              class="work-item-container-scroll-massive"
            >
              <div class="warning-hint" *ngIf="warningMessage != undefined">
                {{ warningMessage }}
              </div>
              <div
                class="pop-up-hint"
                *ngIf="modelState.model.form.layout.pages[0].hint != undefined"
              >
                {{ modelState.model.form.layout.pages[0].hint }}
              </div>
              <div class="work-item-container-massive">
                <clc-work-item-section-container
                  [percentage]="percentage"
                  *ngIf="modelState.form != undefined"
                >
                  <cl-work-item-section
                    *ngFor="
                      let section of modelState.model.form.layout.pages[0][
                        'sections'
                      ]
                    "
                    [formGroup]="
                      modelState.form.get(
                        modelState.model.form.layout.pages[0].name
                      )
                    "
                    [location]="section.location"
                  >
                    <clc-work-item-section-group
                      *ngFor="let group of section.groups"
                      [title]="group.label"
                      [formGroup]="
                        modelState.form
                          .get(modelState.model.form.layout.pages[0].name)
                          .get(group.name)
                      "
                      [visibled]="
                        modelState.form
                          .get(modelState.model.form.layout.pages[0].name)
                          .get(group.name).visibled
                      "
                    >
                      <clc-work-item-control-dynamic
                        [controls]="group.controls"
                        [mode]="'full'"
                        [state]="modelState"
                        [autoFocus]="
                          modelState.model.form.layout.pages[0][
                            'sections'
                          ][0] === section && section.groups[0] === group
                        "
                      ></clc-work-item-control-dynamic>
                    </clc-work-item-section-group>
                  </cl-work-item-section>
                </clc-work-item-section-container>
              </div>
            </div>
          </div>
        </cl-work-page-content>
      </dx-scroll-view>
    </dxi-item>
  </dx-box>

  <div class="close-btn">
    <dx-button
      *ngIf="showCloseButton"
      icon="fal fa-times"
      (onClick)="close()"
    ></dx-button>
  </div>
</dx-popup>
