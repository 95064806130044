import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetChannelsBaseVariables, FirstChannelsBaseVariables, CountChannelsBaseVariables, FindChannelsBaseVariables, SearchChannelsBaseVariables, ExportSchemaChannelsBaseVariables, ExportDataChannelsBaseVariables, CustomQueryChannelsBaseVariables, CustomQueryIdChannelsBaseVariables, FindUnassociatedSecurityGroupsChannelsBaseVariables, FindAssociatedOperatorTeamSecurityGroupsChannelsBaseVariables, UsedChannelsBaseVariables, FindDefaultChannelsBaseVariables, ExistChannelsBaseVariables, FindRecyclesChannelsBaseVariables, CountRecyclesChannelsBaseVariables, ReadOnlyChannelsBaseVariables, FindArchivedChannelsBaseVariables, CountAllChannelsBaseVariables, FindDynamicPropertyFieldsChannelsBaseVariables, FindUnassociatedTicketsChannelsBaseVariables, InsertChannelsBaseVariables, UpdateChannelsBaseVariables, ImportDataChannelsBaseVariables, UpdateManyChannelsBaseVariables, DeleteChannelsBaseVariables, OrderChannelsBaseVariables, AddSecurityGroupChannelsBaseVariables, RemoveSecurityGroupChannelsBaseVariables, AddOperatorTeamSecurityGroupChannelsBaseVariables, RemoveOperatorTeamSecurityGroupChannelsBaseVariables, RestoreChannelsBaseVariables, RecycleChannelsBaseVariables, RestoreArchivedChannelsBaseVariables, ArchivedChannelsBaseVariables, AddFileDynamicFieldChannelsBaseVariables, RemoveFileDynamicFieldChannelsBaseVariables, AddTicketsChannelsBaseVariables, RemoveTicketsChannelsBaseVariables } from '../gqls'
import { GetChannelsDocument, FirstChannelsDocument, CountChannelsDocument, FindChannelsDocument, SearchChannelsDocument, ExportSchemaChannelsDocument, ExportDataChannelsDocument, CustomQueryChannelsDocument, CustomQueryIdChannelsDocument, FindUnassociatedSecurityGroupsChannelsDocument, FindAssociatedOperatorTeamSecurityGroupsChannelsDocument, UsedChannelsDocument, FindDefaultChannelsDocument, ExistChannelsDocument, FindRecyclesChannelsDocument, CountRecyclesChannelsDocument, ReadOnlyChannelsDocument, FindArchivedChannelsDocument, CountAllChannelsDocument, FindDynamicPropertyFieldsChannelsDocument, FindUnassociatedTicketsChannelsDocument, InsertChannelsDocument, UpdateChannelsDocument, ImportDataChannelsDocument, UpdateManyChannelsDocument, DeleteChannelsDocument, OrderChannelsDocument, AddSecurityGroupChannelsDocument, RemoveSecurityGroupChannelsDocument, AddOperatorTeamSecurityGroupChannelsDocument, RemoveOperatorTeamSecurityGroupChannelsDocument, RestoreChannelsDocument, RecycleChannelsDocument, RestoreArchivedChannelsDocument, ArchivedChannelsDocument, AddFileDynamicFieldChannelsDocument, RemoveFileDynamicFieldChannelsDocument, AddTicketsChannelsDocument, RemoveTicketsChannelsDocument } from '../gqls'
import { ServiceSingleResultOfChannel, QueryContextOfChannel, FilterOfChannel, ServiceSingleResultOfInt64, ServiceListResultOfChannel, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfChannel, QueryContextOfTicket, FilterOfTicket, ServiceListResultOfTicket, ChannelInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ChannelBaseService {
    
public modelName = 'channel';
public modelPluralName = 'channels';

	private getChannelsQuery: GetChannelsDocument;
	private firstChannelsQuery: FirstChannelsDocument;
	private countChannelsQuery: CountChannelsDocument;
	private findChannelsQuery: FindChannelsDocument;
	private searchChannelsQuery: SearchChannelsDocument;
	private exportSchemaChannelsQuery: ExportSchemaChannelsDocument;
	private exportDataChannelsQuery: ExportDataChannelsDocument;
	private customQueryChannelsQuery: CustomQueryChannelsDocument;
	private customQueryIdChannelsQuery: CustomQueryIdChannelsDocument;
	private findUnassociatedSecurityGroupsChannelsQuery: FindUnassociatedSecurityGroupsChannelsDocument;
	private findAssociatedOperatorTeamSecurityGroupsChannelsQuery: FindAssociatedOperatorTeamSecurityGroupsChannelsDocument;
	private usedChannelsQuery: UsedChannelsDocument;
	private findDefaultChannelsQuery: FindDefaultChannelsDocument;
	private existChannelsQuery: ExistChannelsDocument;
	private findRecyclesChannelsQuery: FindRecyclesChannelsDocument;
	private countRecyclesChannelsQuery: CountRecyclesChannelsDocument;
	private readOnlyChannelsQuery: ReadOnlyChannelsDocument;
	private findArchivedChannelsQuery: FindArchivedChannelsDocument;
	private countAllChannelsQuery: CountAllChannelsDocument;
	private findDynamicPropertyFieldsChannelsQuery: FindDynamicPropertyFieldsChannelsDocument;
	private findUnassociatedTicketsChannelsQuery: FindUnassociatedTicketsChannelsDocument;
	private insertChannelsMutation: InsertChannelsDocument;
	private updateChannelsMutation: UpdateChannelsDocument;
	private importDataChannelsMutation: ImportDataChannelsDocument;
	private updateManyChannelsMutation: UpdateManyChannelsDocument;
	private deleteChannelsMutation: DeleteChannelsDocument;
	private orderChannelsMutation: OrderChannelsDocument;
	private addSecurityGroupChannelsMutation: AddSecurityGroupChannelsDocument;
	private removeSecurityGroupChannelsMutation: RemoveSecurityGroupChannelsDocument;
	private addOperatorTeamSecurityGroupChannelsMutation: AddOperatorTeamSecurityGroupChannelsDocument;
	private removeOperatorTeamSecurityGroupChannelsMutation: RemoveOperatorTeamSecurityGroupChannelsDocument;
	private restoreChannelsMutation: RestoreChannelsDocument;
	private recycleChannelsMutation: RecycleChannelsDocument;
	private restoreArchivedChannelsMutation: RestoreArchivedChannelsDocument;
	private archivedChannelsMutation: ArchivedChannelsDocument;
	private addFileDynamicFieldChannelsMutation: AddFileDynamicFieldChannelsDocument;
	private removeFileDynamicFieldChannelsMutation: RemoveFileDynamicFieldChannelsDocument;
	private addTicketsChannelsMutation: AddTicketsChannelsDocument;
	private removeTicketsChannelsMutation: RemoveTicketsChannelsDocument;

	constructor(private injector: Injector) {
		this.getChannelsQuery = this.injector.get(GetChannelsDocument);
		this.firstChannelsQuery = this.injector.get(FirstChannelsDocument);
		this.countChannelsQuery = this.injector.get(CountChannelsDocument);
		this.findChannelsQuery = this.injector.get(FindChannelsDocument);
		this.searchChannelsQuery = this.injector.get(SearchChannelsDocument);
		this.exportSchemaChannelsQuery = this.injector.get(ExportSchemaChannelsDocument);
		this.exportDataChannelsQuery = this.injector.get(ExportDataChannelsDocument);
		this.customQueryChannelsQuery = this.injector.get(CustomQueryChannelsDocument);
		this.customQueryIdChannelsQuery = this.injector.get(CustomQueryIdChannelsDocument);
		this.findUnassociatedSecurityGroupsChannelsQuery = this.injector.get(FindUnassociatedSecurityGroupsChannelsDocument);
		this.findAssociatedOperatorTeamSecurityGroupsChannelsQuery = this.injector.get(FindAssociatedOperatorTeamSecurityGroupsChannelsDocument);
		this.usedChannelsQuery = this.injector.get(UsedChannelsDocument);
		this.findDefaultChannelsQuery = this.injector.get(FindDefaultChannelsDocument);
		this.existChannelsQuery = this.injector.get(ExistChannelsDocument);
		this.findRecyclesChannelsQuery = this.injector.get(FindRecyclesChannelsDocument);
		this.countRecyclesChannelsQuery = this.injector.get(CountRecyclesChannelsDocument);
		this.readOnlyChannelsQuery = this.injector.get(ReadOnlyChannelsDocument);
		this.findArchivedChannelsQuery = this.injector.get(FindArchivedChannelsDocument);
		this.countAllChannelsQuery = this.injector.get(CountAllChannelsDocument);
		this.findDynamicPropertyFieldsChannelsQuery = this.injector.get(FindDynamicPropertyFieldsChannelsDocument);
		this.findUnassociatedTicketsChannelsQuery = this.injector.get(FindUnassociatedTicketsChannelsDocument);
		this.insertChannelsMutation = this.injector.get(InsertChannelsDocument);
		this.updateChannelsMutation = this.injector.get(UpdateChannelsDocument);
		this.importDataChannelsMutation = this.injector.get(ImportDataChannelsDocument);
		this.updateManyChannelsMutation = this.injector.get(UpdateManyChannelsDocument);
		this.deleteChannelsMutation = this.injector.get(DeleteChannelsDocument);
		this.orderChannelsMutation = this.injector.get(OrderChannelsDocument);
		this.addSecurityGroupChannelsMutation = this.injector.get(AddSecurityGroupChannelsDocument);
		this.removeSecurityGroupChannelsMutation = this.injector.get(RemoveSecurityGroupChannelsDocument);
		this.addOperatorTeamSecurityGroupChannelsMutation = this.injector.get(AddOperatorTeamSecurityGroupChannelsDocument);
		this.removeOperatorTeamSecurityGroupChannelsMutation = this.injector.get(RemoveOperatorTeamSecurityGroupChannelsDocument);
		this.restoreChannelsMutation = this.injector.get(RestoreChannelsDocument);
		this.recycleChannelsMutation = this.injector.get(RecycleChannelsDocument);
		this.restoreArchivedChannelsMutation = this.injector.get(RestoreArchivedChannelsDocument);
		this.archivedChannelsMutation = this.injector.get(ArchivedChannelsDocument);
		this.addFileDynamicFieldChannelsMutation = this.injector.get(AddFileDynamicFieldChannelsDocument);
		this.removeFileDynamicFieldChannelsMutation = this.injector.get(RemoveFileDynamicFieldChannelsDocument);
		this.addTicketsChannelsMutation = this.injector.get(AddTicketsChannelsDocument);
		this.removeTicketsChannelsMutation = this.injector.get(RemoveTicketsChannelsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfChannel> {
    
    		let variables: GetChannelsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.get));
            }
            else{
                let result:ServiceSingleResultOfChannel={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfChannel,
		@Args('filter?') filter?: FilterOfChannel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfChannel> {
    
    		let variables: FirstChannelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfChannel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountChannelsBaseVariables = {
    			filter: filter
    		}
    				return this.countChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfChannel,
		@Args('filter?') filter?: FilterOfChannel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfChannel> {
    
    		let variables: FindChannelsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfChannel,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfChannel> {
    
    		let variables: SearchChannelsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaChannelsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfChannel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataChannelsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfChannel,
		@Args('filter?') filter?: FilterOfChannel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfChannel> {
    
    		let variables: CustomQueryChannelsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfChannel,
		@Args('filter?') filter?: FilterOfChannel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfChannel> {
    
    		let variables: CustomQueryIdChannelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsChannelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les equipes d'operateur via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedOperatorTeamSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOperatorTeam> {
    
    		let variables: FindAssociatedOperatorTeamSecurityGroupsChannelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedOperatorTeamSecurityGroupsChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.findAssociatedOperatorTeamSecurityGroups));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedChannelsBaseVariables = {
    			ids: ids
    		}
    				return this.usedChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.used));
    	}

    	/**  */
    	@InjectArgs
    	public findDefault(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfChannel> {
    
    		let variables: FindDefaultChannelsBaseVariables = {
    			key: key
    		}
    				return this.findDefaultChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.findDefault));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistChannelsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfChannel,
		@Args('filter?') filter?: FilterOfChannel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfChannel> {
    
    		let variables: FindRecyclesChannelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfChannel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesChannelsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyChannelsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfChannel,
		@Args('filter?') filter?: FilterOfChannel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfChannel> {
    
    		let variables: FindArchivedChannelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfChannel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllChannelsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfChannel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsChannelsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedTickets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfTicket,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicket,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfTicket> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('tickets', fields, null, [
			GqlSubFieldArg.create('filterOfTickets', 'filter'),
			GqlSubFieldArg.create('optionsOfTickets', 'options'),
		]),
		])
		extendedVariables['filterOfTickets'] = filter;
		extendedVariables['optionsOfTickets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.tickets));
            }
            else{
                let result: ServiceListResultOfTicket = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicket,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicket> {
    
    		let variables: FindUnassociatedTicketsChannelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedTicketsChannelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.channels.findUnassociatedTickets));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ChannelInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfChannel> {
    
    		let variables: InsertChannelsBaseVariables = {
    			entity: entity
    		}
    				return this.insertChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfChannel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfChannel> {
    
    		let variables: UpdateChannelsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataChannelsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfChannel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyChannelsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteChannelsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public order(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: OrderChannelsBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.orderChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.order));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupChannelsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupChannelsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorTeamSecurityGroupChannelsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds,
			isNew: isNew
    		}
    				return this.addOperatorTeamSecurityGroupChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.addOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorTeamSecurityGroupChannelsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds
    		}
    				return this.removeOperatorTeamSecurityGroupChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.removeOperatorTeamSecurityGroup));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreChannelsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleChannelsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedChannelsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedChannelsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldChannelsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldChannelsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketIds') ticketIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddTicketsChannelsBaseVariables = {
    			id: id,
			ticketIds: ticketIds
    		}
    				return this.addTicketsChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.addTickets));
    	}

    	/**  */
    	@InjectArgs
    	public removeTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketIds') ticketIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveTicketsChannelsBaseVariables = {
    			ticketIds: ticketIds
    		}
    				return this.removeTicketsChannelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.channels.removeTickets));
    	}
    
}