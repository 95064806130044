import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetCampaignHistoriesBaseVariables, FirstCampaignHistoriesBaseVariables, CountCampaignHistoriesBaseVariables, FindCampaignHistoriesBaseVariables, SearchCampaignHistoriesBaseVariables, ExportSchemaCampaignHistoriesBaseVariables, ExportDataCampaignHistoriesBaseVariables, CustomQueryCampaignHistoriesBaseVariables, CustomQueryIdCampaignHistoriesBaseVariables, UsedCampaignHistoriesBaseVariables, ExistCampaignHistoriesBaseVariables, InsertCampaignHistoriesBaseVariables, UpdateCampaignHistoriesBaseVariables, ImportDataCampaignHistoriesBaseVariables, UpdateManyCampaignHistoriesBaseVariables, DeleteCampaignHistoriesBaseVariables } from '../gqls'
import { GetCampaignHistoriesDocument, FirstCampaignHistoriesDocument, CountCampaignHistoriesDocument, FindCampaignHistoriesDocument, SearchCampaignHistoriesDocument, ExportSchemaCampaignHistoriesDocument, ExportDataCampaignHistoriesDocument, CustomQueryCampaignHistoriesDocument, CustomQueryIdCampaignHistoriesDocument, UsedCampaignHistoriesDocument, ExistCampaignHistoriesDocument, InsertCampaignHistoriesDocument, UpdateCampaignHistoriesDocument, ImportDataCampaignHistoriesDocument, UpdateManyCampaignHistoriesDocument, DeleteCampaignHistoriesDocument } from '../gqls'
import { ServiceSingleResultOfCampaignHistory, QueryContextOfCampaignHistory, FilterOfCampaignHistory, ServiceSingleResultOfInt64, ServiceListResultOfCampaignHistory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, CampaignHistoryInput, FieldUpdateOperatorOfCampaignHistory } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class CampaignHistoryBaseService {
    
public modelName = 'campaignHistory';
public modelPluralName = 'campaignHistories';

	private getCampaignHistoriesQuery: GetCampaignHistoriesDocument;
	private firstCampaignHistoriesQuery: FirstCampaignHistoriesDocument;
	private countCampaignHistoriesQuery: CountCampaignHistoriesDocument;
	private findCampaignHistoriesQuery: FindCampaignHistoriesDocument;
	private searchCampaignHistoriesQuery: SearchCampaignHistoriesDocument;
	private exportSchemaCampaignHistoriesQuery: ExportSchemaCampaignHistoriesDocument;
	private exportDataCampaignHistoriesQuery: ExportDataCampaignHistoriesDocument;
	private customQueryCampaignHistoriesQuery: CustomQueryCampaignHistoriesDocument;
	private customQueryIdCampaignHistoriesQuery: CustomQueryIdCampaignHistoriesDocument;
	private usedCampaignHistoriesQuery: UsedCampaignHistoriesDocument;
	private existCampaignHistoriesQuery: ExistCampaignHistoriesDocument;
	private insertCampaignHistoriesMutation: InsertCampaignHistoriesDocument;
	private updateCampaignHistoriesMutation: UpdateCampaignHistoriesDocument;
	private importDataCampaignHistoriesMutation: ImportDataCampaignHistoriesDocument;
	private updateManyCampaignHistoriesMutation: UpdateManyCampaignHistoriesDocument;
	private deleteCampaignHistoriesMutation: DeleteCampaignHistoriesDocument;

	constructor(private injector: Injector) {
		this.getCampaignHistoriesQuery = this.injector.get(GetCampaignHistoriesDocument);
		this.firstCampaignHistoriesQuery = this.injector.get(FirstCampaignHistoriesDocument);
		this.countCampaignHistoriesQuery = this.injector.get(CountCampaignHistoriesDocument);
		this.findCampaignHistoriesQuery = this.injector.get(FindCampaignHistoriesDocument);
		this.searchCampaignHistoriesQuery = this.injector.get(SearchCampaignHistoriesDocument);
		this.exportSchemaCampaignHistoriesQuery = this.injector.get(ExportSchemaCampaignHistoriesDocument);
		this.exportDataCampaignHistoriesQuery = this.injector.get(ExportDataCampaignHistoriesDocument);
		this.customQueryCampaignHistoriesQuery = this.injector.get(CustomQueryCampaignHistoriesDocument);
		this.customQueryIdCampaignHistoriesQuery = this.injector.get(CustomQueryIdCampaignHistoriesDocument);
		this.usedCampaignHistoriesQuery = this.injector.get(UsedCampaignHistoriesDocument);
		this.existCampaignHistoriesQuery = this.injector.get(ExistCampaignHistoriesDocument);
		this.insertCampaignHistoriesMutation = this.injector.get(InsertCampaignHistoriesDocument);
		this.updateCampaignHistoriesMutation = this.injector.get(UpdateCampaignHistoriesDocument);
		this.importDataCampaignHistoriesMutation = this.injector.get(ImportDataCampaignHistoriesDocument);
		this.updateManyCampaignHistoriesMutation = this.injector.get(UpdateManyCampaignHistoriesDocument);
		this.deleteCampaignHistoriesMutation = this.injector.get(DeleteCampaignHistoriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCampaignHistory> {
    
    		let variables: GetCampaignHistoriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getCampaignHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.get));
            }
            else{
                let result:ServiceSingleResultOfCampaignHistory={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCampaignHistory,
		@Args('filter?') filter?: FilterOfCampaignHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCampaignHistory> {
    
    		let variables: FirstCampaignHistoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstCampaignHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCampaignHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountCampaignHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countCampaignHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCampaignHistory,
		@Args('filter?') filter?: FilterOfCampaignHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCampaignHistory> {
    
    		let variables: FindCampaignHistoriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findCampaignHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfCampaignHistory,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCampaignHistory> {
    
    		let variables: SearchCampaignHistoriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchCampaignHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaCampaignHistoriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaCampaignHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCampaignHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataCampaignHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataCampaignHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfCampaignHistory,
		@Args('filter?') filter?: FilterOfCampaignHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCampaignHistory> {
    
    		let variables: CustomQueryCampaignHistoriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryCampaignHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfCampaignHistory,
		@Args('filter?') filter?: FilterOfCampaignHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCampaignHistory> {
    
    		let variables: CustomQueryIdCampaignHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdCampaignHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedCampaignHistoriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedCampaignHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistCampaignHistoriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existCampaignHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: CampaignHistoryInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCampaignHistory> {
    
    		let variables: InsertCampaignHistoriesBaseVariables = {
    			entity: entity
    		}
    				return this.insertCampaignHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfCampaignHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCampaignHistory> {
    
    		let variables: UpdateCampaignHistoriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateCampaignHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataCampaignHistoriesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataCampaignHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfCampaignHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyCampaignHistoriesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyCampaignHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteCampaignHistoriesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteCampaignHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaignHistories.delete));
    	}
    
}