import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { OneSettingBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Authorize('clarilog.one-settings')
export class OneSettingCoreService extends OneSettingBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
