import { OrganizationBaseService } from '../service-bases';
import { EventEmitter, Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { LocalizeService } from '@clarilog/core/modules/globalize';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import {
  FilterOfFileModel,
  QueryContextOfFileModel,
  ServiceListResultOfFileModel,
  ServiceSingleResultOfOrganization,
  ServiceSingleResultOfString,
} from '../types';
import { Observable } from 'rxjs/internal/Observable';
import { GqlField, GqlFields, GqlSubField } from '../helpers';

@Injectable({ providedIn: 'root' })
@Authorize({
  policies: ['clarilog.administrator', 'help-desk-operator.help-me'],
  operator: 'or',
})
export class OrganizationCoreService extends OrganizationBaseService {
  constructor(injector: Injector, private localizeService: LocalizeService) {
    super(injector);
  }

  public locales(): any[] {
    return this.localizeService.getLocales();
  }

  @InjectArgs
  public getOrganisationLocalize(): Observable<ServiceSingleResultOfOrganization> {
    let extendedVariables = {};
    let langFields = OrganizationCoreService.getLanguageFields();

    return this.organizationLocalize(langFields, extendedVariables);
  }

  @InjectArgs
  public getIdleTimer(): Observable<ServiceSingleResultOfOrganization> {
    let extendedVariables = {};
    let idleTimerFields = OrganizationCoreService.getIdleTimerFields();

    return this.idleTimer(idleTimerFields, extendedVariables);
  }
  public static getIdleTimerFields(): GqlFields {
    return [GqlSubField.create('data', [GqlField.create('idleTimer')])];
  }

  public static getLanguageFields(): GqlFields {
    return [GqlSubField.create('data', [GqlField.create('lang')])];
  }
}
