<clc-work-sub-form
  [rawModel]="subFormModel"
  [parentModel]="state"
  [(isPopupOpen)]="isPopupOpen"
  [service]="service"
  (onSaved)="saved($event)"
  [fieldName]="fieldName"
  [key]="key"
  [saveTitle]="'globals/send'"
  [saveSplitButton]="false"
></clc-work-sub-form>
<cl-work-page>
  <cl-work-page-header>
    <cl-work-page-header-content style="border-left: 0px !important">
      <cl-work-page-header-content-body>
        <cl-toolbar class="action">
          <cl-toolbar-items>
            <cl-toolbar-item-button
              [text]="'new' | translate"
              [visible]="!readOnly"
              icon="fas fa-plus"
              (onClick)="newEmail($event)"
              [disabled]="disabledEmailfunction"
            >
            </cl-toolbar-item-button>
            <cl-toolbar-item-button
              [text]="'reply' | translate"
              [visible]="!readOnly"
              icon="fas fa-reply"
              [disabled]="
                selectedKeys.length !== 1 || disabledEmailfunction === true
                  ? true
                  : false
              "
              (onClick)="reply($event)"
            >
            </cl-toolbar-item-button>
            <cl-toolbar-item-button
              [text]="'transfer' | translate"
              [visible]="!readOnly"
              icon="fas fa-retweet"
              [disabled]="
                selectedKeys.length !== 1 || disabledEmailfunction === true
                  ? true
                  : false
              "
              (onClick)="transfer($event)"
            >
            </cl-toolbar-item-button>
            <cl-toolbar-item-wait-button
              [text]="'sendBack' | translate"
              [visible]="!readOnly"
              [icon]="iconSendBack"
              [disabled]="
                selectedKeys.length !== 1 || disabledEmailfunction === true
                  ? true
                  : false
              "
              [wait]="waitBtnSendBack"
              (onClick)="sendBack($event)"
            >
            </cl-toolbar-item-wait-button>
          </cl-toolbar-items>
        </cl-toolbar>
      </cl-work-page-header-content-body>
    </cl-work-page-header-content>
  </cl-work-page-header>
  <cl-work-page-left> </cl-work-page-left>
  <cl-work-page-content>
    <clc-list
      *ngIf="currentSource != undefined"
      [source]="currentSource"
      (onBeforeRefresh)="removeNotificationEmail()"
      [multiple]="true"
      [columns]="columns"
      [masterDetail]="masterDetail"
      [(selectedKeys)]="selectedKeys"
      [(selectedData)]="selectedData"
      [modelState]="state"
    >
    </clc-list>
  </cl-work-page-content>
</cl-work-page>
