import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../services/translate/translate.service';

/**
 * Permet de traduire une clé en sa représentation traduite
 *
 * @export
 * @class TranslatePipe
 * @implements {PipeTransform}
 * @example {{ 'key1' | translate }}
 * @example {{ 'key1' | translate:{param1:'value'} }}
 * @example {{ 'key1' | translate:{param1:'value'}:'en' }}
 */
@Pipe({
    name: 'translate'
})
export class TranslatePipe implements PipeTransform {
    constructor() { }
    transform(value: string, ...args: any[]): any {
      return TranslateService.get(value,...args);
    }
}
