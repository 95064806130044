import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetEsxCredentialsBaseVariables, FirstEsxCredentialsBaseVariables, CountEsxCredentialsBaseVariables, FindEsxCredentialsBaseVariables, SearchEsxCredentialsBaseVariables, ExportSchemaEsxCredentialsBaseVariables, ExportDataEsxCredentialsBaseVariables, CustomQueryEsxCredentialsBaseVariables, CustomQueryIdEsxCredentialsBaseVariables, UsedEsxCredentialsBaseVariables, ExistEsxCredentialsBaseVariables, FindRecyclesEsxCredentialsBaseVariables, CountRecyclesEsxCredentialsBaseVariables, ReadOnlyEsxCredentialsBaseVariables, FindArchivedEsxCredentialsBaseVariables, CountAllEsxCredentialsBaseVariables, FindDynamicPropertyFieldsEsxCredentialsBaseVariables, FindUnassociatedScanConfigurationsEsxCredentialsBaseVariables, FindUnassociatedInventoryHistoriesEsxCredentialsBaseVariables, FindUnassociatedScanByLdapsEsxCredentialsBaseVariables, InsertEsxCredentialsBaseVariables, UpdateEsxCredentialsBaseVariables, ImportDataEsxCredentialsBaseVariables, UpdateManyEsxCredentialsBaseVariables, DeleteEsxCredentialsBaseVariables, RestoreEsxCredentialsBaseVariables, RecycleEsxCredentialsBaseVariables, RestoreArchivedEsxCredentialsBaseVariables, ArchivedEsxCredentialsBaseVariables, AddFileDynamicFieldEsxCredentialsBaseVariables, RemoveFileDynamicFieldEsxCredentialsBaseVariables } from '../gqls'
import { GetEsxCredentialsDocument, FirstEsxCredentialsDocument, CountEsxCredentialsDocument, FindEsxCredentialsDocument, SearchEsxCredentialsDocument, ExportSchemaEsxCredentialsDocument, ExportDataEsxCredentialsDocument, CustomQueryEsxCredentialsDocument, CustomQueryIdEsxCredentialsDocument, UsedEsxCredentialsDocument, ExistEsxCredentialsDocument, FindRecyclesEsxCredentialsDocument, CountRecyclesEsxCredentialsDocument, ReadOnlyEsxCredentialsDocument, FindArchivedEsxCredentialsDocument, CountAllEsxCredentialsDocument, FindDynamicPropertyFieldsEsxCredentialsDocument, FindUnassociatedScanConfigurationsEsxCredentialsDocument, FindUnassociatedInventoryHistoriesEsxCredentialsDocument, FindUnassociatedScanByLdapsEsxCredentialsDocument, InsertEsxCredentialsDocument, UpdateEsxCredentialsDocument, ImportDataEsxCredentialsDocument, UpdateManyEsxCredentialsDocument, DeleteEsxCredentialsDocument, RestoreEsxCredentialsDocument, RecycleEsxCredentialsDocument, RestoreArchivedEsxCredentialsDocument, ArchivedEsxCredentialsDocument, AddFileDynamicFieldEsxCredentialsDocument, RemoveFileDynamicFieldEsxCredentialsDocument } from '../gqls'
import { ServiceSingleResultOfEsxCredential, QueryContextOfEsxCredential, FilterOfEsxCredential, ServiceSingleResultOfInt64, ServiceListResultOfEsxCredential, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfEsxCredential, QueryContextOfScanConfiguration, FilterOfScanConfiguration, ServiceListResultOfScanConfiguration, QueryContextOfInventoryHistory, FilterOfInventoryHistory, ServiceListResultOfInventoryHistory, QueryContextOfScanByLdap, FilterOfScanByLdap, ServiceListResultOfScanByLdap, EsxCredentialInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class EsxCredentialBaseService {
    
public modelName = 'esxCredential';
public modelPluralName = 'esxCredentials';

	private getEsxCredentialsQuery: GetEsxCredentialsDocument;
	private firstEsxCredentialsQuery: FirstEsxCredentialsDocument;
	private countEsxCredentialsQuery: CountEsxCredentialsDocument;
	private findEsxCredentialsQuery: FindEsxCredentialsDocument;
	private searchEsxCredentialsQuery: SearchEsxCredentialsDocument;
	private exportSchemaEsxCredentialsQuery: ExportSchemaEsxCredentialsDocument;
	private exportDataEsxCredentialsQuery: ExportDataEsxCredentialsDocument;
	private customQueryEsxCredentialsQuery: CustomQueryEsxCredentialsDocument;
	private customQueryIdEsxCredentialsQuery: CustomQueryIdEsxCredentialsDocument;
	private usedEsxCredentialsQuery: UsedEsxCredentialsDocument;
	private existEsxCredentialsQuery: ExistEsxCredentialsDocument;
	private findRecyclesEsxCredentialsQuery: FindRecyclesEsxCredentialsDocument;
	private countRecyclesEsxCredentialsQuery: CountRecyclesEsxCredentialsDocument;
	private readOnlyEsxCredentialsQuery: ReadOnlyEsxCredentialsDocument;
	private findArchivedEsxCredentialsQuery: FindArchivedEsxCredentialsDocument;
	private countAllEsxCredentialsQuery: CountAllEsxCredentialsDocument;
	private findDynamicPropertyFieldsEsxCredentialsQuery: FindDynamicPropertyFieldsEsxCredentialsDocument;
	private findUnassociatedScanConfigurationsEsxCredentialsQuery: FindUnassociatedScanConfigurationsEsxCredentialsDocument;
	private findUnassociatedInventoryHistoriesEsxCredentialsQuery: FindUnassociatedInventoryHistoriesEsxCredentialsDocument;
	private findUnassociatedScanByLdapsEsxCredentialsQuery: FindUnassociatedScanByLdapsEsxCredentialsDocument;
	private insertEsxCredentialsMutation: InsertEsxCredentialsDocument;
	private updateEsxCredentialsMutation: UpdateEsxCredentialsDocument;
	private importDataEsxCredentialsMutation: ImportDataEsxCredentialsDocument;
	private updateManyEsxCredentialsMutation: UpdateManyEsxCredentialsDocument;
	private deleteEsxCredentialsMutation: DeleteEsxCredentialsDocument;
	private restoreEsxCredentialsMutation: RestoreEsxCredentialsDocument;
	private recycleEsxCredentialsMutation: RecycleEsxCredentialsDocument;
	private restoreArchivedEsxCredentialsMutation: RestoreArchivedEsxCredentialsDocument;
	private archivedEsxCredentialsMutation: ArchivedEsxCredentialsDocument;
	private addFileDynamicFieldEsxCredentialsMutation: AddFileDynamicFieldEsxCredentialsDocument;
	private removeFileDynamicFieldEsxCredentialsMutation: RemoveFileDynamicFieldEsxCredentialsDocument;

	constructor(private injector: Injector) {
		this.getEsxCredentialsQuery = this.injector.get(GetEsxCredentialsDocument);
		this.firstEsxCredentialsQuery = this.injector.get(FirstEsxCredentialsDocument);
		this.countEsxCredentialsQuery = this.injector.get(CountEsxCredentialsDocument);
		this.findEsxCredentialsQuery = this.injector.get(FindEsxCredentialsDocument);
		this.searchEsxCredentialsQuery = this.injector.get(SearchEsxCredentialsDocument);
		this.exportSchemaEsxCredentialsQuery = this.injector.get(ExportSchemaEsxCredentialsDocument);
		this.exportDataEsxCredentialsQuery = this.injector.get(ExportDataEsxCredentialsDocument);
		this.customQueryEsxCredentialsQuery = this.injector.get(CustomQueryEsxCredentialsDocument);
		this.customQueryIdEsxCredentialsQuery = this.injector.get(CustomQueryIdEsxCredentialsDocument);
		this.usedEsxCredentialsQuery = this.injector.get(UsedEsxCredentialsDocument);
		this.existEsxCredentialsQuery = this.injector.get(ExistEsxCredentialsDocument);
		this.findRecyclesEsxCredentialsQuery = this.injector.get(FindRecyclesEsxCredentialsDocument);
		this.countRecyclesEsxCredentialsQuery = this.injector.get(CountRecyclesEsxCredentialsDocument);
		this.readOnlyEsxCredentialsQuery = this.injector.get(ReadOnlyEsxCredentialsDocument);
		this.findArchivedEsxCredentialsQuery = this.injector.get(FindArchivedEsxCredentialsDocument);
		this.countAllEsxCredentialsQuery = this.injector.get(CountAllEsxCredentialsDocument);
		this.findDynamicPropertyFieldsEsxCredentialsQuery = this.injector.get(FindDynamicPropertyFieldsEsxCredentialsDocument);
		this.findUnassociatedScanConfigurationsEsxCredentialsQuery = this.injector.get(FindUnassociatedScanConfigurationsEsxCredentialsDocument);
		this.findUnassociatedInventoryHistoriesEsxCredentialsQuery = this.injector.get(FindUnassociatedInventoryHistoriesEsxCredentialsDocument);
		this.findUnassociatedScanByLdapsEsxCredentialsQuery = this.injector.get(FindUnassociatedScanByLdapsEsxCredentialsDocument);
		this.insertEsxCredentialsMutation = this.injector.get(InsertEsxCredentialsDocument);
		this.updateEsxCredentialsMutation = this.injector.get(UpdateEsxCredentialsDocument);
		this.importDataEsxCredentialsMutation = this.injector.get(ImportDataEsxCredentialsDocument);
		this.updateManyEsxCredentialsMutation = this.injector.get(UpdateManyEsxCredentialsDocument);
		this.deleteEsxCredentialsMutation = this.injector.get(DeleteEsxCredentialsDocument);
		this.restoreEsxCredentialsMutation = this.injector.get(RestoreEsxCredentialsDocument);
		this.recycleEsxCredentialsMutation = this.injector.get(RecycleEsxCredentialsDocument);
		this.restoreArchivedEsxCredentialsMutation = this.injector.get(RestoreArchivedEsxCredentialsDocument);
		this.archivedEsxCredentialsMutation = this.injector.get(ArchivedEsxCredentialsDocument);
		this.addFileDynamicFieldEsxCredentialsMutation = this.injector.get(AddFileDynamicFieldEsxCredentialsDocument);
		this.removeFileDynamicFieldEsxCredentialsMutation = this.injector.get(RemoveFileDynamicFieldEsxCredentialsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEsxCredential> {
    
    		let variables: GetEsxCredentialsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.get));
            }
            else{
                let result:ServiceSingleResultOfEsxCredential={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEsxCredential,
		@Args('filter?') filter?: FilterOfEsxCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEsxCredential> {
    
    		let variables: FirstEsxCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEsxCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountEsxCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEsxCredential,
		@Args('filter?') filter?: FilterOfEsxCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEsxCredential> {
    
    		let variables: FindEsxCredentialsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfEsxCredential,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEsxCredential> {
    
    		let variables: SearchEsxCredentialsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaEsxCredentialsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEsxCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataEsxCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfEsxCredential,
		@Args('filter?') filter?: FilterOfEsxCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEsxCredential> {
    
    		let variables: CustomQueryEsxCredentialsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfEsxCredential,
		@Args('filter?') filter?: FilterOfEsxCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEsxCredential> {
    
    		let variables: CustomQueryIdEsxCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedEsxCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.usedEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistEsxCredentialsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEsxCredential,
		@Args('filter?') filter?: FilterOfEsxCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEsxCredential> {
    
    		let variables: FindRecyclesEsxCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEsxCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesEsxCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyEsxCredentialsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEsxCredential,
		@Args('filter?') filter?: FilterOfEsxCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEsxCredential> {
    
    		let variables: FindArchivedEsxCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEsxCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllEsxCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfEsxCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsEsxCredentialsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedScanConfigurations(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanConfiguration> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanConfigurations', fields, null, [
			GqlSubFieldArg.create('filterOfScanConfigurations', 'filter'),
			GqlSubFieldArg.create('optionsOfScanConfigurations', 'options'),
		]),
		])
		extendedVariables['filterOfScanConfigurations'] = filter;
		extendedVariables['optionsOfScanConfigurations'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanConfigurations));
            }
            else{
                let result: ServiceListResultOfScanConfiguration = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindUnassociatedScanConfigurationsEsxCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanConfigurationsEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.findUnassociatedScanConfigurations));
    	}

	@InjectArgs
	public findAssociatedInventoryHistories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfInventoryHistory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('inventoryHistories', fields, null, [
			GqlSubFieldArg.create('filterOfInventoryHistories', 'filter'),
			GqlSubFieldArg.create('optionsOfInventoryHistories', 'options'),
		]),
		])
		extendedVariables['filterOfInventoryHistories'] = filter;
		extendedVariables['optionsOfInventoryHistories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.inventoryHistories));
            }
            else{
                let result: ServiceListResultOfInventoryHistory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedInventoryHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindUnassociatedInventoryHistoriesEsxCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedInventoryHistoriesEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.findUnassociatedInventoryHistories));
    	}

	@InjectArgs
	public findAssociatedScanByLdaps(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanByLdap> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanByLdaps', fields, null, [
			GqlSubFieldArg.create('filterOfScanByLdaps', 'filter'),
			GqlSubFieldArg.create('optionsOfScanByLdaps', 'options'),
		]),
		])
		extendedVariables['filterOfScanByLdaps'] = filter;
		extendedVariables['optionsOfScanByLdaps'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanByLdaps));
            }
            else{
                let result: ServiceListResultOfScanByLdap = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanByLdaps(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByLdap> {
    
    		let variables: FindUnassociatedScanByLdapsEsxCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanByLdapsEsxCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.findUnassociatedScanByLdaps));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: EsxCredentialInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEsxCredential> {
    
    		let variables: InsertEsxCredentialsBaseVariables = {
    			entity: entity
    		}
    				return this.insertEsxCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfEsxCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEsxCredential> {
    
    		let variables: UpdateEsxCredentialsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateEsxCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataEsxCredentialsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataEsxCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfEsxCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyEsxCredentialsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyEsxCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteEsxCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteEsxCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreEsxCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreEsxCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleEsxCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleEsxCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedEsxCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedEsxCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedEsxCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedEsxCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldEsxCredentialsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldEsxCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldEsxCredentialsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldEsxCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.esxCredentials.removeFileDynamicField));
    	}
    
}