<cl-work-page>
  <cl-work-page-header>
    <cl-work-page-header-content style="border-left: 0px !important">
      <cl-work-page-header-content-body>
        <cl-toolbar>
          <cl-toolbar-items>
            @for (item of toolbarItems; track item){ @switch(item.buttonType){
            @case ("button") {
            <cl-toolbar-item-button
              id="item.name"
              [visible]="true"
              [text]="item.label"
              [icon]="item.icon"
              (onClick)="buttonAction($event, item)"
              [disabled]="
                item.disabledBySelectedKey === true
                  ? selectedKeys.length !== 1
                  : false
              "
            ></cl-toolbar-item-button>
            } @case("button-dropdown"){

            <cl-toolbar-item-dropdown-button
              [visible]="item.dropdownSource !== undefined"
              [text]="item.label"
              [icon]="item.icon"
              keyExpr="key"
              displayExpr="name"
              [source]="item.dropdownSource"
              [disabled]="
                item.disabledBySelectedKey === true
                  ? selectedKeys.length !== 1
                  : false
              "
              (onItemClick)="buttonAction($event, item)"
            >
            </cl-toolbar-item-dropdown-button>
            } } }
          </cl-toolbar-items>
        </cl-toolbar>
      </cl-work-page-header-content-body>
    </cl-work-page-header-content>
  </cl-work-page-header>

  <cl-work-page-content>
    <clc-list
      class="list"
      [source]="currentSource"
      [type]="typeMode === undefined ? 'Grid' : typeMode"
      [columns]="columns"
      [keyExpr]="keyExpr"
      [multiple]="true"
      [parentIdExpr]="parentIdExpr"
      [masterDetail]="masterDetail"
      [recursive]="recursive"
      [(selectedKeys)]="selectedKeys"
      [(selectedData)]="selectedData"
      [canSelect]="canSelect"
      [modelState]="modelState"
    >
    </clc-list>
  </cl-work-page-content>
</cl-work-page>
