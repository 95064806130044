import { AuthentificationService } from '../services/authentification/authentification.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private _authentificationService: AuthentificationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq: HttpRequest<any> = undefined;
    const tokenValue = this._authentificationService.getAuthorizationHeaderValue();
    authReq = req.clone({ setHeaders: { Authorization: tokenValue } });
    return next.handle(authReq);
  }

}