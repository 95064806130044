import { LoanStatusBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql';

@Injectable({ providedIn: 'root' })
@Authorize('loan.manage-status')
export class LoanStatusCoreService extends LoanStatusBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
