import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWindowsFirewallsBaseVariables, FirstWindowsFirewallsBaseVariables, CountWindowsFirewallsBaseVariables, FindWindowsFirewallsBaseVariables, SearchWindowsFirewallsBaseVariables, ExportSchemaWindowsFirewallsBaseVariables, ExportDataWindowsFirewallsBaseVariables, CustomQueryWindowsFirewallsBaseVariables, CustomQueryIdWindowsFirewallsBaseVariables, UsedWindowsFirewallsBaseVariables, ExistWindowsFirewallsBaseVariables } from '../gqls'
import { GetWindowsFirewallsDocument, FirstWindowsFirewallsDocument, CountWindowsFirewallsDocument, FindWindowsFirewallsDocument, SearchWindowsFirewallsDocument, ExportSchemaWindowsFirewallsDocument, ExportDataWindowsFirewallsDocument, CustomQueryWindowsFirewallsDocument, CustomQueryIdWindowsFirewallsDocument, UsedWindowsFirewallsDocument, ExistWindowsFirewallsDocument } from '../gqls'
import { ServiceSingleResultOfWindowsFirewall, QueryContextOfWindowsFirewall, FilterOfWindowsFirewall, ServiceSingleResultOfInt64, ServiceListResultOfWindowsFirewall, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WindowsFirewallBaseService {
    
public modelName = 'windowsFirewall';
public modelPluralName = 'windowsFirewalls';

	private getWindowsFirewallsQuery: GetWindowsFirewallsDocument;
	private firstWindowsFirewallsQuery: FirstWindowsFirewallsDocument;
	private countWindowsFirewallsQuery: CountWindowsFirewallsDocument;
	private findWindowsFirewallsQuery: FindWindowsFirewallsDocument;
	private searchWindowsFirewallsQuery: SearchWindowsFirewallsDocument;
	private exportSchemaWindowsFirewallsQuery: ExportSchemaWindowsFirewallsDocument;
	private exportDataWindowsFirewallsQuery: ExportDataWindowsFirewallsDocument;
	private customQueryWindowsFirewallsQuery: CustomQueryWindowsFirewallsDocument;
	private customQueryIdWindowsFirewallsQuery: CustomQueryIdWindowsFirewallsDocument;
	private usedWindowsFirewallsQuery: UsedWindowsFirewallsDocument;
	private existWindowsFirewallsQuery: ExistWindowsFirewallsDocument;

	constructor(private injector: Injector) {
		this.getWindowsFirewallsQuery = this.injector.get(GetWindowsFirewallsDocument);
		this.firstWindowsFirewallsQuery = this.injector.get(FirstWindowsFirewallsDocument);
		this.countWindowsFirewallsQuery = this.injector.get(CountWindowsFirewallsDocument);
		this.findWindowsFirewallsQuery = this.injector.get(FindWindowsFirewallsDocument);
		this.searchWindowsFirewallsQuery = this.injector.get(SearchWindowsFirewallsDocument);
		this.exportSchemaWindowsFirewallsQuery = this.injector.get(ExportSchemaWindowsFirewallsDocument);
		this.exportDataWindowsFirewallsQuery = this.injector.get(ExportDataWindowsFirewallsDocument);
		this.customQueryWindowsFirewallsQuery = this.injector.get(CustomQueryWindowsFirewallsDocument);
		this.customQueryIdWindowsFirewallsQuery = this.injector.get(CustomQueryIdWindowsFirewallsDocument);
		this.usedWindowsFirewallsQuery = this.injector.get(UsedWindowsFirewallsDocument);
		this.existWindowsFirewallsQuery = this.injector.get(ExistWindowsFirewallsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWindowsFirewall> {
    
    		let variables: GetWindowsFirewallsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWindowsFirewallsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.windowsFirewalls.get));
            }
            else{
                let result:ServiceSingleResultOfWindowsFirewall={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWindowsFirewall,
		@Args('filter?') filter?: FilterOfWindowsFirewall,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWindowsFirewall> {
    
    		let variables: FirstWindowsFirewallsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWindowsFirewallsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.windowsFirewalls.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWindowsFirewall,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWindowsFirewallsBaseVariables = {
    			filter: filter
    		}
    				return this.countWindowsFirewallsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.windowsFirewalls.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWindowsFirewall,
		@Args('filter?') filter?: FilterOfWindowsFirewall,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWindowsFirewall> {
    
    		let variables: FindWindowsFirewallsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWindowsFirewallsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.windowsFirewalls.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWindowsFirewall,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWindowsFirewall> {
    
    		let variables: SearchWindowsFirewallsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWindowsFirewallsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.windowsFirewalls.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWindowsFirewallsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWindowsFirewallsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.windowsFirewalls.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWindowsFirewall,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWindowsFirewallsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWindowsFirewallsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.windowsFirewalls.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWindowsFirewall,
		@Args('filter?') filter?: FilterOfWindowsFirewall,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWindowsFirewall> {
    
    		let variables: CustomQueryWindowsFirewallsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWindowsFirewallsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.windowsFirewalls.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWindowsFirewall,
		@Args('filter?') filter?: FilterOfWindowsFirewall,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWindowsFirewall> {
    
    		let variables: CustomQueryIdWindowsFirewallsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWindowsFirewallsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.windowsFirewalls.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWindowsFirewallsBaseVariables = {
    			ids: ids
    		}
    				return this.usedWindowsFirewallsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.windowsFirewalls.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWindowsFirewallsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWindowsFirewallsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.windowsFirewalls.exist));
    	}
    
    
}