import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowInvitationTasksBaseVariables, FirstWorkflowInvitationTasksBaseVariables, CountWorkflowInvitationTasksBaseVariables, FindWorkflowInvitationTasksBaseVariables, SearchWorkflowInvitationTasksBaseVariables, ExportSchemaWorkflowInvitationTasksBaseVariables, ExportDataWorkflowInvitationTasksBaseVariables, CustomQueryWorkflowInvitationTasksBaseVariables, CustomQueryIdWorkflowInvitationTasksBaseVariables, UsedWorkflowInvitationTasksBaseVariables, ExistWorkflowInvitationTasksBaseVariables, InsertWorkflowInvitationTasksBaseVariables, UpdateWorkflowInvitationTasksBaseVariables, ImportDataWorkflowInvitationTasksBaseVariables, UpdateManyWorkflowInvitationTasksBaseVariables, DeleteWorkflowInvitationTasksBaseVariables } from '../gqls'
import { GetWorkflowInvitationTasksDocument, FirstWorkflowInvitationTasksDocument, CountWorkflowInvitationTasksDocument, FindWorkflowInvitationTasksDocument, SearchWorkflowInvitationTasksDocument, ExportSchemaWorkflowInvitationTasksDocument, ExportDataWorkflowInvitationTasksDocument, CustomQueryWorkflowInvitationTasksDocument, CustomQueryIdWorkflowInvitationTasksDocument, UsedWorkflowInvitationTasksDocument, ExistWorkflowInvitationTasksDocument, InsertWorkflowInvitationTasksDocument, UpdateWorkflowInvitationTasksDocument, ImportDataWorkflowInvitationTasksDocument, UpdateManyWorkflowInvitationTasksDocument, DeleteWorkflowInvitationTasksDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowInvitationTask, QueryContextOfWorkflowInvitationTask, FilterOfWorkflowInvitationTask, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowInvitationTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowInvitationTaskInput, FieldUpdateOperatorOfWorkflowInvitationTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowInvitationTaskBaseService {
    
public modelName = 'workflowInvitationTask';
public modelPluralName = 'workflowInvitationTasks';

	private getWorkflowInvitationTasksQuery: GetWorkflowInvitationTasksDocument;
	private firstWorkflowInvitationTasksQuery: FirstWorkflowInvitationTasksDocument;
	private countWorkflowInvitationTasksQuery: CountWorkflowInvitationTasksDocument;
	private findWorkflowInvitationTasksQuery: FindWorkflowInvitationTasksDocument;
	private searchWorkflowInvitationTasksQuery: SearchWorkflowInvitationTasksDocument;
	private exportSchemaWorkflowInvitationTasksQuery: ExportSchemaWorkflowInvitationTasksDocument;
	private exportDataWorkflowInvitationTasksQuery: ExportDataWorkflowInvitationTasksDocument;
	private customQueryWorkflowInvitationTasksQuery: CustomQueryWorkflowInvitationTasksDocument;
	private customQueryIdWorkflowInvitationTasksQuery: CustomQueryIdWorkflowInvitationTasksDocument;
	private usedWorkflowInvitationTasksQuery: UsedWorkflowInvitationTasksDocument;
	private existWorkflowInvitationTasksQuery: ExistWorkflowInvitationTasksDocument;
	private insertWorkflowInvitationTasksMutation: InsertWorkflowInvitationTasksDocument;
	private updateWorkflowInvitationTasksMutation: UpdateWorkflowInvitationTasksDocument;
	private importDataWorkflowInvitationTasksMutation: ImportDataWorkflowInvitationTasksDocument;
	private updateManyWorkflowInvitationTasksMutation: UpdateManyWorkflowInvitationTasksDocument;
	private deleteWorkflowInvitationTasksMutation: DeleteWorkflowInvitationTasksDocument;

	constructor(private injector: Injector) {
		this.getWorkflowInvitationTasksQuery = this.injector.get(GetWorkflowInvitationTasksDocument);
		this.firstWorkflowInvitationTasksQuery = this.injector.get(FirstWorkflowInvitationTasksDocument);
		this.countWorkflowInvitationTasksQuery = this.injector.get(CountWorkflowInvitationTasksDocument);
		this.findWorkflowInvitationTasksQuery = this.injector.get(FindWorkflowInvitationTasksDocument);
		this.searchWorkflowInvitationTasksQuery = this.injector.get(SearchWorkflowInvitationTasksDocument);
		this.exportSchemaWorkflowInvitationTasksQuery = this.injector.get(ExportSchemaWorkflowInvitationTasksDocument);
		this.exportDataWorkflowInvitationTasksQuery = this.injector.get(ExportDataWorkflowInvitationTasksDocument);
		this.customQueryWorkflowInvitationTasksQuery = this.injector.get(CustomQueryWorkflowInvitationTasksDocument);
		this.customQueryIdWorkflowInvitationTasksQuery = this.injector.get(CustomQueryIdWorkflowInvitationTasksDocument);
		this.usedWorkflowInvitationTasksQuery = this.injector.get(UsedWorkflowInvitationTasksDocument);
		this.existWorkflowInvitationTasksQuery = this.injector.get(ExistWorkflowInvitationTasksDocument);
		this.insertWorkflowInvitationTasksMutation = this.injector.get(InsertWorkflowInvitationTasksDocument);
		this.updateWorkflowInvitationTasksMutation = this.injector.get(UpdateWorkflowInvitationTasksDocument);
		this.importDataWorkflowInvitationTasksMutation = this.injector.get(ImportDataWorkflowInvitationTasksDocument);
		this.updateManyWorkflowInvitationTasksMutation = this.injector.get(UpdateManyWorkflowInvitationTasksDocument);
		this.deleteWorkflowInvitationTasksMutation = this.injector.get(DeleteWorkflowInvitationTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowInvitationTask> {
    
    		let variables: GetWorkflowInvitationTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowInvitationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowInvitationTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowInvitationTask,
		@Args('filter?') filter?: FilterOfWorkflowInvitationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowInvitationTask> {
    
    		let variables: FirstWorkflowInvitationTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowInvitationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowInvitationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowInvitationTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowInvitationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowInvitationTask,
		@Args('filter?') filter?: FilterOfWorkflowInvitationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowInvitationTask> {
    
    		let variables: FindWorkflowInvitationTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowInvitationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowInvitationTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowInvitationTask> {
    
    		let variables: SearchWorkflowInvitationTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowInvitationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowInvitationTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowInvitationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowInvitationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowInvitationTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowInvitationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowInvitationTask,
		@Args('filter?') filter?: FilterOfWorkflowInvitationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowInvitationTask> {
    
    		let variables: CustomQueryWorkflowInvitationTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowInvitationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowInvitationTask,
		@Args('filter?') filter?: FilterOfWorkflowInvitationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowInvitationTask> {
    
    		let variables: CustomQueryIdWorkflowInvitationTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowInvitationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowInvitationTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowInvitationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowInvitationTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowInvitationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowInvitationTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowInvitationTask> {
    
    		let variables: InsertWorkflowInvitationTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowInvitationTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowInvitationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowInvitationTask> {
    
    		let variables: UpdateWorkflowInvitationTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowInvitationTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowInvitationTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowInvitationTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowInvitationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowInvitationTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowInvitationTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowInvitationTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowInvitationTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitationTasks.delete));
    	}
    
}