import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

/** Représente le context de Let. */
interface LetContext<T> {
    ngLet: T;
}
/** Permet de créer une variable local. */
@Directive({
    selector: '[ngLet]'
})
export class LetDirective<T> {
    private _context: LetContext<T> = {ngLet: null};

    constructor(_viewContainer: ViewContainerRef, _templateRef: TemplateRef<LetContext<T>>) {
        _viewContainer.createEmbeddedView(_templateRef, this._context);
    }

    @Input()
    set ngLet(value: T) {
        this._context.ngLet = value;
    }
}
