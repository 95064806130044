import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLifeCycleReasonsBaseVariables, FirstLifeCycleReasonsBaseVariables, CountLifeCycleReasonsBaseVariables, FindLifeCycleReasonsBaseVariables, SearchLifeCycleReasonsBaseVariables, ExportSchemaLifeCycleReasonsBaseVariables, ExportDataLifeCycleReasonsBaseVariables, CustomQueryLifeCycleReasonsBaseVariables, CustomQueryIdLifeCycleReasonsBaseVariables, UsedLifeCycleReasonsBaseVariables, ExistLifeCycleReasonsBaseVariables, InsertLifeCycleReasonsBaseVariables, UpdateLifeCycleReasonsBaseVariables, ImportDataLifeCycleReasonsBaseVariables, UpdateManyLifeCycleReasonsBaseVariables, DeleteLifeCycleReasonsBaseVariables } from '../gqls'
import { GetLifeCycleReasonsDocument, FirstLifeCycleReasonsDocument, CountLifeCycleReasonsDocument, FindLifeCycleReasonsDocument, SearchLifeCycleReasonsDocument, ExportSchemaLifeCycleReasonsDocument, ExportDataLifeCycleReasonsDocument, CustomQueryLifeCycleReasonsDocument, CustomQueryIdLifeCycleReasonsDocument, UsedLifeCycleReasonsDocument, ExistLifeCycleReasonsDocument, InsertLifeCycleReasonsDocument, UpdateLifeCycleReasonsDocument, ImportDataLifeCycleReasonsDocument, UpdateManyLifeCycleReasonsDocument, DeleteLifeCycleReasonsDocument } from '../gqls'
import { ServiceSingleResultOfLifeCycleReason, QueryContextOfLifeCycleReason, FilterOfLifeCycleReason, ServiceSingleResultOfInt64, ServiceListResultOfLifeCycleReason, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, LifeCycleReasonInput, FieldUpdateOperatorOfLifeCycleReason } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LifeCycleReasonBaseService {
    
public modelName = 'lifeCycleReason';
public modelPluralName = 'lifeCycleReasons';

	private getLifeCycleReasonsQuery: GetLifeCycleReasonsDocument;
	private firstLifeCycleReasonsQuery: FirstLifeCycleReasonsDocument;
	private countLifeCycleReasonsQuery: CountLifeCycleReasonsDocument;
	private findLifeCycleReasonsQuery: FindLifeCycleReasonsDocument;
	private searchLifeCycleReasonsQuery: SearchLifeCycleReasonsDocument;
	private exportSchemaLifeCycleReasonsQuery: ExportSchemaLifeCycleReasonsDocument;
	private exportDataLifeCycleReasonsQuery: ExportDataLifeCycleReasonsDocument;
	private customQueryLifeCycleReasonsQuery: CustomQueryLifeCycleReasonsDocument;
	private customQueryIdLifeCycleReasonsQuery: CustomQueryIdLifeCycleReasonsDocument;
	private usedLifeCycleReasonsQuery: UsedLifeCycleReasonsDocument;
	private existLifeCycleReasonsQuery: ExistLifeCycleReasonsDocument;
	private insertLifeCycleReasonsMutation: InsertLifeCycleReasonsDocument;
	private updateLifeCycleReasonsMutation: UpdateLifeCycleReasonsDocument;
	private importDataLifeCycleReasonsMutation: ImportDataLifeCycleReasonsDocument;
	private updateManyLifeCycleReasonsMutation: UpdateManyLifeCycleReasonsDocument;
	private deleteLifeCycleReasonsMutation: DeleteLifeCycleReasonsDocument;

	constructor(private injector: Injector) {
		this.getLifeCycleReasonsQuery = this.injector.get(GetLifeCycleReasonsDocument);
		this.firstLifeCycleReasonsQuery = this.injector.get(FirstLifeCycleReasonsDocument);
		this.countLifeCycleReasonsQuery = this.injector.get(CountLifeCycleReasonsDocument);
		this.findLifeCycleReasonsQuery = this.injector.get(FindLifeCycleReasonsDocument);
		this.searchLifeCycleReasonsQuery = this.injector.get(SearchLifeCycleReasonsDocument);
		this.exportSchemaLifeCycleReasonsQuery = this.injector.get(ExportSchemaLifeCycleReasonsDocument);
		this.exportDataLifeCycleReasonsQuery = this.injector.get(ExportDataLifeCycleReasonsDocument);
		this.customQueryLifeCycleReasonsQuery = this.injector.get(CustomQueryLifeCycleReasonsDocument);
		this.customQueryIdLifeCycleReasonsQuery = this.injector.get(CustomQueryIdLifeCycleReasonsDocument);
		this.usedLifeCycleReasonsQuery = this.injector.get(UsedLifeCycleReasonsDocument);
		this.existLifeCycleReasonsQuery = this.injector.get(ExistLifeCycleReasonsDocument);
		this.insertLifeCycleReasonsMutation = this.injector.get(InsertLifeCycleReasonsDocument);
		this.updateLifeCycleReasonsMutation = this.injector.get(UpdateLifeCycleReasonsDocument);
		this.importDataLifeCycleReasonsMutation = this.injector.get(ImportDataLifeCycleReasonsDocument);
		this.updateManyLifeCycleReasonsMutation = this.injector.get(UpdateManyLifeCycleReasonsDocument);
		this.deleteLifeCycleReasonsMutation = this.injector.get(DeleteLifeCycleReasonsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLifeCycleReason> {
    
    		let variables: GetLifeCycleReasonsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLifeCycleReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.get));
            }
            else{
                let result:ServiceSingleResultOfLifeCycleReason={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLifeCycleReason,
		@Args('filter?') filter?: FilterOfLifeCycleReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLifeCycleReason> {
    
    		let variables: FirstLifeCycleReasonsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLifeCycleReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLifeCycleReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLifeCycleReasonsBaseVariables = {
    			filter: filter
    		}
    				return this.countLifeCycleReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLifeCycleReason,
		@Args('filter?') filter?: FilterOfLifeCycleReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLifeCycleReason> {
    
    		let variables: FindLifeCycleReasonsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLifeCycleReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLifeCycleReason,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLifeCycleReason> {
    
    		let variables: SearchLifeCycleReasonsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLifeCycleReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLifeCycleReasonsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLifeCycleReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLifeCycleReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLifeCycleReasonsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLifeCycleReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLifeCycleReason,
		@Args('filter?') filter?: FilterOfLifeCycleReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLifeCycleReason> {
    
    		let variables: CustomQueryLifeCycleReasonsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLifeCycleReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLifeCycleReason,
		@Args('filter?') filter?: FilterOfLifeCycleReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLifeCycleReason> {
    
    		let variables: CustomQueryIdLifeCycleReasonsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLifeCycleReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLifeCycleReasonsBaseVariables = {
    			ids: ids
    		}
    				return this.usedLifeCycleReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLifeCycleReasonsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLifeCycleReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: LifeCycleReasonInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLifeCycleReason> {
    
    		let variables: InsertLifeCycleReasonsBaseVariables = {
    			entity: entity
    		}
    				return this.insertLifeCycleReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLifeCycleReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLifeCycleReason> {
    
    		let variables: UpdateLifeCycleReasonsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateLifeCycleReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataLifeCycleReasonsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataLifeCycleReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfLifeCycleReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyLifeCycleReasonsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyLifeCycleReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteLifeCycleReasonsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteLifeCycleReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleReasons.delete));
    	}
    
}