import { LoadOptions } from 'devextreme/data/load_options';
import { Observable, Subject } from 'rxjs';
import { QueryFilterInputType, QueryOptionsInputType } from './graphql-types';
import { ServiceListResult, ServiceSingleResult } from './graphql.service';

/**
 * Représente la configuration du store.
 */
export interface StoreConfig {
  /**
   * Obtient ou définit les paramètres supplémentaires.
   */
  parameters?: any[];
  /**
   * Obtient ou définit le nom de la clé.
   */
  key?: string;
  /**
   * Obtient ou définit les filtres
   */
  filters?: any[];
  /**
   * Obtient les options du load.
   */
  options?: LoadOptions;
  /**
   * Obtient ou définit l'id pour une relation ManytoMany
   */
  manyToManyId?: string;
  /** Obtient ou définit les paramètres du pooling */
  poolingOptions?: {
    query: any;
  };
}

/**
 * Représente la configuration d'un store graphql.
 */
export interface GraphQLStoreConfig<TType, TInputType> extends StoreConfig {
  //query2?: { query:QueryRef<TType>, result:Observable<ServiceListResult<TType>>};
  /**
   * Obtient ou définit la requête à executer.
   */
  query: (
    filters?: QueryFilterInputType[],
    options?: QueryOptionsInputType,
    ...args
  ) =>
    | ServiceListResult<TType>
    | Promise<ServiceListResult<TType>>
    | Observable<ServiceListResult<TType>>;
  /**
   * Callback exécuté à la fin du load.
   */
  //load?:(result:ServiceListResult<TType>) => void;
  insert?: (entry: TInputType) => Observable<ServiceSingleResult<boolean>>;
  update?: (
    id: string,
    entry: TInputType,
  ) => Observable<ServiceSingleResult<boolean>>;
  delete?: (id: string) => Observable<ServiceSingleResult<boolean>>;
}
/**
 * Représente l'interface d'implémentation du store.
 */
export interface IQueryableStore {
  loadMode: string;
  /**
   * Obtient ou définit le nom de la clé.
   */
  key: string | string[];
  /** Obtient ou définit le suivi des erreurs. */
  errors: Subject<any>;
  /**
   * Charge les données.
   * @param args les arguments du chargement.
   */
  load(...args: any[]): Promise<any>;
  /**
   * Insert l'élément
   * @param args Les arguments d'insertion.
   */
  insert(...args: any[]): Promise<any>;
  /**
   * Supprime l'élément.
   * @param args Les arguments de suppression.
   */
  remove(...args: any[]): Promise<any>;

  /**
   * Met à jour l'élément.
   * @param args Les arguments de mise à jour.
   */
  update(...args: any[]): Promise<any>;
  /**
   * Retrouve la donnée.
   *
   * @param {...any[]} args Les arguments de la récupération.
   */
  byKey(...args: any[]): Promise<any>;
}
/**
 * La classe wrapper du store. Utiliser pour garder le context d'exécution.
 */
export class WrapperStore implements IQueryableStore {
  errors: Subject<any>;
  /** @inheritdoc */
  public get loadMode(): any {
    return this.context.loadMode;
  }
  public set loadMode(value: any) {
    this.context.loadMode = value;
  }
  /**
   * Le context d'exécution.
   * @private
   * @type {IQueryableStore}
   * @memberof WrapperStore
   */
  private context: IQueryableStore;
  constructor(context: IQueryableStore) {
    this.context = context;
    this.errors = new Subject();
    this.context.errors = this.errors;
  }

  /** @inheritdoc */
  public get key(): any {
    return this.context.key;
  }
  public set key(value: any) {
    this.context.key = value;
  }
  /** @inheritdoc */
  byKey(...args: any[]): Promise<any> {
    return this.context.byKey.apply(this.context, args);
  }
  /** @inheritdoc */
  load(...args: any[]): Promise<any> {
    return this.context.load.apply(this.context, args);
  }
  /** @inheritdoc */
  insert(...args: any[]): Promise<any> {
    return this.context.insert.apply(this.context, args);
  }
  /** @inheritdoc */
  remove(...args: any[]): Promise<any> {
    return this.context.remove.apply(this.context, args);
  }
  /** @inheritdoc */
  update(...args: any[]): Promise<any> {
    return this.context.update.apply(this.context, args);
  }
}
